import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
//import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { UserService } from "src/app/shared/services/user.service";
import { ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { ToastService } from "../../../shared/services/toastr.service";


@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  profileForm: UntypedFormGroup;
  googleCustomer: GoogleCustomer;
  minDate: string;
  currentUrl: string;

  getProfileSubscription: Subscription;
  onProfileUpdateSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private api: APIFactoryEndPoints,
    private toastService: ToastService,
    // private OAuth: SocialAuthService,
    private route: ActivatedRoute
  ) {
    const today = new Date();
    // Subtract 12 years from the current date
    const minYear = today.getFullYear() - 12;
    this.minDate = new Date(minYear, today.getMonth(), today.getDate()).toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname
    this.createProfileForm();
    this.getProfileDetails();
  }

  createProfileForm() {
    this.profileForm = this.formBuilder.group({
      id: [""],
      idToken: [""],
      name: [""],
      firstName: [""],
      lastName: [""],
      dob: [""],
      provider: [""],
      spouseName: [""],
      spouseDob: [""],
      anniversaryDate: [""],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phoneNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
    });
  }

  getProfileDetails() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.getProfileSubscription = this.userService.getProfiledetails(this.api.getGoogleCustomer, this.googleCustomer.email, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data != null) {
          this.profileForm.patchValue({
            id: data.id,
            name: data.name,
            firstName: data.name,
            lastName: data.name,
            dob: data.dob,
            email: data.email,
            phoneNo: data.phoneNo,
            provider: data.provider,
            spouseName: data.spouseName,
            spouseDob: data.spouseDob,
            anniversaryDate: data.anniversaryDate,
          });
        }
      });
  }

  onProfileUpdateClick() {
    if (this.profileForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }

    this.profileForm.patchValue({
      idToken: this.googleCustomer.idToken,
    });

    this.onProfileUpdateSubscription = this.userService
      .insertProfile(this.api.insertGoogleCustomer, this.profileForm)
      .subscribe((data) => {
        this.toastService.showSuccessToast("Updated Successfully");
      });
  }
}
