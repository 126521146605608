import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { CartDetails } from "../models/cartdetails";
import { FBCustomer } from "../models/fbcustomer";
import { GoogleCustomer } from "../models/googlecustomer";
import { User } from "../models/user";
import { WishListDetails } from "../models/wishlistdetails";
import { Product } from "../models/product";
import { Observable } from "rxjs";
import { WhatsAppResponse } from "../models/whats-app-response";
import { DatePipe } from "@angular/common";
import { WebOrderCustomProduct } from "../models/web-order-custom-product";

@Injectable({
  providedIn: "root",
})
export class UserService {


  selectedUser: User = new User();
  // users: AngularFireList<User>;

  name: string;

  checkLogin: string;

  location = {
    lat: 0,
    lon: 0,
  };

  // constructor(private db: AngularFireDatabase) {
  // //   this.getUsers();
  // }

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) {

    // this.getUsers();
  }

  // getUsers() {
  //   this.users = this.db.list("clients");
  //   return this.users;
  // }

  getUserById(id: string) { }

  // createUser(data: any) {
  //   const updatedData = {
  //     ...data,
  //     location: this.location,
  //     createdOn: moment(new Date()).format("X"),
  //     isAdmin: false,
  //   };
  //   this.users.push(updatedData);
  // }

  // isAdmin(emailId: string) {
  //   return this.db.list("clients", (ref) =>
  //     ref.orderByChild("email").equalTo(emailId)
  //   );
  // }

  // updateUser(user: User) {
  //   this.users.update(user.$key, user);
  // }

  setLocation(lat: any, lon: any) {
    this.location = { lat, lon };
  }

  getLocation() {
    return this.location;
  }

  setCheckLoggedIn(checkLogin: string) {
    this.checkLogin = checkLogin;
  }

  getCheckLoggedIn() {
    return this.checkLogin;
  }

  insertCartSetUp(url: string, req: CartDetails) {
    return this.http.post(url, req, { responseType: "text" });
  }

  // Method to handle product and file uploads
  addProductToCartWithFiles(url: string, cartDetails: CartDetails, files?: File[]): Observable<any> {
    const formData = new FormData();

    // Prepare and append the product data
    formData.append('product', new Blob([JSON.stringify(cartDetails)], { type: 'application/json' }));
    // Append the File to FormData
    if (files) {
      files.forEach(file => {
        formData.append('files', file);
      })
    } else {
      formData.append('files', null);
    }
    return this.http.post(url, formData, { responseType: "text" });
  }

  addCustomCakeWithFile(url: string, form: AbstractControl, file: File): Observable<any> {
    const formData = new FormData();

    // Prepare and append the product data
    formData.append('custom', new Blob([JSON.stringify(form.value)], { type: 'application/json' }));
    // Append the File to FormData
    if (file) {
      formData.append('file', file);
    } else {
      formData.append('file', null);
    }
    return this.http.post(url, formData, { responseType: "json" });
  }

  removeProductCart(url: string, email: string, id: number) {
    return this.http.post<any>(url + "/" + email + "/" + id, {
      responseType: "json",
    });
  }

  removeDesignProductCart(url: string, email: string, design: Product) {
    return this.http.post<any>(url + "/" + email, design, {
      responseType: "json",
    });
  }

  removeAllProductCart(url: string, email: string, id: number[]) {
    return this.http.post<any>(url + "/" + email + "/" + id, {
      responseType: "text",
    });
  }

  emptyProductCart(url: string, email: string) {
    return this.http.post<any>(url + "/" + email, { responseType: "text" });
  }

  getProductCart(url: string, email: string) {
    return this.http.get<any>(url + "/" + email, { responseType: "json" });
  }

  insertWishlistSetUp(url: string, req: WishListDetails) {
    return this.http.post(url, req, { responseType: "text" });
  }

  onGoogleSetUp(url: string, req: GoogleCustomer) {
    return this.http.post(url, req, { responseType: "json" });
  }

  onFbSetUp(url: string, req: FBCustomer) {
    return this.http.post(url, req, { responseType: "json" });
  }

  getProfiledetails(url: string, email: string, currentUrl: string) {
    return this.http.get<any>(url + "/" + email + "/" + currentUrl, { responseType: "json" });
  }

  insertProfile(url: string, req: UntypedFormGroup) {
    return this.http.post<any>(url, req.value, { responseType: "json" });
  }

  sendWhatsAppOptInByMobileNo(url: string, companyId: string, mobileNo: string) {
    return this.http.get(url + "/" + companyId + "/" + mobileNo, {
      responseType: "json",
    });
  }

  validateCustomer(url: string, email: string, mobileNo: string) {
    return this.http.get(url + "/" + email + "/" + mobileNo, {
      responseType: "json",
    });
  }

  private encryptedOtp: string = '';
  sendWhatsAppOTPByMobileNo(url: string, companyId: string, mobileNo: string, otp: string) {
    this.encryptFun(otp);
    return this.http.get(url + "/" + companyId + "/" + mobileNo + "/" + this.encryptedOtp, {
      responseType: "json",
    });
  }

  encryptFun(data) {
    if (data.length > 9 || data.length < 5)
      return;
    var sum = 0;
    var newData = "";
    var newDataFinal = "";
    var sumz = "";
    const date = new Date();
    const day = this.datePipe.transform(date, 'dd');
    let ascV = 0;
    for (let i = 0; i < data.length; i++) {

      switch (Number(data.charAt(i))) {
        case 0: ascV = 59; break;
        case 1: ascV = 62; break;
        case 2: ascV = 35; break;
        case 3: ascV = 78; break;
        case 4: ascV = 67; break;
        case 5: ascV = 71; break;
        case 6: ascV = 46; break;
        case 7: ascV = 29; break;
        case 8: ascV = 53; break;
        default: ascV = 92; break;
      }
      sum += ascV;
      newData += ascV.toString();
    }
    sum += Number(day);
    for (let i = 0; i < newData.length; i++) {
      newDataFinal += newData.toString().charAt(i);
      if (i == 2)
        newDataFinal += sum.toString().charAt(0);
      if (i == 5)
        newDataFinal += sum.toString().charAt(1);
      if (i == 8)
        newDataFinal += sum.toString().charAt(2);
    }

    newDataFinal = sum.toString() + newDataFinal + data.length.toString();
    this.encryptedOtp = newDataFinal;

    // alert(sum+"==="+data+"==="+newData+"==="+newDataFinal)
  }

  checkOTPSentOrNot(url: string, obj: string): Observable<any> {
    return this.http.get<string>(url + "/" + obj);
  }

  getWhatsAppOTPResponseByMobileNo(url: string, mobileNo: string) {
    return this.http.get<WhatsAppResponse[]>(url + "/" + mobileNo, {
      responseType: "json",
    });
  }

  saveVisitorCount(sendUpiPayment: string, userCnt: number) {
    return this.http.get(sendUpiPayment + "/" + userCnt, {
      responseType: "json",
    });
  }

  getVisitorCount(api: string, type: string) {
    return this.http.get(api + "/" + type, {
      responseType: "json",
    });
  }

  deleteCustomCake(url: string, refNo: number) {
    return this.http.get<any>(url + '/' + refNo);
  }

  getCustomCakeByEmail(url: string, email: string) {
    return this.http.get<WebOrderCustomProduct[]>(url + '/' + email);
  }

  getCustomCakeByRefNo(url: string, refNo: number) {
    return this.http.get<WebOrderCustomProduct>(url + '/' + refNo);
  }

  updateCustomerStatus(url: string, customerCode: string, status: string) {
    return this.http.get(url + '/' + customerCode + '/' + status);
  }
}
