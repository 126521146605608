import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { Product } from "src/app/shared/models/product";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product-dialog",
  templateUrl: "./product-dialog.component.html",
  styleUrls: ["./product-dialog.component.scss"],
})
export class ProductDialogComponent implements OnInit {
  product: Product = new Product();

  webUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public productValue: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private navRoute: Router
  ) {}

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.onProductDetails();
  }

  onProductDetails() {
    this.product = this.productValue;
  }
}
