import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { FeedbackDetailsDto } from 'src/app/shared/models/feedback-details-dto';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { MyCouponService } from 'src/app/shared/services/my-coupon.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { ImagePreviewDialogComponent } from '../image-preview-dialog/image-preview-dialog.component';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-details',
  templateUrl: './feedback-details.component.html',
  styleUrls: ['./feedback-details.component.scss']
})
export class FeedbackDetailsComponent implements OnInit {

  feedbackData: FeedbackDetailsDto[] = [];
  googleCustomer: GoogleCustomer;
  expandedIndex: number | null = null;
  activeSort: string = '';

  //Subscription
  private onViewSetUpSubscription: Subscription;
  private viewSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private myCouponService: MyCouponService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.getFeedbackDetailsByEmail();
  }

  getFeedbackDetailsByEmail() {
    var customerEmail: string;
    if (this.googleCustomer != null) {
      customerEmail = this.googleCustomer.email;
    }

    this.onViewSetUpSubscription = this.myCouponService.getFeedbackDetailsByEmail(this.apiFactory.getFeedbackDetailsByEmail, customerEmail)
      .subscribe((data: FeedbackDetailsDto[]) => {
        if (data.length > 0) {
          this.feedbackData = data;
        } else {
          this.toastService.showErrorToast("No Data Available.");
        }

      });
  }

  toggleCard(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  getImage(feedBack: any, event: Event): void {
    event.stopPropagation();
    if (feedBack.imageFileName) {
      this.viewSubscription = this.myCouponService.getImage(this.apiFactory.getServerUrl, feedBack.imageFileName).subscribe((data) => {
        if (!data || !(data instanceof ArrayBuffer)) {
          this.toastService.showErrorToast("Image Not Found.");
          return;
        }

        const file = new Blob([data], { type: 'image/jpeg' });
        const fileURL = URL.createObjectURL(file);
        let image: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(fileURL);
        if (image) {
          const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
            width: '500px',
            height: 'max-content',
            autoFocus: false,
            data: { image }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
          });
        } else {
          this.toastService.showErrorToast("Image Not Found.");
        }
      });
    } else {
      this.toastService.showErrorToast("Image Not Found.");
    }
  }

  getVideo(feedBack: any, event: Event): void {
    event.stopPropagation();
    if (feedBack.videoFileName) {
      this.viewSubscription = this.myCouponService.getImage(this.apiFactory.getServerUrl, feedBack.videoFileName).subscribe((data) => {
        if (!data || !(data instanceof ArrayBuffer)) {
          this.toastService.showErrorToast("Video Not Found.");
          return;
        }

        const file = new Blob([data], { type: 'video/mp4' });
        const fileURL = URL.createObjectURL(file);
        let video: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(fileURL);
        if (video) {
          const dialogRef = this.dialog.open(ImagePreviewDialogComponent, {
            width: '500px',
            height: 'max-content',
            autoFocus: false,
            data: { video }
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result) {

            }
          });
        } else {
          this.toastService.showErrorToast("Video Not Found.");
        }
      });
    } else {
      this.toastService.showErrorToast("Video Not Found.");
    }
  }

  sortProducts(criteria: string) {
    this.activeSort = criteria;
    if (criteria === 'lowToHigh') {
      this.feedbackData.sort((a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime());
    } else if (criteria === 'highToLow') {
      this.feedbackData.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());
    } else if (criteria === 'recommended') {
    }
  }

}
