export class ViewOfferSetUpDto {
    schemeName: string;
    schemeType: string;
    offerId: string;
    productCode: number;
    productName: string;
    netSalePrice: number;
    maxQtyPerBill: number;
    minQty: number;
    toTime: string;
    checked: boolean;
}