<div style="margin-top: 2%;">
    <div class="section-heading">
        <h2>Sign-up</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="form-style" id="contact_form" class="col-lg-12 col-md-12 text-center profile-line">
    <form [formGroup]="profileForm" (ngSubmit)="onProfileUpdateClick()">
        <div class="form-group">

            <div class="input-wrap">
                <input type="text" formControlName="firstName" required />
                <label>First Name</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="lastName" required />
                <label>Last Name</label>
            </div>

            <div class="input-wrap">
                <input type="text" onfocus="(this.type='date')" formControlName="dob" required />
                <label>D.O.B</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="phoneNo" required />
                <label>Mobile</label>
            </div>

            <div class="input-wrap">
                <input type="Email" formControlName="email" required />
                <label>Email</label>
            </div>

            <!-- <div class="input-wrap">
                <input type="text" formControlName="spouseName" />
                <label>Spouse Name</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="spouseDob" onfocus="(this.type='date')" />
                <label>Spouse D.O.B</label>
            </div> -->
            <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white;">
                    CREATE
                </button> CREATE
            </a>
        </div>
    </form>
</div>