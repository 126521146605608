<form [formGroup]="createSetUpForm">
  <div class="section-heading">
    <h5>Search Delivery Area or PIN</h5>
  </div>
  <div style="display: flex;justify-content: center;">
    <mat-card class="contain_card" fxFlex="100">
      <mat-form-field class="example-full-width" style="width: 100%;" visivility="none">
        <input type="text" style="font-size: 14px;" placeholder="Search Area or PIN" matInput [matAutocomplete]="auto"
          [formControl]="myControl" (keyup)="onSearchAreaName($any($event.target).value)">

        <mat-icon matSuffix *ngIf="myControl.value" (click)="clearSearch()" class="clear-icon">
          close
        </mat-icon>

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onAreaSelect($event.option.value)'>
          <mat-option *ngFor="let element of filteredOptions | async " [value]="element">
            <div class="area-name">{{element.areaName}},{{element.cityName}}</div>
            <div class="pin-text">
              {{element.pincode}}
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-card>

    <!-- <input type="text" style="width: 40%; margin-left: 30%; text-align: center" name="pincode"
      class="form-control input-field" maxlength="6" placeholder="Pin Code" formControlName="pincode"
      (keyup)="choosePincode()" required /> -->
  </div>

  <div *ngIf="showToast">
    <p class="h7">
      Delivery to this area {{ areaName }} is currently not available
    </p>
  </div>

  <div class="section-heading" style="max-height: 30px" *ngIf="!showClickButton">
    <h6 style="margin-top: 15px">Select Preferred Location</h6>
  </div>
  <div style="max-height: 200px;overflow: auto;" *ngIf="!showClickButton">
    <!-- <div class="col-lg-12" *ngFor="let item of locationSetupList" -->
    <div class="col-lg-12" style="display: flex;height: 130px;align-items: center;">
      <div class="location_div">
        <div class="location-name">
          {{ locationSetup.locationName }}
        </div>
        <div *ngIf="locationSetup.address1" class="address1">
          {{ locationSetup.address1 }}
        </div>
        <div *ngIf="locationSetup.address2" class="address2">
          {{ locationSetup.address2 }}
        </div>
        <div *ngIf="locationSetup.areaNames" class="pincode">
          {{ locationSetup.areaNames }}
        </div>
        <div *ngIf="locationSetup.pincode" class="pincode">
          {{ locationSetup.pincode }}
        </div>
        <div class="proceed_div">
          <button (click)="onCreateSetUpClick(locationSetup)" type="button" class="proceed_btn">
            Proceed
            <mat-icon class="proceed_icon">arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

</form>