import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { FileService } from "src/app/common/File-Service";
import { WebOrderCareerSetUp } from "src/app/shared/models/webordercareersetup";
import { CarrerService } from "src/app/shared/services/carrer.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { getWindow } from "ssr-window";

export interface PeriodicElement {
  name: string;
  position: string;
  place: string;
}

@Component({
  selector: "app-career",
  templateUrl: "./career.component.html",
  styleUrls: ["./career.component.scss"],
})
export class CareerComponent implements OnInit {
  displayedColumns: string[] = ["position", "name", "place", "Submit"];
  dataSource;

  uploadedFile: File;
  uploadedFileName: string;
  showfile: boolean = false;

  showCareer: boolean = false;
  showApplay: boolean = false;

  readonly fileBigSize: string = "10Mb Max File Size..";

  readonly uploadFileSize: number = 10485760; //10mb to bytes

  createSetUpForm: UntypedFormGroup;

  careerSetupList: WebOrderCareerSetUp[] = [];

  currentUrl: string;

  //Subscription
  private onCreateSetUpClickSubscription: Subscription;
  private webOrderCareerSubcription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private careerService: CarrerService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private fileService: FileService,
  ) {
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.onCreateSetUp();
    this.getWebOrderCareerSetUpList();
  }

  handleFileInput(files: FileList) {
    if (files[0].size > this.uploadFileSize) alert(this.fileBigSize);
    else {
      this.createSetUpForm.patchValue({
        documentPath: files.item(0).name,
      });
      this.uploadedFile = files.item(0);
      this.uploadedFileName = files.item(0).name;
    }
  }

  removeSelectedFile() {
    this.uploadedFileName = null;
    this.uploadedFile = undefined;
  }

  applayCareer(type: string, index: number) {
    this.uploadedFile = new File([""], "");
    this.uploadedFileName = "";
    this.createSetUpForm.get("documentPath").reset();
    this.createSetUpForm.patchValue({
      position: this.careerSetupList[index].position,
      place: this.careerSetupList[index].place,
    });
    if (type) {
      this.showfile = true;
    } else {
      this.showfile = false;
    }
    this.scrollToElementById("contact");
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phone: [null, [Validators.required, Validators.pattern("[6-9][0-9]{9}")]],
      position: ["", Validators.required],
      place: ["", Validators.required],
      documentPath: ["", Validators.required],
    });
  }

  onCreateSetUpClick() {
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    this.createSetUpForm.patchValue({
      documentPath: this.uploadedFile.name,
    });
    this.onCreateSetUpClickSubscription = this.careerService.sendMessageByMailChimp(this.apiFactory.sendMessageByMailChimp, this.createSetUpForm, this.uploadedFile)
      .subscribe((data) => {
        if (this.createSetUpForm.valid) {
          this.toastService.showSuccessToast("Thank you for your intrest to work with us.");
          this.createSetUpForm.reset();
        }
      });
  }

  getWebOrderCareerSetUpList() {
    // this.webOrderCareerSubcription = this.careerService.getWebOrderCareerSetup(this.apiFactory.getCareerSetupDetails, this.currentUrl)
    //   .subscribe((data) => {
    
    let data: WebOrderCareerSetUp[] = this.fileService.getFileData("careerSetupList");
    if (data.length > 0) {
      this.showCareer = true;
      this.careerSetupList = data;
      console.log(this.careerSetupList);
      this.careerSetupList.forEach(element => {
        if (element.description)
          element.description = "<li>" + element.description.replace('. ', ".<li>");
      })
      this.dataSource = this.careerSetupList;
    } else {
      this.showApplay = false;
    }
    // });
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}