import { Routes, RouterModule } from "@angular/router";
import { PaymentComponent } from './payment.component';
const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "payment-app",
                component: PaymentComponent,
            },
        ],
    },
];

export const PaymentRoutes = RouterModule.forChild(routes);