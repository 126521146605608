<form [formGroup]="createPhoneSetUpForm">
    <div class="section-heading">
        <h5>Verify WhatsApp No</h5>
    </div>
    <div fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 13%; margin-right: 13%; margin-bottom:10px">
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required matInput maxlength="10" placeholder="WhatsApp Number" formControlName="phoneNo"
                    readonly />
            </mat-form-field>
        </mat-card>
    </div>

    <div *ngIf="showHideSendOtpDiv" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 3%; margin-bottom: 10px">
        <button type="button" style="margin-top: 10px; margin-left: 70px" id="compose-button" id="send-button"
            class="btn btn-primary" (click)="sendOtpClick()">
            SEND OTP
        </button>
    </div>

    <div *ngIf="showHideOtpDiv" fxLayout="row" fxLayout.lt-sm="column"
        style="margin-left: 13%; margin-right: 13%; margin-bottom: 10px">
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required matInput maxlength="10" placeholder="Enter Otp"
                    (keyup)="checkOtp($any($event.target).value)" />
            </mat-form-field>
        </mat-card>
    </div>
    <div *ngIf="showResend" style="margin-bottom: 10px;margin-bottom: 5px;text-align: center;font-size: 11px;">
        Didn't Receive OTP?
        <span style="text-align: center; color: #4285f4; cursor: pointer" (click)="sendOtp()">RESEND
            OTP</span>
    </div>
    <div *ngIf="errorText" style="margin-bottom: 10px;margin-bottom: 5px;text-align: center;font-size: 11px;color: red">
        {{errorText}}
    </div>
</form>