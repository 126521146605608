import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  rightBtn: string;
  leftBtn: string;
  showCancel: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    dialogRef.disableClose = true;
    if (dialogData.enableOuterClick) {
      this.showCancel = true;
    } else {
      this.showCancel = false;
    }
    this.rightBtn = dialogData.rightBtn;
    this.leftBtn = dialogData.leftBtn;

    if (!this.rightBtn || !this.leftBtn) {
      this.rightBtn = "CONFIRM";
      this.leftBtn = "BACK";
    }
  }
  ngOnInit(): void {
  }
}
