<!-- <div class="dialog-header">
    <button mat-icon-button [mat-dialog-close]="false">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>Select Time Slot</h2>
  </div>
  
  <div class="dialog-content">
    <p class="delivery-info">Express Delivery - ₹ 19</p>
    <mat-radio-group class="time-slot-group">
      <mat-radio-button value="09:00-14:00">09:00 - 14:00 hrs</mat-radio-button>
      <mat-radio-button value="12:00-17:00">12:00 - 17:00 hrs</mat-radio-button>
      <mat-radio-button value="16:00-21:00">16:00 - 21:00 hrs</mat-radio-button>
      <mat-radio-button value="17:00-23:00">17:00 - 23:00 hrs</mat-radio-button>
    </mat-radio-group>
  </div>
  
  <div class="dialog-actions">
    <button mat-button class="back-to-calendar" [mat-dialog-close]="false">Back to Calendar</button>
  </div> -->





<div style="height: 100%;width: 100%;">
    <div class="section-heading" style="display: flex;justify-content: center;">
        <h5>Select Delivery Date & Slot</h5>
        <i [mat-dialog-close]="false" class="fa fa-close"
            style="color: white;font-size: 18px;cursor: pointer;display: flex;align-items: center;"></i>
    </div>
    <div class="container" style="width: 100%;height: 338px;overflow: auto;">
        <div class="dialog-content">
            <p class="delivery-info">
                <mat-card class="contain_card">
                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                        <input matInput placeholder="Delivery Date" [matDatepicker]="licker1"
                            style="text-align: left; padding-bottom: 2%" [(ngModel)]="product.deliveryDate"
                            min="{{ product.defaultDate | date: 'yyyy-MM-dd' }}"
                            max="{{ product.maxDeliveryDate | date: 'yyyy-MM-dd' }}" (dateChange)="dateChanged()"
                            readonly />
                        <mat-datepicker-toggle matSuffix [for]="licker1"></mat-datepicker-toggle>
                        <mat-datepicker #licker1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                </mat-card>
            </p>
            <mat-radio-group class="time-slot-group" [(ngModel)]="product.deliveryTime">
                <mat-radio-button *ngFor="let d of webOrderDeliveryTimeSlotSetup" [value]="d.timeSlot">{{d.timeSlot}}
                    hrs
                    <span class="offer_span" *ngIf="d.deliveryDiscount > 0"> {{d.deliveryDiscount}}% Off On Delivery
                        Charge</span>
                    <span class="premium_span" *ngIf="d.deliveryPremium > 0"> {{d.deliveryPremium}}% Extra On Delivery
                        Charge</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div style="height: 32px ;display: flex;cursor: pointer;justify-content: center;">
        <button type="button" class="confirm_btn" (click)="onConfirmClick()">
            Confirm
        </button>
    </div>
</div>