<div class="categories categories_sticky">
    <div class="category-item" *ngFor="let category of categorieList"
        [ngClass]="{'selected': selectedCategory === category.name}" (click)="selectCategory(category.name)">
        <img [src]="category.img" [alt]="category.label" class="category-img">
        {{ category.label }}
        <span class="selected-icon" *ngIf="selectedCategory === category.name">✔️</span>
    </div>
</div>

<!-- Products Section -->
<h3 class="h2_font_size" *ngIf="selectedCategory && displayedProducts.length > 0 && selectedCategory != 'customCakes'">
    Products for {{selectedCategoryName }}
</h3>
<div *ngIf="selectedCategory && selectedCategory != 'customCakes'" class="products-wrapper">
    <div *ngIf="displayedProducts.length == 0" class="coming-soon-container">
        <div class="coming-soon-content">
            <h1>Coming Soon</h1>
            <p>Exciting products are on the way! Stay tuned.</p>
        </div>
    </div>
    <div class="products-list" *ngIf="displayedProducts.length > 0">
        <div *ngFor="let product of displayedProducts" class="product-item"
            [ngClass]="{'selected': selectedProduct === product}" (click)="openStorePickupDialog(product)">
            <div class="product-image-wrapper">
                <img src="../../../../assets/img/{{ product.product_Picture }}" alt="Image not found"
                    onerror="this.src='../../../../assets/img/defaultCake.webp';">
                <img style="border-radius: 0px;" *ngIf="product.food_Type == 'Veg'" class="img-responsive img_align"
                    src="../../../../assets/img/Veg.webp" alt="" />
                <img style="border-radius: 0px;" *ngIf="product.food_Type == 'Non-Veg'" class="img-responsive img_align"
                    src="../../../../assets/img/Nonveg.webp" alt="" />

                <img *ngIf="product.offers >= 1" class="img-responsive offer_align"
                    src="../../../../assets/img/Offer 1.webp" alt="" matTooltip="Offers" />
                <span class="product-selected-icon" *ngIf="selectedProduct === product">✔️</span>
            </div>
            <p>{{ product.product_Name }}</p>
        </div>
    </div>

    <!-- Product Details Section -->
    <div class="container" *ngIf="selectedProduct">
        <div class="row" style="display: flex;justify-content: center;">
            <div class="col-sm-4">
                <div class="product-image">
                    <div class="view hm-zoom z-depth-2" id="img_div_align">
                        <img src="../../../../assets/img/{{ selectedProduct.product_Picture }}" alt="Image not found"
                            style="height: 100%" class="img-fluid rounded"
                            onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                        <span style="position: absolute;top: 6%;right: 5%;">
                            <img *ngIf="selectedProduct.food_Type == 'Veg'" style="width: 17px; height: 17px"
                                src="../../../../assets/img/Veg.webp" alt="" />
                            <img *ngIf="selectedProduct.food_Type == 'Non-Veg'" style="width: 17px; height: 17px"
                                src="../../../../assets/img/Nonveg.webp" alt="" />
                        </span>
                        <span style="cursor: pointer;">
                            <img *ngIf="selectedProduct.offers >= 1" class="img-responsive offer_align_product"
                                src="../../../../assets/img/Offer 1.webp" alt=""
                                (click)="viewOfferDetail(selectedProduct.offerDetails)" matTooltip="Offers" />
                        </span>
                    </div>
                </div>
                <h2 class="mbr-section-title h2_font_size">{{ selectedProduct.product_Name }}</h2>
            </div>

            <div class="col-sm-6">
                <div class="product-detail">
                    <form [formGroup]="createSetUpForm" style="margin-left: 1%;">
                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%">
                                    <input matInput placeholder="Price/{{ selectedProduct.product_Unit }}"
                                        formControlName="productPrice"
                                        [value]="createSetUpForm.get('productPrice').value | number: '1.2-2'" readonly
                                        style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>

                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <input matInput type="number"
                                        placeholder="Quantity/{{selectedProduct.product_Unit}}" type="number"
                                        formControlName="qty" (focusout)="onQtySelect($event)"
                                        style="text-align: left" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showComposition">
                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <mat-select placeholder="Flavour" (selectionChange)="onFlavourSelect($event.value)"
                                        formControlName="composition">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let composition of productCompositionList"
                                            [value]="composition.refNo">
                                            {{ composition.compositionName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>

                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <input matInput placeholder="Flavour Premium Price"
                                        formControlName="premiumFlavourPrice"
                                        [value]="createSetUpForm.get('premiumFlavourPrice').value | number: '1.2-2'"
                                        readonly style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card *ngIf="showColor" class="contain_card"
                                [ngClass]="showShape || showDimension ? 'mat-card_style2': 'mat-card_style'">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <mat-select placeholder="Color" formControlName="color">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let color of productColorList" [value]="color.refCode">
                                            {{ color.colorName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>

                            <mat-card *ngIf="showShape" class="contain_card"
                                [ngClass]="showColor || showDimension ? 'mat-card_style2': 'mat-card_style'">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <mat-select placeholder="Shape" formControlName="shape">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let shape of productShapeList" [value]="shape.refNo">
                                            {{ shape.productShape }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>

                            <mat-card *ngIf="showDimension" class="contain_card"
                                [ngClass]="(showColor && showShape) || (!showColor && !showShape) ? 'mat-card_style': 'mat-card_style2'">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <input matInput placeholder="Dimension" formControlName="dimension"
                                        style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <input matInput placeholder="Delivery Date" [matDatepicker]="licker1"
                                        [min]="todayDate" [max]="maxDate" formControlName="deliveryDate"
                                        (click)="licker1.open()" (dateChange)="dateChanged()"
                                        style="text-align: left; padding-bottom: 2%" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="licker1"></mat-datepicker-toggle>
                                    <mat-datepicker #licker1 disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </mat-card>

                            <mat-card class="contain_card mat-card_style2">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <mat-select placeholder="Delivery Time Slot" formControlName="deliveryTime">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let slot of timeSlotAvl" [value]="slot">
                                            {{ slot }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 90%" visivility="none">
                                    <input matInput #input placeholder="Message/Note On Cake" maxlength="40"
                                        formControlName="note" style="text-align: left" />
                                </mat-form-field>
                                <mat-hint style="font-size: 10px;">{{ input.value?.length || 0 }}/40</mat-hint>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>SubTotal</mat-label> -->
                                    <input matInput placeholder="SubTotal" formControlName="subTotal"
                                        [value]="createSetUpForm.get('subTotal').value | number: '1.2-2'"
                                        style="text-align: left; padding-bottom: 2%" readonly />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card mat-card_style"
                                style="height: 90px;display: flex;overflow: auto;">
                                <div *ngFor="let file of fileInputs; let i = index" class="fileData_div">
                                    <span class="span_with_file">
                                        <img [src]="getFileData('fileImage' + i)" alt="Picture"
                                            *ngIf="getFileData('fileImage' + i)" class="image_size" />

                                        <mat-icon *ngIf="!getFileData('fileImage' + i)" class="file_icon_size"> image
                                        </mat-icon>
                                    </span>
                                    <input type="file" readonly
                                        (change)="onChangeUploadFile($event, 'fileImage' + i, 'image' + i)"
                                        accept="image/*" (click)="$any($event.target).value=null"
                                        class="hidden-file-input" [id]="'image' + i">

                                    <button class="custom-file-button" (click)="triggerFileInput('image' + i)">
                                        Choose File
                                    </button>

                                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileImage' + i)"
                                        (click)="onRemoveFileClick('fileImage' + i, 'image' + i);">
                                        clear
                                    </mat-icon>
                                </div>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div_btn">
                            <button type="button" class="btn btn-primary bg-pink"
                                (click)="addToCart(selectedProduct, false)">
                                Add to Cart
                            </button>
                            <button type="button" class="btn btn-primary bg-pink"
                                (click)="addToCart(selectedProduct, true)">
                                Buy Now
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Custom Cakes Section -->
<div class="custom_cake_div" *ngIf="selectedCategory && selectedCategory == 'customCakes' && deliveryType">
    <!-- Product Details Section -->
    <form [formGroup]="createSetUpFormCustomCake">
        <div formArrayName="cakes">
            <div class="container custom_container" *ngFor="let cake of cakes.controls; let i=index"
                [formGroupName]="i">
                <div class="col-sm-4">
                    <div class="custom_product_image">
                        <div class="view hm-zoom z-depth-2" id="img_div_align_custom">
                            <img [src]="getFileDataCustomCake('fileImage',i)" alt="Picture"
                                *ngIf="getFileDataCustomCake('fileImage',i) && !checkCustomRefNo(i)"
                                alt="Image not found" style="height: 100%" class="img-fluid rounded"
                                onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                            <img [src]="cake.value.selectedImageEdit" alt="Picture" *ngIf="checkCustomRefNo(i)"
                                alt="Image not found" style="height: 100%" class="img-fluid rounded"
                                onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                            <span style="position: absolute;top: 6%;right: 5%;">

                            </span>
                            <mat-icon *ngIf="!getFileDataCustomCake('fileImage',i)" class="file_icon_size_custom">
                                image
                            </mat-icon>
                        </div>
                    </div>

                    <div class="terms_div">
                        <label class="checkbox">
                            <input type="checkbox" style="height: 20px; width: 15px" formControlName="isAgreedTerms" />
                            I hereby certify that the uploaded image is not religious, derogatory, or objectionable.
                        </label>
                    </div>

                    <div class="file_select_div">
                        <input type="file" *ngIf="!checkCustomRefNo(i)" readonly
                            (change)="onChangeUploadFileCustomCake($event, 'fileImage', 'image',i)" accept="image/*"
                            (click)="onFileInputClick($event, i)" class="input_file_size" [id]="'image'">

                        <input matInput type="text" *ngIf="checkCustomRefNo(i)" formControlName="fileImage" readonly>

                        <mat-icon class="cancel_icon_custom"
                            *ngIf="getFileDataCustomCake('fileImage',i) && !checkCustomRefNo(i)"
                            (click)="onRemoveFileClickCustomCake('fileImage', 'image',i);">
                            clear
                        </mat-icon>
                    </div>
                    <h2 class="mbr-section-title h2_font_size"></h2>
                </div>

                <div class="col-sm-6 product_div">
                    <div class="product-detail">
                        <div style="margin-left: 1%;">
                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput type="number" placeholder="Quantity" type="number"
                                            formControlName="requestedQty" style="text-align: left" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class=" contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Delivery Date" [matDatepicker]="licker2"
                                            [min]="todayDate" [max]="maxDate" formControlName="requestedDeliveryDate"
                                            (click)="licker2.open()" (dateChange)="customDateChanged(i,'Requested')"
                                            style="text-align: left; padding-bottom: 2%" readonly />
                                        <mat-datepicker-toggle matSuffix [for]="licker2"></mat-datepicker-toggle>
                                        <mat-datepicker #licker2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Delivery Time Slot"
                                            formControlName="requestedDeliveryTime">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let slot of cake.value.requestedSlots" [value]="slot">
                                                {{ slot }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showComposition">
                                <mat-card class="contain_card mat-card_style2">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Flavour"
                                            (selectionChange)="onCustomFlavourSelect($event.value,i)"
                                            formControlName="composition">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let composition of productCompositionList"
                                                [value]="composition.refNo">
                                                {{ composition.compositionName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card mat-card_style2">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Flavour Premium Price"
                                            formControlName="premiumFlavourPrice" readonly
                                            style="text-align: left; padding-bottom: 2%" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card mat-card_style">
                                    <mat-form-field class="example-full-width" style="width: 90%" visivility="none">
                                        <input matInput #input placeholder="Message/Note On Cake" maxlength="40"
                                            formControlName="note" style="text-align: left"
                                            [readonly]="cake.value.refNo" />
                                    </mat-form-field>
                                    <mat-hint style="font-size: 10px;">{{ input.value?.length || 0 }}/40</mat-hint>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div_btn">
                                <button type="button" class="btn btn-primary bg-pink-custom"
                                    *ngIf="!checkCustomRefNo(i)" (click)="openDeliveryTypeDialogForCheckPrice(i)">
                                    Check Price & Delivery Date
                                </button>

                                <button type="button" class="btn btn-primary bg-pink-custom"
                                    *ngIf="checkCustomRefNo(i) && cake.value.status == 'Requested'"
                                    (click)="getCustomCakeByRefNo(i)">
                                    Get Price & Delivery Date
                                </button>

                                <button type="button" class="btn btn-primary bg-pink-custom"
                                    *ngIf="checkCustomRefNo(i) && cake.value.status != 'Requested'">
                                    Approved status
                                </button>
                            </div>

                            <div *ngIf="cake.value.status && cake.value.status != 'Requested'" fxLayout="row"
                                fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput type="number" placeholder="Minimum Quantity" type="number"
                                            formControlName="responseQty" (focusout)="onResponseQtySelect($event,i)"
                                            style="text-align: left" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Expected Delivery Date" [matDatepicker]="licker2"
                                            [min]="todayDate" [max]="maxDate" formControlName="responseDeliveryDate"
                                            (dateChange)="customDateChanged(i,'Response')"
                                            style="text-align: left; padding-bottom: 2%" />
                                        <mat-datepicker-toggle matSuffix [for]="licker2"></mat-datepicker-toggle>
                                        <mat-datepicker #licker2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Delivery Time Slot"
                                            formControlName="responseDeliveryTime">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let slot of cake.value.responseSlots" [value]="slot">
                                                {{ slot }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div *ngIf="cake.value.status && cake.value.status != 'Requested'" fxLayout="row"
                                fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Rate" formControlName="rate"
                                            style="text-align: left; padding-bottom: 2%" readonly />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card mat-card_style3">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Amount" formControlName="totalAmount"
                                            style="text-align: left; padding-bottom: 2%" readonly />
                                    </mat-form-field>
                                </mat-card>

                                <button type="button" *ngIf="cake.value.status == 'Resolved'"
                                    class="btn btn-primary accept_btn" (click)="openDeliveryTypeDialogForCheckPrice(i)">
                                    Accept
                                </button>

                                <button type="button" *ngIf="cake.value.status == 'Accepted'"
                                    class="btn btn-primary accept_btn">
                                    Accepted
                                </button>

                                <button type="button" *ngIf="cake.value.status == 'Declined'"
                                    class="btn btn-primary accept_btn" style="background-color: red;">
                                    Declined
                                </button>
                            </div>

                            <div *ngIf="cake.value.status == 'Accepted'"
                                style="display: flex;justify-content: center;align-items: center;">
                                <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div_btn" style="width: 90%;">
                                    <button type="button" class="btn btn-primary bg-pink"
                                        (click)="addToCartCustomCake(i, false)">
                                        Add to Cart
                                    </button>
                                    <button type="button" class="btn btn-primary bg-pink"
                                        (click)="addToCartCustomCake(i, true)">
                                        Buy Now
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    <button mat-fab color="primary" class="fab-button-delete" (click)="deleteCustomCake(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="add-cake-button">
            <button mat-fab color="primary" class="fab-button" (click)="addCake()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </form>
</div>