<div class="section-heading" style="height: 12%;">
    <h5>Wallet Offers</h5>
</div>

<div style="height: 50%;overflow: auto;">
    <table *ngIf="dataSource.data.length > 0" mat-table [dataSource]="dataSource" class="mat-elevation-z0"
        style="height: 50%;overflow: auto;">
        <ng-container matColumnDef="offerName">
            <th mat-header-cell *matHeaderCellDef>Offer Name</th>
            <td mat-cell *matCellDef="let element">{{ element.walletOfferName }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let element" style="text-align: right;">{{ element.walletAmount }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="text-align: center;">Action</th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: center;color: green;"> Offer
                Applied

                <!-- <mat-radio-button style="width: 20px; height: 20px; accent-color: #db3f4e" [value]="element"
                    (click)="onChangeOffer(element)">
                </mat-radio-button> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <h5 *ngIf="dataSource.data.length == 0" style="color:red;font-size: 18px;">No Offer Available</h5>
</div>
<!-- <mat-button class="btn btn-primary" style="margin-top: 20px; margin-left: 45%" [mat-dialog-close]="offer">OK
</mat-button> -->



<form style="height: 36%;">
    <!-- <div class="section-heading">
        <h5>WALLET CONFIRMATION</h5>
    </div> -->
    <div>
        <span *ngIf="isShowCheckout" style="color: #e91e63;margin-left: 4%;font-size: 16px;">You are using
            {{dialogData.amount}} credits from your wallet</span>
        <span *ngIf="!isShowCheckout" style="color: #e91e63;margin-left: 4%;font-size: 16px;">You are using
            {{dialogData.wallet}} credits from your wallet</span>
    </div>
    <div style="padding-top: 1%;padding-bottom: 4%;">
        <span *ngIf="isShowCheckout" style="color: #e91e63;margin-left: 4%;font-size: 16px;">Do you want to check
            out?</span>
    </div>
    <div>
        <button *ngIf="isShowProceed" type="button" mat-stroked-button color="primary" class="okay"
            (click)="proceedClick()">{{rightBtn}} </button>
        <button *ngIf="!isShowProceed" type="button" mat-stroked-button color="primary" class="okay"
            style="width: 105px !important;font-size: 12px !important;">Please wait..</button>
        <button type="button" mat-stroked-button color="primary" [mat-dialog-close]="false" class="okay"> {{leftBtn}}
        </button>
    </div>
</form>