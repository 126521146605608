import { Routes, RouterModule } from "@angular/router";
import { InvoiceHistoryComponent } from './invoice-history.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "invoice-history-app",
        component: InvoiceHistoryComponent,
      },
    ],
  },
];

export const InvoiceHistoryRoutes = RouterModule.forChild(routes);
