<div style="height: 100%;width: 100%;">
    <div class="section-heading" style="display: flex;justify-content: center;height: 42px;">
        <span class="header_destils">
            <h5 style="width: 100%;">Select Your Design</h5>
            <p class="header_product_name">
                {{designProductSelected.product_Name }}
            </p>
        </span>
        <i (click)="onNoDesignClick()" class="fa fa-close"
            style="color: white;font-size: 20px;cursor: pointer;display: flex;align-items: center;"></i>
    </div>
    <div class="container" style="width: 100%;height: 324px;margin-top: 5px;overflow: auto;">
        <div class="container" style="width: 100%;" *ngIf="productValue.length > 0">
            <!-- <div style="display: flex;flex-wrap: wrap;">
                <div *ngFor="let product of productValue; let i = index" style="width: 100%;">
                    <div class="item" style="width: 33%;">
                        <div class="view overlay hm-white-slight" style="cursor: pointer;"
                            (click)="onProductClick(product)">
                            <img src="{{ serverUrl }}{{ product.product_Picture }}" class="img-fluid"
                                alt="Image not found" width="360px" height="300px"
                                onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                            <span style="position: absolute;top: 6%;right: 5%;">
                                <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp" alt=""
                                    style="width: 20px; height: 20px" />
                                <img *ngIf="product.food_Type == 'Non-Veg'" src="../../../../assets/img/Nonveg.webp"
                                    alt="" style="width: 20px; height: 20px;float: right" />
                            </span>
                        </div>

                        <div class="card-body text-center no-padding" style="padding: 0;">
                            <p class="card-title" style="margin: 0;">
                                <strong style="font-size: 12px;font-weight: normal;">
                                    ₹ {{ product.net_Sale_Price | number: "1.2-2" }}
                                </strong>
                            </p>
                            <p class="card-title product_name">
                                <strong style="height: 80%;">
                                    <a style="color: #e91e63;" (click)="toggle(i)">
                                        {{product.product_Name }}</a>
                                </strong>
                            </p>
                        </div>
                    </div>
                    <div class="expandCollapseContent" *ngIf="hideRuleContent[i]" style="width: 100%;">
                        <div *ngIf="product.product_Name">
                            <div class="view overlay hm-white-slight" style="cursor: pointer;">
                                <img src="{{ serverUrl }}{{ product.product_Picture }}" class="img-fluid"
                                    alt="Image not found" width="200px" height="300px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                <span style="position: absolute;top: 6%;right: 5%;">
                                    <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp" alt=""
                                        style="width: 20px; height: 20px" />
                                    <img *ngIf="product.food_Type == 'Non-Veg'" src="../../../../assets/img/Nonveg.webp"
                                        alt="" style="width: 20px; height: 20px;float: right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->


            <div class="row">
                <div *ngFor="let product of productValue; let i = index" class="col-lg-4 col-md-4 col-sm-4 col-4">
                    <div class="item">
                        <div class="view overlay hm-white-slight" id="div_img"
                            (click)="onProductClick(selectedProductCode == product.product_Code, product)"
                            [class.active_mto]="selectedProductCode === product.product_Code">
                            <div *ngIf="selectedProductCode === product.product_Code && product.productThumbnails.length > 0"
                                class="image-slider">
                                <div class="image-slide" *ngFor="let image of product.productThumbnails; let i = index"
                                    [ngClass]="{'active': i === currentIndex}">
                                    <img src="../../../../assets/img/{{ image.fileName }}" alt="Image {{i + 1}}"
                                        onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                                    <span style="position: absolute;top: 6%;right: 5%;">
                                        <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp"
                                            alt="" style="width: 12px; height: 12px" />
                                        <img *ngIf="product.food_Type == 'Non-Veg'"
                                            src="../../../../assets/img/Nonveg.webp" alt=""
                                            style="width: 12px; height: 12px;float: right" />
                                    </span>
                                </div>
                            </div>

                            <div *ngIf="selectedProductCode != product.product_Code || product.productThumbnails.length == 0"
                                class="image-slider">
                                <img src="../../../../assets/img/{{ product.product_Picture }}" class="img-fluid"
                                    alt="Image not found" width="360px" height="300px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                                <span style="position: absolute;top: 6%;right: 5%;">
                                    <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp" alt=""
                                        style="width: 12px; height: 12px" />
                                    <img *ngIf="product.food_Type == 'Non-Veg'" src="../../../../assets/img/Nonveg.webp"
                                        alt="" style="width: 12px; height: 12px;float: right" />
                                </span>
                            </div>

                            <!-- <mat-icon *ngIf="selectedProductCode === product.product_Code"
                                class="selected_prd_icon_mto">check_circle
                            </mat-icon> -->
                        </div>

                        <div class="card-body text-center no-padding" style="padding: 0;">
                            <p class="card-title" style="margin: 0;">
                                <strong style="font-size: 12px;font-weight: normal;">
                                    ₹ {{ product.net_Sale_Price | number: "1.2-2" }}
                                </strong>
                            </p>
                            <p class="card-title product_name">
                                <strong style="height: 80%;">
                                    <a style="color: #e91e63;">
                                        {{product.product_Name }}</a>
                                </strong>
                            </p>
                        </div>
                    </div>
                    <div class="expandCollapseContent" *ngIf="hideRuleContent[i]">
                        <div *ngIf="product.product_Name">
                            <div class="view overlay hm-white-slight" style="cursor: pointer;">
                                <img src="../../../../assets/img/{{ product.product_Picture }}" class="img-fluid"
                                    alt="Image not found" width="200px" height="300px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="productValue.length === 0" class="col-md-12">
            <h4 class="empty"><i class="i-style">No Design Available</i></h4>
        </div>
    </div>
    <div style="height: 38px ;display: flex;cursor: pointer;justify-content: center;">
        <button type="button" class="confirm_btn" (click)="onConfirmClick()">
            Confirm
        </button>
        <!-- <h5 style="width: 100%;" (click)="onConfirmClick()">Confirm</h5> -->
    </div>
</div>