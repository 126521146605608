import { Component, ElementRef, HostListener, Injectable, OnInit, VERSION, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
// import { StorageType } from "angular-persistence";
//import { SocialAuthService } from "angularx-social-login";
import { filter, forkJoin, interval, map, Observable, startWith, Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { FCMMessageRegistration } from "src/app/shared/models/FCMMessageRegistration";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { Product } from "src/app/shared/models/product";
import { CommonService } from "src/app/shared/services/CommonService";
import { ThemeService } from "src/app/shared/services/theme.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { PinDialogComponent } from "src/app/views/pages/product/pin-dialog/pin-dialog.component";
import { AuthService } from "./../../../../shared/services/auth.service";
import { ProductService } from "./../../../../shared/services/product.service";
import { TranslateService } from "./../../../../shared/services/translate.service";
import { UntypedFormControl } from "@angular/forms";
import { ProductSearchDetail } from "src/app/shared/models/product-search-detail";
import { getWindow } from "ssr-window";
import { SearchProductListComponent } from "src/app/views/pages/product/search-product-list/search-product-list.component";
import { revokeGoogleSignIn } from "src/google-oauth2";
import { StorePickupDialogComponent } from "src/app/views/pages/product/store-pickup-dialog/store-pickup-dialog.component";
import { Constant } from "src/app/shared/models/constant";
declare var $: any;
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
@Injectable({
  providedIn: "root",
})
export class NavbarComponent implements OnInit {

  angularVersion = VERSION;
  googleCustomer = new GoogleCustomer();
  name: string;
  loggedUserName: string;
  showSignIn: boolean = true;
  pincode: string;
  isShowMenu: boolean;

  colorPallet1 = [
    {
      title: "Purple Theme",
      color: "color-purple",
      id: "purple-theme",
    },
    {
      title: "Blue Theme",
      color: "color-blue",
      id: "blue-theme",
    },
  ];

  colorPallet2 = [
    {
      title: "Red Theme",
      color: "color-red",
      id: "red-theme",
    },
    {
      title: "Violet Theme",
      color: "color-violet",
      id: "violet-theme",
    },
  ];
  languageList = [
    { language: "English", langCode: "en" },
    { language: "French", langCode: "fr" },
    { language: "Persian", langCode: "fa" },
    { language: "Japanese", langCode: "ja" },
    { language: "Hindi", langCode: "hin" },
  ];

  lastName: string = "";
  cartList: Product[] = [];
  wishList: Product[] = [];

  persistenceService: any;
  fCMMessageRegistration: any;
  id: any;
  DeviceID: string;
  currentUrl: string;
  formattedUrl: string;

  myControl = new UntypedFormControl();
  filteredOptions: Observable<Product[]>;
  productList: Product[] = [];
  productName: string;
  isNavigate: boolean = false;
  isSearchEnable: boolean = false;
  isMenuOpen: boolean = false;

  private cartSetUpSubscription: Subscription;
  private wishListSubscription: Subscription;
  private intervalSubscription: Subscription;
  private getProductSubscription: Subscription;
  private getCustomerSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public authService: AuthService,
    private router: Router,
    public productService: ProductService,
    public translate: TranslateService,
    private themeService: ThemeService,
    private userService: UserService,
    //private socialAuthService: SocialAuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private commonService: CommonService,
    private constant: Constant,
  ) {
    let googleCustomer: GoogleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    if (googleCustomer && !googleCustomer.refNo) {
      this.logout(false);
    }

  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.formattedUrl = event.urlAfterRedirects.replace("/", "");
      // console.log(this.formattedUrl); // Now it correctly logs the route
    });

    if (this.showSignIn == true) {
      this.onAccountOperation();
    }

    // this.onAccountOperation();
    // const source = interval(2000);
    // let ss = source.subscribe((val) => {
    //   console.log(this.showSignIn);
    //   if (this.showSignIn == true) {
    //     this.onAccountOperation();
    //   }
    //    else {
    //     ss.unsubscribe();
    //     //setTimeout(() => ss.unsubscribe(), 1000);
    //   }
    // });

    this.getCartProduct();
    this.getWishlistProduct();

    if (this.showSignIn == false) {
      if (this.localStorage.getItem("DeviceID") == null) {
        this.DeviceID = crypto["randomUUID"]();
        this.localStorage.setItem("DeviceID", this.DeviceID);
        // console.log("DeviceID1=" + this.DeviceID);
      } else {
        this.DeviceID = this.localStorage.getItem("DeviceID");
        // console.log("DeviceID2=" + this.DeviceID);
      }

      this.fCMMessageRegistration = new FCMMessageRegistration();
      this.fCMMessageRegistration.deviceId = this.DeviceID;
      this.fCMMessageRegistration.locId = 0;
      this.fCMMessageRegistration.locCusId = JSON.parse(this.localStorage.getItem("google_email"));
      this.fCMMessageRegistration.token = this.localStorage.getItem("FirebaseToken");
      this.fCMMessageRegistration.deviceType = "Cus";
      this.fCMMessageRegistration.applicationName = "CUI"
      // console.log(this.fCMMessageRegistration);
      this.commonService.saveFCMRegistrationData(this.apiFactory.getUsersaveTokenIdLocId, this.fCMMessageRegistration).subscribe((data: any) => {
        // console.log("DATA:" + data);
      });
      // console.log(this.fCMMessageRegistration);
    }
  }

  logout(isRefresh: boolean) {
    this.localStorage.removeItem("google_id");
    this.localStorage.removeItem("fb_id");
    this.localStorage.removeItem("google_item");
    this.localStorage.removeItem("pincode_item");
    this.localStorage.removeItem("location_code");
    this.localStorage.removeItem("company_code");
    this.localStorage.removeItem("min_selflife");
    this.localStorage.removeItem("google_loggedIn_details");
    this.localStorage.removeItem("google_email");
    this.localStorage.removeItem("avct_item");
    this.localStorage.removeItem("avf_item");
    this.localStorage.removeItem("matrix_code");
    this.localStorage.removeItem("mto_matrix_code");
    this.localStorage.removeItem("prod_group_code");
    this.localStorage.removeItem("prod_subgroup_code");
    this.localStorage.removeItem("mto_group_code");
    this.localStorage.removeItem("mto_subgroup_code");
    this.localStorage.removeItem("orderFromTvLocation");
    this.localStorage.removeItem("web_user_info");
    this.localStorage.removeItem("signin_resp");
    this.localStorage.removeItem("facebook_signin_resp");
    this.localStorage.removeItem("filterProductItems");
    this.localStorage.removeItem("filterMtoItems");
    // this.localStorage.removeItem("delivery_type");
    this.localStorage.removeItem("pickup_location");
    this.localStorage.removeItem("delivery_location");
    this.localStorage.removeItem("parent_location_code");
    this.localStorage.removeItem("client_ip");
    this.name = null;
    this.onAccountOperation();
    this.router.navigate(["/"]);
    this.showSignIn = true;
    this.isShowB2b = false;

    // this.authService.logout();
    revokeGoogleSignIn();
    if (isRefresh) {
      // this.router.navigate(["/"]);
      window.location.reload();
    }
  }

  getCartProduct() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    if (!this.googleCustomer) {
      return;
    }

    // Fetch both API calls in parallel
    this.cartSetUpSubscription = forkJoin({
      productCart: this.userService.getProductCart(this.apiFactory.getProductCart, this.googleCustomer.email),
      mtoCart: this.userService.getProductCart(this.apiFactory.getMakeToOrderCart, this.googleCustomer.email)
    }).subscribe(({ productCart, mtoCart }) => {

      // Merge both cart items
      this.cartList = [...(mtoCart?.product || []), ...(productCart?.product || [])];

      // Store the updated cart in localStorage
      this.localStorage.setItem("avct_item", JSON.stringify(this.cartList));
    });
  }

  getCartProduct1() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    var productCart: Product[] = [];
    var mtoCart: Product[] = [];
    this.cartList = [];
    if (this.googleCustomer != null || this.googleCustomer != undefined) {
      this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getProductCart, this.googleCustomer.email).subscribe((data: any) => {
        if (data != null) {
          productCart = data.product;
        }
      });

      setTimeout(() => {
        this.cartSetUpSubscription = this.userService.getProductCart(this.apiFactory.getMakeToOrderCart, this.googleCustomer.email).subscribe((data: any) => {
          if (data != null) {
            mtoCart = data?.product;
            this.cartList = mtoCart?.concat(productCart);
          } else {
            this.cartList = productCart;
          }
          this.localStorage.setItem("avct_item", JSON.stringify(this.cartList));
        });
      }, 500);
    }
  }

  getWishlistProduct() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.wishList = [];
    if (this.googleCustomer != null || this.googleCustomer != undefined) {
      this.wishListSubscription = this.userService.getProductCart(this.apiFactory.getProductWishlist, this.googleCustomer.email)
        .subscribe((data: any) => {
          if (data != null) {
            this.wishList = data.product;
            this.localStorage.setItem("avf_item", JSON.stringify(this.wishList));
          }
        });
    }
  }

  setLang(lang: string) {
    this.translate.use(lang).then(() => { });
  }

  updateTheme(theme: string) {
    this.themeService.updateThemeUrl(theme);
  }

  onAccountOperation() {
    if (this.localStorage.getItem("google_item") != null) {
      this.name = JSON.parse(this.localStorage.getItem("google_item"));
      this.id = JSON.parse(this.localStorage.getItem("google_email"));
    }

    if (this.name != null) {
      this.showSignIn = false;
      this.getB2bCustomerByEmail(this.id);
      // console.log("IN " + this.id + "====" + this.showSignIn);
    } else if (this.name == null) {
      // console.log("IOUT " + this.id);
      this.showSignIn = true;
    }
  }

  openDeliveryTypeDialog() {
    const dialogRef = this.dialog.open(StorePickupDialogComponent, {
      width: '300px',
      autoFocus: false,
      panelClass: 'store-dialog-container'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result) {
        if (result === 'storePickup') {
          this.toastService.showSuccessToast("Store changed sucessfully");
          if (this.constant.isDeliveryChangeRoute(this.formattedUrl)) {
            window.location.reload();
          }
        } else if (result === 'delivery') {
          this.openPinDialog();
        }
      }
    });
  }

  openPinDialog() {
    const dialogRef = this.dialog.open(PinDialogComponent, {
      width: "300px",
      // data: product,
      panelClass: "pin-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result?.result == true) {
        this.toastService.showSuccessToast("Pin changed sucessfully");
        // window.location.reload();
        console.log(this.formattedUrl)
        if (this.constant.isDeliveryChangeRoute(this.formattedUrl)) {
          window.location.reload();
        }
      }
    });
  }

  getProductFromInventorytypeNameWeb(productName: string) {
    if (this.isNavigate) {
      this.router.navigate(["/search-product"], {
        state: { data: this.productList },
      });
    }

    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();
    const source = interval(2000);
    this.intervalSubscription = source.subscribe(val => {
      this.intervalSubscription.unsubscribe();

      if (productName) {
        this.getProductSubscription = this.productService.getProductSearchByName(this.apiFactory.getProductByName, this.currentUrl, productName).subscribe((data: Product[]) => {
          this.productList = data;
          this.isNavigate = true;
          console.log(data)
          this.router.navigate(["/search-products"], {
            state: { data: this.productList },
          });

          this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        });
      }
    })
  }

  _filter(value: string): any {
    if (typeof (value) === 'string') {
      let filterValue: string = value.toLowerCase();
      return this.productList.filter((option: ProductSearchDetail) => option.product_Name.toLowerCase().includes(filterValue));
    }
  }

  onSearchProductSelect(productDetails: ProductSearchDetail) {
    if (this.intervalSubscription)
      this.intervalSubscription.unsubscribe();
    this.myControl.reset();
    console.log(productDetails);
    this.productName = productDetails.product_Name;
    this.router.navigate(["/" + productDetails.type + "/" + productDetails.product_Code]);
  }

  onSearchClick($event) {
    $event.stopPropagation();
    this.isSearchEnable = !this.isSearchEnable;
    this.myControl.reset();
    this.filteredOptions = null;
    console.log(this.isSearchEnable);
  }

  hide() {
    // console.log(this.isSearchEnable)
    // this.isSearchEnable = false;
  }

  onGoClick() {
    this.router.navigate(["/search-products"], {
      state: { data: this.productList },
    });
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isSearchEnable = false;
    this.myControl.reset();
  }

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  toggle() {
    var x = document.getElementById("myTopnav");
    x.className = "topnav";
    this.isShowMenu = false;
  }
  loginClick() {
    this.router.navigateByUrl('/login')
  }

  isShowB2b: boolean = false;
  getB2bCustomerByEmail(googleEmailId: string) {
    this.getCustomerSubscription = this.productService.getB2bCustomerByEmail(googleEmailId).subscribe((data) => {
      // console.log(data)
      if (data) {
        this.isShowB2b = true;
      }
      else {
        this.isShowB2b = false;
      }
    });
  }

  navigateToB2b(): void {
    const dataToSend = { name: this.name, id: this.id };
    const queryParams = new URLSearchParams(dataToSend).toString();

    const url = `https://googlesso.mobitrix.net?${queryParams}`;
    window.location.href = url;
  }

  navigateToCart() {
    const hasQueryParams = this.route.snapshot.queryParamMap.keys.length > 0;

    // Navigate to /cart-items without query parameters
    this.router.navigate(['/cart-items']).then(() => {
      if (hasQueryParams) {
        // Reload the page if query parameters were present initially
        window.location.reload();
      }
    });
  }
  whenClickedOnLogo() {

  }
}
