import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { ToastService } from "./toastr.service";

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError = <T>(
  operation?: string,
  result?: T
) => (error: HttpErrorResponse) => Observable<T>;

@Injectable({
  providedIn: "root",
})
export class HttpErrorHandlerService {
  constructor(private errorMsgHandlerService: ToastService) {}

  /** Create curried handleError function that already knows the service name */
  createHandleError =
    (serviceName = "") =>
    <T>(operation = "operation", result = {} as T) =>
      this.handleError(serviceName, operation, result);

  handleError<T>(serviceName = "", operation = "operation", result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      const message =
        error.error instanceof ErrorEvent
          ? error.error.message
          : `server returned code ${error.status} with body "${error.error}"`;

      // log to console instead
      console.error(
        "Http error occured in ::  " +
          serviceName +
          ":  " +
          operation +
          ":  " +
          message
      );
      // Let the app keep running by returning a safe result.
      return of(result);
    };
  }
}
