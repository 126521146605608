<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Order History</h2>
    <div class="hr" style="padding: 4px;"></div>
  </div>
</div>

<div id="table-container"
  style="width: 96% !important; overflow: auto; height: auto; padding-top: 2%;margin-left: 2%;margin-right: 2%;">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0"
    style="border-collapse: collapse; width: 100%">
    <caption></caption>

    <ng-container matColumnDef="DeliveryDate">
      <th scope="col" [attr.rowspan]="2" mat-header-cell *matHeaderCellDef style="width: 15%; display: none !important">
        Order Date
      </th>
      <td mat-cell style="width: 13%" *matCellDef="let element" (click)="element.isExpanded = !element.isExpanded">
        <span style="display: flex;flex-direction: row;justify-content: center;">
          <div>
            {{ element.createdDate | date: 'YYYY-MM-dd HH:mm:ss'}}
          </div>
          <div *ngIf="element.isExpanded">
            <mat-icon class="mat_icon">arrow_drop_up</mat-icon>
          </div>
          <div *ngIf="!element.isExpanded">
            <mat-icon class="mat_icon">arrow_drop_down</mat-icon>
          </div>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="OrderId">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 15%; display: none !important" *matHeaderCellDef>
        Order No
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.orderNo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ShippingId">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 15%; display: none !important" *matHeaderCellDef>
        Shipping No
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.shippingDetails?.orderNo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="OrderAmount">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 5%; display: none !important" *matHeaderCellDef>
        Order Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.totalAmount | number: "1.2-2"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="ShippingAmount">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 5%; display: none !important" *matHeaderCellDef>
        Shipping Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.shippingDetails?.totalAmount | number: "1.2-2"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="TotalAmount">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 5%; display: none !important" *matHeaderCellDef>
        Total Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{element.totalAmount+element.shippingDetails?.totalAmount | number: "1.2-2"}}
      </td>
    </ng-container>

    <ng-container matColumnDef="otp">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 5%; display: none !important" *matHeaderCellDef>
        OTP
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: left;color: green;font-weight: bold;">
        <span *ngIf="!element.isShippingCharge && element.trackingStatus != 'Delivered'">{{element.otp}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="location">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 30%; display: none !important" *matHeaderCellDef>
        Location
      </th>
      <td mat-cell *matCellDef="let element" class="td_loc" style="text-align: left">
        {{element.location?.locationName}},{{element.location?.address}},{{element.location?.pin}}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 12%; display: none !important" *matHeaderCellDef>
        Order Status
      </th>
      <td mat-cell *matCellDef="let element;" style="width: 13%;text-align: center">
        <span *ngIf="!element.isShippingCharge"
          (click)="onStatusClick(element);element.isExpandedStatus = !element.isExpandedStatus"
          style="display: flex;flex-direction: row;justify-content: center;">
          <div>{{ element.trackingStatus }}
          </div>
          <div *ngIf="element.isExpandedStatus">
            <mat-icon class="mat_icon">arrow_drop_up</mat-icon>
          </div>
          <div *ngIf="!element.isExpandedStatus">
            <mat-icon class="mat_icon">arrow_drop_down</mat-icon>
          </div>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="toLocation">
      <th scope="col" mat-header-cell *matHeaderCellDef class="custom-header-cell">
        To Location
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        <ngb-rating [formControl]="locationRateValue" *ngIf="element.trackingStatus == 'Delivered'"
          [readonly]="element.deliveryFeedBackWebDto?.toLocation > 0" [max]="5" (click)="onLocationRateChange(element)"
          [(rate)]="element.deliveryFeedBackWebDto.toLocation" required>
        </ngb-rating>
      </td>
    </ng-container>

    <ng-container matColumnDef="toDeliveryBoy">
      <th scope="col" mat-header-cell *matHeaderCellDef class="custom-header-cell">
        To Delivery Boy
      </th>
      <td mat-cell *matCellDef="let element" style="text-align: center">
        <ngb-rating [formControl]="deliveryRateValue"
          *ngIf="element.trackingStatus == 'Delivered' && !element.dispatchStorePickupDto"
          [readonly]="element.deliveryFeedBackWebDto?.toDeliveryBoy > 0" [max]="5"
          (click)="onDeliveryRateChange(element)" [(rate)]="element.deliveryFeedBackWebDto.toDeliveryBoy" required>
        </ngb-rating>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="FeedBack">
        <th scope="col" mat-header-cell style="width: 30%;" *matHeaderCellDef>Feed Back</th>
        <td mat-cell *matCellDef="let element" style="width: 30%;">
          <span *ngIf="webOrderFeedBackSetup.length != 0">
            <a href="{{ webOrderFeedBackSetup[0].link }}" target="_blank">Share Your Valuable Feed Back</a>
          </span>
        </td>
      </ng-container> -->

    <ng-container matColumnDef="download">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 10%; display: none !important" *matHeaderCellDef>
        Invoice
        <mat-icon style="font-size: 19px; vertical-align: middle">download</mat-icon>
        <!-- <span class="material-icons">download_for_offline</span> -->
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%">
        <a id="invoiceLink" [routerLink]=""
          (click)="generateInvoice(element.location?.refNo,element.invoiceDetails[0]?.invoiceNo,element.invoiceDetails[0]?.invoiceType)">
          {{ element.invoiceDetails[0]?.invoiceNo }}
        </a>
        <a id="invoiceLink" *ngIf="element.invoiceDetails.size == 2" [routerLink]=""
          (click)="
            generateInvoice(element.location?.refNo,element.invoiceDetails[1].invoiceNo,element.invoiceDetails[1]?.invoiceType)">
          {{ element.invoiceDetails[1]?.invoiceNo }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="ShippingInvoiceNo">
      <th scope="col" [attr.rowspan]="2" mat-header-cell style="width: 10%; display: none !important" *matHeaderCellDef>
        Shipping Invoice
        <mat-icon style="font-size: 19px; vertical-align: middle">download</mat-icon>
        <!-- <span class="material-icons">download_for_offline</span> -->
      </th>
      <td mat-cell *matCellDef="let element" style="width: 10%">
        <a id="invoiceLink" [routerLink]=""
          (click)="generateInvoice(element.location?.refNo,element.shippingDetails?.invoiceDetails[0]?.invoiceNo,element.shippingDetails?.invoiceDetails[0]?.invoiceType)">
          {{ element.shippingDetails?.invoiceDetails[0]?.invoiceNo }}
        </a>
      </td>
    </ng-container>

    <!-- Header row first group -->

    <ng-container matColumnDef="header-row-first-group">
      <th scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Order Date
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-second-group">
      <th scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Order No
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-third-group">
      <th scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Shipping No
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-fourth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Order Amount
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-fifth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Shipping Amount
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-sixth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Total Amount
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-seventh-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        OTP
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-eighth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Location
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-ninth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Order Status
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-tenth-group">
      <th style="text-align: center" scope="col" mat-header-cell *matHeaderCellDef [attr.colspan]="2">
        FeedBack
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-eleventh-group">
      <th scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Invoice
        <mat-icon style="font-size: 19px; vertical-align: middle">download</mat-icon>
      </th>
    </ng-container>

    <ng-container matColumnDef="header-row-twelvth-group">
      <th scope="col" mat-header-cell *matHeaderCellDef [attr.rowspan]="2">
        Shipping Invoice
        <mat-icon style="font-size: 19px; vertical-align: middle">download</mat-icon>
      </th>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="viewtableColumns.length">
        <div class="example-element-detail" style="text-align: start;"
          [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
          <div class="example-element-description">
            <span class="example-element-description-attribution">Customer Delivery Details
            </span>

            <table class="mat-table">
              <caption></caption>
              <tr class="mat-header-row" style="height: 20px !important">
                <th scope="col" class="mat-header-cell">Customer Name</th>
                <th scope="col" class="mat-header-cell">Mobile No</th>
                <!-- <th scope="col" class="mat-header-cell">Email</th> -->
                <th scope="col" class="mat-header-cell">Delivery Date</th>
                <th scope="col" class="mat-header-cell">Delivery Time</th>
                <th scope="col" class="mat-header-cell">House Address</th>
                <th scope="col" class="mat-header-cell">Plot No</th>
                <th scope="col" class="mat-header-cell">Area Locality</th>
                <th scope="col" class="mat-header-cell">City</th>
                <th scope="col" class="mat-header-cell">Pincode</th>
              </tr>
              <tr class="mat-row" style="height: 25px !important">
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails ? element.customerDeliveryDetails?.contactName :
                  element.customer?.customerName}}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails ? element.customerDeliveryDetails?.contactMobile :
                  element.customer?.customerMobile}}
                </td>
                <!-- <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails??.email }}
                </td> -->
                <td class="mat-cell" id="text_align">
                  {{ element.deliveryDate }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.deliveryTime }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails?.shippingAddress }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails?.shippingPlotNo }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails?.shippingStreet }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails?.shippingCity.cityName }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.customerDeliveryDetails?.shippingPinCode }}
                </td>
              </tr>
            </table>
          </div>
          <div class="example-element-description">
            <span class="example-element-description-attribution">Product Details
            </span>

            <table class="mat-table">
              <caption></caption>
              <tr class="mat-header-row" style="height: 20px !important">
                <th scope="col" class="mat-header-cell">Product Name</th>
                <th scope="col" class="mat-header-cell">Offer Type</th>
                <th scope="col" class="mat-header-cell">Qty</th>
                <th scope="col" class="mat-header-cell">Price</th>
                <th scope="col" class="mat-header-cell">Message</th>
              </tr>
              <tr *ngFor="let element of element.webOrderDetails" class="mat-row" style="height: 25px !important">
                <td class="mat-cell" id="text_align">
                  {{ element.productName }}
                </td>
                <td class="mat-cell" id="text_align">
                  {{ element.promotionMaster?.schemeType }}
                </td>
                <td class="mat-cell" id="text_align" style="text-align: right !important">
                  {{ element.qty }}
                </td>
                <td class="mat-cell" id="text_align" style="text-align: right !important">
                  {{ element.rate | number: "1.2-2"}}
                </td>
                <td class="mat-cell" id="text_align" style="text-align: right !important">
                  {{ element.message }}
                </td>
              </tr>
            </table>

            <!-- <mat-table [dataSource]="element.webOrderDetails" class="mat-elevation-z0">
                <ng-container matColumnDef="ProductName">
                  <mat-header-cell *matHeaderCellDef>
                    Product Name
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.productDetail.product_Name }}
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="OfferType">
                  <mat-header-cell *matHeaderCellDef>
                    Offer Type
                  </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span *ngIf="element.promotionMaster != null">
                      {{ element.promotionMaster.schemeType }}
                    </span>
                    <span *ngIf="element.promotionMaster == null"> </span>
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="Qty">
                  <mat-header-cell *matHeaderCellDef> Qty </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.productDetail.minimum_Order_Quantity }}
                  </mat-cell>
                </ng-container>
  
                <ng-container matColumnDef="Price">
                  <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    {{ element.productDetail.sale_Price }}
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="childDivColumnsToDisplay1"></mat-header-row>
                <mat-row *matRowDef="let row; columns: childDivColumnsToDisplay1"></mat-row>
              </mat-table> -->
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedOrderStatus">
      <td mat-cell *matCellDef="let element" [attr.colspan]="viewtableColumns.length">
        <div class="example-element-detail" [@detailExpand]="element.isExpandedStatus ? 'expanded' : 'collapsed'">
          <div class="example-element-description">
            <span class="example-element-description-attribution">Oredr Status
            </span>
            <div class="stepper-horizontal" id="index_step{{element.orderNo}}">
              <div class="step" *ngFor="let status of element.statusList;let i = index">
                <div class="step-circle"><span>{{i+1}}</span></div>
                <div class="step-title">{{status}}</div>
                <div class="step-bar-left"></div>
                <div class="step-bar-right"></div>
              </div>
            </div>
            <div class="step">
              <button type="button" class="cancel_btn"
                *ngIf="element.trackingStatus == 'Accepted' && element.invoiceDetails[0]?.invoiceType == 'Make To Order'"
                (click)="onOrderCancelClick(element)">Cancel Order</button>
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr style="height: 25px !important" mat-header-row
      *matHeaderRowDef="['header-row-first-group',
          'header-row-second-group','header-row-third-group','header-row-fourth-group',
          'header-row-fifth-group','header-row-sixth-group','header-row-seventh-group','header-row-eighth-group',
          'header-row-ninth-group','header-row-tenth-group','header-row-eleventh-group','header-row-twelvth-group'];sticky: true"></tr>

    <tr mat-header-row *matHeaderRowDef="viewtableColumns; sticky: true"></tr>
    <tr id="expTableRowHeight" mat-row *matRowDef="let element; columns: viewtableColumns"
      [class.example-expanded-row]="element.isExpanded"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedOrderStatus']" class="example-detail-row"></tr>
    <!-- <tr mat-header-row *matHeaderRowDef="viewtableColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: viewtableColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr> -->
  </table>
</div>