import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-payment-fail",
  templateUrl: "./payment-fail.component.html",
  styleUrls: ["./payment-fail.component.scss"],
})
export class PaymentFailComponent implements OnInit {

  encRequest: string = "dzjwwYeAqV5E3G0TRdge0QZjUYBwlEqmMw0EDjq6fkfb5PPeHtrKvIUNy+emPbc34u3I42bGxyOn2l9MgmC0Lj7LM/6rWR5A7f9pffv7haNDfma0Okv4sWW6/lBvQECXZGpkcFVqV0ygouvYmbSv2plQvAVKAAk+bbYarfFtSsypOMVLt2VN5YLGka9RDMpsaYI0gTNgrlRZVXFHSvg0OX6vqscA2M4+toQsdXB4exdut/dwzyad1H7KmcCn2ObNaxd5uJbkesAlXmjIzgHpiLh/hMw8mKPn2HXYDPWjc2Jx4HfX8kt5xLKmMjM2NIv8jneK+ibmlFkD6QBJt5huVS5at+vDwu17J8Y9nQSRtuWvDQYirbkHnspUDRCx51jFK6r+IVNe7iKGGdsJEOrcnxNdgC19r4YyHfbEQxXI1bA7lnbOx8jPkUNtv60XAZvCoIsruAqOYMUGKhmOqYA2kK7mMHiCBG53tMaygslRTGHsC+SQmQTpMVjh14agJOjL/lVtnuFB7YB3CqWqMvAcW9g8Zk/OUNNdM7fsVinokQHUxifHvUKpE8Bl7B81waQZL5/zhvDisqVIKySCgx8VGfC5iERJqGIItioDiW+4jcg4fxRBRmeR+yxHiFmqJ0IpjQMzC5rSuWAp5P8u7RFptMCuH+iTc3UFJDcbiWHN560=";

  constructor(
    private navRoute: Router,
  ) { }

  ngOnInit() { }

  go() {
    this.navRoute.navigate(["/cart-items"]);
  }

  download() {
    this.navRoute.navigate(["/invoice-history"]);
  }
}
