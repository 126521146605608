import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { MakeToOrderSummary } from "../models/maketoordersummary";
import { OrderSummary } from "../models/ordersummary";
import { WebOrderLocationSetup } from "../models/weborderlocationsetup";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";
import { WebOrderAreaAndPinSetupDto } from "../models/web-order-area-and-pin-setup-dto";

@Injectable({
  providedIn: "root",
})
export class PinDialogService {
  orderSummary: OrderSummary;
  makeToOrderSummary: MakeToOrderSummary;
  pincode: number;
  mtoPincode: number;

  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) { }

  setOrderSummary(summary: OrderSummary) {
    this.orderSummary = summary;
  }
  getOrderSummary() {
    return this.orderSummary;
  }

  setMakeToOrderSummary(summary: MakeToOrderSummary) {
    this.makeToOrderSummary = summary;
  }
  getMakeToOrderSummary() {
    return this.makeToOrderSummary;
  }

  setPincode(pincode: number) {
    this.pincode = pincode;
  }
  getPincode() {
    return this.pincode;
  }

  setMTOPincode(pincode: number) {
    this.mtoPincode = pincode;
  }
  getMTOPincode() {
    return this.mtoPincode;
  }

  getLocationDetailsByPincode(pincode: number, currentUrl: string): Observable<any> {
    return this.http.get<WebOrderLocationSetup>(this.apiFactory.getWebOrderLocationDetails + "/" + pincode + "/" + currentUrl);
  }

  getStoreLocationList(api: string, currentUrl: string, lat: string, lng: string): Observable<any> {
    return this.http.get<WebOrderLocationSetup[]>(api + "/" + currentUrl + "/" + lat + "/" + lng);
  }

  getWebOrderAreaAndPinByNameSearch(api: string, currentUrl: string, name: string): Observable<any> {
    return this.http.get<WebOrderAreaAndPinSetupDto[]>(api + "/" + currentUrl + "/" + name);
  }

  getWebOrderLocationDetailsByRefNo(api: string, refNo: number): Observable<any> {
    return this.http.get<WebOrderLocationSetup>(api + "/" + refNo);
  }
}
