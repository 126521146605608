import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSliderModule } from "@angular/material/slider";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule, Meta } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { ProductFilterComponent } from "./views/pages/product/product-filter/product-filter/product-filter.component";
// import {
//   FacebookLoginProvider,
//   GoogleLoginProvider,
//   SocialAuthServiceConfig,
//   SocialLoginModule,
// } from "angularx-social-login";
import { environment } from "../environments/environment";
// Firebase Config
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { APIFactoryEndPoints } from "./common/apifactory-end-points";
import { TranslateService } from "./shared/services/translate.service";
import { SharedModule } from "./shared/shared.module";
import { IndexModule } from "./views/base/index/index.module";
import { ProductModule } from "./views/pages/product/product.module";
import { FsbComponent } from "./views/pages/fsb/fsb.component";
import { CommonModule, DatePipe } from "@angular/common";
import { MakeCakeModule } from "./views/pages/make-cake/make-cake.module";
import { CareerModule } from "./views/pages/career/career.module";
import { StoreModule } from "./views/pages/store/store.module";
import { VerifyMobileNoDialogComponent } from "./views/pages/verify-mobile-no-dialog/verify-mobile-no-dialog.component";
import { AgmCoreModule } from "@agm/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { ProfileModule } from "./views/pages/profile/profile.module";
import { FranchaiseModule } from "./views/pages/franchaise/franchaise.module";
import { InvoiceHistoryModule } from "./views/pages/invoice-history/invoice-history.module";
import { MakeToOrderAddressModule } from "./views/pages/make-to-order-address/make-to-order-address.module";
import { MakeToOrderPaymentModule } from "./views/pages/make-to-order-payment/make-to-order-payment.module";
import { OrderSummaryModule } from "./views/pages/order-summary/order-summary.module";
import { PaymentModule } from "./views/pages/payment/payment.module";
import { ContactModule } from "./views/pages/contact/contact.module";
import { GoogleMapsModule } from "@angular/google-maps";
import $ from "jquery";
import { initializeApp } from "firebase/app";
import { NgbRating } from "@ng-bootstrap/ng-bootstrap";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { LoaderComponent } from '../app/views/base/index/loader/loader.component';
import { LoaderService } from "./shared/services/loader.service";
import { LoaderInterceptor } from "./common/loader-interceptor";
import { ScrollingModule } from '@angular/cdk/scrolling';
import { Constant } from "./shared/models/constant";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { HttpinterceptorInterceptor } from "./httpinterceptor.interceptor";
import { MAT_DATE_LOCALE } from "@angular/material/core";

// import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
initializeApp(environment.firebase);
/* to load and set en.json as the default application language */
export function setupTranslateFactory(service: TranslateService) {
  return () => service.use("en");
}

@NgModule({

  declarations: [
    AppComponent,
    ProductFilterComponent,
    FsbComponent,
    VerifyMobileNoDialogComponent,
    LoaderComponent,
  ],
  imports: [
    NgbRating,
    CommonModule,
    GoogleMapsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IndexModule,
    ProductModule,
    MakeCakeModule,
    SharedModule,
    MatDialogModule,
    BrowserModule,
    MatButtonModule,
    ProfileModule,
    MatTableModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatSliderModule,
    MatIconModule,
  
    CareerModule,
    FranchaiseModule,
    InvoiceHistoryModule,
    MakeToOrderAddressModule,
    MakeToOrderPaymentModule,
    OrderSummaryModule,
    ContactModule,
    PaymentModule,
    StoreModule,
    AgmCoreModule,
    ShareButtonsModule,
    ShareIconsModule,
    ScrollingModule,
    ZXingScannerModule,

    // ServiceWorkerModule.register("./ngsw-worker.js", {
    //   enabled: environment.production,
    //   registrationStrategy: "registerImmediately",
    // }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBZSKMDTyKJqx27kFdm2KoarNDTinm6Bfw",
      libraries: ["places"],
    }),
    BrowserModule.withServerTransition({
      appId: 'ng-universal-demystified'
    }),
    
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  providers: [
    TranslateService, LoaderService, DatePipe, Constant,
    { provide: APP_INITIALIZER, useFactory: setupTranslateFactory, deps: [TranslateService], multi: true, },
    { provide: HTTP_INTERCEPTORS, useClass: HttpinterceptorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    Meta,
    APIFactoryEndPoints,
    // {
    //   provide: "SocialAuthServiceConfig",
    //   useValue: {
    //     autoLogin: false,
    //     providers: [
    //       {
    //         id: GoogleLoginProvider.PROVIDER_ID,
    //         provider: new GoogleLoginProvider(
    //           environment.googleClientId
    //         ),
    //       },
    //       {
    //         id: FacebookLoginProvider.PROVIDER_ID,
    //         provider: new FacebookLoginProvider("6890336221092025"),
    //       },
    //     ],
    //   } as SocialAuthServiceConfig,
    // },
  ],

  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
