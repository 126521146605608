<!-- <section class="mbr-section mbr-after-navbar" id="form1-1r"
    style="background-color: #f8f9fa; padding-top: 0px; height: 15%;">
    <form>
        <div>
            <mat-list class="mat-list-group">
                <mat-list-item class="mat-list-item-group" *ngFor="let group of makeToOrderGroupList"
                    (click)="onGroupSelect(group.refNo)"
                    [ngClass]="groupClickId == group.refNo ? 'group_active' : 'cell-style'">
                    {{ group.productGroupName }}
                </mat-list-item>
            </mat-list>
        </div>

        <div *ngIf="subGroupShow">
            <mat-list class="mat-list-subgroup">
                <mat-list-item class="mat-list-item-subgroup" *ngFor="let subGroup of subGroupList"
                    (click)="onSubGroupSelect(subGroup.refNo)"
                    [ngClass]="subGroupClickId == subGroup.refNo ? 'group_active' : 'cell-style'">
                    {{ subGroup.productSubGroupName }}
                </mat-list-item>
            </mat-list>
        </div>
    </form>
</section> -->

<div class="div-display">
    <div [ngClass]="openfilter ? 'system-view': 'system-view display_none_small'" *ngIf="show">
        <div class="filter-sidebar">
            <div class="filter-header">
                <h3>Filters</h3>
                <h3 *ngIf="selectedFilters.showClearAll" class="h3_clear_all" (click)="clearAllFilters()">Clear All</h3>
            </div>
            <div class="selected-filters">
                <!-- <div *ngFor="let filter of selectedFilters">
                    <span>{{ filter }}</span>
                    <button (click)="removeFilter(filter)">x</button>
                </div> -->
            </div>
            <form [formGroup]="searchForm">
                <!-- Price Filter -->
                <div class="filter-section">
                    <h3 class="section-header" (click)="toggleSection('price')">Price
                        <i class="icon" [ngClass]="isCollapsed.price ? 'fa fa-plus': 'fa fa-minus'"></i>
                    </h3>
                    <ul *ngIf="!isCollapsed.price">
                        <div style="display: flex; flex-direction: row;justify-content: space-around;">
                            <div class="main-div">
                                <div class="sub-div">
                                    <div class="content-div">
                                        <input type="number" placeholder="₹ Min"
                                            (keyup)="validateMinMax($any($event.target).value, 'min')"
                                            formControlName="minValue" autocomplete="off"
                                            (focus)="$any($event.target).select()"
                                            style="width: 100%; border-radius: 6px;" />
                                    </div>
                                </div>
                            </div>
                            <h1 style="margin-top:-5px;">_</h1>
                            <div class="main-div">
                                <div class="sub-div">
                                    <div class="content-div">
                                        <input type="number" placeholder="₹ Max"
                                            (focusout)="validateMinMax($any($event.target).value, 'max')"
                                            formControlName="maxValue" autocomplete="off"
                                            style="width: 100%; border-radius: 6px;" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <button *ngIf="!selectedFilters.cleatBtnPrice" class="button-style"
                                    (click)="applyPriceInput('price')">Apply</button>
                                <button *ngIf="selectedFilters.cleatBtnPrice" class="clear-btn-style"
                                    (click)="clearPriceInput()">Clear</button>
                            </div>
                        </div>
                    </ul>
                </div>

                <!-- Flavour Filter -->
                <div class="filter-section" *ngIf="flavoursArray.length > 0">
                    <h3 class="section-header" (click)="toggleSection('flavour')">Flavour
                        <i class="icon" [ngClass]="isCollapsed.flavour ? 'fa fa-plus': 'fa fa-minus'"></i>
                    </h3>
                    <ul *ngIf="!isCollapsed.flavour">

                    </ul>
                </div>

                <!-- Food Type Filter -->
                <div class="filter-section">
                    <h3 class="section-header" (click)="toggleSection('foodType')">Food Type
                        <i class="icon" [ngClass]="isCollapsed.foodType ? 'fa fa-plus': 'fa fa-minus'"></i>
                    </h3>
                    <ul *ngIf="!isCollapsed.foodType">
                        <div *ngFor="let f of foodTypes;" class="filter-inner-section">
                            <mat-checkbox (change)="onFoodTypeCheckBoxClick($event.checked, f)"
                                [checked]="isFilterChecked('foodTypes', f)">
                                {{f}}
                            </mat-checkbox>
                        </div>
                    </ul>
                </div>

                <!-- Offer Filter -->
                <div class="filter-section" *ngIf="offerTypeArray.length > 0">
                    <h3 class="section-header" (click)="toggleSection('offer')">Offers
                        <i class="icon" [ngClass]="isCollapsed.offer ? 'fa fa-plus': 'fa fa-minus'"></i>
                    </h3>
                    <ul *ngIf="!isCollapsed.offer">
                        <div *ngFor="let p of offerTypeArray" class="filter-inner-section">
                            <mat-checkbox (change)="onOfferCheckBoxClick($event.checked, p)"
                                [checked]="isFilterChecked('offers', p)">
                                {{p}}
                            </mat-checkbox>
                        </div>
                    </ul>
                </div>

                <!-- Delivery With in Filter -->
                <div class="filter-section">
                    <h3 class="section-header" (click)="toggleSection('deliveryWithin')">Expected Delivery
                        <i class="icon" [ngClass]="isCollapsed.deliveryWithin ? 'fa fa-plus': 'fa fa-minus'"></i>
                    </h3>
                    <ul *ngIf="!isCollapsed.deliveryWithin">
                        <div *ngFor="let option of deliveryWithinOptions" class="filter-inner-section">
                            <mat-checkbox (change)="ondeliveryWithinCheckBoxClick($event.checked, option)"
                                [checked]="isFilterChecked('deliveryWithin', option.value)">
                                {{option.label}}
                            </mat-checkbox>
                        </div>
                    </ul>
                </div>

                <!-- Group Filter -->
                <div class="filter-header">
                    <h3>Products</h3>
                </div>
                <div *ngFor="let group of makeToOrderGroupList">
                    <div class="filter-section">
                        <h3 class="section-header" (click)="group.collapsed = !group.collapsed">
                            {{group.productGroupName}}
                            <i class="icon" [ngClass]="group.collapsed ? 'fa fa-minus':'fa fa-plus'"></i>
                        </h3>
                        <ul *ngIf="group.collapsed">
                            <div *ngFor="let subGroup of group.subGroupList" class="filter-inner-section">
                                <mat-checkbox (change)="onSubGroupChange($event.checked, subGroup.refNo)"
                                    [checked]="isFilterChecked('subGroupCodes', subGroup.refNo)">{{subGroup.productSubGroupName}}
                                </mat-checkbox>
                            </div>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div [ngClass]="openfilter ? 'display-container' : 'display-container display-bottom' ">
        <div class="display_holder" (click)="openfilter = !openfilter">
            <span class="display_detail">Filter
            </span>
            <mat-icon *ngIf="!openfilter" id="expand_more_icon">expand_less
            </mat-icon>
            <mat-icon *ngIf="openfilter" id="expand_less_icon">expand_more
            </mat-icon>
        </div>
    </div>

    <div class="container product_list_div" [ngClass]="openfilter ? 'display_none_small' : 'd-block' " *ngIf="show">
        <mat-icon class="sortbar_icon" [matMenuTriggerFor]="menu">filter_list</mat-icon>
        <mat-menu #menu="matMenu">
            <div class="sortbar_tabs_icon">
                <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'lowToHigh' }"
                    type="button" (click)="sortProducts('lowToHigh')">Price: Low to
                    High</button>
                <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'highToLow' }"
                    type="button" (click)="sortProducts('highToLow')">Price: High to
                    Low</button>
            </div>
        </mat-menu>
        <div *ngIf="selectedFilters.groupSubGroups.length > 0" class="sortbar_tabs_container">
            <div class="selected-filters">
                <div *ngFor="let subGroup of selectedFilters.groupSubGroups">
                    <span>{{ subGroup.productSubGroupName }}</span>
                    <button (click)="removeSubGroupFilter(subGroup.refNo)">x</button>
                </div>
            </div>
        </div>

        <div class="row">
            <cdk-virtual-scroll-viewport appendOnly itemSize="24" class="example-viewport" minBufferPx="1000"
                maxBufferPx="5000">
                <div class="col-md-12">
                    <div class="container div-style">
                        <app-card-loader [loop]="3" [height]="800" *ngIf="loading"></app-card-loader>
                        <div class="row" *ngIf="!loading">
                            <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6" style="padding: 0;"
                                *cdkVirtualFor="let product of productList | filterByBrand: selectedBrand;let i = index"
                                (click)="setRow(i)">
                                <div class="product_detaiils">
                                    <div class="item">
                                        <div class="mb-r">
                                            <div class="card card-cascade wider">
                                                <a>
                                                    <div class="view overlay hm-white-slight">
                                                        <img src="../../../../assets/img/{{ product.product_Picture }}"
                                                            class="img-fluid" alt="Image not found"
                                                            style="min-height: 132px;"
                                                            onerror="this.src='../../../../assets/img/defaultCake.webp';"
                                                            [routerLink]="['/make-product', product.product_Code]" />
                                                        <img *ngIf="product.food_Type == 'Veg'"
                                                            class="img-responsive img_align"
                                                            src="../../../../assets/img/Veg.webp" alt="" />
                                                        <img *ngIf="product.food_Type == 'Non-Veg'"
                                                            class="img-responsive img_align"
                                                            src="../../../../assets/img/Nonveg.webp" alt="" />
                                                        <!-- <i [matMenuTriggerFor]="menu" class="fa fa-share-alt" id="icon_align"></i> -->

                                                        <img *ngIf="product.offers >= 1"
                                                            class="img-responsive offer_align"
                                                            src="../../../../assets/img/Offer 1.webp" alt=""
                                                            (click)="viewOfferDetail(product.offerDetails)"
                                                            matTooltip="Offers" />

                                                    </div>
                                                </a>

                                                <div style="display: flex;justify-content: space-between;">
                                                    <a placement="top" matTooltip="Add to Wishlist" container="body"
                                                        (click)="addFavourite(product)">
                                                        <i class="fa fa-heart"
                                                            style="font-size: 17px; color: #e91e63 !important"></i>
                                                    </a>
                                                    <mat-icon [matMenuTriggerFor]="menu" id="icon_align"
                                                        matTooltip="Share">share</mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <share-buttons theme="circles-dark"
                                                            [include]="['copy','whatsapp','facebook','email','telegram']"
                                                            [showIcon]="true" [showText]="false"
                                                            [url]="sharingUrl+product.product_Code"
                                                            [description]="product.product_Name" class="pt-5">
                                                        </share-buttons>
                                                    </mat-menu>
                                                </div>

                                                <div class="card-body text-center">
                                                    <p class="card-title product_name">
                                                        <strong style="height: 80%;">
                                                            <a [routerLink]="['/make-product', product.product_Code]"
                                                                routerLinkActive="router-link-active">
                                                                {{product.product_Name }}</a>
                                                        </strong>
                                                        <strong class="price_style">
                                                            <span>₹ {{ product.net_Sale_Price | number: "1.2-2"
                                                                }}</span>
                                                        </strong>
                                                    </p>

                                                    <p>
                                                        <span>{{product.portion_Weight }}</span>
                                                    </p>

                                                    <div class="card-footer">
                                                        <span class="left">
                                                            <!-- <a placement="top" mdbTooltip="Add to Wishlist"
                                                                container="body" (click)="addFavourite(product)">
                                                                <i class="fa fa-heart"
                                                                    style="font-size: 17px; color: #e91e63 !important"></i>
                                                            </a> -->
                                                        </span>

                                                        <span class="right" style="margin-right: -25px">
                                                            <!-- <a placement="top" mdbTooltip="Add to Cart" container="body"
                                                                [routerLink]="['/make-product', product.product_Code]">
                                                                <i class="fa fa-shopping-cart"
                                                                    style="margin-left: 6px;color: #e91e63 !important;font-size: 19px;"></i>
                                                            </a> -->
                                                            <button type="button" class="add_to_cart_btn"
                                                                [routerLink]="['/make-product', product.product_Code]">Buy
                                                                Now</button>

                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>
</div>