import { HttpClient } from "@angular/common/http";
import { HostListener, Injectable } from "@angular/core";
import { readFile } from "src/assets/js/read-file";
import { ProductService } from "../shared/services/product.service";
import { APIFactoryEndPoints } from "./apifactory-end-points";
import { Subscription } from "rxjs";
import { getWindow } from "ssr-window";
//import { allGroupList, allProductList, allProductListMto, allSubGroupListMto, careerSetupList, city, feedBackSetupList, franchiseSetupList, locationDetailsForStore, locationWithLongLat, makeToOrderGroupList, matrixList, stateList } from "src/assets/all-data-in-static-db";
@Injectable({
  providedIn: 'root'
})
export class FileService {
  currentUrl: string;
  private productSubscription: Subscription;
  dataToReturn: string;
  constructor(
    private http: HttpClient,
    private productService: ProductService,
    private apiFactory: APIFactoryEndPoints,
  ) {
    this.currentUrl = getWindow().location.hostname;
  }
  getFileData(dataType: string) {
    this.dataToReturn = readFile(dataType, this.currentUrl);
    if(this.dataToReturn=="NA")
      return null;
    else
      return JSON.parse(this.dataToReturn);
  }
}