import { Routes, RouterModule } from "@angular/router";
import { OrderSummaryComponent } from './order-summary.component';

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "order-summary-app",
                component: OrderSummaryComponent,
            },
        ],
    },
];

export const OrderSummaryRoutes = RouterModule.forChild(routes);