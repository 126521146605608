<br />
<div style="overflow-x: hidden;">
    <div class="mbr-section mbr-section__container mbr-section__container--middle">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-center">
                    <h2 class="mbr-section-title h2_font_size"
                        style="display: flex;justify-content: center;align-items: center;">
                        {{product.product_Name}}
                        <!-- Make To Order -->
                        <br />
                        <i *ngIf="product.product_Name" [matMenuTriggerFor]="menu" class="fa fa-share-alt"
                            id="icon_align"></i>
                        <mat-menu #menu="matMenu">
                            <share-buttons theme="circles-dark"
                                [include]="['copy','whatsapp','facebook','email','telegram']" [showIcon]="true"
                                [showText]="false" [url]="sharingUrl" [description]="product.product_Name" class="pt-5">
                            </share-buttons>
                        </mat-menu>
                    </h2>
                    <div class="hr"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row" style="display: flex;justify-content: center;">
            <div class="col-sm-4">
                <div class="product-image" style="height: 83%;">
                    <div class="view hm-zoom z-depth-2" id="img_div_align">
                        <img src="../../../../assets/img/{{ product.product_Picture }}" alt="Image not found"
                            width="100%" style="height: 100%" class="img-fluid rounded"
                            onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                        <span style="position: absolute;top: 3px;right: 3px;">
                            <img *ngIf="product.food_Type == 'Veg'" style="width: 17px; height: 17px"
                                src="../../../../assets/img/Veg.webp" alt="" />
                            <img *ngIf="product.food_Type == 'Non-Veg'" style="width: 17px; height: 17px"
                                src="../../../../assets/img/Nonveg.webp" alt="" />
                        </span>
                        <span *ngIf="offerNames" style="position: absolute;top: 9px;left: 4px;">
                            <img style="width: 40px; height: 48px;cursor: pointer;"
                                src="../../../../assets/img/Offer 1.webp" alt="" (click)="viewOfferDetail()"
                                matTooltip="Offers" />
                        </span>
                        <!-- <i [matMenuTriggerFor]="menu" class="fa fa-share-alt" id="icon_align"></i> -->
                        <!-- <mat-icon [matMenuTriggerFor]="menu" id="icon_align">share</mat-icon>
                    <mat-menu #menu="matMenu">
                        <share-buttons theme="circles-dark"
                            [include]="['copy','whatsapp','facebook','email','telegram']" [showIcon]="true"
                            [showText]="false" [url]="sharingUrl" [description]="product.product_Name" class="pt-5">
                        </share-buttons>
                    </mat-menu> -->
                    </div>
                </div>
            </div>

            <div class="padding-left-child" style="width: auto;">
                <div class="product-detail" style="width: 380px;">
                    <form [formGroup]="createSetUpForm" style="margin-left: 1%;">
                        <!-- <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                        <mat-card class="contain_card" fxFlex="47" style="width: 100%">
                            <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="productName"
                                    style="text-align: left; padding-bottom: 2%" readonly />
                            </mat-form-field>
                        </mat-card>
                    </div> -->

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Price({{product.product_Unit}})</mat-label> -->
                                    <input matInput placeholder="Price/{{product.product_Unit}}"
                                        formControlName="productPrice"
                                        [value]="createSetUpForm.get('productPrice').value | number: '1.2-2'" readonly
                                        style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label
                                    style="padding-bottom: 2% !important">Quantity({{product.product_Unit}})</mat-label> -->
                                    <input matInput placeholder="Quantity/{{product.product_Unit}}" type="number"
                                        formControlName="qty" (focusout)="onQtySelect($event)"
                                        style="text-align: left" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showComposition">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style2">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Flavour</mat-label> -->
                                    <mat-select placeholder="Flavour" (selectionChange)="onFlavourSelect($event.value)"
                                        formControlName="composition">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let composition of productCompositionList"
                                            [value]="composition.refNo">
                                            {{ composition.compositionName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>

                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Flavour Premium Price</mat-label> -->
                                    <input matInput placeholder="Flavour Premium Price"
                                        formControlName="premiumFlavourPrice"
                                        [value]="createSetUpForm.get('premiumFlavourPrice').value | number: '1.2-2'"
                                        readonly style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showColor">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Color</mat-label> -->
                                    <mat-select placeholder="Color" formControlName="color">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let color of productColorList" [value]="color.refCode">
                                            {{ color.colorName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showShape">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Shape</mat-label> -->
                                    <mat-select placeholder="Shape" formControlName="shape">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let shape of productShapeList" [value]="shape.refNo">
                                            {{ shape.productShape }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div" *ngIf="showDimension">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Dimension</mat-label> -->
                                    <input matInput placeholder="Dimension" formControlName="dimension"
                                        style="text-align: left; padding-bottom: 2%" />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style2">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Delivery Date</mat-label> -->
                                    <input matInput placeholder="Delivery Date" [matDatepicker]="licker"
                                        [min]="todayDate" [max]="maxDate" formControlName="deliveryDate"
                                        (click)="licker.open()" (dateChange)="dateChanged()"
                                        style="text-align: left; padding-bottom: 2%" readonly />
                                    <mat-datepicker-toggle matSuffix [for]="licker"></mat-datepicker-toggle>
                                    <mat-datepicker #licker disabled="false"></mat-datepicker>
                                </mat-form-field>
                            </mat-card>

                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style2">
                                <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                    <!-- <mat-label>Delivery Time Slot</mat-label> -->
                                    <mat-select placeholder="Delivery Time Slot" formControlName="deliveryTime">
                                        <mat-option style="text-align: left; padding-bottom: 2%"
                                            *ngFor="let slot of timeSlotAvl" [value]="slot">
                                            {{ slot }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 90%" visivility="none">
                                    <!-- <mat-label>Message/Note On Cake</mat-label> -->
                                    <input matInput #input placeholder="Message/Note On Cake" maxlength="40"
                                        formControlName="note" style="text-align: left" />
                                </mat-form-field>
                                <mat-hint style="font-size: 10px;">{{ input.value?.length || 0 }}/40</mat-hint>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                            <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                    <!-- <mat-label>SubTotal</mat-label> -->
                                    <input matInput placeholder="SubTotal" formControlName="subTotal"
                                        [value]="createSetUpForm.get('subTotal').value | number: '1.2-2'"
                                        style="text-align: left; padding-bottom: 2%" readonly />
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div_btn">
                            <button type="button" (click)="addToCart(product, false)" class="btn btn-primary bg-pink"
                                *ngIf="!showUpdate">
                                Add to Cart
                            </button>
                            <button type="button" (click)="addToCart(product, true)" class="btn btn-primary bg-pink"
                                *ngIf="!showUpdate">
                                Buy Now
                            </button>
                            <button type="button" (click)="addToCart(product, true)" class="btn btn-primary bg-pink"
                                *ngIf="showUpdate">
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>