export class GoogleCustomer {
  id: string;
  refNo: string;
  name: string;
  email: string;
  firstName: string;
  lastName: string;
  idToken: string;
  authToken: string;
  provider: string;
  lattitude: string;
  longitude: string;
  phoneNo: string;
  status: string;

  stateCode: number;
  locationCode: number;
  source: string;
  isNewCustomer: boolean;

}