import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  setItem(setItem, data) {
    localStorage.setItem(setItem, data);
  }

  getItem(getItem) {
    return localStorage.getItem(getItem);
  }

  removeItem(remItem) {
    localStorage.removeItem(remItem);
  }

  removeAllItem() {
    localStorage.clear();
  }

  checkLength() {
    return localStorage.length;
  }
}