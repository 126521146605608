import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { DeliveryFeedBack } from "../models/delivery-feed-back";
import { PaymentHistoryDto } from "../models/payment-history-dto";
import { WebOrderDto } from "../models/weborderdto";
import { WebOrderFeedBackDto } from "../models/weborderfeedbackdto";

@Injectable({
  providedIn: "root",
})
export class InvoiceHistoryService {
  constructor(private http: HttpClient, private apiURL: APIFactoryEndPoints) { }

  getWebOrderDetailsByLoggedInUser(url: string, customerEmail: string) {
    return this.http.get<WebOrderDto[]>(url + "/" + customerEmail);
  }

  getWebOrderFeedBackDetails(url: string) {
    return this.http.get<WebOrderFeedBackDto[]>(url);
  }

  insertFeedBack(url: string, req: DeliveryFeedBack[]): Observable<any> {
    return this.http.post(url, req, { responseType: "text" });
  }

  getWebOrderPaymentHistory(url: string, customerEmail: string) {
    return this.http.get<PaymentHistoryDto[]>(url + "/" + customerEmail);
  }

  cancelOrder(url: string, invNo: string, status: string) {
    return this.http.get<any>(url + "/" + invNo + "/" + status);
  }
}
