import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { TermsAgreed } from 'src/app/shared/models/terms-agreed';
import { MyCouponService } from 'src/app/shared/services/my-coupon.service';
import { ToastService } from 'src/app/shared/services/toastr.service';

@Component({
  selector: 'app-agreed-terms-dialog',
  templateUrl: './agreed-terms-dialog.component.html',
  styleUrls: ['./agreed-terms-dialog.component.scss']
})
export class AgreedTermsDialogComponent implements OnInit {

  isAgreed: boolean = false;
  googleCustomer: GoogleCustomer;

  private saveTermsSubcription: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AgreedTermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private myCouponService: MyCouponService,
    private localStorage: LocalStorageService,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.scrollToElementById(this.data);
  }

  close(isClose: boolean) {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInUp");
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutDown");
    setTimeout(() => {
      this.dialogRef.close({ isClose });
    }, 700);
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }

  proceed() {
    let termsAgreedDto: TermsAgreed = new TermsAgreed();
    termsAgreedDto.userCode = this.googleCustomer.email;
    termsAgreedDto.userType = "Customer";
    termsAgreedDto.termsType = "Coupon";

    this.saveTermsSubcription = this.myCouponService.saveAgreedTerms(this.apiFactory.saveTermsAgreed, termsAgreedDto)
      .subscribe((data) => {
        this.toastService.showSuccessToast("Terms Agreed Successfully");
        this.close(true);
      });
  }
}