import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  SecurityContext,
  ViewChild,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import html2canvas from "html2canvas";
import { Subscription, throwError } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import fileSaver from "file-saver";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-invoice-preview",
  templateUrl: "./invoice-preview.component.html",
  styleUrls: ["./invoice-preview.component.scss"],
})
export class InvoicePreviewComponent implements OnInit {
  public dialog: MatDialog;

  byTypeAndCompanyCodeSubscription: Subscription;
  getprintsetupSubscription: Subscription;
  DeclarationSubscription: Subscription;
  getGstSaleInvCumBosByInvNo: Subscription;

  companyCode: number;

  signature: any;
  termsData: any;
  declarationData: any;

  //constant: Constant
  dateFormat: string = "";
  timeFormat: string = "";

  fromLocationName: string;

  file: any;
  fileURL: any;

  @ViewChild("screen") screen: ElementRef;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("downloadLink") downloadLink: ElementRef;
  @ViewChild("pdfViewer") pdfViewer: ElementRef;
  private _sanitizer: any;

  constructor(
    protected sanitizer: DomSanitizer,
    private apiUrl: APIFactoryEndPoints,
    private apiFactory: APIFactoryEndPoints,
    public dialogRef: MatDialogRef<InvoicePreviewComponent>,
    private productOrderSummaryService: ProductOrderSummaryService,
    @Inject(MAT_DIALOG_DATA) public previewValue: string[]
  ) {
    this.productOrderSummaryService.getWebOrderInvoiceDetails(this.apiUrl.getWebOrderInvoiceDetails, previewValue[2], previewValue[1], previewValue[0], previewValue[3])
      .subscribe(
        (response) => {
          console.log(response);
          let file = new Blob([response], { type: "application/pdf" });
          this.fileURL = URL.createObjectURL(file);
          console.log(this.fileURL);
          this.pdfViewer.nativeElement.data =
            this.fileURL + "#toolbar=0&navpanes=0&scrollbar=0#zoom=page-width";
          // previewValue=fileURL
        },
        (e) => {
          throwError(e);
          console.log(e);
        }
      );
    dialogRef.disableClose = true;
  }

  ngOnInit(): void { }

  downloadPdf() {
    let content = this.pdfViewer.nativeElement.data;
    fileSaver.saveAs(content, this.previewValue[1] + ".pdf");
  
  }

  print() {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl(this.fileURL)
    );
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  // downloadPreview() {
  //   if (this.screen) {
  //     html2canvas(this.screen.nativeElement).then(canvas => {
  //       this.canvas.nativeElement.src = canvas.toDataURL();
  //       this.downloadLink.nativeElement.href = canvas.toDataURL("image/png");
  //       this.downloadLink.nativeElement.download = "order_preview.webp";
  //       this.downloadLink.nativeElement.click();
  //     });
  //   }
  // }
  cancel() {
    this.dialogRef.close();
  }
}
