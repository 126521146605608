import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { Router } from "@angular/router";
import { interval, Subscription, timer } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CustomerDTO } from "src/app/common/customerDto";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { Product } from "src/app/shared/models/product";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { UpiCodeResponse } from "src/app/shared/models/upicoderesponse";
import { WebOrderBillingSetup } from "src/app/shared/models/weborderbillingsetup";
import { AddressService } from "src/app/shared/services/address.service";
import { CartPaymentService } from "src/app/shared/services/cart-payment.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { environment } from "src/environments/environment";
import moment from "moment";
import { UpiPayment } from "src/app/shared/models/upipayment";
import { UpiPaymentDetails } from "src/app/shared/models/upi-payment-details";
import { map, takeWhile } from "rxjs/operators";
import { IciciTransactionStatus } from "src/app/shared/models/icicitransactionstatus";
import { HttpErrorResponse } from "@angular/common/http";
import { LocalStorageService } from "src/app/common/LocalStorageService";

@Component({
  selector: "app-cart-payment",
  templateUrl: "./cart-payment.component.html",
  styleUrls: ["./cart-payment.component.scss"],
})
export class CartPaymentComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  webUrl: string;
  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];
  customerDTO: CustomerDTO = new CustomerDTO();
  deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
  createSetUpForm: UntypedFormGroup;

  // createQrCodeSetUpForm:FormGroup;

  webOrderBillingSetUp: WebOrderBillingSetup;

  merchantTranId: string;

  upiCodeResponse: UpiCodeResponse;
  qrCodeUpi: string;
  showQrCodeLabel: boolean = true;
  showQrCode: boolean = false;
  merchantTnxId: string;

  time: number = 0;
  display;
  // interval;

  showNotification: boolean = true;
  showQrCodePage: boolean = false;
  showNetbanking: boolean = false;
  showUpi: boolean = true;

  companyCode: number;
  googleCustomer: GoogleCustomer;
  cartList: Product[] = [];

  in: any = null;
  inSub: any = null;
  upiPayment: UpiPayment = new UpiPayment();
  private onUpiSetUpSubscription: Subscription;
  iciciTransactionStatus: IciciTransactionStatus;
  private sub;
  value: number = 0;
  isApiCall: boolean = true;

  disableQrBtn: boolean = true;
  disableVpaBtn: boolean = true;

  usedWallet: number = 500;

  private locationCodeForBillingSubcription: Subscription;
  private onCreateSetUpSubscription: Subscription;
  private qrCodeSubscription: Subscription;
  private onWebOrderInsertSubscription: Subscription;
  private onCustomerInsertSubscription: Subscription;
  private onDeliveryInsertSubscription: Subscription;
  private cartSetUpSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private addressService: AddressService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private productOrderSummaryService: ProductOrderSummaryService,
    private cartPaymentService: CartPaymentService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    // this.startTimer();
    this.onCreateSetUp();
    // this.onCreateQRCodeSetUp();
    this.getProductOrderSummaryDetails();
    // this.getLocationCodeForBillingDetails();
    this.getWebOrders();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      payerVa: ["", [Validators.required, Validators.pattern("[A-Za-z0-9]*@[a-z]*")],],
      amount: [""],
      locId: [""],
      paymentType: [""],
    });
  }

  // onCreateQRCodeSetUp() {
  //   this.createQrCodeSetUpForm = this.formBuilder.group({
  //     payerVa: [''],
  //     amount: [''],
  //     locId: [''],
  //     paymentType: ['']
  //   })
  // }

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(this.productOrderSummary);
    this.createSetUpForm.patchValue({ amount: this.productOrderSummary.totalPrice.toFixed(), });

    // this.createQrCodeSetUpForm.patchValue({
    //   amount: this.productOrderSummary.totalPrice.toFixed()
    // })
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);

    this.gstSaleInvcumBOSArray = this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);
  }

  getLocationCodeForBillingDetails() {
    this.locationCodeForBillingSubcription = this.productOrderSummaryService.getLocationCodeForBillingDetails(this.companyCode).subscribe((data) => {
      this.webOrderBillingSetUp = data;
      console.log(data);
      // this.onQrCodeBtnClick(this.webOrderBillingSetUp);
    });
  }

  //For Local insert
  onCreateSetUpClick1() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
    let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

    this.createSetUpForm.patchValue({
      // amount: this.productOrderSummary.totalPrice.toFixed(2),
      // locId: this.webOrderBillingSetUp.locationCode,
      locId: locationCode,
      paymentType: "B2C",
    });
    console.log(this.createSetUpForm);

    if (this.gstSaleInvcumBOS.details.length != 0) {
      let momentDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
      let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
      this.gstSaleInvcumBOS.locationCode = locationCode;
      this.gstSaleInvcumBOS.deliveryDate = formattedDate;
      this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
      this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;

      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);

      console.log(this.gstSaleInvcumBOS);
      this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;

      this.gstSaleInvcumBOS.customer = this.customerDTO;

      this.gstSaleInvcumBOS.upipayment.amount = this.productOrderSummary.totalPrice - this.usedWallet;
      this.gstSaleInvcumBOS.upipayment.fromLocationCode = locationCode;
      let upiPaymentDetails = new UpiPaymentDetails();
      upiPaymentDetails.transAmt = this.productOrderSummary.totalPrice - this.usedWallet;
      upiPaymentDetails.transStatus = "true";
      upiPaymentDetails.transDate = "2022-06-17 15:09:33";
      upiPaymentDetails.merchantTranId = "000034251617";
      this.gstSaleInvcumBOS.upipayment.upiPaymentDetails.push(upiPaymentDetails);

      this.gstSaleInvcumBOS.walletPayment.amount = this.usedWallet;
      this.gstSaleInvcumBOS.walletPayment.fromLocationCode = locationCode;

      this.gstSaleInvcumBOS.totalAmt = Number(this.productOrderSummary.totalPrice);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder, this.gstSaleInvcumBOS)
        .subscribe((data) => {
          console.log(data);
          if (data != null) {
            let id: number[] = [];
            data.details.forEach((element) => {
              if (element.productCode)
                id.push(element.productCode);
            });
            this.cartSetUpSubscription = this.userService.removeAllProductCart(this.apiFactory.removeAllProductFromCart, this.googleCustomer.email, id)
              .subscribe((data: any) => {
                console.log(data);
                this.localStorage.removeItem("orderFromTvLocation");
                // window.location.reload();
              });
          } else {
            this.toastService.showErrorToast("Server side error...");
          }
        });
    }

    if (this.gstSaleInvcumBOSArray.length != 0) {
      this.gstSaleInvcumBOSArray.forEach((element) => {
        let momentDate = new Date(element.details[0].deliveryDate);
        let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
        element.deliveryDate = formattedDate;
        element.deliveryTime = element.details[0].deliveryTime;
        element.locationCode = locationCode;
        element.orderFromTvLocation = orderFromTvLocation;

        element.upipayment.amount = Number(this.productOrderSummary.totalPrice) - this.usedWallet;
        element.upipayment.fromLocationCode = locationCode;
        let upiPaymentDetails = new UpiPaymentDetails();
        upiPaymentDetails.transAmt = Number(this.productOrderSummary.totalPrice) - this.usedWallet;
        upiPaymentDetails.transStatus = "true";
        upiPaymentDetails.transDate = "2022-06-17 15:09:33";
        upiPaymentDetails.merchantTranId = "000034251617";
        element.upipayment.upiPaymentDetails.push(upiPaymentDetails);

        element.walletPayment.amount = this.usedWallet;
        element.walletPayment.fromLocationCode = locationCode;

        element.totalAmt = Number(this.productOrderSummary.totalPrice);
      });
      this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;

      this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

      console.log(this.gstSaleInvcumBOSArray);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderArray(this.apiFactory.insertWebOrderArray, this.gstSaleInvcumBOSArray)
        .subscribe((data) => {
          console.log(data);
          if (data.length > 0) {
            this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
              .subscribe((data: any) => {
                console.log(data.Res);
                // window.location.reload();
              });
          } else {
            this.toastService.showErrorToast("Server side error...");
          }
        });
    }

    // this.onCreateSetUpSubscription = this.cartPaymentService
    //   .sendUpiPayment(this.apiFactory.sendUpiPayment, this.createSetUpForm)
    //   .subscribe((data) => {
    //     console.log(data);
    //     this.merchantTranId = data;

    //     this.cartPaymentService.setUpiPayment(
    //       this.merchantTranId,
    //       this.webOrderBillingSetUp.locationCode
    //     );

    //     if (this.merchantTranId != null) {
    //       this.cartPaymentService.setUpiPayment(
    //         this.merchantTranId,
    //         this.webOrderBillingSetUp.locationCode
    //       );
    //       this.gstSaleInvcumBOS.locationCode = this.webOrderBillingSetUp.locationCode;
    //       this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
    //       this.navRoute.navigate(["/payment-confirmation"])
    //       this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder,
    //         this.gstSaleInvcumBOS)
    //         .subscribe((data) => {
    //           console.log(data);
    //         })
    //     } else {
    //       this.toastService.showErrorToast("Payment Failed..!");
    //     }
    //   });
  }

  //For Cloud Insert
  onCreateSetUpClick() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: string = JSON.parse(this.localStorage.getItem("state_code"));

    this.createSetUpForm.patchValue({
      // amount: this.productOrderSummary.totalPrice.toFixed(2),
      // locId: this.webOrderBillingSetUp.locationCode,
      locId: locationCode,
      paymentType: "B2C",
    });
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid");
      return;
    }
    console.log(this.createSetUpForm);

    this.onCreateSetUpSubscription = this.cartPaymentService.sendUpiPayment(this.apiFactory.sendUpiPayment, this.createSetUpForm).subscribe((data) => {
      console.log(data);
      this.merchantTranId = data;
      this.disableQrBtn = false;
      this.disableVpaBtn = false;
      // this.merchantTranId = "TDA0092826";
      if (this.merchantTranId != null) {
        // this.cartPaymentService.setUpiPayment(this.merchantTranId, this.webOrderBillingSetUp.locationCode);
        // this.gstSaleInvcumBOS.locationCode =this.webOrderBillingSetUp.locationCode;
        this.cartPaymentService.setUpiPayment(
          this.merchantTranId,
          locationCode
        );
        this.gstSaleInvcumBOS.locationCode = locationCode;
        this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
        this.click();
        // this.navRoute.navigate(["/payment-confirmation"]);
      } else {
        this.toastService.showErrorToast("Payment Failed..!");
      }
    });
  }

  onQrCodeBtnClick() {
    // var locId = this.webOrderBillingSetUp.locationCode;
    // var amount = this.createQrCodeSetUpForm.get('amount').value;
    var locId: number = JSON.parse(this.localStorage.getItem("location_code"));
    var paymentType = "B2C";

    var amount = this.createSetUpForm.get("amount").value;

    this.qrCodeSubscription = this.cartPaymentService.getQrCode(this.apiFactory.getQrForUpi, locId, amount, paymentType).subscribe((data: any) => {
      if (null != data) {
        this.upiCodeResponse = data;
        this.qrCodeUpi = this.upiCodeResponse.qrString;
        this.merchantTnxId = this.upiCodeResponse.merchantTnxId;
        this.showQrCodeLabel = false;
        this.showQrCode = true;
        this.disableVpaBtn = false;
        this.disableQrBtn = false;
        if (this.merchantTnxId != null) {
          this.cartPaymentService.setUpiPayment(
            this.merchantTnxId,
            locId
            // this.webOrderBillingSetUp.locationCode
          );
          // this.gstSaleInvcumBOS.locationCode = this.webOrderBillingSetUp.locationCode;
          this.gstSaleInvcumBOS.locationCode = locId;

          this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
          this.click();
        }
      } else {
        this.showQrCodeLabel = true;
        this.showQrCode = false;
        this.toastService.showErrorToast("Server side error");
      }
    });
  }

  click() {
    // this.sub && this.sub.unsubscribe(); //to unsubscribe if not
    this.in = interval(25000).subscribe((x) => {
      this.upiPayment = this.cartPaymentService.getUpiPayment();
      console.log(this.upiPayment);
      this.in.unsubscribe();
      this.getTransactionDetails();
    });

    this.sub = this.interval(120000).subscribe((res) => {
      this.value = res;
    });

    setTimeout(() => {
      this.in.unsubscribe();
      this.sub.unsubscribe();
      this.onUpiSetUpSubscription.unsubscribe();
      // this.inSub.unsubscribe();
      this.isApiCall = false;
      this.navRoute.navigate(["/payment-fail"]);
    }, 120000);
  }

  getTransactionDetails() {
    if (this.isApiCall)
      this.onUpiSetUpSubscription = this.cartPaymentService
        .getUpiTransactionDetails(this.apiFactory.getUpiTransactionDetails, this.upiPayment.merchantTranId, this.upiPayment.locId).subscribe((data) => {
          this.iciciTransactionStatus = data;
          console.log(this.iciciTransactionStatus);
          if (this.iciciTransactionStatus) {
            if (this.iciciTransactionStatus.status == "SUCCESS") {
              this.in.unsubscribe();

              let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
              let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
              let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

              if (this.gstSaleInvcumBOS.details.length != 0) {
                let momentDate = new Date(
                  this.gstSaleInvcumBOS.details[0].deliveryDate);
                let formattedDate = moment(momentDate).format("YYYY-MM-DD");
                this.gstSaleInvcumBOS.locationCode = locationCode;
                this.gstSaleInvcumBOS.deliveryDate = formattedDate;
                this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
                this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;

                this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
                console.log(this.gstSaleInvcumBOS);

                this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

                this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
                this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
                this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
                this.customerDTO.stateCode = stateCode;
                this.customerDTO.fromLocationCode = locationCode;
                this.customerDTO.location.refNo = locationCode;
                this.gstSaleInvcumBOS.customer = this.customerDTO;

                this.gstSaleInvcumBOS.upipayment.amount = this.productOrderSummary.totalPrice;
                this.gstSaleInvcumBOS.upipayment.fromLocationCode = locationCode;
                let upiPaymentDetails = new UpiPaymentDetails();
                upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
                upiPaymentDetails.transStatus = this.iciciTransactionStatus.status;
                upiPaymentDetails.transDate = moment(new Date(this.iciciTransactionStatus.txnInitDate)).format("YYYY-MM-DD HH:mm:ss");
                upiPaymentDetails.merchantTranId = this.iciciTransactionStatus.merchantTranId;
                // upiPaymentDetails.transAmt = this.productOrderSummary.totalPrice;
                // upiPaymentDetails.transStatus = "true";
                // upiPaymentDetails.transDate = "2022-06-17 15:09:33";
                // upiPaymentDetails.merchantTranId = "000034251617";
                this.gstSaleInvcumBOS.upipayment.upiPaymentDetails.push(upiPaymentDetails);

                this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder, this.gstSaleInvcumBOS)
                  .subscribe((data) => {
                    console.log(data);
                    if (data != null) {
                      let id: number[] = [];
                      data.details.forEach((element) => {
                        if (element.productCode)
                          id.push(element.productCode);
                      });
                      this.cartSetUpSubscription = this.userService.removeAllProductCart(this.apiFactory.removeAllProductFromCart, this.googleCustomer.email, id)
                        .subscribe((data: any) => {
                          console.log(data);
                          // window.location.reload();
                          this.navRoute.navigate(["/payment-success"], {
                            state: { data: "reload" },
                          });
                        });
                    } else {
                      this.toastService.showErrorToast("Server side error...");
                    }
                  });
              }

              if (this.gstSaleInvcumBOSArray.length != 0) {
                this.gstSaleInvcumBOSArray.forEach((element) => {
                  let momentDate = new Date(element.details[0].deliveryDate);
                  let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
                  element.deliveryDate = formattedDate;
                  element.deliveryTime = element.details[0].deliveryTime;
                  element.locationCode = locationCode;
                  element.orderFromTvLocation = orderFromTvLocation;

                  element.upipayment.amount = element.grandTotal;
                  element.upipayment.fromLocationCode = locationCode;
                  let upiPaymentDetails = new UpiPaymentDetails();
                  upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
                  upiPaymentDetails.transStatus = this.iciciTransactionStatus.status;
                  upiPaymentDetails.transDate = moment(new Date(this.iciciTransactionStatus.txnInitDate)).format("YYYY-MM-DD HH:mm:ss");
                  upiPaymentDetails.merchantTranId = this.iciciTransactionStatus.merchantTranId;
                  // upiPaymentDetails.transAmt = element.grandTotal;
                  // upiPaymentDetails.transStatus = "true";
                  // upiPaymentDetails.transDate = "2022-06-17 15:09:33";
                  // upiPaymentDetails.merchantTranId = "000034251617";
                  element.upipayment.upiPaymentDetails.push(upiPaymentDetails);
                });
                this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;
                this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
                this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
                this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
                this.customerDTO.stateCode = stateCode;
                this.customerDTO.fromLocationCode = locationCode;
                this.customerDTO.location.refNo = locationCode;

                this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

                console.log(this.gstSaleInvcumBOSArray);

                this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderArray(this.apiFactory.insertWebOrderArray, this.gstSaleInvcumBOSArray)
                  .subscribe((data) => {
                    console.log(data);
                    if (data.length > 0) {
                      this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
                        .subscribe((data: any) => {
                          console.log(data.Res);
                          // window.location.reload();
                          this.navRoute.navigate(["/payment-success"], {
                            state: { data: "reload" },
                          });
                        });
                    } else {
                      this.toastService.showErrorToast("Server side error...");
                    }
                  });
              }
            } else if (this.iciciTransactionStatus.status == "FAIL") {
              // this.webOrder.merchnatTranId = "TID000120210607T181322";
              console.log(this.gstSaleInvcumBOS);
              this.in.unsubscribe();
              this.sub.unsubscribe();
              this.onUpiSetUpSubscription.unsubscribe();
              this.navRoute.navigate(["/payment-fail"]);
            } else {
            }
          } else {
            this.inSub = interval(25000).subscribe((x) => {
              this.inSub.unsubscribe();
              this.getTransactionDetails();
            });
          }
        });
  }

  interval(timeToWait) {
    const initial = new Date().getTime();
    return timer(0, 200).pipe(
      map(() => new Date().getTime()),
      takeWhile((res) => res <= initial + timeToWait, true),
      map((now) => {
        const porc = (100 * (now - initial)) / timeToWait;
        // console.log(porc);
        return porc < 100 ? Math.round(porc) : 100;
      })
    );
  }

  openMyMenu() {
    this.trigger.toggleMenu();
  }
  closeMyMenu() {
    this.trigger.closeMenu();
    console.log("close");
  }

  // startTimer() {
  //   console.log("=====>");
  //   this.interval = setInterval(() => {
  //     if (this.time === 0) {
  //       this.time++;
  //     } else {
  //       this.time++;
  //     }
  //     this.display = this.transform(this.time);
  //   }, 1000);
  // }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ":" + (value - minutes * 60);
  }
  pauseTimer() {
    // clearInterval(this.interval);
  }

  clickUPI() {
    this.showUpi = true;
    this.showNetbanking = false;
  }
  clickNetBanking() {
    this.showNetbanking = true;
    this.showQrCodePage = false;
    this.showNotification = false;
  }

  //For Local insert with payment
  onCreateSetUpClick2() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: string = JSON.parse(this.localStorage.getItem("state_code"));

    this.createSetUpForm.patchValue({
      locId: locationCode,
      paymentType: "B2C",
    });
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid");
      return;
    }
    console.log(this.createSetUpForm);

    // this.onCreateSetUpSubscription = this.cartPaymentService
    //   .sendUpiPayment(this.apiFactory.sendUpiPayment, this.createSetUpForm)
    //   .subscribe((data) => {
    //     console.log(data);
    //   this.merchantTranId = data;
    this.merchantTranId = "TDA0092826";
    if (this.merchantTranId != null) {
      // this.cartPaymentService.setUpiPayment(this.merchantTranId, this.webOrderBillingSetUp.locationCode);
      // this.gstSaleInvcumBOS.locationCode =this.webOrderBillingSetUp.locationCode;
      this.cartPaymentService.setUpiPayment(this.merchantTranId, locationCode);
      this.gstSaleInvcumBOS.locationCode = locationCode;
      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
      this.click();
      // this.navRoute.navigate(["/payment-confirmation"]);
    } else {
      this.toastService.showErrorToast("Payment Failed..!");
    }
    // });
  }
}
