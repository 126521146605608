import { Injectable } from "@angular/core";
import * as SockJS from "sockjs-client";
import { Client } from "@stomp/stompjs";

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  private stompClient: Client;

  public connect(): Client {
    if (!this.stompClient) {
      let socket = new SockJS("https://sse.mobitrix.net/socket");
      this.stompClient = new Client({
        webSocketFactory: () => socket,
        debug: (msg) => console.log(msg),
        reconnectDelay: 5000,
      });
    }
    return this.stompClient;
  }
}
