<div class="firstDiv" style="margin-top: 10%">
    <!-- Show message dynamically with correct color -->
    <div class="mainDiv">
        <h2 class="h2_font_size" [ngClass]="statusClass">{{ statusMessage }}</h2>
    </div>

    <!-- Show Try Again button for Failed or Unknown cases -->
    <div *ngIf="status" class="mainDiv" style="text-align: center">
        <button class="btn btn-primary" (click)="onTryAgain()" id="downloadBtnStyle">
            <span *ngIf="status !== 'Success'">Try Again</span>
            <span *ngIf="status == 'Success'">More Payment</span>
        </button>
    </div>
</div>