import { City } from "./city";

export class LocationDetailsDto {
  locationName: string;
  pin: string;
  address: string;
  lat: number;
  lng: number;
  cityName: string;
  city : City;
}
