import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { SharedModule } from "src/app/shared/shared.module";
import { MakeToOrderPaymentComponent } from "./make-to-order-payment.component";
import { MakeToOrderPaymentRoutes } from "./make-to-order-payment.routing";

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    SharedModule,
    MakeToOrderPaymentRoutes,
  ],
  declarations: [MakeToOrderPaymentComponent],
})
export class MakeToOrderPaymentModule {}
