import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BrowserModule, Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { BehaviorSubject, filter, Subscription, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getWindow } from 'ssr-window';
import { APIFactoryEndPoints } from './common/apifactory-end-points';
import { CanonicalService } from './common/canonical.service';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { UserService } from './shared/services/user.service';
import { FCMMessageRegistration } from './shared/models/FCMMessageRegistration';
import { CommonService } from './shared/services/CommonService';
import { ToastService } from './shared/services/toastr.service';
import { DatePipe } from '@angular/common';
import { WebUserInfo } from './shared/models/web-user-info';
import { LocalStorageService } from './common/LocalStorageService';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Mobitrix-Cart-UI';
  static isBrowser = new BehaviorSubject<boolean>(null);
  currentUrl: string;
  message: any = null;
  fCMMessageRegistration: FCMMessageRegistration;

  userIP: any;
  private getClientIP: Subscription;
  private isConsented: boolean = false;
  cnt: number = 0;
  COOKIE_CONSENT: string = "cookiesAllowed";
  COOKIE_CONSENT_EXPIRE_DAYS: number = 1;

  route: string;
  locality: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;

  webUserInfo: WebUserInfo = new WebUserInfo();
  visitors: any;

  //Subscription
  private saveWebUserInfoSubscription: Subscription;
  private getVisitorCountSubscription: Subscription;

  constructor(
    private browser: BrowserModule,
    private _http: HttpClient,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    private userService: UserService,
    private apiUrl: APIFactoryEndPoints,
    private commonService: CommonService,
    private localStorage: LocalStorageService,
    private toastrService: ToastService,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
  ) {

  }
  ngAfterViewInit() {
    this.canonicalService.setCanonicalURL();
    this.currentUrl = getWindow().location.hostname
    // this._http.get<MetaDefinition[]>(environment.webApiUrl + "get-web-order-meta-data-by-url/" + this.currentUrl).subscribe(res => {
    //   this.setMetaTags(res);
    // })
  }
  ngOnInit() {
    $(".banner").owlCarousel({
      autoHeight: true,
      center: true,
      nav: true,
      items: 1,
      margin: 30,
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setGeoLocation.bind(this), this.handleLocationError.bind(this));
    }
    this.requestPermission();
    this.listen();
    this.getVisitorCount();
    // this.getClientInfo();
    // this.getGoogleMap();
  }

  setGeoLocation(position: { coords: { latitude: any; longitude: any } }) {
    const { coords: { latitude, longitude }, } = position;
    this.userService.setLocation(latitude, longitude);
    this.getGoogleMap(latitude, longitude);
  }

  handleLocationError(error: GeolocationPositionError) {
    // Handle errors in retrieving the geolocation
    this.getClientInfo();
  }

  getGoogleMap(latitude: number, longitude: number) {
    this.mapsAPILoader.load().then(() => {
      this.getAddress(latitude, longitude);
      // this.setCurrentLocation();
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.getAddress(position.coords.latitude, position.coords.longitude);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    const geocoder = new google.maps.Geocoder();
    const latlng = { lat: latitude, lng: longitude };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const address = results[0].address_components;
        this.extractAddressComponents(address);
      } else {
        console.error("Geocoder failed due to:", status);
      }
    });
  }

  extractAddressComponents(address: google.maps.GeocoderAddressComponent[]) {
    for (const component of address) {
      switch (true) {
        case component.types.includes('route'):
          this.route = component.long_name;
          break;
        case component.types.includes('sublocality_level_1'):
          this.locality = component.long_name;
          break;
        case component.types.includes('locality'):
          this.city = component.long_name;
          break;
        case component.types.includes('administrative_area_level_1'):
          this.state = component.long_name;
          break;
        case component.types.includes('country'):
          this.country = component.long_name;
          break;
        case component.types.includes('postal_code'):
          this.postalCode = component.long_name;
          break;
        default:
          break;
      }
    }
    this.getClientInfo();
  }

  getClientInfo() {
    // this.getClientIP = this.commonService.getClientIP(this.apiUrl.getClientIP)
    //   .subscribe((data: any) => {
    this.getClientIPAddress();
    let currentDateTime = this.transform((new Date).toUTCString());
    if (!this.getCookie("inTime")) {
      this.setCookie("inTime", currentDateTime, 1);
    }
    this.webUserInfo.intiatedFrom = this.getIntiatedFrom();
    // this.webUserInfo.clientIp = data;
    this.webUserInfo.clientIp = JSON.parse(this.localStorage.getItem("client_ip"));
    this.webUserInfo.cleintBrowser = this.detectBrowserName();
    this.webUserInfo.cleintDevice = this.isMobileTabletOrPC();
    this.webUserInfo.cleintBrowserVersion = this.getBrowserVersion();
    this.webUserInfo.sessionId = this.getJSessionId();
    this.webUserInfo.inTime = this.getCookie("inTime");
    this.webUserInfo.outTime = currentDateTime;
    this.webUserInfo.emailId = JSON.parse(this.localStorage.getItem("google_email"));
    this.webUserInfo.route = this.route;
    this.webUserInfo.locality = this.locality;
    this.webUserInfo.city = this.city;
    this.webUserInfo.state = this.state;
    this.webUserInfo.country = this.country;
    this.webUserInfo.postalCode = this.postalCode;
    this.webUserInfo.latitude = this.userService.getLocation().lat.toString();
    this.webUserInfo.longitude = this.userService.getLocation().lon.toString();
    console.log(this.webUserInfo)

    this.saveWebUserInfo();
    // });
    const myPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve("foo");
      }, 300);
    });
  }

  getIntiatedFrom(): string {
    let referrer = document.referrer;

    this.activatedRoute.queryParams.pipe(take(1)).subscribe(params => {
      if ('referrer' in params) {
        referrer = params['referrer'];
      }
    });

    return referrer;
  }

  getClientIPAddress() {
    let clientIp = JSON.parse(this.localStorage.getItem("client_ip"));
    if (!clientIp) {
      var xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState == 4 && xhttp.status == 200) {
          const ip = xhttp.responseText;
          console.log(ip);
          this.localStorage.setItem("client_ip", JSON.stringify(ip));
        }
      };
      xhttp.open("GET", "https://api.ipify.org", true); // Ensure the URL starts with https://
      xhttp.send();
    }
  }

  saveWebUserInfo() {
    this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiUrl.inserWebUserInfo, this.webUserInfo).subscribe((data) => {
      if (data) {
        this.localStorage.setItem("web_user_info", JSON.stringify(this.webUserInfo));
      }
    });
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapId }).then(
        (currentToken) => {
          if (currentToken) {
            console.log("we got the token.....");
            // console.log(currentToken);

            if (localStorage.getItem("FirebaseToken") != currentToken) {
              localStorage.setItem("FirebaseToken", currentToken);
              // console.log("Token==" + currentToken);
            } else {
              // console.log("Same Token==" + currentToken);
            }
            this.fCMMessageRegistration = new FCMMessageRegistration();
            this.fCMMessageRegistration.deviceId = localStorage.getItem("DeviceID");
            this.fCMMessageRegistration.locId = 0;
            this.fCMMessageRegistration.applicationName="CUI"
            if (localStorage.getItem("userId") == undefined || localStorage.getItem("userId") == null)
              this.fCMMessageRegistration.locCusId = localStorage.getItem("DeviceID");
            else
              this.fCMMessageRegistration.locCusId = localStorage.getItem("userId");

            this.fCMMessageRegistration.token = currentToken;
            this.fCMMessageRegistration.deviceType = "Cus";
            // console.log("AA=" + this.fCMMessageRegistration);
            // this.commonService.saveFCMRegistrationData(this.apiUrl.getUsersaveTokenIdLocId, this.fCMMessageRegistration)
            //   .subscribe((data: any) => {
            //     console.log("DATA:" + data);
            //   });
            // console.log("BB=" + this.fCMMessageRegistration);
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
      console.log("Message : " + this.message.notification.body);
      this.toastrService.showSuccessToast(this.message.notification.body);
    });
  }

  setMetaTags(tags: MetaDefinition[]) {
    this.metaTagService.addTags(tags)
  }

  /////////////////////////////////////////////////////////
  transform(value: string) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'MM/dd/yyyy h:mm:ss');
    return value;
  }

  getBrowserVersion() {
    var browserVersion = '' + parseFloat(navigator.appVersion);
    var Offset, OffsetVersion, ix;
    var browserAgent = navigator.userAgent;

    if ((OffsetVersion = browserAgent.indexOf("Chrome")) != -1) {
      browserVersion = browserAgent.substring(OffsetVersion + 7);
    }
    // For Microsoft internet explorer 
    else if ((OffsetVersion = browserAgent.indexOf("MSIE")) != -1) {
      browserVersion = browserAgent.substring(OffsetVersion + 5);
    }
    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf("Safari")) != -1) {
      browserVersion = browserAgent.substring(OffsetVersion + 7);
      if ((OffsetVersion = browserAgent.indexOf("Version")) != -1)
        browserVersion = browserAgent.substring(OffsetVersion + 8);
    }
    return browserVersion;
  }
  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
    return;
  }
  getJSessionId() {
    return /SESS\w*ID=([^;]+)/i.test(document.cookie) ? RegExp.$1 : "false";
  }
  isMobileTabletOrPC() {
    var uA = getWindow().navigator.userAgent || getWindow().navigator.vendor;
    if ((/iPad|iPhone|iPod/.test(uA)) || (uA.includes('Mac') && 'ontouchend' in document)) return 'iOS';
    // if (this.getBrowserName().includes("Safari"))
    //   return "Android"
    // else
    //   return "Windows"
    var i, os = ['Windows', 'Android', 'Unix', 'Mac', 'Linux', 'BlackBerry'];
    for (i = 0; i < os.length; i++) if (new RegExp(os[i], 'i').test(uA)) return os[i];
  }
  private getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private deleteCookie(name) {
    this.setCookie(name, '', -1);
  }

  private setCookie(name: string, value: string, expireDays: number, path: string = '') {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  private consent(isConsent: boolean, e: any) {
    if (!isConsent) {
      return this.isConsented;
    } else if (isConsent) {
      this.setCookie(this.COOKIE_CONSENT, '1', this.COOKIE_CONSENT_EXPIRE_DAYS);
      this.isConsented = true;
      e.preventDefault();
    }
  }

  getVisitorCount() {
    this.getVisitorCountSubscription = this.userService.getVisitorCount(this.apiUrl.getVisitorCount, "home").subscribe((data: any) => {
      console.log("get Vistor Count home:::" + data);
      this.visitors = data;
      this.localStorage.setItem('visitors', JSON.stringify(this.visitors));
    });
  }
}
