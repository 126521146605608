<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Wallet</h2>
        <div class="hr"></div>
    </div>
</div>

<div style="margin-top: 2%">
    <div class="section-heading">
        <h3 class="h3_font_size">Wallet Balance: {{totalCredits}}</h3>
    </div>
</div>

<div id="table-container"
    style="width: 96% !important;overflow: auto;padding-top: 1%;margin-left: 2%;margin-right: 2%;">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0"
        style="border-collapse: collapse; width: 100%">
        <caption></caption>

        <ng-container matColumnDef="transactionDate">
            <th scope="col" mat-header-cell *matHeaderCellDef>Transaction Date</th>
            <td mat-cell *matCellDef="let element" id="text_align">
                {{ element.transactionDate }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th scope="col" mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element" id="text_align">
                {{ element.description }}
            </td>
        </ng-container>

        <ng-container matColumnDef="transactionRefNo">
            <th scope="col" mat-header-cell *matHeaderCellDef>Transaction Ref No</th>
            <td mat-cell *matCellDef="let element" id="text_align">
                {{ element.transactionRefNo }}
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="validTill">
            <th scope="col" mat-header-cell *matHeaderCellDef>Valid Till</th>
            <td mat-cell *matCellDef="let element" id="text_align">
                {{ element.validTill }}
            </td>
        </ng-container> -->

        <ng-container matColumnDef="status">
            <th scope="col" mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element" id="text_align">
                {{ element.status }}
            </td>
        </ng-container>

        <ng-container matColumnDef="credits">
            <th scope="col" mat-header-cell *matHeaderCellDef>Credits</th>
            <td mat-cell *matCellDef="let element" id="number_align">
                <span *ngIf="element.status == 'Credit'" style="color: green;">+ {{ element.credits }}</span>
                <span *ngIf="element.status != 'Credit'" style="color: red;">- {{ element.credits }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="balance">
            <th scope="col" mat-header-cell *matHeaderCellDef>Balance</th>
            <td mat-cell *matCellDef="let element" id="number_align">
                {{ element.balance }}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>