<div class="firstDiv" style="margin-top: 10%">
  <div class="mainDiv">
    <h2 class="h2_font_size">Sorry Your Transaction is Failed Try Again!</h2>
  </div>
  <div class="mainDiv" style="text-align: center">
    <button type="button" class="btn btn-primary" (click)="download()">Order History</button>&nbsp;&nbsp;&nbsp;&nbsp;
    <button type="button" class="btn btn-primary" (click)="go()">Try again</button>
  </div>
</div>

<!-- <form
  #form
  ngNoForm
  id="nonseamless"
  method="post"
  name="redirect"
  action="https://apibankingone.icicibank.com/api/MerchantAPI/UPI/v0/CollectPay3/400918"
  target="votar"
>
  <input
    type="text"
    id="encryptText"
    name="encRequest"
    value="{{ encRequest }}"
  />
  <button type="submit">pay</button>
</form> -->