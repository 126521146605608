import { CustomerDeliveryAddress } from "./customerdeliveryaddress";
import { DeliveryAddress } from "./deliveryaddress";
import { Product } from "./product";

export class ProductOrderSummary {
  cartProducts: Product[];
  totalPrice: number;
  totTaxValue: number;
  totDiscount: number;
  gstAmt: number;
  cessAmt: number;
  pincode: number;
  totBasePremiumFlavourPrice: number;
  totPremiumFlavourPrice: number;
  // deliveryAddress:DeliveryAddress;
  shippingCharge: number;
  deliveryAddressRefNo: number;
  deliveryAddress: CustomerDeliveryAddress;
}
