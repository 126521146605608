import { SafeUrl } from "@angular/platform-browser";
import { Cess } from "./cess";
import { ProductSubGroup } from "./productsubgroup";
import { ProductsWithWebOrderOffers } from "./productswithweborderoffers";
import { Tax } from "./tax";
import { ViewOfferSetUpDto } from "./viewoffersetupdto";
import { ProductThumbnail } from "./product-thumbnail";

export class Product {
  // double slash after right of the property are unused properties.we should review on this
  $key: string;
  productId: number; //
  productName: string; //
  productCategory: string; //
  productPrice: number; //
  productDescription: string; //
  productImageUrl: string; //
  productAdded: number; //
  productQuatity: number; //
  ratings: number; //
  favourite: boolean; //
  productSeller: string; //
  //============================================================start============================
  product_Name: string;
  product_Code: number;
  product_Picture: string;
  customizedProductPicture: string;
  description: string;
  net_Sale_Price: number;
  sale_Price: number;
  minimum_Order_Quantity: number;
  minimum_Order_Time: number;
  return_Allowed: string;
  exchange_Allowed: string;
  productSubGroup: ProductSubGroup;
  gst_Rate_Code: number;
  cess_Rate_Code: number;
  shelf_Life_In_Hours: number;
  product_Unit: string;
  product_Type: string;
  max_Discount_Percentage: number;
  hsn_Code: string;
  subGroupCode: number;

  orderQuantity: number;
  offers: number = 0;
  offerDetails: ViewOfferSetUpDto[] = [];
  discountAmt: number = 0.0;
  discountRate: number = 0.0;
  cessRate: number;
  taxRate: number;
  tax: Tax;
  cess: Cess;
  taxAmount: number;
  cessAmount: number;
  cgstAmt: number;
  sgstAmt: number;
  promotionOfferId: string;
  offerId: string;
  pic: string;
  subTotal: number;
  orderQuantityKgs: number;
  cartProductType: string;
  deliveryDate: string;
  deliveryTime: string;
  maxDeliveryDate: string;
  maxDeliveryTime: string;
  color: string;
  shape: string;
  dimension: string;
  composition: string;
  note: string;
  timeSlot: string;
  defaultDate: string;
  defaultTimeSlotAvl: string[] = [];
  timeSlotAvl: string[] = [];
  status: string;
  food_Type: string;
  premiumFlavourPrice: number = 0;
  basePremiumFlavourPrice: number = 0;
  systemStock: number;
  type: string;
  portion_Weight: string;
  freeByProductCode: number;
  designForProductCode: number;
  productThumbnails: ProductThumbnail[];
  is_Default: boolean;
  is_Default_Design: boolean;
  designProductCode: number;
  designProductName: string;
  designProductPicture: string;
  designProductNetSalePrice: number = 0;
  designTax: Tax;
  designTaxAmount: number = 0;
  designCessAmount: number;
  selectedDate: string;
  sharingUrl: string;
  isShowProductMessage: boolean;
  fileNames: string[];
  subMtoType: string;
  customizeType: string;
  no_Of_Images: number;

  image: SafeUrl;
  netAmt: number;
  netAmountForLocation: number;
  taxableAmount: number;
  rate: number;
  totalAmount: number;

  freeProductNetSalePrice: number;
}
