<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Franchise Enquiry</h2>
        <div class="hr"></div>
    </div>
</div>



<section id="contact">
    <!-- <span style="display: flex;justify-content: center;">
    <h4 *ngIf="franchiseSetupList.length > 0" style="cursor: pointer;" ="onExpandClick(isExpand=!isExpand)">
      Cities available for franchise</h4>
  </span> -->
    <div class="container">
        <div class="row" id="contactRow">
            <div class="text-center">
                <h4 class="h4_style">
                    Welcome to 1 Bake Shop, your premier destination for bakery franchise opportunities! Our franchise
                    program
                    offers a
                    proven track record of
                    success, comprehensive training, and dedicated support to help you thrive in the industry.
                    <br> Discover the benefits of partnering with us and learn how you can become a part of our growing
                    franchise
                    family.
                    <br> Explore our franchise opportunities today and take the first step towards your entrepreneurial
                    journey!
                </h4>

                <ul class="contact-info">
                    <li style="font-family: myFirstFont; font-size: 30px; color: #e91e63">
                        1 Bake Shop
                    </li>
                    <br />
                    <li class="li_clr">Bharti Restaurants Pvt Ltd.</li>
                    <br />
                    <li class="li_clr">
                        <i class="fa fa-phone info_clr"></i>+91 7978875088 &nbsp;&nbsp;
                        <i class="fa fa-envelope info_clr"></i><a
                            href="mailto:associate@1bakeshop.com">associate&#64;1bakeshop.com</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row" id="contactRow">
            <div class="form-style" id="contact_form" class="col-md-5 col-lg-7 col-xs-12">
                <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
                    <div class="form-group" style="text-align: center">

                        <mat-card id="name" class="form-control input-field"
                            style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input type="text" matInput formControlName="name" required />
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Gender" formControlName="gender">
                                    <mat-option *ngFor="let genderNames of genders" [value]="genderNames">
                                        {{genderNames}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <!-- <mat-card class="form-control input-field" style="padding: 0px;">
                        <mat-form-field>
                            <mat-label>DOB</mat-label>
                            <input type=" text" name="dob" required onfocus="(this.type='date')" matInput
                                formControlName="dob" required />
                        </mat-form-field>
                    </mat-card> -->

                        <mat-card class="form-control input-field" style="padding: 0px;">
                            <mat-form-field>
                                <input readonly matInput [matDatepicker]="ricker" placeholder="DOB"
                                    style="cursor: pointer;" formControlName="dob" [max]="currentDate"
                                    (click)="ricker.open()">
                                <mat-datepicker-toggle matSuffix [for]="ricker"></mat-datepicker-toggle>
                                <mat-datepicker #ricker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </mat-card>

                        <!-- <mat-card class="form-control input-field"
                            style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>D.O.B</mat-label>
                                <input type="text" style="width: 20px;" matInput [matDatepicker]="licker" onfocus="(this.type='date')" formControlName="dob"
                                    required />
                                    <mat-datepicker-toggle matSuffix [for]="licker"></mat-datepicker-toggle>
                                <mat-datepicker #licker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </mat-card> -->

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Maritial Status" formControlName="maritialStatus">
                                    <mat-option *ngFor="let statusValues of maritialStatus" [value]="statusValues">
                                        {{statusValues}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>WhatsApp No</mat-label>
                                <input type="number" matInput formControlName="phone" pattern="[6-9]{1}[0-9]{9}"
                                    (keyup)="checkOtp($any($event.target).value)" maxlength="10" required />
                                <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'No'" class="clearBtn"
                                    (click)="onVerifyClick()">Verify</span>
                                <span *ngIf="isDisabledSubmit && otpVerficationStatus == 'Yes'"
                                    class="clearBtn1">Verified</span>
                                <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'Exceeds'"
                                    class="clearBtn2">Exceeds</span>
                            </mat-form-field>
                        </mat-card>

                        <!-- <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                        <mat-form-field>
                            <mat-label>WhatsApp No</mat-label>
                            <input type="number" matInput formControlName="phone" pattern="[6-9]{1}[0-9]{9}"
                                (keyup)="checkOtp($any($event.target).value)" required />
                            <span *ngIf="!isDisabledSubmit" class="clearBtn" (click)="onVerifyClick()">Verify</span>
                            <span *ngIf="isDisabledSubmit" class="clearBtn1">Verified</span>
                        </mat-form-field>
                    </mat-card> -->

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input type="Email" matInput formControlName="email" />
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Education" formControlName="education">
                                    <mat-option *ngFor="let courses of courseList" [value]="courses">
                                        {{courses}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Occupation" formControlName="occupation">
                                    <mat-option *ngFor="let occupation of occupationList" [value]="occupation">
                                        {{occupation}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Franchise Type" formControlName="franchiseType">
                                    <mat-option [value]="null"> None </mat-option>
                                    <mat-option *ngFor="let type of franchiseTypes" [value]="type">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <!-- <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 3px">
                        <mat-form-field>
                            <mat-label>Franchise Type</mat-label>
                            <input id="franchiseType" type="text" matInput formControlName="franchiseType" />
                        </mat-form-field>
                    </mat-card> -->

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select id="franchiseType" placeholder="City" formControlName="cityApplied">
                                    <mat-option *ngFor="let city of cities" [value]="city.cityName">
                                        {{city.cityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 1px">
                            <mat-form-field>
                                <mat-label>Location</mat-label>
                                <input type="text" matInput formControlName="areaApplied" />
                            </mat-form-field>
                        </mat-card>

                        <!-- <input type="text" name="name" class="form-control input-field" placeholder="Name"
                        formControlName="name" required />

                    <select name="gender" formControlName="gender" class="form-control input-field" required>
                        <option value="" disabled selected hidden>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Others</option>
                    </select>

                    <input type="text" name="dob" class="form-control input-field" placeholder="DOB" required
                        onfocus="(this.type='date')" formControlName="dob" />

                    <select name="maritalStatus" formControlName="maritialStatus" class="form-control input-field"
                        required>
                        <option value="" disabled selected hidden>
                            Maritial Status
                        </option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                    </select>

                    <div class="text-container">
                        <input type="number" name="phone" class="form-control input-field" placeholder="WhatsApp No"
                            formControlName="phone" pattern="[6-9]{1}[0-9]{9}"
                            (keyup)="checkOtp($any($event.target).value)" required />
                        <span *ngIf="!isDisabledSubmit" class="clearBtn" (click)="onVerifyClick()">Verify</span>
                        <span *ngIf="isDisabledSubmit" class="clearBtn1">Verified</span>
                    </div>

                    <input type="number" name="phone" class="form-control input-field" placeholder="Mobile No"
                        formControlName="phone" required />

                    <input type="email" name="email" class="form-control input-field" placeholder="Email"
                        formControlName="email" required />

                    <select name="education" formControlName="education" class="form-control input-field" required>
                        <option value="" disabled selected hidden>Education</option>
                        <option value="Vocational">Vocational</option>
                        <option value="UG">Under Graduate/Secondary School</option>
                        <option value="Graduate">Graduate</option>
                        <option value="PG">Post Graduate/Mastes</option>
                    </select>

                    <select name="occupation" formControlName="occupation" class="form-control input-field"
                        required>
                        <option value="" disabled selected hidden>Occupation</option>
                        <option value="Student">Student</option>
                        <option value="SelfEmployed">Self Employed</option>
                        <option value="GOVT">Service-Govt</option>
                        <option value="PVT">Service-Private</option>
                        <option value="Others">Others</option>
                    </select>

                    <input id="franchiseType" type="text" class="form-control input-field"
                        placeholder="Franchise Type" formControlName="franchiseType" [readonly]="isReadonly"
                        required />

                    <input type="text" name="cityAppliedFor" class="form-control input-field"
                        placeholder="City intrested in" formControlName="cityApplied" [readonly]="isReadonly"
                        required /> -->

                        <a class="btn" id="submit_btn">
                            <div class="btn-line"></div>
                            <button type="submit" class="btn-line btn-line-shift"
                                style="background-color: #d0324b; color: white">
                                Submit
                            </button> Send Message
                            <!-- <button type="submit" class="btn-line btn-line-shift" [disabled]="!isDisabledSubmit"
                            style="background-color: #d0324b; color: white">
                            Submit
                        </button> Send Message -->
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="franchise_parent_div">
        <div class="frachise_list" *ngIf="isShowMasterFranchise">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Cloud Bakery</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <mat-card class="contain_card" fxFlex="100">
                        <mat-form-field class="selectOption">
                            <mat-select placeholder="Select State"
                                (selectionChange)="onStateSelect($event.value,'Cloud Bakery')">
                                <mat-option *ngFor="let c of stateList[0]" [value]="c.refNo">
                                    {{c.stateName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of masterFranchiseList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="opportunity_h6">Cloud Bakery Franchise opportunity in </h6>
                                <h6 class="h6_custom wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="frachise_list" *ngIf="isShowFranchiseStore">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Franchise Store</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select State"
                                        (selectionChange)="onStateSelect($event.value,'Franchise Store')">
                                        <mat-option *ngFor="let c of stateList[1]" [value]="c.refNo">
                                            {{c.stateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select District" [formControl]="districtControl"
                                        (selectionChange)="onDistrictSelect($event.value,'Franchise Store',1)">
                                        <mat-option *ngIf="districtList[1]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let district of districtList[1]" [value]="district.id">
                                            {{ district.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 34%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select City" [formControl]="cityControl"
                                        (selectionChange)="onCitySelect($event.value,'Franchise Store')">
                                        <mat-option *ngIf="cityList[1]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let city of cityList[1]" [value]="city.refNo">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of franchiseStoreList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="opportunity_h6">Bakery Franchise opportunity in </h6>
                                <h6 class="h6_custom  wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="frachise_list" *ngIf="isShowKiosk">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Kiosk</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select State"
                                        (selectionChange)="onStateSelect($event.value,'Kiosk')">
                                        <mat-option *ngFor="let c of stateList[2]" [value]="c.refNo">
                                            {{c.stateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select District" [formControl]="districtControl2"
                                        (selectionChange)="onDistrictSelect($event.value,'Kiosk',2)">
                                        <mat-option *ngIf="districtList[2]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let district of districtList[2]" [value]="district.id">
                                            {{ district.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 34%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select City" [formControl]="cityControl2"
                                        (selectionChange)="onCitySelect($event.value,'Kiosk')">
                                        <mat-option *ngIf="cityList[2]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let city of cityList[2]" [value]="city.refNo">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of kioskList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="opportunity_h6">Kiosk Franchise opportunity in </h6>
                                <h6 class="h6_custom wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page-scroll">
        <a (click)="scrollToElementById('page-down')" class="back-to-top1">
            <i class="fa fa-angle-down" id="scroll_icon"></i></a>
    </div>

    <h4 class="h4_faqs text-center">FRANCHISE FAQs</h4>
    <div *ngFor="let faq of franchiseFaqs;let i = index;" class="franchise_faqs_container">
        <div style="width: 90%;padding: 10px;">
            <p class="franchise_faqs_p_qus">
                Q. {{faq.question}}
            </p>
            <p class="franchise_faqs_p_ans" [innerHTML]="getSanitizedAnswer(faq.answer)">
            </p>
        </div>
    </div>

    <p style="color: white;text-align: center;" id="page-down"><span>{{visitors}}</span> </p>
</section>