import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { UserService } from 'src/app/shared/services/user.service';
import { getWindow } from 'ssr-window';
import { VerifyMobileNoDialogComponent } from '../../verify-mobile-no-dialog/verify-mobile-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { NavbarComponent } from 'src/app/views/base/index/navbar/navbar.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-user-data',
  templateUrl: './delete-user-data.component.html',
  styleUrls: ['./delete-user-data.component.scss']
})
export class DeleteUserDataComponent implements OnInit {

  profileForm: UntypedFormGroup;
  googleCustomer: GoogleCustomer;

  currentUrl: string;

  isDisabledSubmit: boolean = false;
  phoneNo: string = "";

  private getProfileSubscription: Subscription;
  private onProfileUpdateSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private api: APIFactoryEndPoints,
    public dialog: MatDialog,
    private router: Router,
    private toastService: ToastService,
    private navbarComponent: NavbarComponent,
  ) { }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname;
    this.createProfileForm();
    this.getProfileDetails();
  }

  createProfileForm() {
    this.profileForm = this.formBuilder.group({
      name: [""],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phoneNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
      customerStatus: [""],
    });
  }

  getProfileDetails() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    if (this.googleCustomer) {
      this.getProfileSubscription = this.userService.getProfiledetails(this.api.getGoogleCustomer, this.googleCustomer.email, this.currentUrl)
        .subscribe((data) => {
          console.log(data);
          if (data != null) {
            this.profileForm.patchValue({
              name: data.name,
              email: data.email,
              phoneNo: data.phoneNo,
              customerStatus: data.customerStatus,
            });
          }
        });
    } else {
      this.router.navigate(["/"]);
    }
  }

  onVerifyClick() {
    if (this.profileForm.get("phoneNo").invalid) {
      this.toastService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.profileForm.get("phoneNo").value,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      this.isDisabledSubmit = result;
      this.phoneNo = result.mblNo;
      this.onDeleteUserClick();
    });
  }

  checkOtp(value: string) {
    console.log(this.phoneNo);
    if (value.length == 10) {
      this.checkNumberVerfiedSubscription = this.userService.checkOTPSentOrNot(this.api.checkOTPSentOrNot, value).subscribe((data: string) => {
        if (data == "Yes") {
          this.isDisabledSubmit = true;
        }
        if (data == "No") {
          this.isDisabledSubmit = false;
        }
      });
    }
  }

  onDeleteUserClick() {
    if (this.profileForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    if (!this.isDisabledSubmit) {
      this.toastService.showErrorToast("Verify WhatsApp No");
      return;
    }
    this.profileForm.get("customerStatus").setValue("Inactive");
    this.onProfileUpdateSubscription = this.userService.updateCustomerStatus(this.api.updateCustomerStatus, this.googleCustomer.refNo, "Inactive")
      .subscribe((data) => {
        this.toastService.showSuccessToast("Deleted Successfully");
        this.navbarComponent.logout(true);
      });

  }

}
