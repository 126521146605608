<div style="margin-top: 3%;">
    <div class="section-heading">
        <h2 class="h2_font_size">Make Payment</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="payment-container">
    <form [formGroup]="createPaymentForm" (ngSubmit)="onPayClick()">
        <div class="form-group">
            <label for="customerName">Customer Name:</label>
            <input id="customerName" type="text" class="form-control" placeholder="Enter your name"
                formControlName="name"
                [ngClass]="{ 'is-invalid': createPaymentForm.get('name')?.touched && createPaymentForm.get('name')?.invalid }"
                required />
        </div>

        <div class="form-group">
            <label for="email">Email:</label>
            <input id="email" type="email" class="form-control" placeholder="Enter your email" formControlName="email"
                [ngClass]="{ 'is-invalid': createPaymentForm.get('email')?.touched && createPaymentForm.get('email')?.invalid }"
                required />
        </div>

        <div class="form-group">
            <label for="mobileNumber">Mobile Number:</label>
            <input id="mobileNumber" type="tel" class="form-control" placeholder="Enter your mobile number"
                formControlName="mobileNo" maxlength="10" (keypress)="validateNumber($event)"
                [ngClass]="{ 'is-invalid': createPaymentForm.get('mobileNo')?.touched && createPaymentForm.get('mobileNo')?.invalid }"
                required />

        </div>

        <div class="form-group">
            <label for="reason">Reason:</label>
            <select id="reason" class="form-control-spinner" formControlName="reason"
                [ngClass]="{ 'is-invalid': createPaymentForm.get('reason')?.touched && createPaymentForm.get('reason')?.invalid }"
                required>
                <option value="" disabled selected>Select a reason</option>
                <option *ngFor="let reason of reasons" [value]="reason">
                    {{ reason }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="amount">Amount:</label>
            <input id="amount" type="number" class="form-control" placeholder="Enter amount" formControlName="amount"
                [ngClass]="{ 'is-invalid': createPaymentForm.get('amount')?.touched && createPaymentForm.get('amount')?.invalid }"
                required />
        </div>

        <button type="submit" class="btn btn-primary">Pay Now</button>
    </form>

</div>