<div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="product-image">
          <div class="view hm-zoom z-depth-2" style="cursor: pointer;">
            <img src="{{webUrl}}{{product.product_Picture}}" [alt]="product.product_Name" width="100%"
              class="img-fluid rounded" />
          </div>
        </div>
      </div>
  <div class="col-sm-8">
      <div class="product-detail">
        <h5 class="product-head">Product Details</h5>
        <table class="table" cellspacing="0" style="max-height: 28px;">
          <tbody>
            <tr>
              <th scope="row">Product Name</th>
              <td>{{ product.product_Name }}</td>
            </tr>
            <tr>
              <th scope="row">Sub Group</th>
              <td>{{ product.productSubGroup.productSubGroupName }}</td>
            </tr>
            <tr>
              <th scope="row">Group</th>
              <td>{{ product.productSubGroup.productGroup.productGroupName }}</td>
            </tr>
            <tr>
              <th scope="row">Matrix</th>
              <td>{{ product.productSubGroup.productGroup.productMatrix.productMatrixName }}</td>
            </tr>
            <tr>
              <th scope="row">Product Description</th>
              <td>{{ product.description }}</td>
            </tr>
            <tr>
              <th scope="row">Min Quantity(Kgs)</th>
              <td>{{ product.minimum_Order_Quantity }}</td>
            </tr>
            <tr>
              <th scope="row">Price</th>
              <td>{{ product.net_Sale_Price}}</td>
            </tr>
            <tr *ngIf="product.return_Allowed == 'true'">
              <th scope="row">Return Allowed</th>
              <td>Yes</td>
            </tr>
            <tr *ngIf="product.return_Allowed == 'false'">
              <th scope="row">Return Allowed</th>
              <td>No</td>
            </tr>
            <tr *ngIf="product.exchange_Allowed == 'true'">
              <th scope="row">Exchange Allowed</th>
              <td>Yes</td>
            </tr>
            <tr *ngIf="product.exchange_Allowed == 'false'">
              <th scope="row">Exchange Allowed</th>
              <td>No</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>