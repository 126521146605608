<div style="margin-top: 3%;">
    <div class="section-heading">
        <h2 class="h2_font_size">Profile</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="form-style" id="contact_form" class="col-lg-7 col-md-7 text-center profile-line">
    <!-- <div id="contact_results"></div> -->
    <form [formGroup]="profileForm" (ngSubmit)="onProfileUpdateClick()">
        <div class="form-group">

            <div class="input-wrap">
                <input type="text" formControlName="name" required />
                <label>Name</label>
            </div>

            <!-- <div class="input-wrap">
                <input type="text" formControlName="lastName" required />
                <label>Last Name</label>
            </div> -->

            <div class="input-wrap">
                <input type="text" onfocus="(this.type='date')" formControlName="dob" [attr.max]="minDate" required />
                <label>D.O.B</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="phoneNo" required readonly/>
                <label>Mobile</label>
            </div>

            <div class="input-wrap">
                <input type="Email" formControlName="email" required readonly/>
                <label>Email</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="spouseName" />
                <label>Spouse Name</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="spouseDob" onfocus="(this.type='date')" [attr.max]="minDate" />
                <label>Spouse D.O.B</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="anniversaryDate" onfocus="(this.type='date')" />
                <label>Anniversary Date</label>
            </div>

            <!-- <input type="text" class="form-control input-field" placeholder="First Name" formControlName="firstName"
        required />
      <input type="text" class="form-control input-field" placeholder="Last Name" formControlName="lastName" required /> 
      <input type="text" class="form-control input-field" placeholder="D.O.B" onfocus="(this.type='date')"
        formControlName="dob" required />
      <input type="number" class="form-control input-field" placeholder="Mobile" formControlName="phoneNo" required />
      <input type="email" class="form-control input-field" placeholder="Email" formControlName="email" readonly
        required />
      <input type="text" class="form-control input-field" placeholder="Spouse Name" formControlName="spouseName"
        required />
      <input type="text" class="form-control input-field" placeholder="Spouse D.O.B" onfocus="(this.type='date')"
        formControlName="spouseDob" required />-->

            <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white;">
                    UPDATE
                </button> UPDATE
            </a>
        </div>
    </form>
</div>