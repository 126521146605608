import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileComponent } from './profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProfileRoutes } from './profile-routing.module';


@NgModule({
  declarations: [ProfileComponent],
  imports: [ReactiveFormsModule,MatTabsModule,MatSelectModule,FormsModule,MatInputModule,MatCardModule,MatIconModule,MatTableModule,CommonModule, SharedModule, ProfileRoutes]
})
export class ProfileModule { }
