import { Routes, RouterModule } from "@angular/router";
import { MakeCakeComponent } from './make-cake.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "make-cake-app",
        component: MakeCakeComponent,
      },
    ],
  },
];

export const MakeCakeRoutes = RouterModule.forChild(routes);
