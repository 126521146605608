import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile.component';


const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "profile-app",
        component: ProfileComponent,
      },
    ],
  },
];

export const ProfileRoutes = RouterModule.forChild(routes);

