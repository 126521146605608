import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CustomerDTO } from "src/app/common/customerDto";
import { CustomerDeliveryAddress } from "../models/customerdeliveryaddress";
import { CustomerPaymentInformation } from "../models/customerpaymentinformation";
import { GstSaleInvCumBosDto } from "../models/gstsaleinvcumbosdto";
import { IciciTransactionStatus } from "../models/icicitransactionstatus";
import { ProductOrderSummary } from "../models/productordersummary";
import { UpiCodeResponse } from "../models/upicoderesponse";
import { UpiPayment } from "../models/upipayment";
import { AuthService } from "./auth.service";
import {
  HandleError,
  HttpErrorHandlerService,
} from "./http-error-handler.service";
import { ToastService } from "./toastr.service";
import { WalletOffers } from "../models/wallet-offers";
import { WalletOfferRequestDto } from "../models/wallet-offer-request-dto";
import { MakePaymentWebMisc } from "../models/make-payment-web-misc";

@Injectable({
  providedIn: "root",
})
export class CartPaymentService {
  productOrderSummary: ProductOrderSummary;
  merchantTranId: string;
  locId: number;
  upiPayment: UpiPayment = new UpiPayment();

  private handleError: HandleError;

  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandlerService
  ) {
    this.handleError = httpErrorHandler.createHandleError("CartPaymentService");
  }

  sendUpiPayment(url: string, obj: UntypedFormGroup): Observable<any> {
    return this.http.post(url, obj.value, { responseType: "text" });
  }

  setUpiPayment(merchantTranId: string, locId: number) {
    this.upiPayment.merchantTranId = merchantTranId;
    this.upiPayment.locId = locId;
  }

  getUpiPayment() {
    return this.upiPayment;
  }

  getUpiTransactionDetails(url: string, merchantTranId: string, locId: number): Observable<any> {
    let params = new HttpParams();
    params = params.append("merchantTnxId", merchantTranId);
    params = params.append("locId", "" + locId);
    return this.http
      .get<IciciTransactionStatus>(url, { params: params })
      .pipe(catchError(this.handleError("getUpiTransactionDetails()", null)));
  }

  insertCustomerPaymnetInformation(url: string, req: CustomerPaymentInformation) {
    return this.http.post(url, req, { responseType: "json" });
  }

  getQrCode(url: string, locId: number, amount: number, paymentType: string) {
    let params = new HttpParams();
    params = params.append("locId", "" + locId);
    params = params.append("amount", "" + amount);
    params = params.append("paymentType", paymentType);
    return this.http.get<UpiCodeResponse>(url, { params: params });
  }

  insertWebOrder(url: string, req: GstSaleInvCumBosDto) {
    return this.http.post<any>(url, req, {
      responseType: "json",
    });
  }

  insertWebOrderArray(url: string, req: GstSaleInvCumBosDto[]) {
    return this.http.post<GstSaleInvCumBosDto[]>(url, req, {
      responseType: "json",
    });
  }

  insertWebOrderTemp(url: string, orderId: string, req: any) {
    return this.http.post<GstSaleInvCumBosDto>(url + "/" + orderId, req, {
      responseType: "json",
    });
  }

  insertCustomerBillingAddress(url: string, customerDTO: CustomerDTO) {
    return this.http.post<any>(url, customerDTO, { responseType: "json" });
  }

  insertDeliveryDetails(url: string, deliveryDTO: CustomerDeliveryAddress) {
    return this.http.post<any>(url, deliveryDTO, { responseType: "json" });
  }

  getCCAvenueTransactionDetails(url: string, merchantTranId: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("merchantTnxId", merchantTranId);
    return this.http.get<IciciTransactionStatus>(url, { params: params })
      .pipe(catchError(this.handleError("getUpiTransactionDetails()", null)));
  }

  getTempData(url: string, order_id: string) {
    return this.http.get(url + "/" + order_id, {
      responseType: "json",
    });
  }

  getWalletOffers(url: string, walletOfferRequestDto: WalletOfferRequestDto) {
    return this.http.post<WalletOffers[]>(url, walletOfferRequestDto, { responseType: "json" });
  }

  getGatewaySetup(url: string, locId: number, provider: string) {
    return this.http.get<any>(url + "/" + locId + "/" + provider)
  }

  insertPayment(url: string, req: MakePaymentWebMisc) {
    return this.http.post<any>(url, req, { responseType: "json" });
  }
}
