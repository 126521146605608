import { Injectable } from "@angular/core";
import { interval } from "rxjs";

@Injectable()
export class Constant {

  storePickupRoutings: string[] = ['cart-items', 'delivery-address', 'order-summary', 'order-summary/checkout', 'process-payment'];
  deliveryChangeRoutings: string[] = ['make-product', 'customized-product'];

  isStorePickupRoute(currentUrl: string): boolean {
    const basePath = currentUrl.split('?')[0]; // Extract the base path without query parameters
    return this.storePickupRoutings.includes(basePath);
  }  

  isDeliveryChangeRoute(currentUrl: string): boolean {
    const basePath = currentUrl.split('?')[0]; // Remove query parameters
    return this.deliveryChangeRoutings.some(route => basePath.startsWith(route));
  }

} 
