import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { Product } from 'src/app/shared/models/product';
import { AddressService } from 'src/app/shared/services/address.service';
import { PaymentService } from 'src/app/shared/services/payment.service';
import { PinDialogService } from 'src/app/shared/services/pin-dialog.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  webUrl: string;
  product: Product = new Product();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private pincodeDialogService: PinDialogService,
    private addressService: AddressService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private paymentService: PaymentService
  ) { }

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.getPaymentDetails();
  }

  getPaymentDetails() {
    this.product = this.paymentService.getProduct();
    console.log(this.product);
  }
}
