export class MakePaymentWebMisc {

    name: string;
    email: string;
    mobileNo: string;
    locationCode: string;
    orderId: string;
    makePaymentWebMiscDetails: MakePaymentWebMiscDetails[];
}

export class MakePaymentWebMiscDetails {
    reason: string;
    amount: number;
    orderId: string;
    status: string;
}
