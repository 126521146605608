// Core Dependencies
import { CommonModule } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from "@angular/router";
import { MatTooltipModule } from '@angular/material/tooltip';
import { QRCodeModule } from "angularx-qrcode";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { SharedModule } from "../../../shared/shared.module";
import { BestProductComponent } from "./best-product/best-product.component";
import { CartPaymentComponent } from "./cart-payment/cart-payment.component";
import { CartProductsComponent } from "./cart-products/cart-products.component";
import { FavouriteProductsComponent } from "./favourite-products/favourite-products.component";
import { GeneralTermDialogComponent } from "./general-term-dialog/general-term-dialog.component";
import { GstinDialogComponent } from "./gstin-dialog/gstin-dialog.component";
import { InvoicePreviewDialogComponent } from "./invoice-preview-dialog/invoice-preview-dialog.component";
import { MakeProductComponent } from "./make-product/make-product.component";
import { MakeToOrderPinDialogComponent } from "./make-to-order-pin-dialog/make-to-order-pin-dialog.component";
import { OfferDialogComponent } from "./offer-dialog/offer-dialog.component";
import { PaymentConfirmationComponent } from "./payment-confirmation/payment-confirmation.component";
import { PaymentFailComponent } from "./payment-fail/payment-fail.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { PinDialogComponent } from "./pin-dialog/pin-dialog.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ProductDeliveryAddressComponent } from "./product-delivery-address/product-delivery-address.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductOrderSummaryComponent } from "./product-order-summary/product-order-summary.component";
// Components
import { ProductComponent } from "./product.component";
// configuration and services
import { ProductRoutes } from "./product.routing";
import { ShippingDialogComponent } from "./shipping-dialog/shipping-dialog.component";
import { MakeToOrderConfirmationComponent } from "./make-to-order-confirmation/make-to-order-confirmation.component";
import { ProcessPaymentComponent } from "./process-payment/process-payment.component";
import { RefundAndCancellationDialogComponent } from "./refund-and-cancellation-dialog/refund-and-cancellation-dialog.component";
import { PaymentHistoryComponent } from "./payment-history/payment-history.component";
import { PaymentStatusComponent } from "./payment-status/payment-status.component";
import { WalletTransactionComponent } from './wallet-transaction/wallet-transaction.component';
import { WalletConfirmationDialogComponent } from './wallet-confirmation-dialog/wallet-confirmation-dialog.component';
import { AgmCoreModule } from "@agm/core";
import { SearchProductListComponent } from './search-product-list/search-product-list.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ProductDesignDialogComponent } from './product-design-dialog/product-design-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DeleteUserDataComponent } from './delete-user-data/delete-user-data.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { OfferDetailsDialogComponent } from './offer-details-dialog/offer-details-dialog.component';
import { HomeComponent } from './home/home.component';
import { DeliveryTimeDialogComponent } from './delivery-time-dialog/delivery-time-dialog.component';
import { StorePickupDialogComponent } from './store-pickup-dialog/store-pickup-dialog.component';
import { CustomizeProductComponent } from './customize-product/customize-product.component';
import { MatButtonModule } from "@angular/material/button";
import { GenerateQrCodeComponent } from './generate-qr-code/generate-qr-code.component';
import { MyCouponComponent } from "./my-coupon/my-coupon.component";
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { MakePaymentStatusComponent } from './make-payment-status/make-payment-status.component';
import { FeedbackDetailsComponent } from './feedback-details/feedback-details.component';
import { ImagePreviewDialogComponent } from './image-preview-dialog/image-preview-dialog.component';
import { B2bOnBoardingComponent } from './b2b-on-boarding/b2b-on-boarding.component';
import { NgxScannerQrcodeModule } from "ngx-scanner-qrcode";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import { NearestLocationDialogComponent } from './nearest-location-dialog/nearest-location-dialog.component';
import { AgreedTermsDialogComponent } from './agreed-terms-dialog/agreed-terms-dialog.component';
import { NgbRating } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    AgmCoreModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    MatListModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    CommonModule,
    RouterModule.forChild(ProductRoutes),
    SharedModule,
    MatExpansionModule,
    MatRadioModule,
    MatProgressBarModule,
    NgxMaterialTimepickerModule,
    QRCodeModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSliderModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgxScannerQrcodeModule,
    ZXingScannerModule,
    NgbRating
  ],
  declarations: [
    ProductComponent,
    BestProductComponent,
    ProductListComponent,
    ProductDetailComponent,
    FavouriteProductsComponent,
    CartProductsComponent,
    PinDialogComponent,
    MakeProductComponent,
    MakeToOrderPinDialogComponent,
    OfferDialogComponent,
    ProductOrderSummaryComponent,
    CartPaymentComponent,
    ProductDeliveryAddressComponent,
    PaymentConfirmationComponent,
    PaymentSuccessComponent,
    PaymentFailComponent,
    PrivacyPolicyComponent,
    GeneralTermDialogComponent,
    ShippingDialogComponent,
    GstinDialogComponent,
    InvoicePreviewDialogComponent,
    MakeToOrderConfirmationComponent,
    ProcessPaymentComponent,
    RefundAndCancellationDialogComponent,
    PaymentHistoryComponent,
    PaymentStatusComponent,
    WalletTransactionComponent,
    WalletConfirmationDialogComponent,
    SearchProductListComponent,
    ProductDesignDialogComponent,
    ConfirmationDialogComponent,
    DeleteUserDataComponent,
    PrivacyPolicyPageComponent,
    OfferDetailsDialogComponent,
    HomeComponent,
    DeliveryTimeDialogComponent,
    StorePickupDialogComponent,
    CustomizeProductComponent,
    MyCouponComponent,
    GenerateQrCodeComponent,
    MakePaymentComponent,
    MakePaymentStatusComponent,
    FeedbackDetailsComponent,
    ImagePreviewDialogComponent,
    B2bOnBoardingComponent,
    NearestLocationDialogComponent,
    AgreedTermsDialogComponent,
  ],
  exports: [BestProductComponent],
   schemas: [NO_ERRORS_SCHEMA],
})
export class ProductModule { }
