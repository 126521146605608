import { WalletOffers } from "./wallet-offers";

export class WalletPayment {

    refNo: string;
    amount: number;
    fromLocationCode: number;
    specialOffer: string;
    specialOfferAmount: number;

    appliedOfferList: WalletOffers[] = [];
}
