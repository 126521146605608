<div class="container">
    <div class="mbr-section mbr-section__container mbr-section__container--middle">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 text-xs-center" id="div1_style">
                    <h2 class="mbr-section-title h2_font_size">
                        Shopping Cart
                        <br />
                    </h2>
                    <div class="hr"></div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="cartProducts.length === 0" class="col-md-12">
        <h4 class="empty"><i class="i-style">Your Shopping Cart is Empty</i></h4>
        <button type="button" class="btn btn-primary"
            style="display: block;padding-top: 10px;padding-bottom: 10px;font-size: 16px;font-weight: 500;border-radius: 4px;margin-top: 5px;float: right;"
            (click)="goShopping()"> Start Shopping
            <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
        </button>
        <!-- <a class="btn btn-continue pull-right" routerLink="/">
      Start Shopping
      <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
    </a> -->
    </div>

    <div *ngIf="cartProducts.length !== 0" class="col-md-8 col-xs-12 invisible-scrollbar" id="div_style">
        <div>
            <a class="btn btn-check-out1 delivery">
                <div class="selected-delivery">
                    <div class="delivery-text">
                        <span>
                            <img [src]="deliveryType === 'storePickup' ? 'assets/img/pickup_pink.png' : 'assets/img/delivery_pink.svg'"
                                class="delivery-icon" />
                            {{ deliveryType === 'storePickup' ? 'Pickup' : 'Delivery' }}&nbsp;
                        </span>
                        <small class="delivery-details">
                            {{
                            deliveryType === 'storePickup'
                            ? storeLocation?.locationName + ', ' + storeLocation?.cityName + ', ' + storeLocation?.pin
                            : lastDeliveryAddress
                            ? (lastDeliveryAddress?.shippingPlotNo || '') +
                            (lastDeliveryAddress?.shippingAddress ? ', ' + lastDeliveryAddress?.shippingAddress : '') +
                            (lastDeliveryAddress?.cityName ? ', ' + lastDeliveryAddress?.cityName : '') +
                            (lastDeliveryAddress?.stateName ? ', ' + lastDeliveryAddress?.stateName : '') +
                            (lastDeliveryAddress?.shippingPinCode ? ', ' + lastDeliveryAddress?.shippingPinCode : '')
                            : ' address not set'
                            }}
                        </small>


                    </div>
                </div>

            </a>
        </div>
        <div>
            <div *ngFor="let item of cartProducts; let i = index" [class.even-section]="i % 2 == 0"
                style="max-height: 250px">
                <div *ngIf="!item.designForProductCode" class="row cart_font_size border_cart"
                    style="margin: 0 0 2px 0;">
                    <!-- Image,Name & Offer Div -->
                    <div class="col-md-6 col-sm-6 col-xs-6">
                        <div class="row">
                            <div class="col-md-8 col-xs-8">
                                <img *ngIf="!item.designProductPicture && item.subMtoType != 'Custom Cakes'"
                                    style="border-radius: 4px;" src="../../../../assets/img/{{ item.product_Picture }}"
                                    width="120px" height="100px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                <img *ngIf="!item.designProductPicture && item.subMtoType == 'Custom Cakes'"
                                    style="border-radius: 4px;" [src]="item.image" width="120px" height="100px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                <img *ngIf="item.designProductPicture && item.subMtoType != 'Custom Cakes'"
                                    style="border-radius: 4px;"
                                    src="../../../../assets/img/{{ item.designProductPicture }}" width="120px"
                                    height="100px" onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                <span>
                                    <img class="img-responsive img_align" *ngIf="item.food_Type == 'Veg'"
                                        src="../../../../assets/img/Veg.webp" alt="" />
                                    <img class="img-responsive img_align" *ngIf="item.food_Type == 'Non-Veg'"
                                        src="../../../../assets/img/Nonveg.webp" alt="" />
                                </span>

                                <span *ngIf="item.offers >= 1"> <img class="img-responsive offer_align"
                                        src="../../../../assets/img/Offer 1.webp" alt=""
                                        (click)="viewOfferDetail(item.offerDetails)" matTooltip="Offers" />
                                </span>
                            </div>

                            <div *ngIf="item.cartProductType == 'Make To Order'" class="col-md-4 col-xs-4"
                                style="position: absolute; left: 122px !important; top: 10px">
                                <span class="span_style">
                                    Make To Order</span>
                            </div>

                            <div class="col-md-4 col-xs-4 dlt-line">
                                <button type="button" *ngIf="!item.designForProductCode && !item.freeByProductCode"
                                    class="close" aria-label="Close" (click)="removeCartProduct(item)"
                                    style="color: red">
                                    <mat-icon *ngIf="item.net_Sale_Price > 0" style="color: red"
                                        matTooltip="Remove">delete_forever
                                    </mat-icon>
                                </button>

                                <button type="button" class="close" aria-label="Close"
                                    (click)="editMto(item.product_Code)">
                                    <mat-icon style="color: black" matTooltip="Edit"
                                        *ngIf="item.cartProductType == 'Make To Order' && item.net_Sale_Price > 0 && !item.freeByProductCode && !item.subMtoType">edit
                                    </mat-icon>
                                </button>

                                <button type="button" class="close" aria-label="Close"
                                    (click)="getDesignBySubGroupAndUrl(item)">
                                    <mat-icon style="color: black" matTooltip="Edit"
                                        *ngIf="item.cartProductType == 'Product' && item.designForProductCode">edit
                                    </mat-icon>
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <p class="item-number" style="margin: 0;padding-left: 8px;"
                                *ngIf="item.offers >= 1 && !item.freeByProductCode">
                                <span class="offer_span" (click)="openDialog(item.offerDetails, i)">
                                    {{ item.offers }} Offers Available</span>
                                <span *ngIf="item.offerId" class="offer_span"
                                    (click)="openDialog(item.offerDetails, i)">
                                    ,1 Offer Applied</span>
                            </p>

                            <p class="item-name" style="margin: 0;padding-left: 8px;">
                                {{ item.product_Name }}
                            </p>
                            <p *ngIf="item.designProductName" class="item-name"
                                style="padding-left: 8px;display: flex;margin: 0 !important;">
                                Design: {{ item.designProductName }}
                                <button type="button" class="close" aria-label="Close"
                                    (click)="getDesignBySubGroupAndUrl(item)">
                                    <mat-icon style="color: black;font-size: 18px !important;" matTooltip="Edit"
                                        *ngIf="item.cartProductType == 'Product' && item.designProductName">edit
                                    </mat-icon>
                                </button>
                            </p>

                        </div>
                    </div>

                    <!-- Quantity Cal Div -->
                    <div class="col-md-4 col-sm-4 col-xs-4 d-flex">
                        <p *ngIf="item.cartProductType == 'Product' && item.freeByProductCode" class="qty_p">
                            <strong style="width: 1em;">{{ item.orderQuantity }}</strong>
                            <strong>{{ item.product_Unit }}</strong>
                            x <strong style="text-decoration: line-through 2px red; color: #333333;">
                                {{ (item.freeProductNetSalePrice || 0) | number: '1.2-2' }}
                            </strong>
                            <span *ngIf="item.net_Sale_Price > 0">
                                &nbsp;<strong>{{ item.net_Sale_Price | number: '1.2-2' }}</strong>
                            </span>
                        </p>

                        <p *ngIf="item.net_Sale_Price > 0 && item.cartProductType == 'Product' && !item.freeByProductCode"
                            class="qty_p">
                            <!-- <input type="number" *ngIf="!item.designForProductCode" style="width: 3em;"
                            [(ngModel)]="item.orderQuantity" (change)="updateItem(item)" [step]="1" [min]="1" /> -->

                            <span class="qty-container">
                                <button class="qty-btn" (click)="decreaseQuantity(item)">−</button>

                                <input type="number" *ngIf="!item.designForProductCode" class="qty-input"
                                    [(ngModel)]="item.orderQuantity" (change)="updateItem(item)" [step]="1" [min]="1" />

                                <button class="qty-btn" (click)="increaseQuantity(item)">+</button>
                            </span>
                            <strong *ngIf="item.designForProductCode" style="width: 3em;">
                                {{item.orderQuantity }}
                            </strong>

                            <strong>{{item.product_Unit}}</strong> x
                            <strong>{{item.net_Sale_Price | number: "1.2-2"}}</strong>
                        </p>
                        <!-- <p *ngIf="item.net_Sale_Price > 0 && item.cartProductType == 'Product' && item.designProductNetSalePrice > 0"
                        class="qty_p">

                        <strong *ngIf="item.designForProductCode" style="width: 3em;">
                            {{item.orderQuantity }}
                        </strong>

                        <strong>{{item.product_Unit}}</strong> x
                        <strong>{{item.net_Sale_Price | number: "1.2-2"}}</strong>
                    </p> -->

                        <p *ngIf="item.cartProductType == 'Make To Order' && !item.freeByProductCode" class="qty_p">
                            <strong *ngIf="item.orderQuantityKgs == null">{{ item.orderQuantity }}
                                {{item.product_Unit}}</strong>
                            <strong *ngIf="item.orderQuantityKgs > 0">{{ item.orderQuantityKgs }}
                                {{ item.product_Unit}}</strong> x
                            <strong>{{item.net_Sale_Price + item.premiumFlavourPrice | number: "1.2-2"}}</strong>
                        </p>

                        <p *ngIf="item.cartProductType == 'Make To Order' && item.freeByProductCode" class="qty_p">
                            <strong *ngIf="item.orderQuantity > 0" style="width: 1em;">{{ item.orderQuantity }}</strong>
                            <strong>{{ item.product_Unit }}</strong>
                            x <strong style="text-decoration: line-through 2px red;color: #333333;">
                                {{ (item.freeProductNetSalePrice || 0) | number: '1.2-2' }}
                            </strong>
                            <span *ngIf="item.net_Sale_Price > 0">
                                &nbsp;<strong>{{ item.net_Sale_Price | number: '1.2-2' }}</strong>
                            </span>
                        </p>
                    </div>

                    <!-- Net Sale Price Div -->
                    <div class="col-md-2 col-sm-2 col-xs-2 net_price">
                        <p style="float: right; font-weight: bold;"
                            *ngIf="item.net_Sale_Price > 0 && item.cartProductType == 'Product' && !item.freeByProductCode">
                            {{ item.orderQuantity * item.net_Sale_Price | number: "1.2-2" }}
                        </p>
                        <p style="float: right; font-weight: bold;"
                            *ngIf="item.cartProductType == 'Make To Order' && !item.freeByProductCode">
                            {{ item.orderQuantityKgs * item.net_Sale_Price + item.orderQuantityKgs *
                            item.premiumFlavourPrice | number: "1.2-2" }}
                        </p>
                        <p style="float: right; font-weight: bold;"
                            *ngIf="item.net_Sale_Price > 0 && item.cartProductType == 'Product' && item.designProductNetSalePrice > 0  && !item.freeByProductCode">
                            {{ item.orderQuantity * item.designProductNetSalePrice | number: "1.2-2" }}
                        </p>
                        <p *ngIf="item.freeByProductCode" style="float: right; font-weight: bold;">
                            <strong *ngIf="item.net_Sale_Price == 0">FREE</strong>
                            <span *ngIf="item.net_Sale_Price > 0 && item.cartProductType == 'Product'">
                                {{ item.orderQuantity * item.net_Sale_Price | number: "1.2-2" }}
                            </span>
                            <span *ngIf="item.orderQuantityKgs > 0 && item.cartProductType == 'Make To Order'">
                                {{ item.orderQuantityKgs * item.net_Sale_Price | number: "1.2-2" }}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="cartProducts.length !== 0" class="col-md-4 col-xs-12" id="div3_style">
        <section class="order-summary">
            <!-- <div class="section-header" style="background-color: #666; color: white">
                <h1 class="h1_summary">Order Summary</h1>
            </div> -->
            <!-- Replacing Order Summary Header with Tabs -->
            <div class="delivery-selection">
                <button class="delivery-tab" [class.active]="deliveryType === 'storePickup'"
                    (click)="openStorePickupDialogCustom('storePickup')">
                    <img [src]="deliveryType === 'storePickup' ? 'assets/img/pickup_white.png' : 'assets/img/pickup_pink.png'"
                        class="delivery-icon" />
                    Pickup
                    <span *ngIf="deliveryType === 'storePickup'" class="selected-tick">✔</span>
                    <span *ngIf="deliveryType !== 'storePickup'" class="selected-tick"></span>
                </button>

                <button class="delivery-tab" [class.active]="deliveryType === 'delivery'"
                    (click)="openStorePickupDialogCustom('delivery')">
                    <img [src]="deliveryType === 'delivery' ? 'assets/img/delivery_white.svg' : 'assets/img/delivery_pink.svg'"
                        class="delivery-icon" />
                    Delivery
                    <span *ngIf="deliveryType === 'delivery'" class="selected-tick">✔</span>
                    <span *ngIf="deliveryType !== 'delivery'" class="selected-tick"></span>
                </button>
            </div>



            <!-- <div class="delivery-selection">
                <button class="delivery-tab" [class.active]="deliveryType === 'delivery'"
                    (click)="openStorePickupDialogCustom()">
                    <img src="../../../../assets/img/delivery_white.svg" class="delivery-icon" />
                    Delivery
                    <span *ngIf="deliveryType === 'delivery'" class="selected-tick">✔</span>
                </button>

                <button class="delivery-tab" [class.active]="deliveryType === 'storePickup'"
                    (click)="openStorePickupDialogCustom()">
                    <img src="../../../../assets/img/pickup_pink.png" class="delivery-icon" />
                    Pickup
                    <span *ngIf="deliveryType === 'storePickup'" class="selected-tick">✔</span>
                </button>
            </div> -->

            <div class="section-header">
                <h1 class="h1_summary">Order Summary</h1>
            </div>


            <div class="section-body">
                <div>
                    <div class="price-info">
                        <span class="pull-left">Amount</span>
                        <span class="pull-right">{{ totalSaleAmt | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="totBasePremiumFlavourPrice > 0">
                        <span class="pull-left">Flavour Premium Price</span>
                        <span class="pull-right">{{ totBasePremiumFlavourPrice | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="totDiscount > 0">
                        <span class="pull-left">Discount</span>
                        <span class="pull-right">{{ totDiscount | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info">
                        <span class="pull-left">Taxble Value</span>
                        <span class="pull-right">{{ totTaxValue | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="gstAmt > 0">
                        <span class="pull-left">GST</span>
                        <span class="pull-right">{{ gstAmt | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="cessAmt > 0">
                        <span class="pull-left">CESS</span>
                        <span class="pull-right">{{ cessAmt | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="netAmountBeforeRoundedOff >= minimumOrdeSetup?.orderMinValue">
                        <span class="pull-left">Net Amount</span>
                        <span class="pull-right">{{ netAmountBeforeRoundedOff | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="netAmountBeforeRoundedOff < minimumOrdeSetup?.orderMinValue">
                        <span class="pull-left">Net Amount</span>
                        <span class="pull-right">{{ netAmountBeforeRoundedOff | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info">
                        <span class="pull-left">Rounded off Amount</span>
                        <span class="pull-right">{{ roundedOffAmount | number: "1.2-2" }}</span>
                    </div>

                    <!-- <div class="price-info" *ngIf="deliveryCharge == 0">
                         <span class="pull-left">Shipping Charges</span>
                            <span class="pull-right">FREE</span>
                        </div>
                        <div class="price-info" *ngIf="deliveryCharge != 0">
                        <span class="pull-left">Shipping Charges</span>
                        <span class="pull-right">{{deliveryCharge | number: "1.2-2"}}</span>
                        </div> -->
                    <hr />
                    <div class="price-info total">
                        <span class="pull-left">Grand Total</span>
                        <span class="pull-right">{{ grandTotal | number: "1.2-2" }}</span>
                    </div>

                    <!-- <span>
                        <a class="btn btn-check-out1 delivery">
                            <div class="selected-delivery">
                                <div class="delivery-text">
                                    <span>
                                        {{ deliveryType === 'storePickup' ? '🏬 Pick Up from Store' : '🏠 Home Delivery'
                                        }}
                                    </span>
                                    <small class="delivery-details">
                                        {{ deliveryType === 'storePickup' ? storeLocation?.locationName :
                                        deliveryLocation?.locationName }},
                                        {{ deliveryType === 'storePickup' ? storeLocation?.cityName :
                                        deliveryLocation?.cityName }}
                                        ({{ deliveryType === 'storePickup' ? storeLocation?.pin :
                                        deliveryLocation?.pincode }})
                                    </small>
                                </div>
                                <i class="edit-icon" (click)="openStorePickupDialogCustom('')">✏️</i>
                            </div>
                        </a>
                    </span> -->




                    <span *ngIf="isShowCheckOut">
                        <span *ngIf="grandTotal >= minimumOrdeSetup?.orderMinValue">
                            <a class="btn btn-success btn-check-out" (click)="getProductWithStock()">Check Out</a>
                        </span>
                        <span *ngIf="grandTotal < minimumOrdeSetup?.orderMinValue">
                            <a class="btn btn-danger btn-check-out1" style="height: 48px">Check Out
                                <br />
                                <span style="font-size: 9px; color: white; font-weight: bold">
                                    Minimum Order Amount {{minimumOrdeSetup?.orderMinValue }}</span>
                            </a>
                        </span>
                    </span>
                    <span *ngIf="!isShowCheckOut">
                        <a class="btn btn-success btn-check-out" style="cursor: progress">Please wait...</a>
                    </span>


                    <button type="button" class="btn btn-primary" id="button_Style" (click)="goShopping()">Continue
                        Shopping
                    </button>
                </div>
            </div>
        </section>
    </div>

    <section class="section pb-3" style="color: #212529">
        <div style="display: block" class="row">
            <div class="col-md-6"></div>
        </div>

        <div *ngIf="loading">
            <!-- <app-card-loader [loop]="4" [height]="500"></app-card-loader> -->
        </div>

        <owl-carousel *ngIf="!loading" [options]="options" [carouselClasses]="['owl-theme', 'row', 'sliding']">
            <div class="item" style="padding: 20px" *ngFor="let product of bestProducts; let i = index">
                <div class="mb-r">
                    <div class="card card-cascade wider" id="div_img">
                        <a [routerLink]="['/product', product.product_Code]">
                            <div class="view overlay hm-white-slight">
                                <img src="../../../../assets/img/{{ product.product_Picture }}" class="img-fluid"
                                    width="360px" height="300px"
                                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                                <img *ngIf="product.food_Type == 'Veg'" class="img-responsive img_align2"
                                    src="../../../../assets/img/Veg.webp" alt="" />
                                <img *ngIf="product.food_Type == 'Non-Veg'" class="img-responsive img_align2"
                                    src="../../../../assets/img/Nonveg.webp" alt="" />
                                <a>
                                    <div class="mask waves-effect waves-light" mdbRippleRadius></div>
                                </a>
                            </div>
                        </a>

                        <div class="card-body text-center no-padding">
                            <h5 class="card-title" style="font-size: 15px; height: 35px">
                                <strong>
                                    <a [routerLink]="['/product', product.product_Code]">{{product.product_Name}}</a>
                                </strong>
                            </h5>

                            <div class="card-footer">
                                <span class="left" style="font-size: 17px">₹ {{ product.net_Sale_Price | number:
                                    "1.2-2"}}</span>
                                <span class="right">
                                    <!-- <a placement="top" matTooltip="Add to Wishlist" container="body"(click)="addFavourite(product)">
                                            <i class="fa fa-heart" style="color: #e91e63;"></i>
                                         </a> -->
                                    <a placement="top" matTooltip="Add to Cart" container="body"
                                        (click)="openDeliveryTypeDialog(product)">
                                        <i class="fa fa-shopping-cart"
                                            style="margin-left: 6px; color: #e91e63; font-size: 20px"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </owl-carousel>
    </section>
</div>