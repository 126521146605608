<div style="overflow-x: hidden;">
  <div class="mbr-section mbr-section__container mbr-section__container--middle">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-xs-center">
          <h2 class="mbr-section-title h2_font_size" style="display: flex;justify-content: center;align-items: center;">
            {{product.product_Name}}
            <!-- Product -->
            <br />
            <i *ngIf="product.product_Name" [matMenuTriggerFor]="menu" class="fa fa-share-alt" id="icon_align"></i>
            <mat-menu #menu="matMenu">
              <share-buttons theme="circles-dark" [include]="['copy','whatsapp','facebook','email','telegram']"
                [showIcon]="true" [showText]="false" [url]="sharingUrl" [description]="product.product_Name"
                class="pt-5">
              </share-buttons>
            </mat-menu>
          </h2>
          <div class="hr"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <div class="product-image" style="height: 70%;">
          <div class="view hm-zoom z-depth-2"
            style="cursor: pointer;height: 100%;max-height: 300px; min-height: 300px;">
            <img *ngIf="thumnnailType == 'image'" src="../../../../assets/img/{{ productImgName }}"
              [alt]="product.product_Name" width="100%" class="img-fluid rounded" alt="Image not found"
              style="height: 100%;cursor: auto;" onerror="this.src='../../../../assets/img/defaultCake.webp';" />
            <video *ngIf="thumnnailType == 'video'" controls controlsList="nodownload"
              style="height: 100%;width: 100%;">
              <source src="../../../../assets/img/{{ productVideoName }}" type="video/mp4" />
            </video>
            <span style="position: absolute;top: 3px;right: 3px;">
              <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp" alt=""
                style="width: 20px; height: 20px" />
              <img *ngIf="product.food_Type == 'Non-Veg'" src="../../../../assets/img/Nonveg.webp" alt=""
                style="width: 20px; height: 20px;float: right" />
            </span>

            <span *ngIf="offerNames" style="position: absolute;top: -6px;left: -8px;">
              <img style="width: 40px; height: 48px;cursor: pointer;" src="../../../../assets/img/Offer 1.webp" alt=""
                (click)="viewOfferDetail()" matTooltip="Offers" />
            </span>
            <!-- <i class="fa fa-share-alt" (click)="onShareClick(product)" id="icon_align"></i> -->
          </div>
          <div style="margin-top: 8px;display: flex;overflow: auto;">
            <div style="padding: 2px;" *ngFor="let thumb of productThumbnails">
              <img *ngIf="thumb.fileType == 'image'" src="../../../../assets/img/{{ thumb.fileName }}"
                [alt]="product.product_Name" class="img-fluid rounded thumbnail_img" alt="Image not found"
                (click)="onthumbNailimgClick(thumb)" onerror="this.src='../../../../assets/img/defaultCake.webp';" />
              <span style="display: block;">
                <video *ngIf="thumb.fileType == 'video'" class="thumbnail_img" style="width: 50px;"
                  controlsList="nodownload" (click)="onthumbNailimgClick(thumb)">
                  <source src="../../../../assets/img/{{ thumb.fileName }}" type="video/mp4" />
                </video>
              </span>
            </div>
          </div>
          <div class="d-flex  btn-cart-buy-now-div">
            <button type="button" class="btn btn-primary content bg-pink" (click)="openDeliveryTypeDialog(product, false)">
              Add to Cart
            </button>
            <button type="button" class="btn btn-primary content bg-pink" (click)="openDeliveryTypeDialog(product, true)">
              Buy Now
            </button>
          </div>
        </div>
      </div>

      <div class="col-sm-8">
        <!-- <i [matMenuTriggerFor]="menu" class="fa fa-share-alt" id="icon_align"></i>
      <mat-menu #menu="matMenu">
        <share-buttons theme="circles-dark" [include]="['copy','whatsapp','facebook','email','telegram']"
          [showIcon]="true" [showText]="false" [url]="sharingUrl" [description]="product.product_Name" class="pt-5">
        </share-buttons>
      </mat-menu> -->
        <div class="product-detail">
          <!-- <h5 class="product-head" style="font-size: 17px; text-align: center">
          Product Details
        </h5> -->
          <!-- <i [matMenuTriggerFor]="menu" class="fa fa-share-alt" id="icon_align"></i>
        <mat-menu #menu="matMenu">
          <share-buttons theme="circles-dark" [include]="['copy','whatsapp','facebook','email','telegram']"
            [showIcon]="true" [showText]="false" [url]="sharingUrl" [description]="product.product_Name" class="pt-5">
          </share-buttons>
        </mat-menu> -->

          <table class="table" cellspacing="0" style="max-height: 28px">
            <tbody>
              <!-- <tr>
              <th scope="row">Product Name</th>
              <td>{{ product.product_Name }}</td>
            </tr> -->
              <tr *ngIf="descStr">
                <th scope="row" style="border: none;">Description</th>
                <td [innerHTML]="descStr" style="border: none;"></td>
              </tr>
              <tr>
                <th scope="row">Min Quantity</th>
                <td>{{ product.minimum_Order_Quantity }} {{ product.product_Unit }}</td>
              </tr>
              <tr *ngIf="product.minimum_Order_Time">
                <th scope="row">Order Before</th>
                <td>{{ product.minimum_Order_Time }} Day(s)</td>
              </tr>
              <tr>
                <th scope="row">Price({{ product.product_Unit }})</th>
                <td>₹ {{ product.net_Sale_Price | number: "1.2-2" }}</td>
              </tr>
              <tr *ngIf="offerNames.length > 0">
                <th scope="row">Offers</th>
                <td>{{ offerNames }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>