import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocationDetailsForStore } from "../models/locationdetailsforstore";
import { State } from "../models/state";
import { WebOrderMinimumDateTimeSetup } from "../models/weborderminimumdatetimesetup";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";
import { CustomerB2BDetails } from "../models/customer-b2-bdetails";
import { NameWithIdDto } from "../models/namewithiddto";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) { }

  getStateList() {
    return this.http.get<State[]>(this.apiFactory.getStateList);
  }

  getDistrictByStateCode(url: string, stateCode: number) {
    return this.http.get<NameWithIdDto[]>(url + "/" + stateCode);
  }

  getWebOrderMinimumDateTimeDetails(currentUrl: string) {
    return this.http.get<WebOrderMinimumDateTimeSetup>(
      this.apiFactory.getWebOrderMinimumDateTimeDetails + "/" + currentUrl
    );
  }

  getDeliveryDetails(url: string, email: string, pin: any) {
    return this.http.get<any>(url + "/" + email + "/" + pin);
  }

  insertDeliveryDetails(url: string, req: any): Observable<any> {
    return this.http.post(url, req);
  }

  insertCustomerB2bDetails(url: string, data: CustomerB2BDetails) {
    return this.http.post(url, data);
  }

}
