export class CCAvenueDataFormat {
    amount: number;
    orderId: string;
    merchantId: string;
    source: string;
    validTill: string;

    name: string;
    billing_name: string;
    billing_address: string;
    billing_city: string;
    billing_state: string;
    billing_zip: string;
    billing_country: string;
    billing_tel: string;
    billing_email: string;
}