import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { FeedbackSetupWebDto } from "../models/feedback-setup-web-dto";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  constructor(private http: HttpClient, private apiURL: APIFactoryEndPoints) { }

  onCreateSetUp(url: string, obj: UntypedFormGroup): Observable<any> {
    return this.http.post(url, obj.value, { responseType: "text" });
  }

  checkOTPSentOrNot(url: string, obj: string): Observable<any> {
    return this.http.get<string>(url + "/" + obj);
  }

  sendMessageByMailChimpFromContact(url: string, obj: UntypedFormGroup, file: File, videoFile: File): Observable<any> {
    const formData = new FormData();
    formData.append("imgFile", file);
    formData.append("videoFile", file);
    formData.append("formData", new Blob([JSON.stringify(obj.value)], { type: "application/json" }));

    return this.http.post(url, formData);
  }

  getWebOrderFeedbackSetup(url: string, currentUrl: string) {
    return this.http.get<FeedbackSetupWebDto[]>(url + "/" + currentUrl);
  }

  sendMessage(url: string, obj: UntypedFormGroup, imgFile: File, videoFile: File): Observable<any> {
    const formData = new FormData();

    formData.append('imgFile', imgFile);
    formData.append('videoFile', videoFile);
    formData.append("formData", new Blob([JSON.stringify(obj.value)], { type: "application/json" }));

    return this.http.post(url, formData);
  }

  checkOTPSentOrNotWithIpAddress(url: string, mblNo: string, ip: string): Observable<any> {
    return this.http.get<string>(url + "/" + mblNo + "/" + ip);
  }
}
