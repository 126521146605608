<div class="container py-5">
    <div class="row mb-4">
        <div class="col-lg-8 mx-auto text-center">
            <h2 class="display-6">Payment</h2>
            <div class="hr"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 mx-auto">
            <div class="card">
                <div class="card-header">
                    <div class="bg-white shadow-sm pt-4 pl-2 pr-2 pb-2">
                        <div *ngIf="value"
                            style="background-color: teal;text-align: center;color: white;overflow: hidden;height: 2rem;line-height: 1rem;"
                            [style.width]="value + '%'">
                            <span style="display: inline-block; padding: 0.5rem">{{ value }}%</span>
                        </div>
                        <ul role="tablist" class="nav bg-light nav-pills rounded nav-fill mb-3"
                            style="margin-bottom: 0rem !important">
                            <li class="nav-item">
                                <a *ngIf="!isShowUpi" data-toggle="pill" class="nav-link"
                                    style="background-color: beige;cursor: default;font-size: 14px;font-weight: 500;margin: 0px;font-family: inherit;">
                                    Select Payment Method
                                </a>
                                <a *ngIf="isShowUpi" data-toggle="pill" class="nav-link a_custom">
                                    Pay With UPI
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="tab-content">
                        <div id="notification" class="tab-pane fade show active pt-3">
                            <form id="nonseamless" ngNoForm method="post">
                                <div class="form-group" style="text-align: center">
                                    <label><b>Amount to pay</b></label>
                                    <input *ngIf="!isShowUpi" style="font-size: 15px; width: 50%; margin: auto"
                                        type="text" required class="form-control" placeholder="Invoice Amount"
                                        [value]="amount | number: '1.2-2'" name="amount" id="amount" readonly />
                                    <input *ngIf="isShowUpi" style="font-size: 15px; width: 50%; margin: auto"
                                        type="text" required class="form-control" placeholder="Invoice Amount"
                                        [value]="availabeAmount | number: '1.2-2'" name="amount" id="amount" readonly />

                                    <!-- <input *ngIf="isShowUpi"
                                        style="font-size: 15px;width: 50%;margin: auto;margin-top: 8px;" type="text"
                                        class="form-control" placeholder="UPI Id" [(ngModel)]="payerVa" name="payerVa"
                                        id="payerVa" /> -->
                                </div>

                                <div *ngIf="isShowUpi" class="card-footer">
                                    <!-- <button type="button" [disabled]="!disableVpaBtn"
                                        (click)="onSendNotificationClick()"
                                        style="font-size: 13px;margin-right: 5%;width: 50%;margin: auto;"
                                        class="subscribe btn btn-primary btn-block">
                                        Send Payment Request
                                    </button> -->
                                    <br />
                                    <button type="button" [disabled]="!disableQrBtn" (click)="onQrCodeBtnClick()"
                                        style="font-size: 13px;margin-top: 0;width: 50%;margin: auto;"
                                        class="subscribe btn btn-primary btn-block">
                                        Generate QR
                                    </button>
                                    <br />
                                </div>

                                <div *ngIf="isShowUpi" style="margin-bottom: 8px">
                                    <div class="qrCode_container" *ngIf="showQrCode">
                                        <div class="qrCodeContainerbox">
                                            <p *ngIf="showQrCodeLabel" class="qrCodeLabel"></p>

                                            <qrcode *ngIf="showQrCode" [qrdata]="qrCodeUpi"
                                                style="height: 155px; width: 170px" [width]="170">
                                            </qrcode>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <form *ngIf="!isShowUpi" id="nonseamless" #form ngNoForm name="redirect">
                                <!-- <input type="hidden" id="encRequest" name="encRequest" value="{{ encRequest }}" />
                                <input type="hidden" name="access_code" id="access_code" value="{{ accessCode }}" /> -->

                                <div class="card-footer">
                                    <button type="button" (click)="walletClick()"
                                        class="subscribe btn btn-primary btn-block wallet_btn">
                                        <p class="wallet_span"><span>Wallet Balance</span><span>{{walletBalance}}</span>
                                        </p>
                                        <p class="wallet_span">
                                            <span>Pay through Wallet</span><span>{{usedWallet}}</span>
                                        </p>
                                    </button>
                                    <!-- <br />
                                     <button type="button" (click)="payWithUpi()"
                                        class="subscribe btn btn-primary btn-block gateway_btn">
                                        Pay thorugh UPI/QR &nbsp;&nbsp;<span>{{availabeAmount | number: '1.2-2'}}</span>
                                    </button> -->
                                    <br />
                                    <button type="button" (click)="payWithCCavenue()"
                                        class="subscribe btn btn-primary btn-block gateway_btn">
                                        Pay through Gateway <span>{{availabeAmount | number:
                                            '1.2-2'}}</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container">
    <div class="row">
        <h1>Proceed to Pay</h1>
        <button type="button" (click)="pay()">pay</button>
    </div>
</div>

<form #form ngNoForm id="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction">
    <input type="text" id="encRequest" name="encRequest" value="{{ encRequest }}" />
    <input type="text" name="access_code" id="access_code" value="{{ accessCode }}" />
</form> -->