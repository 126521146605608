<h1>Please Wait While Your Transaction is Being Proceed!</h1>

<!-- <ng-template #loading>
    <div>Loading...</div>
</ng-template> -->

<!-- <mat-progress-bar mode="determinate" [value]="progressbarValue"></mat-progress-bar> -->

<!-- <div *ngIf="posts | async as postsSync; else loading">
    {{ postsSync| json }}
 </div>  -->

 <div *ngIf="value" style="background-color:teal;text-align:center;color:white;overflow:hidden;height:2rem;line-height:1rem;" [style.width]="value+'%'">
    <span style="display:inline-block;padding:.5rem">{{value}}%</span></div>