import { Injectable } from "@angular/core";
import { LocalStorageService } from "src/app/common/LocalStorageService";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
 
  constructor(private localStorage: LocalStorageService) {}

  updateThemeUrl(theme: string) {
    document.getElementsByTagName("body")[0].className = theme;

    this.localStorage.setItem("theme", JSON.stringify(theme));
  }
}
