import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { DeliveryAddress } from "src/app/shared/models/deliveryaddress";
import { MakeToOrderSummary } from "src/app/shared/models/maketoordersummary";
import { NameWithIdDto } from "src/app/shared/models/namewithiddto";
import { State } from "src/app/shared/models/state";
import { AddressService } from "src/app/shared/services/address.service";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";

@Component({
  selector: "app-make-to-order-address",
  templateUrl: "./make-to-order-address.component.html",
  styleUrls: ["./make-to-order-address.component.scss"],
})
export class MakeToOrderAddressComponent implements OnInit {
  customerName: string;
  contactNo: number;
  pincode: number;
  cityName: string;
  stateId: number;
  districtId: number;
  address: string;

  createSetUpForm: UntypedFormGroup;

  stateList: State[] = [];
  makeToOrderSummary: MakeToOrderSummary = new MakeToOrderSummary();
  districtList: NameWithIdDto[] = [];

  private stateSubcription: Subscription;
  private districtListSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pincodeDialogService: PinDialogService,
    private addressService: AddressService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router
  ) {}

  ngOnInit() {
    this.onCreateSetUp();
    this.getSummaryDetails();
    this.getStateList();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      customerName: ["", Validators.required],
      contactNo: ["", Validators.required],
      pincode: ["", Validators.required],
      cityName: ["", Validators.required],
      stateId: ["", Validators.required],
      districtId: ["", Validators.required],
      address: ["", Validators.required],
    });
  }

  getSummaryDetails() {
    this.makeToOrderSummary = this.pincodeDialogService.getMakeToOrderSummary();

    this.createSetUpForm.patchValue({
      pincode: this.makeToOrderSummary.pincode,
    });
  }

  getStateList() {
    this.stateSubcription = this.addressService
      .getStateList()
      .subscribe((data) => {
        this.stateList = data;
      });
  }

  onStateSelect(stateCode: number) {
    this.districtListSubscription = this.addressService
      .getDistrictByStateCode(this.apiFactory.getDistrictByStateCode, stateCode)
      .subscribe((data) => {
        this.districtList = data;
        console.log(data);
      });
  }

  onCreateSetUpClick() {
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }

    this.customerName = this.createSetUpForm.get("customerName").value;
    this.contactNo = this.createSetUpForm.get("contactNo").value;
    this.pincode = this.createSetUpForm.get("pincode").value;
    this.cityName = this.createSetUpForm.get("cityName").value;
    this.stateId = this.createSetUpForm.get("stateId").value;
    this.districtId = this.createSetUpForm.get("districtId").value;
    this.address = this.createSetUpForm.get("address").value;

    var deliveryAddress: DeliveryAddress = new DeliveryAddress();
    deliveryAddress.customerName = this.customerName;
    deliveryAddress.contactNo = this.contactNo;
    deliveryAddress.pincode = this.pincode;
    deliveryAddress.cityName = this.cityName;
    deliveryAddress.stateId = this.stateId;
    deliveryAddress.districtId = this.districtId;
    deliveryAddress.address = this.address;
    this.makeToOrderSummary.deliveryAddress = deliveryAddress;

    this.pincodeDialogService.setMakeToOrderSummary(this.makeToOrderSummary);
    this.navRoute.navigate([
      "/make-to-order-summary/make-to-order-summary-app",
    ]);
  }
}
