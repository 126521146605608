<div class="container py-5">
  <div class="row mb-4">
    <div class="col-lg-8 mx-auto text-center">
      <h2 class="display-6">Payment</h2>
      <div class="hr"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <div class="card" style="height: 178%">
        <div class="card-header">
          <div class="bg-white shadow-sm pt-4 pl-2 pr-2 pb-2">
            <div *ngIf="value" style="
                background-color: teal;
                text-align: center;
                color: white;
                overflow: hidden;
                height: 2rem;
                line-height: 1rem;
              " [style.width]="value + '%'">
              <span style="display: inline-block; padding: 0.5rem">{{ value }}%</span>
            </div>
            <ul role="tablist" class="nav bg-light nav-pills rounded nav-fill mb-3">
              <li class="nav-item">
                <a data-toggle="pill" href="#" (click)="clickUPI()" style="background-color: beige" class="nav-link">
                  Pay With UPI
                </a>
              </li>
            </ul>
          </div>

          <div class="tab-content">
            <div id="notification" class="tab-pane fade show active pt-3" *ngIf="showUpi">
              <form [formGroup]="createSetUpForm">
                <div class="form-group">
                  <input style="font-size: 15px" type="text" required class="form-control" placeholder="Invoice Amount"
                    readonly formControlName="amount" />
                </div>
                <div class="form-group">
                  <input style="font-size: 15px" type="text" required class="form-control" placeholder="UPI Id"
                    formControlName="payerVa" />
                </div>

                <div class="card-footer" style="display: flex">
                  <button [disabled]="!disableVpaBtn" style="font-size: 15px; margin-right: 5%" type="button"
                    (click)="onCreateSetUpClick1()" class="subscribe btn btn-primary btn-block">
                    Send Notification
                  </button>
                  <button [disabled]="!disableQrBtn" style="font-size: 15px; margin-top: 0" type="button"
                    (click)="onQrCodeBtnClick()" class="subscribe btn btn-primary btn-block">
                    Generate QR
                  </button>
                  <br />
                </div>

                <div style="height: 100%; width: 33.33%">
                  <div class="qrCode_container">
                    <div class="qrCodeContainerbox">
                      <p *ngIf="showQrCodeLabel" class="qrCodeLabel"></p>

                      <qrcode *ngIf="showQrCode" [qrdata]="qrCodeUpi" style="height: 155px; width: 170px" [width]="170">
                      </qrcode>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div id="net-banking" class="tab-pane fade pt-3" *ngIf="showNetbanking">
            <div class="form-group">
              <label for="Select Your Bank">
                <h6>Select your Bank</h6>
              </label>
              <select class="form-control" id="ccmonth">
                <option value="" selected disabled>
                  --Please select your Bank--
                </option>
                <option>Bank 1</option>
                <option>Bank 2</option>
                <option>Bank 3</option>
                <option>Bank 4</option>
                <option>Bank 5</option>
                <option>Bank 6</option>
                <option>Bank 7</option>
                <option>Bank 8</option>
                <option>Bank 9</option>
                <option>Bank 10</option>
              </select>
            </div>
            <div class="form-group">
              <p>
                <button type="button" class="btn btn-primary">
                  <i class="fas fa-mobile-alt mr-2"></i> Proceed Payment
                </button>
              </p>
            </div>
            <p class="text-muted">
              Note: After clicking on the button, you will be directed to a
              secure gateway for payment. After completing the payment process,
              you will be redirected back to the website to view details of your
              order.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>