import { Product } from "./product";

export class WishListDetails {
  id: string;
  product: Product[];
  status: string;
  logInCustomerEmail: string;
  locationCode: number;
  // locationId:number;
  // userId:string;
  // provider:string;
}
