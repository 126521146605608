<div class="offerContainer">
    <div class="offerCard" *ngFor="let offer of promotionOfferData"
        style="box-shadow: none !important; border-bottom: 1px solid rgb(163, 161, 161);">
        <div id="offerHeader">
            <mat-icon style="color: #4CAF50;margin-left: 10px !important;height: 24px !important;" fxFlex="11"
                (click)="offer.index = !offer.index">local_offer</mat-icon>
            <p class="schemeName" fxFlex="61" (click)="offer.index = !offer.index">{{offer.schemeName}}
            </p>
            <mat-icon *ngIf="!offer.index" id="expand_more_icon" (click)="offer.index = !offer.index">expand_more
            </mat-icon>
            <mat-icon *ngIf="offer.index" id="expand_less_icon" (click)="offer.index = !offer.index">expand_less
            </mat-icon>
        </div>

        <div [ngClass]="offer.index == true ? 'details_div' : 'details_div_1'">

            <div style="display: flex;">
                <div *ngIf="offer.index" class="offerDetails" style="width: 50% !important;">

                    <fieldset class="field_set">
                        <legend class="BuyCondition legend_indv">Buy Details</legend>
                        <div class="flex_row" style="justify-content: center;">
                            <div class="product-image">
                                <div class="view overlay hm-white-slight">
                                    <img src="../../../../assets/img/{{ offer.productPicture }}"
                                        [alt]="offer.productName" width="100%" class="img-fluid rounded img_responsive"
                                        alt="Image not found"
                                        onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                    <span class="food_type_span">
                                        <img *ngIf="offer.foodType == 'Veg'" src="../../../../assets/img/Veg.webp"
                                            alt="" style="width: 14px; height: 14px" />
                                        <img *ngIf="offer.foodType == 'Non-Veg'"
                                            src="../../../../assets/img/Nonveg.webp" alt=""
                                            style="width: 14px; height: 14px;" />
                                    </span>
                                </div>
                                <div class="card-body text-center no-padding">
                                    <p class="card-title product_name">
                                        <strong class="product_name">
                                            <a>{{offer.productName}}</a>
                                        </strong>
                                    </p>
                                </div>
                            </div>

                            <!-- <mat-card class="mat_card_style" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <input type="text" placeholder="Buy Product" matInput [value]="offer.productName"
                                        readonly>
                                </mat-form-field>
                            </mat-card> -->
                        </div>
                    </fieldset>
                </div>

                <div *ngIf="offer.index" class="offerDetails" style="width: 50% !important;">
                    <fieldset class="field_set">
                        <legend class="BuyCondition legend_indv">Get Details</legend>

                        <div *ngIf="!offer.discPercent && !offer.discValue" class="flex_row"
                            style="justify-content: center;">
                            <div class="product-image">
                                <div class="view overlay hm-white-slight">
                                    <img src="../../../../assets/img/{{ offer.getProductPicture }}"
                                        [alt]="offer.getProductName" width="100%"
                                        class="img-fluid rounded img_responsive" alt="Image not found" style=""
                                        onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                                    <span class="food_type_span">
                                        <img *ngIf="offer.getFoodType == 'Veg'" src="../../../../assets/img/Veg.webp"
                                            alt="" style="width: 14px; height: 14px" />
                                        <img *ngIf="offer.getFoodType == 'Non-Veg'"
                                            src="../../../../assets/img/Nonveg.webp" alt=""
                                            style="width: 14px; height: 14px;" />
                                    </span>
                                </div>
                                <div class="card-body text-center no-padding">
                                    <p class="card-title product_name">
                                        <strong class="product_name">
                                            <a>{{offer.getProductName}}</a>
                                        </strong>
                                    </p>
                                </div>
                            </div>

                            <!-- <mat-card class="mat_card_style" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <input type="text" placeholder="Get Product" matInput [value]="offer.getProductName"
                                        readonly>
                                </mat-form-field>
                            </mat-card> -->
                        </div>

                        <div *ngIf="offer.discPercent || offer.discValue" class="flex_row">
                            <div *ngIf="offer.discValue" class="align left_align"
                                style="display: flex; justify-content: start; align-items: center;">
                                <mat-icon
                                    style="color:#4CAF50; left: -2px !important; top: 0px !important;height: 65% !important;"
                                    id="offer_icon">view_module</mat-icon>
                                <span class="rupee" style="padding-left: 5px;">Discount Amount: </span>
                                <span class="span_details">{{offer.discValue}}</span>
                            </div>
                            <div *ngIf="offer.discPercent" class="align left_align"
                                style="display: flex; justify-content: start; align-items: center;">
                                <img async src="../assets/img/discountg.svg"
                                    style="max-width: 20px !important; max-height: 20px !important; min-width: 20px !important; min-height: 30px !important; background: #ffffff00; border: 0;"
                                    alt="icon">
                                <span class="rupee">Discount %: </span> <span
                                    class="span_details">{{offer.discPercent}}</span>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div *ngIf="offer.index" class="offerDetails">
                <fieldset class="field_set"
                    style="width: 100% !important;padding-left: 4px !important; padding-right: 4px !important;">
                    <legend class="BuyCondition legend_indv">Offer Details</legend>
                    <div>
                        <!-- <div class="flex_row">
                            <div class="align left_align">
                                <mat-icon style="color:#4CAF50; left: -2px !important;" id="offer_icon">date_range
                                </mat-icon>
                                <span style="bottom: 8px;">Start Date: </span> <span
                                    style="bottom: 8px;">{{offer.fromTime |
                                    date: 'yyyy-MM-dd HH:mm'}}</span>
                            </div>
                            <div class="align">
                                <mat-icon style="color:#f44336;; left: -2px !important;" id="offer_icon">date_range
                                </mat-icon>

                                <span style="bottom: 8px;">End Date: </span> <span style="bottom: 8px;">{{offer.toTime |
                                    date: 'yyyy-MM-dd HH:mm'}}</span>
                            </div>
                        </div> -->

                        <div class="flex_row">
                            <div class="align left_align">
                                <img async src="../assets/img/rupeeg.svg"
                                    style="max-width: 30px; max-height: 30px; min-width: 30px; min-height: 30px; background: #ffffff00; border: 0;"
                                    alt="icon">
                                <span class="rupee">Min Qty: </span> <span class="span_details">{{offer.minQty}}</span>
                            </div>

                            <div class="align">
                                <img async src="../assets/img/rupeeg.svg"
                                    style="max-width: 30px; max-height: 30px; min-width: 30px; min-height: 30px; background: #ffffff00; border: 0;"
                                    alt="icon">
                                <span class="rupee">Max Qty: </span> <span
                                    class="span_details">{{offer.maxQtyPerBill}}</span>
                            </div>
                        </div>

                        <!-- <div class="flex_row">
                            <div class="align left_align">
                                <img async src="../assets/img/rupeeg.svg"
                                    style="max-width: 30px; max-height: 30px; min-width: 30px; min-height: 30px; background: #ffffff00; border: 0;"
                                    alt="icon">
                                <span class="rupee">Min Bill Amount: </span> <span class="span_details">{{offer.fromValue}}</span>
                            </div>

                            <div class="align">
                                <img async src="../assets/img/rupeeg.svg"
                                    style="max-width: 30px; max-height: 30px; min-width: 30px; min-height: 30px; background: #ffffff00; border: 0;"
                                    alt="icon">
                                <span class="rupee">Max Bill Amount: </span> <span class="span_details">{{offer.toValue}}</span>
                            </div>
                        </div> -->
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div fxFlex="100" style="height: 53px; background: grey;">
        <button type="submit" id="btnn" (click)="okay()" style="margin-top: 8px; margin-right: 11px;">
            OK
        </button>
    </div>
</div>