import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { CCAvenueDataFormat } from 'src/app/shared/models/CCAvenueDataFormat';
import { GatewayPaymentSetup } from 'src/app/shared/models/gateway-payment-setup';
import { MakePaymentWebMisc, MakePaymentWebMiscDetails } from 'src/app/shared/models/make-payment-web-misc';
import { CartPaymentService } from 'src/app/shared/services/cart-payment.service';
import { CommonService } from 'src/app/shared/services/CommonService';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { getWindow } from 'ssr-window';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit {

  reasons: string[] = ['Miscellaneous'];
  createPaymentForm: UntypedFormGroup;
  cCAvenueurl: string;
  orderId: string;
  locId: number;
  currentUrl: string;

  private getGatewaySetupSubscription: Subscription;
  private sendRequestGatewaySubscription: Subscription;
  private onPaymentInsertSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private localStorage: LocalStorageService,
    private apiUrl: APIFactoryEndPoints,
    private cartPaymentService: CartPaymentService,
    private commonService: CommonService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname;
    // this.locId = this.localStorage.getItem("location_code") ? JSON.parse(this.localStorage.getItem("location_code")) : 19;
    this.locId = this.currentUrl == 'localhost' ? 37 : 19;
    console.log(this.locId)
    this.onCreatePaymentFrom();
  }

  onCreatePaymentFrom() {
    this.createPaymentForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNo: ['', [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]],
      reason: ['', Validators.required],
      amount: [, [Validators.required, Validators.min(1.00)]],
      orderId: [''],
      locationCode: [''],
      status: ['']
    });
  }

  validateNumber(event: KeyboardEvent) {
    const input = event.key;
    const mobileNoControl = this.createPaymentForm.get('mobileNo');

    // Allow only digits (0-9)
    if (!/^\d$/.test(input)) {
      event.preventDefault();
      return;
    }

    // Prevent input if already 10 digits
    if (mobileNoControl?.value && mobileNoControl.value.length >= 10) {
      event.preventDefault();
    }
  }

  onPayClick() {
    if (this.createPaymentForm.invalid) {
      this.showFieldErrors();
      return;
    }
    this.payWithCCavenue();
  }

  showFieldErrors() {
    const controls = this.createPaymentForm.controls;

    if (controls['name'].invalid) {
      this.toastService.showErrorToast("Customer Name is required.");
      return;
    }

    if (controls['email'].invalid) {
      if (controls['email'].errors?.['required']) {
        this.toastService.showErrorToast("Email is required.");
      } else {
        this.toastService.showErrorToast("Invalid email format.");
      }
      return;
    }

    if (controls['mobileNo'].invalid) {
      if (controls['mobileNo'].errors?.['required']) {
        this.toastService.showErrorToast("Mobile Number is required.");
      } else {
        this.toastService.showErrorToast("Mobile Number must be exactly 10 digits.");
      }
      return;
    }

    if (controls['reason'].invalid) {
      this.toastService.showErrorToast("Please select a reason.");
      return;
    }

    if (controls['amount'].invalid) {
      this.toastService.showErrorToast("Amount must be greater than zero.");
      return;
    }
  }

  payWithCCavenue() {
    this.orderId = this.getRandomString();

    this.getGatewaySetupSubscription = this.cartPaymentService.getGatewaySetup(this.apiUrl.getGatewayPaymentSetup, this.locId, "CCAvenue").subscribe((data: GatewayPaymentSetup) => {
      if (data) {
        this.getCCAvenueData(data);
      }
    });
  }

  getCCAvenueData(data: GatewayPaymentSetup) {
    let ccAvenue: CCAvenueDataFormat = new CCAvenueDataFormat();
    ccAvenue.amount = this.createPaymentForm.get('amount').value;
    ccAvenue.orderId = this.orderId;
    ccAvenue.merchantId = data.merchantId;
    ccAvenue.source = "1bakeshop.com";
    var fiveMinutesLater = new Date();
    fiveMinutesLater.setMinutes(fiveMinutesLater.getMinutes() + 5);
    ccAvenue.validTill = moment(fiveMinutesLater).format("yyyy-MM-DDTHH:mm:ss");

    console.log(JSON.stringify(ccAvenue))
    this.sendRequestGatewaySubscription = this.commonService.checkPaytmStatus(this.apiUrl.sendGatewayPaymentRequest, JSON.stringify(ccAvenue)).subscribe((encdata: any) => {
      if (encdata) {
        console.log(encdata);
        this.cCAvenueurl = "https://payment.mobitrix.net/ccavenue?data=" + encdata.encResp + "&access=" + data.accessCode
          + "&orderid=" + ccAvenue.orderId + "&sourceLogo=https://1bakeshop.com/assets/img/logo.webp";
        console.log(this.cCAvenueurl);
        this.savePaymentDetails();
      }
      else {
        this.toastService.showErrorToast('Server side error');
      }
    });
  }

  savePaymentDetails() {
    this.createPaymentForm.patchValue({
      orderId: this.orderId,
      locationCode: this.locId
    })
    // Create a new DTO object
    const paymentData = new MakePaymentWebMisc();

    // Map form values to the DTO structure
    paymentData.name = this.createPaymentForm.value.name;
    paymentData.email = this.createPaymentForm.value.email;
    paymentData.mobileNo = this.createPaymentForm.value.mobileNo;
    paymentData.locationCode = this.createPaymentForm.value.locationCode;
    paymentData.orderId = this.createPaymentForm.value.orderId;

    // Map form details (reason, amount, etc.) to the DTO
    const details: MakePaymentWebMiscDetails = {
      reason: this.createPaymentForm.value.reason,
      amount: this.createPaymentForm.value.amount,
      orderId: this.createPaymentForm.value.orderId,
      status: 'Requested'
    };

    // Add details to the parent DTO
    paymentData.makePaymentWebMiscDetails = [details];

    this.onPaymentInsertSubscription = this.cartPaymentService.insertPayment(this.apiUrl.makePaymentWebMisc, paymentData)
      .subscribe((data) => {
        console.log(data);
        window.location.href = this.cCAvenueurl;
      });
  }

  getRandomString() {
    var myDate = moment(new Date()).format("YYYYMMDDHHmmssSSS");
    var moduleName = "WEBPAY";
    var locId = this.locId;
    let fornattedLoc = locId < 10 ? '000' + '' + locId : locId < 100 ? '00' + '' + locId : locId < 1000 ? '0' + '' + locId : locId;
    var randomstring = moduleName + fornattedLoc + myDate;
    console.log(randomstring)
    return randomstring;
  }

}
