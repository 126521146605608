import { CustomerDTO } from "src/app/common/customerDto";
import { CustomerDeliveryAddress } from "./customerdeliveryaddress";
import { GatewayPayment } from "./gateway-payment";
import { LoginCustomerDetails } from "./logincustomerdetails";
import { ProductCartDto } from "./productcartdto";
import { UpiPayment } from "./upipayment";
import { WalletPayment } from "./wallet-payment";

export class GstSaleInvCumBosDto {
  customerCode: string;
  baseAmt: number;
  discAmt: number;
  taxableValue: number;
  cgstAmt: number;
  sgstAmt: number;
  igstAmt: number;
  ugstAmt: number;
  cessAmt: number;
  netAmt: number;
  totalAmt: number;
  netAmountBeforeRoundedOff: number;
  roundedOffAmount: number;
  notes: string;
  invoiceType: string;
  billType: string;
  locationCode: number;
  invoicingLocationCode: number;
  placeOfSupply: number;
  // byUserCode:number;
  status: string;
  gstin: string;
  deliveryAddressRefNo: number;
  deliveryDate: string;
  deliveryTime: string;
  shippingCharge: number;
  grandTotal: number;
  orderFromTvLocation: number;
  url: string;
  offerId: string;
  shippingTaxRate: number;
	shippingCessRate: number;
	shippingHsnCode: string;
	shippingType: string;
	shippingUnit: string;
	shippingSubGroupCode: number;
	shippingMaxDiscountPercentage: number;
  loginDeliveryAddress: LoginCustomerDetails;
  customerDeliveryAddress: CustomerDeliveryAddress;
  details: ProductCartDto[] = [];
  upipayment: UpiPayment = null;
  gatewayPayment: GatewayPayment = new GatewayPayment();
  walletPayment: WalletPayment = new WalletPayment();
  customer: CustomerDTO = new CustomerDTO();
}
