<div style="margin-top: 2%">
    <div class="section-heading">
        <h2 class="h2_font_size">Delivery Details</h2>
        <div class="hr"></div>
    </div>
</div>

<section id="contact">
    <div class="container">
        <div class="col-lg-7 col-md-7 text-center" *ngIf="showDeliveryDiv">
            <h4>Delivery to this address</h4>
            <div class="col-lg-6 col-md-6 col-md-6 col-sm-6" style="padding: 5px"
                *ngFor="let deliveryDetail of deliveryDetailsList; let i = index"
                (click)="onDeliveryDetailsClick(deliveryDetail, i)">
                <div class="hoverTwo" [ngClass]="i == toogle ? 'trColor' : 'trNoColor'" style="padding: 15px">
                    <h6 style="text-align: center; color: #121111; font-size: 12px">
                        {{ deliveryDetail.contactName }}
                    </h6>
                    <h6 style="text-align: center; color: #343333; font-size: 12px">
                        {{ deliveryDetail.shippingAddress }}
                    </h6>
                    <h6 style="text-align: center; color: #403e3e; font-size: 12px">
                        {{ deliveryDetail.shippingPlotNo }}
                    </h6>
                    <h6 style="text-align: center; color: #625e5e; font-size: 12px">
                        {{ deliveryDetail.shippingCity?.cityName }}
                    </h6>
                    <h6 style="text-align: center; color: #625e5e; font-size: 12px">
                        {{ deliveryDetail.shippingPinCode }}
                    </h6>
                </div>
            </div>
        </div>

        <div class="row" id="contactRow">
            <div class="col-lg-7 col-md-7 text-center">
                <h4 style="margin-top: 2px; margin-bottom: 0px">
                    New delivery details
                </h4>

                <div class="form-style" id="contact_form">
                    <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()" class="formStyle">
                        <div class="form-group">
                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Name</mat-label>
                                        <input type="text" matInput formControlName="customerName" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Mobile No</mat-label>
                                        <input type="number" matInput formControlName="contactNo" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>At/Po</mat-label>
                                        <input type="text" matInput formControlName="address" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Building/Plot</mat-label>
                                        <input type="text" matInput formControlName="plotNo" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Street</mat-label>
                                        <input type="text" matInput formControlName="street" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Land Mark</mat-label>
                                        <input type="text" matInput formControlName="landMark" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="State" formControlName="stateCode"
                                            (selectionChange)="onStateSelect($any($event).value)" required>
                                            <mat-option *ngFor="let state of stateList" [value]="state.refNo">
                                                {{ state.stateName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="District" formControlName="districtCode"
                                            (selectionChange)="onDistrictSelect($any($event).value)" required>
                                            <mat-option *ngFor="let district of districtList" [value]="district.id">
                                                {{ district.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="City" formControlName="cityCode" required>
                                            <mat-option *ngFor="let city of cityList" [value]="city.id">
                                                {{ city.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Pincode</mat-label>
                                        <input type="text" matInput formControlName="pincode" (keyup)="choosePincode()"
                                            readonly />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Latitude</mat-label>
                                        <input type="text" matInput formControlName="latitude" readonly />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Longitude</mat-label>
                                        <input type="text" matInput formControlName="longitude" readonly />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div>
                                <div>
                                    <div style="padding-bottom: 2px">
                                        <input type="text" class="form-control"
                                            (keydown.enter)="$any($event).preventDefault()"
                                            placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                            spellcheck="off" type="text" #search [disabled]="!newDeliveryDetails" />
                                        <mat-icon class="fetch_icon" (click)="fetchCurrentLoc()"
                                            matTooltip="Fetch current location">my_location
                                        </mat-icon>
                                    </div>
                                    <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom">
                                        <agm-marker [latitude]="latitudeMap" [longitude]="longitudeMap"
                                            [markerDraggable]="newDeliveryDetails"
                                            (dragEnd)="markerDragEnd($any($event))">
                                        </agm-marker>
                                    </agm-map>
                                </div>
                            </div>

                            <a class="btn" id="submit_btn">
                                <div class="btn-line"></div>
                                <button type="submit" class="btn-line btn-line-shift"
                                    style="background-color: #d0324b; color: white">
                                    Submit
                                </button> Send Message
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>