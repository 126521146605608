<div class="center-wrapper">
  <div class="content">
    <nav>
      <h1 class="logo" style="margin-left: 185px;">Order Summary</h1>
    </nav>

    <div class="bag-product">
      <div class="image">
        <img
          src="{{ webUrl }}{{ product.product_Picture }}"
          [alt]="product.product_Name"
          width="100%"
          class="product-image"
        />
      </div>
      <div class="description">
        <p class="product-code small muted">
          Product Name: {{ product.product_Name }}
        </p>
        <p class="product-code small muted">
          SubGroup: {{ product.productSubGroup.productSubGroupName }}
        </p>
        <p class="product-code small muted">
          Group: {{ product.productSubGroup.productGroup.productGroupName }}
        </p>
        <p class="product-code small muted">
          Matrix:
          {{
            product.productSubGroup.productGroup.productMatrix.productMatrixName
          }}
        </p>
        <p class="description-text">{{ product.description }}</p>
        <h2>{{ product.net_Sale_Price | number: "1.2-2" }}</h2>
      </div>
    </div>
    <div class="bag-total">
      <div class="delivery">
        <p class="small">
          Delivery (Standard - 2 working days):<br />
          <span class="change-delivery">Change delivery method</span>
        </p>
        <p class="small">Free</p>
      </div>
      <div class="total">
        <h3>Total:</h3>
        <h3>{{ product.net_Sale_Price | number: "1.2-2" }}</h3>
      </div>
      <div class="promo-checkbox">
        <input type="checkbox" name="promo-check" checked />
        <label for="promo-check">I have a promo code</label>
      </div>
      <div class="promo-code">
        <input
          type="text"
          name="promo-checkbox"
          placeholder="Enter your promo code here"
          checked
        />
        <button class="apply">Apply</button>
      </div>
      <button class="btn-go-checkout" (click)="checkPaymentOption(product)">
        <i class="fa fa-lock"></i>
        <span>Go to Checkout</span>
      </button>
    </div>
  </div>
</div>
