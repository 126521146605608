export class CustomerDeliveryAddress {
  refNo: number;
  customerCode: string;
  contactName: string;
  contactMobile: number;
  email: string;
  deliveryDate: Date;
  shippingAddress: string;
  shippingPlotNo: string;
  shippingStreet: string;
  shippingLandMark: string;
  shippingPinCode: number;
  shippingCityCode: number;
  districtCode: number;
  stateCode: number;
  latitude: string;
  longitude: string;

  stateName: string;
  cityName: string;

}
