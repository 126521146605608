import { CommonModule } from "@angular/common";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
// Core Dependencies
import { RouterModule } from "@angular/router";
import { ProductModule } from "../../pages/product/product.module";
import { PhoneNumberDialogComponent } from "./phone-number-dialog/phone-number-dialog.component";
import { SharedModule } from "./../../../shared/shared.module";
// Components
import { IndexComponent } from "./index.component";
import { IndexRoutes } from "./index.routing";
import { LoginComponent } from "./login/login.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { SignupDialogComponent } from './signup-dialog/signup-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ProductModule,
    SharedModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatMenuModule,
    MatAutocompleteModule,
    RouterModule.forChild(IndexRoutes),
  ],
  declarations: [
    IndexComponent,
    NavbarComponent,
    LoginComponent,
    PhoneNumberDialogComponent,
    SignupDialogComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
  exports: [NavbarComponent],
  providers: [],
})
export class IndexModule {}
