<div style="margin-top: 2%;">
    <div class="section-heading">
        <h2 class="h2_font_size">Delete User</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="form-style" id="contact_form" class="col-lg-7 col-md-7 text-center profile-line">
    <form [formGroup]="profileForm">
        <div class="form-group">

            <div class="input-wrap">
                <input type="text" formControlName="name" required readonly/>
                <label>Name</label>
            </div>

            <div class="input-wrap">
                <input type="text" formControlName="phoneNo" pattern="[6-9]{1}[0-9]{9}"
                    (keyup)="checkOtp($any($event.target).value)" required readonly/>
                <label>Mobile</label>
                <!-- <span *ngIf="!isDisabledSubmit" class="clearBtn" (click)="onVerifyClick()">Verify</span>
                <span *ngIf="isDisabledSubmit" class="clearBtn1">Verified</span> -->
            </div>

            <div class="input-wrap">
                <input type="Email" formControlName="email" required readonly/>
                <label>Email</label>
            </div>

            <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="button" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white;"
                    (click)="onVerifyClick()">
                    DELETE
                </button> DELETE
            </a>
        </div>
    </form>
</div>