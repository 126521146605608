<div style="height: 100%; overflow-y: scroll">
    <br />
    <div class="popup" id="privacyPolicy">
      <div class="popup-inner" style="padding: 6%; padding-top: 4%">
        <h2 style="margin-bottom: 0px; text-align: center">Privacy Policy</h2>
        <div style="
            display: block;
            width: 60px;
            height: 3px;
            background: red;
            margin: 10px auto;
            margin-top: 1px;
          "></div>
        <p style="margin-top: 20px; font: 14px/18px Roboto">
          <br />
          1bakeshop respects your privacy. You are advised to please read the
          Privacy Policy carefully. By accessing the services provided by
          1bakeshop.com you agree to the collection and use of your data by
          1bakeshop.com in the manner provided in this Privacy Policy.
          <br />
          <br />
          <b style="font: 500 16px/22px Roboto">What log data is collected by us?</b>
          <br />
          <br />
          We will automatically receive and collect certain information in
          standard usage logs through our Web server, including
          computer-identification information obtained from "cookies," sent to
          your browser from a web server cookie stored on your hard drive an IP
          address.
          <br />
          <br />
          <b style="font: 500 16px/22px Roboto">What Personally Identifiable Information is collected by
            1bakeshop.com?</b>
          <br />
          <br />
          <span>
            <ul style="list-style-type: square">
              <li>Name including first and last name.</li>
              <li>Alternate email address.</li>
              <li>ZIP/Postal code.</li>
              <li>Other information as per our registration process.</li>
            </ul>
          </span>
          <br />
          <b style="font: 500 16px/22px Roboto">What purposes personal information is used for?</b>
          <br />
          <br />
          <span>
            <ul style="list-style-type: square">
              <li>Direct our efforts for product improvement.</li>
              <li>Contact you as a survey respondent.</li>
              <li>Send you promotional materials.</li>
            </ul>
          </span>
          <br />
          <b style="font: 500 16px/22px Roboto">Security:</b>
          <br />
          <br />
          Security of your personal information is important to us. We do not
          collect any credit/debit card related information.
          <br />
          <br />
          <b style="font: 500 16px/22px Roboto">What Communication you may receive from us?</b>
          <br />
          <br />
          <span>
            <ul style="list-style-type: square">
              <li>
                Offers & Updates: We may send you information on products,
                services, deals, promotional offers occasionally.
              </li>
              <li>
                Emergency announcements: We shall send you service related
                announcements on occasions when it is most necessary to do so.
              </li>
            </ul>
          </span>
          <br />
          <b style="font: 500 16px/22px Roboto">Privacy policy for links to other sites:</b>
          <br />
          <br />
          This privacy policy applies only to information collated by this website
          and does not apply to other websites that are linked through our site.
          These sites are neither owned nor controlled by us and you are advised
          to refer to their respective privacy policy.
          <br />
          <br />
          <b style="font: 500 16px/22px Roboto">Legal Disclaimer:</b>
          <br />
          <br />
          We reserve the right to disclose your personally identifiable
          information as required by law and when we believe that disclosure is
          necessary to protect our rights and/or to comply with a judicial
          proceeding, court order, or legal process.
          <br />
          <br />
          If you no longer wish to receive our press releases, newsletter and
          promotional communications, you may opt-out of receiving them by
          following the instructions included in each newsletter or communication
          or by emailing us at info@1bakeshop.com.
        </p>
      </div>
    </div>
  
    <!-- <div class="popup" id="shippingPolicy">
      <div class="popup-inner" style="padding: 6%; padding-top: 4%">
        <h2 style="margin-bottom: 0px; text-align: center">Shipping Policy</h2>
        <div style="display: block;width: 60px;height: 3px;background: red;margin: 10px auto;margin-top: 1px;"></div>
        <p style="margin-top: 20px; font: 14px/18px Roboto">
          1. Presently delivery option is available only in Bhubaneswar, Odisha,
          India.
          <br />
          2. Deliveries will be made between 11:00 A.M to 6:00 P.M on the mentioned
          date of delivery. <br />
          3.The order must be confirmed at least 24 hours in advance. <br />
          4. The images displayed on the website are indicative in nature and the
          actual product may vary in appearance, shape or design as per
          availability. <br />
          5.We reserve the right to replace the product with equal/higher value
          incase of non availability of the ordered product. <br />
          6. In case of natural calamities (floods/heavy rains/ Bandh we reserve the
          right to reschedule the delivery for another date. <br />
          7. No change of instructions is possible after an order is confirmed.
          <br />
          8. Orders once placed cannot be cancelled. <br />
          9. Delivery order will have only 1 attempt. Incase the delivery could not
          be performed during the attempt there will not be any refund to the
          customer and no redelivery would be possible for the same order. <br />
          10. The Delivery is assumed to be executed in the following case:-
          <br />&nbsp;&nbsp; a. Delivery was not possible because of wrong address.
          <br />&nbsp;&nbsp; b. Recipient was not available at the time of delivery.
          <br />&nbsp;&nbsp; c. Delivery address premises were locked.
          <br />&nbsp;&nbsp; d. The recipient was not able to be contacted due to
          technical reasons. <br />&nbsp;&nbsp; e. The recipient refuses to receive
          the product. <br />&nbsp;&nbsp; f. If delivery takes place at the
          Reception/Gate because of recipients security issues. <br />
          11. Any complaint pertaining to the ordered product must be communicated
          with in 3 hours of delivery. <br />
          12. Proof of delivery shall be available for a period of 15 days and can
          be shared in case of any dispute. <br />
          13. Orders can be cancelled at least 1 day prior to the delivery date by
          writing a mail to info@1bakeshop.com
        </p>
      </div>
    </div> -->
  </div>