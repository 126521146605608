import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { WebOrderFranchiseSetup } from "../models/web-order-franchise-setup";

@Injectable({
  providedIn: "root",
})
export class FranchiseService {
  constructor(private http: HttpClient, private apiURL: APIFactoryEndPoints) { }

  onCreateSetUp(url: string, obj: UntypedFormGroup): Observable<any> {
    return this.http.post(url, obj.value, { responseType: "text" });
  }

  checkOTPSentOrNot(url: string, obj: string): Observable<any> {
    return this.http.get<string>(url + "/" + obj);
  }

  checkOTPSentOrNotWithIpAddress(url: string, mblNo: string, ip: string): Observable<any> {
    return this.http.get<string>(url + "/" + mblNo + "/" + ip);
  }

  sendMessageByMailChimpFromFranchaise(url: string, obj: UntypedFormGroup, file: File): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("formData", new Blob([JSON.stringify(obj.value)], { type: "application/json" }));

    return this.http.post(url, formData);
  }

  getWebOrderFranchiseSetup(url: string, currentUrl: string) {
    return this.http.get<WebOrderFranchiseSetup[]>(url + "/" + currentUrl);
  }
}
