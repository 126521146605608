export class FBCustomer {
    id: string;
    name: string;
    email: string;
    firstName: string;
    lastName: string;
    authToken: string;
    provider: string;
    lattitude:string;
    longitude:string;
    phoneNo:string;
  }