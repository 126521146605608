import { Product } from "./product";

export class CartDetails {
  id: string;
  status: string;
  invoiceNo: string;
  logInCustomerEmail: string;
  locationCode: number;
  product: Product[] = [];
}
