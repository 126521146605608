import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.scss"],
})
export class PaymentSuccessComponent implements OnInit {

  googleCustomer: GoogleCustomer;

  constructor(private localStorage: LocalStorageService,
    private navRoute: Router
  ) {
    console.log(this.navRoute.getCurrentNavigation().extras.state);
    if (this.navRoute.getCurrentNavigation().extras.state != undefined) {
      this.navRoute.getCurrentNavigation().extras.state = undefined;
      window.location.reload();
    }
  }

  ngOnInit() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
  }

  go() {
    this.navRoute.navigate(["/"]);
  }

  download() {
    this.navRoute.navigate(["/invoice-history"]);
  }
}
