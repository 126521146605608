const { environment } = require("./environments/environment");
var fragmentString = location.hash.substring(1);

// Parse query string to see if page request is coming from OAuth 2.0 server.
var params = {};
var regex = /([^&=]+)=([^&]*)/g, m;
while (m = regex.exec(fragmentString)) {
  params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
}
if (Object.keys(params).length > 0) {
  localStorage.setItem('oauth2-params', JSON.stringify(params));
  if (params['state'] && params['state'] == 'request') {
    googleSignIn();
  }
}

exports.googleSignIn = function () {
  var params = JSON.parse(localStorage.getItem('oauth2-params'));
  if (params && params['access_token']) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET',
      'https://www.googleapis.com/drive/v3/about?fields=user&' +
      'access_token=' + params['access_token']);
    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        console.log(xhr.response);

      } else if (xhr.readyState === 4 && xhr.status === 401) {
        oauth2SignIn();
      }
    };
    xhr.send(null);
  } else {
    oauth2SignIn();
  }
}


function googleSignIn() {
  var res = ""
  var params = JSON.parse(localStorage.getItem('oauth2-params'));
  if (params && params['access_token']) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET',
      'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&' +
      'access_token=' + params['access_token']);
    console.log(params['access_token'])
    localStorage.setItem("access_token", params['access_token'])
    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {

        localStorage.setItem("signin_resp", xhr.response)

        if (window.location.href.includes("state=request&access_token")) {
          window.location.href = localStorage.getItem("current_url")
        }
      } else if (xhr.readyState === 4 && xhr.status === 401) {
        // Token invalid, so prompt for user permission.
        oauth2SignIn();
      }
    };
    xhr.send(null);
  } else {
    oauth2SignIn();
  }
  return res;
}
/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function oauth2SignIn() {

  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Create element to open OAuth 2.0 endpoint in new window.
  var form = document.createElement('form');
  form.setAttribute('method', 'GET'); // Send as a GET request.
  form.setAttribute('action', oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    'client_id': environment.googleClientId,
    'redirect_uri': environment.googleRedirectUrl,
    'scope': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    'state': 'request',
    'ux_mode': 'redirect',
    'include_granted_scopes': 'true',
    'response_type': 'token'
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', p);
    input.setAttribute('value', params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}


exports.revokeGoogleSignIn = function () {



  var xhr = new XMLHttpRequest();
  xhr.open('POST',
    'https://oauth2.googleapis.com/revoke?token=' + localStorage.getItem("access_token"));

  xhr.onreadystatechange = function (e) {
    if (xhr.readyState === 4 && xhr.status === 200) {
      // alert("Success");
    } else if (xhr.readyState === 4 && xhr.status === 401) {
      // alert("Failed")
    }
  };
  xhr.send(null);


  // // Google's OAuth 2.0 endpoint for revoking access tokens.
  // var revokeTokenEndpoint = 'https://oauth2.googleapis.com/revoke';

  // // Create <form> element to use to POST data to the OAuth 2.0 endpoint.
  // var form = document.createElement('form');
  // form.setAttribute('method', 'post');
  // form.setAttribute('action', revokeTokenEndpoint);

  // // Add access token to the form so it is set as value of 'token' parameter.
  // // This corresponds to the sample curl request, where the URL is:
  // //      https://oauth2.googleapis.com/revoke?token={token}
  // var tokenField = document.createElement('input');
  // tokenField.setAttribute('type', 'hidden');
  // tokenField.setAttribute('name', 'token');
  // tokenField.setAttribute('value', localStorage.getItem("access_token"));
  // form.appendChild(tokenField);

  // // Add form to page and submit it to actually revoke the token.
  // document.body.appendChild(form);
  // form.submit();
}
