<div>
    <div class="section-heading">
        <h5>Select your preferred option</h5>
    </div>
    <!-- <h1 mat-dialog-title class="dialog-title">Pickup or Delivery</h1> -->
    <div mat-dialog-content>
        <!-- <p class="dialog-description">Select your preferred option:</p> -->
        <!-- <p *ngIf="deliveryType" class="dialog-description" style="font-size: 12px;margin: 0px;line-height: 12px;">
            Changing this will move items to wishlist and empty your cart.
        </p> -->
        <!-- <mat-radio-group class="radio-group" [(ngModel)]="selectedOption" (ngModelChange)="onOptionChange($event)">
            <mat-radio-button class="radio-button" value="storePickup">
                <span class="icon"> <img [src]="'assets/img/pickup_pink.png'" class="delivery-icon" />
                    Pick Up from Store</span>
            </mat-radio-button>
            <div *ngIf="selectedOption === 'storePickup'" style="width: 100%;">
                <mat-card class="contain_card" fxFlex="100" style="padding-left: 9px !important;">
                    <mat-form-field class="selectOption">
                        <mat-select placeholder="Select Location" (selectionChange)="onLocationSelect($event.value)">
                            <mat-option *ngFor="let loc of storeLocationList" [value]="loc">
                                {{loc.locationName}} ({{loc.cityName}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </mat-card>

                <div *ngIf="storeLocation" class="store-address">
                    <h3>Store Address: {{storeLocation.locationName}}</h3>
                    <p>{{storeLocation.address}}</p>
                </div>

            </div>
            <mat-radio-button class="radio-button" value="delivery">
                <span class="icon"><img [src]="'assets/img/delivery_pink.svg'" class="delivery-icon" />
                    Delivery</span>
            </mat-radio-button>
        </mat-radio-group> -->
        <div style="margin-top: 10px;">
            <div class="delivery-selection">
                <button class="delivery-tab" [class.active]="selectedOption === 'storePickup'"
                    (click)="onOptionChange('storePickup')">
                    <img [src]="selectedOption === 'storePickup' ? 'assets/img/pickup_white.png' : 'assets/img/pickup_pink.png'"
                        class="delivery-icon" />
                    Pickup
                    <span *ngIf="selectedOption === 'storePickup'" class="selected-tick">✔</span>
                    <span *ngIf="selectedOption !== 'storePickup'" class="selected-tick"></span>
                </button>

                <button class="delivery-tab" [class.active]="selectedOption === 'delivery'"
                    (click)="onOptionChange('delivery')">
                    <img [src]="selectedOption === 'delivery' ? 'assets/img/delivery_white.svg' : 'assets/img/delivery_pink.svg'"
                        class="delivery-icon" />
                    Delivery
                    <span *ngIf="selectedOption === 'delivery'" class="selected-tick">✔</span>
                    <span *ngIf="selectedOption !== 'delivery'" class="selected-tick"></span>
                </button>
            </div>

            <div *ngIf="selectedOption === 'storePickup'" style="width: 100%; margin-top: 10px;">

                <!-- Fetch Nearest Location Button -->
                <!-- <button mat-button class="fetch-location-btn" [ngClass]="{ 'selected': showNearestLocation }"
                    (click)="toggleNearestLocation()">
                    <mat-icon class="location-icon">location_on</mat-icon>
                    {{ showNearestLocation ? 'Nearest Location Selected' : 'Fetch Nearest Location' }}
                </button> -->



                <!-- Location Selection -->
                <mat-card class="contain_card" fxFlex="100" style="padding-left: 9px !important; margin-top: 10px;">
                    <mat-form-field class="selectOption">
                        <mat-select [placeholder]="showNearestLocation ? 'Select Nearest Location' : 'Select Location'"
                            (selectionChange)="onLocationSelect($event.value)" [value]="storeLocation"
                            panelClass="custom-panel">

                            <!-- Search Box (Properly Positioned & Non-Selectable) -->
                            <mat-option *ngIf="true" class="search-option" [disableRipple]="true" [disableClose]="true">
                                <mat-form-field class="search-field">
                                    <input matInput type="text" [(ngModel)]="searchText"
                                        placeholder="Search location..." (keyup)="filterLocations($event)"
                                        (click)="$event.stopPropagation()" />
                                </mat-form-field>
                            </mat-option>

                            <!-- Filtered Locations -->
                            <mat-option *ngFor="let loc of filteredLocations" [value]="loc">
                                <div class="location-name">{{ loc.locationName }} ({{ loc.cityName }})</div>
                                <div *ngIf="getDistanceInKm(loc.distanceInMeters) !== 'Distance not available'"
                                    class="distance-text">
                                    {{ getDistanceInKm(loc.distanceInMeters) }}
                                </div>
                            </mat-option>

                        </mat-select>
                    </mat-form-field>
                </mat-card>





                <!-- Display Selected Location Address -->
                <div *ngIf="storeLocation" class="store-address">
                    <h3>Selected Address: {{storeLocation.locationName}}</h3>
                    <p>{{storeLocation.address}}</p>
                    <p><small>{{ getDistanceInKm(storeLocation.distanceInMeters) }}</small></p>
                </div>

            </div>


        </div>
    </div>
    <div mat-dialog-actions class="dialog-actions">
        <button mat-button mat-dialog-close class="cancel-button">Cancel</button>
        <button mat-button (click)="onContinueClick(selectedOption)" class="continue-button">Continue</button>
    </div>
</div>