import { Routes, RouterModule } from "@angular/router";
import { FranchaiseComponent } from './franchaise.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "franchaise-app",
        component: FranchaiseComponent,
      },
    ],
  },
];

export const FranchaiseRoutes = RouterModule.forChild(routes);
