import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CityWithPinPositionDto } from "../models/citywithpinpositiondto";
import { LocationDetailsForStore } from "../models/locationdetailsforstore";
import { State } from "../models/state";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";
import { City } from "../models/city";
import { NameWithIdDto } from "../models/namewithiddto";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) { }

  getStateList() {
    return this.http.get<State[]>(this.apiFactory.getStateList);
  }

  getDistrictByStateCode(url: string, stateCode: number, currentUrl: string) {
    return this.http.get<LocationDetailsForStore>(url + "/" + stateCode + "/" + currentUrl);
  }
  getAllLocations(url: string) {
    return this.http.get<LocationDetailsForStore>(url);
  }
  getCityByDistrictCode(url: string, districtCode: number, currentUrl: string) {
    return this.http.get<LocationDetailsForStore>(url + "/" + districtCode + "/" + currentUrl);
  }

  getCitysByDistrictCode(url: string, districtCode: number) {
    return this.http.get<NameWithIdDto[]>(url + "/" + districtCode);
  }

  getLocationByCityCode(url: string, cityCode: number, currentUrl: string) {
    return this.http.get<LocationDetailsForStore>(url + "/" + cityCode + "/" + currentUrl);
  }

  getLocationByLocationCode(url: string, locationCode: number, currentUrl: string) {
    return this.http.get<LocationDetailsForStore>(url + "/" + locationCode + "/" + currentUrl);
  }

  searchCityWithPinPosition(url: string, search: string) {
    return this.http.get<CityWithPinPositionDto[]>(url + "/" + search);
  }

  getCitiesByDistrictCode(url: string, districtCode: number[]) {
    return this.http.get<City[]>(url + "/" + districtCode);
  }

  getDistrictListByStateCode(url: string, stateCode: number) {
    return this.http.get<any>(url + "/" + stateCode);
  }

  getCityListByDistrictCode(url: string, districtCode: number) {
    return this.http.get<any>(url + "/" + districtCode);
  }
}
