import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "src/app/shared/shared.module";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSelectModule } from "@angular/material/select";
import { InvoiceHistoryComponent } from "./invoice-history.component";
import { InvoiceHistoryRoutes } from "./invoice-history.routing";
import { InvoicePreviewComponent } from "./invoice-preview/invoice-preview.component";
import { NgbRating } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    SharedModule,
    InvoiceHistoryRoutes,
    NgbRating
  ],
  declarations: [InvoiceHistoryComponent, InvoicePreviewComponent],
})
export class InvoiceHistoryModule {}
