<section class="mbr-section mbr-after-navbar" id="form1-1r"
    style="background-color: #f8f9fa; padding-top: 0px; height:15%;">
    <form>
        <div>
            <mat-list class="mat-list-group">
                <mat-list-item class="mat-list-item-group" *ngFor="let matrix of matrixListProduct"
                    (click)="onMatrixSelectProduct(matrix.refNo)"
                    [ngClass]="groupClickIdProduct == matrix.refNo ? 'group_active' : 'cell-style'">
                    {{ matrix.productMatrixName }}</mat-list-item>
            </mat-list>
        </div>
        <div *ngIf="subGroupShow">
            <mat-list class="mat-list-subgroup">
                <mat-list-item class="mat-list-item-subgroup" *ngFor="let group of groupListProduct"
                    (click)="onGroupSelectProduct(group.refNo)"
                    [ngClass]="subGroupClickIdProduct == group.refNo ? 'group_active' : 'cell-style'">
                    {{ group.productGroupName }}
                </mat-list-item>
            </mat-list>
        </div>
    </form>
</section>

<div class="div-display">
    <div class="system-view" *ngIf="show">
        <form [formGroup]="searchFormProduct">
            <div class="display-container">
                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;">
                    <div class="header-style">
                        <h3 class="h3-style">Price</h3>
                    </div>

                    <div style="display: flex; flex-direction: row; ">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Min"
                                        (keyup)="validateMinMaxProduct($any($event.target).value, 'min')"
                                        formControlName="minValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>

                        <h1 style="margin-top:-5px; margin-left:8px;color: #333;">_</h1>

                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Max"
                                        (keyup)="validateMinMaxProduct($any($event.target).value, 'max')"
                                        formControlName="maxValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateProduct('price', $any($event.target).value)"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForPriceProduct" class="button-style"
                                (click)="buttonClickForSearchProduct('price')">Apply</button>
                            <button *ngIf="cleatBtnPriceProduct" class="clear-btn-style"
                                (click)="clearPriceInputProduct()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;">
                    <div class="header-style">
                        <h3 class="h3-style">Discount</h3>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Min"
                                        (keyup)="validateNumberProduct($any($event.target).value, 'template', 'min')"
                                        formControlName="minDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-top: -5px;  margin-left:8px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Max"
                                        (keyup)="validateNumberProduct($any($event.target).value, 'template', 'max')"
                                        formControlName="maxDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateProduct('disc', $any($event.target).value)"
                                        style=" width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForDiscProduct" class="button-style"
                                (click)="buttonClickForSearchProduct('disc')">Apply</button>
                            <button *ngIf="cleatBtnDiscProduct" class="clear-btn-style"
                                (click)="clearDiscInputProduct()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;"
                    *ngFor="let p of offerTypeArrayProduct;">
                    <div class="header-style" *ngIf="foodTypes.length >= 1">
                        <h3 class="h3-style">Offer</h3>
                    </div>
                    <div *ngFor="let p of offerTypeArrayProduct; let i = index;"
                        style="font-size: 13px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onChangeCheckBoxClickProduct($event.checked, p)"
                            [checked]="radioStatusProduct"> {{p}}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px; border-bottom: 1px solid;
                border-width: 2px; border-color: #e91e63;">
                    <div class="header-style">
                        <h3 class="h3-style">Food Type</h3>
                    </div>
                    <div *ngFor="let f of foodTypes" style="font-size: 13px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onCheckBoxClickProduct($event.checked, f)"
                            [checked]="checkedStatusProduct"> {{f}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="container product_list_div" *ngIf="show">
        <div class="row">
            <div class="col-md-12">
                <div class="container div-style">
                    <app-card-loader [loop]="3" [height]="800" *ngIf="loadingProduct"></app-card-loader>
                    <div class="row" *ngIf="!loadingProduct">
                        <!-- List of Products -->
                        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6" style="padding: 0;"
                            *ngFor="let product of productListProduct | filterByBrand: selectedBrand">
                            <div class="product_detaiils">
                                <div class="item">
                                    <div class="mb-r">
                                        <!--Card-->
                                        <div class="card card-cascade wider">
                                            <!--Card image-->
                                            <a>
                                                <div class="view overlay hm-white-slight">
                                                    <img src="../../../../assets/img/{{product.product_Picture}}"
                                                        class="img-fluid" alt="Image not found" width="360px"
                                                        height="300px"
                                                        onerror="this.src='../../../../assets/img/defaultCake.webp';"
                                                        [routerLink]="['/product', product.product_Code]" />
                                                    <img *ngIf="product.food_Type == 'Veg'"
                                                        class="img-responsive img_align"
                                                        src="../../../../assets/img/Veg.webp" alt="" />
                                                    <img *ngIf="product.food_Type == 'Non-Veg'"
                                                        class="img-responsive img_align"
                                                        src="../../../../assets/img/Nonveg.webp" alt="" />

                                                    <img *ngIf="product.offers >= 1" class="img-responsive offer_align"
                                                        src="../../../../assets/img/Offer 1.webp" alt=""
                                                        (click)="viewOfferDetailProduct(product.offerDetails)"
                                                        matTooltip="Offers" />

                                                    <mat-icon [matMenuTriggerFor]="menu"
                                                        id="icon_align">share</mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <share-buttons theme="circles-dark"
                                                            [include]="['copy','whatsapp','facebook','email','telegram']"
                                                            [showIcon]="true" [showText]="false"
                                                            [url]="sharingUrlProduct+product.product_Code"
                                                            [description]="product.product_Name" class="pt-5">
                                                        </share-buttons>
                                                    </mat-menu>
                                                </div>
                                            </a>

                                            <div class="card-body text-center no-padding">
                                                <p class="card-title product_name">
                                                    <strong style="height: 80%;">
                                                        <a [routerLink]="['/product', product.product_Code]"
                                                            routerLinkActive="router-link-active">
                                                            {{product.product_Name }}</a>
                                                    </strong>
                                                    <strong class="price_style">
                                                        <span>₹ {{ product.net_Sale_Price | number: "1.2-2"
                                                            }}</span>
                                                    </strong>
                                                </p>

                                                <div class="card-footer">
                                                    <span>{{product.portion_Weight }}</span>

                                                    <span class="left">
                                                        <a placement="top" mdbTooltip="Add to Wishlist" container="body"
                                                            (click)="addFavouriteProduct(product)">
                                                            <i class="fa fa-heart"
                                                                style="font-size: 17px; color: #e91e63"></i>
                                                        </a>
                                                    </span>

                                                    <span class="right" style="margin-right: -25px">
                                                        <a placement="top" mdbTooltip="Add to Cart" container="body"
                                                            (click)="addToCartProduct(product)">
                                                            <i class="fa fa-shopping-cart"
                                                                style="margin-left: 6px;color: #e91e63;font-size: 19px;"></i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div [ngClass]="openfilterProduct ? 'open_filter' : 'close_filter'">
    <div class="open-filter mobile-view">
        <div class="display-container">
            <div class="display_holder" (click)="openfilterProduct = !openfilterProduct"
                (touchstart)="openfilterProduct = !openfilterProduct">
                <span class="display_detail">Filter
                </span>
                <mat-icon *ngIf="!openfilterProduct" id="expand_more_icon">expand_less
                </mat-icon>
                <mat-icon *ngIf="openfilterProduct" id="expand_less_icon">expand_more
                </mat-icon>
            </div>
        </div>

        <form [formGroup]="searchFormProduct">
            <div *ngIf="openfilterProduct"
                style="display: flex; flex-direction: column; background: white;overflow: auto; height: 299px; overscroll-behavior: contain;">
                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;">
                    <div class="header-style">
                        <h3 class="h3-style">Price</h3>
                    </div>

                    <div style="display: flex; flex-direction: row; margin-left: 2rem;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Min"
                                        (keyup)="validateMinMaxProduct($any($event.target).value, 'min')"
                                        formControlName="minValue" autocomplete="off"
                                        (focus)="$any($event.target).select()" style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-left: -12px;margin-right: 2px;margin-top: -4px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Max"
                                        (keyup)="validateMinMaxProduct($any($event.target).value, 'max')"
                                        formControlName="maxValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateProduct('price', $any($event.target).value)"
                                        style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForPriceProduct" class="button-style"
                                (click)="buttonClickForSearchProduct('price')">Apply</button>
                            <button *ngIf="cleatBtnPriceProduct" class="clear-btn-style"
                                (click)="clearPriceInputProduct()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;">
                    <div class="header-style">
                        <h3 class="h3-style">Discount</h3>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-left: 2rem;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Min"
                                        (keyup)="validateNumberProduct($any($event.target).value, 'template', 'min')"
                                        formControlName="minDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()" style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-left: -12px;margin-right: 2px;margin-top: -4px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Max"
                                        (keyup)="validateNumberProduct($any($event.target).value, 'template', 'max')"
                                        formControlName="maxDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateProduct('disc', $any($event.target).value)"
                                        style=" width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForDiscProduct" class="button-style"
                                (click)="buttonClickForSearchProduct('disc')">Apply</button>
                            <button *ngIf="cleatBtnDiscProduct" class="clear-btn-style"
                                (click)="clearDiscInputProduct()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;">
                    <div class="header-style">
                        <h3 class="h3-style">Offer</h3>
                    </div>
                    <div *ngFor="let p of offerTypeArrayProduct"
                        style="font-size: 12px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onChangeCheckBoxClickProduct($event.checked, p)"
                            [checked]="radioStatusProduct"> {{p}}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; border-bottom: 1px solid; margin-right: 8px;
                    border-width: 2px; border-color: #e91e63;" *ngIf="foodTypes.length >= 1">
                    <div class="header-style">
                        <h3 class="h3-style">Food Type</h3>
                    </div>
                    <div *ngFor="let f of foodTypes" style="font-size: 12px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onCheckBoxClickProduct($event.checked, f)"
                            [checked]="checkedStatusProduct"> {{f}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Make To Order -->
<section class="mbr-section mbr-after-navbar" id="form1-1r"
    style="background-color: #f8f9fa; padding-top: 0px; height: 15%;">
    <form>
        <div>
            <mat-list class="mat-list-group">
                <mat-list-item class="mat-list-item-group" *ngFor="let group of makeToOrderGroupList"
                    (click)="onGroupSelectMto(group.refNo)"
                    [ngClass]="groupClickIdMto == group.refNo ? 'group_active' : 'cell-style'">
                    {{ group.productGroupName }}
                </mat-list-item>
            </mat-list>
        </div>

        <div *ngIf="subGroupShowMto">
            <mat-list class="mat-list-subgroup">
                <mat-list-item class="mat-list-item-subgroup" *ngFor="let subGroup of subGroupListMto"
                    (click)="onSubGroupSelectMto(subGroup.refNo)"
                    [ngClass]="subGroupClickIdMto == subGroup.refNo ? 'group_active' : 'cell-style'">
                    {{ subGroup.productSubGroupName }}
                </mat-list-item>
            </mat-list>
        </div>
    </form>
</section>

<div class="div-display">
    <div class="system-view" *ngIf="show">
        <form [formGroup]="searchFormMto">
            <div class="display-container">
                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;">
                    <div class="header-style">
                        <h3 class="h3-style">Price</h3>
                    </div>

                    <div style="display: flex; flex-direction: row;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Min"
                                        (keyup)="validateMinMaxMto($any($event.target).value, 'min')"
                                        formControlName="minValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-top:-5px; margin-left:8px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Max"
                                        (keyup)="validateMinMaxMto($any($event.target).value, 'max')"
                                        formControlName="maxValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateMto('price', $any($event.target).value)"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForPriceMto" class="button-style"
                                (click)="buttonClickForSearchMto('price')">Apply</button>
                            <button *ngIf="cleatBtnPriceMto" class="clear-btn-style"
                                (click)="clearPriceInputMto()">Clear</button>
                        </div>
                    </div>
                </div>
                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;">
                    <div class="header-style">
                        <h3 class="h3-style">Discount</h3>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Min"
                                        (keyup)="validateNumberMto($any($event.target).value, 'template', 'min')"
                                        formControlName="minDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        style="width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-top: -5px;  margin-left:8px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Max"
                                        (keyup)="validateNumberMto($any($event.target).value, 'template', 'max')"
                                        formControlName="maxDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateMto('disc', $any($event.target).value)"
                                        style=" width: 75%; border-radius: 6px;" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForDiscMto" class="button-style"
                                (click)="buttonClickForSearchMto('disc')">Apply</button>
                            <button *ngIf="cleatBtnDiscMto" class="clear-btn-style"
                                (click)="clearDiscInputMto()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;"
                    *ngIf="offerTypeArray.length >= 1">
                    <div class="header-style">
                        <h3 class="h3-style">Offer</h3>
                    </div>
                    <div *ngFor="let p of offerTypeArray"
                        style="font-size: 13px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onChangeCheckBoxClickMto($event, p)"> {{p}} </mat-checkbox>
                    </div>
                </div>

                <div class="border-style" style="width: 220px; margin-left: 10px; margin-top: 10px;
            border-bottom: 1px solid; border-width: 2px; border-color: #e91e63;" *ngIf="foodTypes.length >= 1">
                    <div class=" header-style">
                        <h3 class="h3-style">Food Type</h3>
                    </div>
                    <div *ngFor="let f of foodTypes;" style="font-size: 13px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onCheckBoxClickMto($event.checked, f)" [checked]="checkedStatusMto">
                            {{f}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="container product_list_div" *ngIf="show">
        <div class="row">
            <div class="col-md-12">
                <div class="container div-style">
                    <app-card-loader [loop]="3" [height]="800" *ngIf="loadingMto"></app-card-loader>
                    <div class="row" *ngIf="!loadingMto">
                        <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6" style="padding: 0;"
                            *ngFor="let product of productListMto | filterByBrand: selectedBrand;let i = index"
                            (click)="setRowMto(i)">
                            <div class="product_detaiils">
                                <div class="item">
                                    <div class="mb-r">
                                        <div class="card card-cascade wider">
                                            <a>
                                                <div class="view overlay hm-white-slight">
                                                    <img src="../../../../assets/img/{{ product.product_Picture }}"
                                                        class="img-fluid" alt="Image not found"
                                                        style="min-height: 132px;"
                                                        onerror="this.src='../../../../assets/img/defaultCake.webp';"
                                                        [routerLink]="['/make-product', product.product_Code]" />
                                                    <img *ngIf="product.food_Type == 'Veg'"
                                                        class="img-responsive img_align"
                                                        src="../../../../assets/img/Veg.webp" alt="" />
                                                    <img *ngIf="product.food_Type == 'Non-Veg'"
                                                        class="img-responsive img_align"
                                                        src="../../../../assets/img/Nonveg.webp" alt="" />
                                                    <mat-icon [matMenuTriggerFor]="menu"
                                                        id="icon_align">share</mat-icon>
                                                    <mat-menu #menu="matMenu">
                                                        <share-buttons theme="circles-dark"
                                                            [include]="['copy','whatsapp','facebook','email','telegram']"
                                                            [showIcon]="true" [showText]="false"
                                                            [url]="sharingUrlMto+product.product_Code"
                                                            [description]="product.product_Name" class="pt-5">
                                                        </share-buttons>
                                                    </mat-menu>
                                                </div>
                                            </a>

                                            <div class="card-body text-center no-padding">
                                                <p class="card-title product_name">
                                                    <strong style="height: 80%;">
                                                        <a [routerLink]="['/make-product', product.product_Code]"
                                                            routerLinkActive="router-link-active">
                                                            {{product.product_Name }}</a>
                                                    </strong>
                                                    <strong class="price_style">
                                                        <span>₹ {{ product.net_Sale_Price | number: "1.2-2"
                                                            }}</span>
                                                    </strong>
                                                </p>

                                                <div class="card-footer">
                                                    <span>{{product.portion_Weight }}</span>
                                                    <span class="left">
                                                        <a placement="top" mdbTooltip="Add to Wishlist" container="body"
                                                            (click)="addFavouriteMto(product)">
                                                            <i class="fa fa-heart"
                                                                style="font-size: 17px; color: #e91e63"></i>
                                                        </a>
                                                    </span>

                                                    <span class="right" style="margin-right: -25px">
                                                        <a placement="top" mdbTooltip="Add to Cart" container="body"
                                                            [routerLink]="['/make-product', product.product_Code]">
                                                            <i class="fa fa-shopping-cart"
                                                                style="margin-left: 6px;color: #e91e63;font-size: 19px;"></i>
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div [ngClass]="openfilterMto ? 'open_filter' : 'close_filter'">
    <div class="open-filter mobile-view">
        <div class="display-container">
            <div class="display_holder" (click)="openfilterMto = !openfilterMto"
                (touchstart)="openfilterMto = !openfilterMto">
                <span class="display_detail">Filter
                </span>
                <mat-icon *ngIf="!openfilterMto" id="expand_more_icon">expand_less
                </mat-icon>
                <mat-icon *ngIf="openfilterMto" id="expand_less_icon">expand_more
                </mat-icon>
            </div>
        </div>
        <form [formGroup]="searchFormMto">
            <div *ngIf="openfilterMto"
                style="display: flex; flex-direction: column; background: white;overflow: auto; height: 262px; overscroll-behavior: contain;">
                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;">
                    <div class="header-style">
                        <h3 class="h3-style">Price</h3>
                    </div>

                    <div style="display: flex; flex-direction: row; margin-left: 2rem;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Min"
                                        (keyup)="validateMinMaxMto($any($event.target).value, 'min')"
                                        formControlName="minValue" autocomplete="off"
                                        (focus)="$any($event.target).select()" style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-left: -12px;margin-right: 2px;margin-top: -4px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="₹ Max"
                                        (keyup)="validateMinMaxMto($any($event.target).value, 'max')"
                                        formControlName="maxValue" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateMto('price', $any($event.target).value)"
                                        style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForPriceMto" class="button-style"
                                (click)="buttonClickForSearchMto('price')">Apply</button>
                            <button *ngIf="cleatBtnPriceMto" class="clear-btn-style"
                                (click)="clearPriceInputMto()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;">
                    <div class="header-style">
                        <h3 class="h3-style">Discount</h3>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-left: 2rem;">
                        <div class="main-div">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Min"
                                        (keyup)="validateNumberMto($any($event.target).value, 'template', 'min')"
                                        formControlName="minDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()" style="width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <h1 style="margin-left: -12px;margin-right: 2px;margin-top: -4px;color: #333;">_</h1>
                        <div class="main-div" style="margin-left: 6px;">
                            <div class="sub-div">
                                <div class="content-div">
                                    <input type="number" placeholder="% Max"
                                        (keyup)="validateNumberMto($any($event.target).value, 'template', 'max')"
                                        formControlName="maxDisc" autocomplete="off"
                                        (focus)="$any($event.target).select()"
                                        (focusout)="validateMto('disc', $any($event.target).value)"
                                        style=" width: 75%; border-radius: 6px;">
                                </div>
                            </div>
                        </div>
                        <div>
                            <button *ngIf="searchBtnForDiscMto" class="button-style"
                                (click)="buttonClickForSearchMto('disc')">Apply</button>
                            <button *ngIf="cleatBtnDiscMto" class="clear-btn-style"
                                (click)="clearDiscInputMto()">Clear</button>
                        </div>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px; margin-right: 8px;"
                    *ngIf="offerTypeArray.length >= 1">
                    <div class="header-style">
                        <h3 class="h3-style">Offer</h3>
                    </div>
                    <div *ngFor="let p of offerTypeArray"
                        style="font-size: 12px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onChangeCheckBoxClickMto($event, p)"> {{p}} </mat-checkbox>
                    </div>
                </div>

                <div class="border-style" style="width: auto; margin-left: 10px; margin-top: 10px;
             border-bottom: 1px solid; border-width: 2px; border-color: #e91e63; margin-right: 8px;"
                    *ngIf="foodTypes.length >= 1">
                    <div class="header-style">
                        <h3 class="h3-style">Food Type</h3>
                    </div>
                    <div *ngFor="let f of foodTypes" style="font-size: 12px;display: flex;align-items: flex-start;">
                        <mat-checkbox (change)="onCheckBoxClickMto($event.checked, f)" [checked]="checkedStatusMto">
                            {{f}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Franchise -->
<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Franchise Enquiry</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="row" id="contactRow">
    <div class="text-center" style="display: flex;flex-direction: column;align-items: center;">
        <h4 class="h4_style">
            Welcome to 1 Bake Shop, your premier destination for bakery franchise opportunities! Our franchise program
            offers a
            proven track record of
            success, comprehensive training, and dedicated support to help you thrive in the industry.
            <br> Discover the benefits of partnering with us and learn how you can become a part of our growing
            franchise
            family.
            <br> Explore our franchise opportunities today and take the first step towards your entrepreneurial journey!
        </h4>

        <ul class="contact-info">
            <li style="font-family: myFirstFont; font-size: 30px; color: #e91e63">
                1 Bake Shop
            </li>
            <br />
            <li class="li_clr">Bharti Restaurants Pvt Ltd.</li>
            <br />
            <li class="li_clr">
                <i class="fa fa-phone info_clr"></i>7978875088 &nbsp;&nbsp;
                <i class="fa fa-envelope info_clr"></i><a
                    href="mailto:associate@1bakeshop.com">associate&#64;1bakeshop.com</a>
            </li>
        </ul>
    </div>
</div>

<section id="contact">
    <div class="container franchise_bg_img">
        <div class="row" id="contactRow">
            <div class="form-style" id="contact_form" class="col-md-5 col-lg-7 col-xs-12">
                <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
                    <div class="form-group" style="text-align: center">

                        <mat-card id="name" class="form-control input-field"
                            style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>Name</mat-label>
                                <input type="text" matInput formControlName="name" required />
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Gender" formControlName="gender">
                                    <mat-option *ngFor="let genderNames of genders" [value]="genderNames">
                                        {{genderNames}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="padding: 0px;">
                            <mat-form-field>
                                <input readonly matInput [matDatepicker]="ricker" placeholder="DOB"
                                    style="cursor: pointer;" formControlName="dob" [max]="currentDate"
                                    (click)="ricker.open()">
                                <mat-datepicker-toggle matSuffix [for]="ricker"></mat-datepicker-toggle>
                                <mat-datepicker #ricker disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Maritial Status" formControlName="maritialStatus">
                                    <mat-option *ngFor="let statusValues of maritialStatus" [value]="statusValues">
                                        {{statusValues}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>WhatsApp No</mat-label>
                                <input type="number" matInput formControlName="phone" pattern="[6-9]{1}[0-9]{9}"
                                    (keyup)="checkOtp($any($event.target).value)" required />
                                <span *ngIf="!isDisabledSubmit" class="clearBtn" (click)="onVerifyClick()">Verify</span>
                                <span *ngIf="isDisabledSubmit" class="clearBtn1">Verified</span>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field>
                                <mat-label>Email</mat-label>
                                <input type="Email" matInput formControlName="email" />
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Education" formControlName="education">
                                    <mat-option *ngFor="let courses of courseList" [value]="courses">
                                        {{courses}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Occupation" formControlName="occupation">
                                    <mat-option *ngFor="let occupation of occupationList" [value]="occupation">
                                        {{occupation}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select placeholder="Franchise Type" formControlName="franchiseType">
                                    <mat-option [value]="null"> None </mat-option>
                                    <mat-option *ngFor="let type of franchiseTypes" [value]="type">
                                        {{type}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 0px">
                            <mat-form-field class="selectOption">
                                <mat-select id="franchiseType" placeholder="City" formControlName="cityApplied">
                                    <mat-option *ngFor="let city of cities" [value]="city.cityName">
                                        {{city.cityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card>

                        <mat-card class="form-control input-field" style="width: 100%; margin-right: 6%; padding: 1px">
                            <mat-form-field>
                                <mat-label>Location</mat-label>
                                <input type="text" matInput formControlName="areaApplied" />
                            </mat-form-field>
                        </mat-card>

                        <a class="btn" id="submit_btn">
                            <div class="btn-line"></div>
                            <button type="submit" class="btn-line btn-line-shift"
                                style="background-color: #d0324b; color: white">
                                Submit
                            </button> Send Message
                        </a>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="franchise_parent_div" id="page-down">
        <div class="frachise_list" *ngIf="isShowMasterFranchise">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Cloud Bakery</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <mat-card class="contain_card" fxFlex="100">
                        <mat-form-field class="selectOption">
                            <mat-select placeholder="Select State"
                                (selectionChange)="onStateSelect($event.value,'Cloud Bakery')">
                                <mat-option *ngFor="let c of stateList[0]" [value]="c.refNo">
                                    {{c.stateName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of masterFranchiseList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="h6_custom wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="frachise_list" *ngIf="isShowFranchiseStore">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Franchise Store</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select State"
                                        (selectionChange)="onStateSelect($event.value,'Franchise Store')">
                                        <mat-option *ngFor="let c of stateList[1]" [value]="c.refNo">
                                            {{c.stateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select District" [formControl]="districtControl"
                                        (selectionChange)="onDistrictSelect($event.value,'Franchise Store',1)">
                                        <mat-option *ngIf="districtList[1]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let district of districtList[1]" [value]="district.id">
                                            {{ district.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 34%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select City" [formControl]="cityControl"
                                        (selectionChange)="onCitySelect($event.value,'Franchise Store')">
                                        <mat-option *ngIf="cityList[1]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let city of cityList[1]" [value]="city.refNo">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of franchiseStoreList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="h6_custom  wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="frachise_list" *ngIf="isShowKiosk">
            <div class="franchise_deatils">
                <div class="franchise_description">
                    <span class="frachise_name">Kiosk</span>
                </div>

                <div style="display: flex;flex-wrap: wrap;">
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select State"
                                        (selectionChange)="onStateSelect($event.value,'Kiosk')">
                                        <mat-option *ngFor="let c of stateList[2]" [value]="c.refNo">
                                            {{c.stateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 33%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select District" [formControl]="districtControl2"
                                        (selectionChange)="onDistrictSelect($event.value,'Kiosk',2)">
                                        <mat-option *ngIf="districtList[2]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let district of districtList[2]" [value]="district.id">
                                            {{ district.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                    <div style="width: 34%;">
                        <div style="padding: 3px">
                            <mat-card class="contain_card" fxFlex="100">
                                <mat-form-field class="selectOption">
                                    <mat-select placeholder="Select City" [formControl]="cityControl2"
                                        (selectionChange)="onCitySelect($event.value,'Kiosk')">
                                        <mat-option *ngIf="cityList[2]?.length > 0" value=0> All</mat-option>
                                        <mat-option *ngFor="let city of cityList[2]" [value]="city.refNo">
                                            {{ city.cityName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </mat-card>
                        </div>
                    </div>
                </div>

                <div class="city_deatils">
                    <div class="city_card col-lg-3 col-md-6 col-sm-6 col-6"
                        *ngFor="let master of kioskList; let i = index" (click)="onCityClick(master)">
                        <div style="padding: 3px">
                            <div class="city_card_details">
                                <h6 class="h6_custom wrapword">
                                    {{master.cityName}}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="page-scroll">
        <a (click)="scrollToElementById('page-down')" class="back-to-top1">
            <i class="fa fa-angle-down"></i></a>
    </div>
    <p style="color: white;text-align: center;"><span>{{visitors}}</span> </p>
</section>

<!-- Store -->
<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Stores</h2>
        <div class="hr"></div>
    </div>
</div>

<section id="contact">
    <div class="container store_bg_img">
        <div class="row" id="contactRow">
            <div class="text-center">
                <h4
                    style="font-size: 16px !important;text-align: center; margin-top: 20px;letter-spacing: 0px !important;line-height: 24px !important">
                    Find your nearest 1 Bake Shop store and experience the best bakery products! Our store network spans
                    across Odisha.<br> Whether you're looking for cakes, bakery products or expert advice, our friendly
                    staff are always ready to help.<br> Use our store locator to find your local store's address.<br>
                    Visit us
                    today!
                </h4>

                <ul class="contact-info">
                    <li style="font-family: myFirstFont;font-size: 30px;color: #e91e63;">1 Bake Shop</li><br>
                    <li>Bharti Restaurants Pvt Ltd.</li><br>
                    <li><i class="fa fa-phone" style="color: #d0324b;"></i>+91 (674) 258 0000 &nbsp;&nbsp; <i
                            class="fa fa-envelope" style="color: #d0324b;"></i><br><a
                            href="mailto:info@1bakeshop.com">info&#64;1bakeshop.com</a>
                    </li>
                </ul>
            </div>

            <div class="row" id="contactRow">
                <div class="col-md-5 col-lg-5 col-xs-12">
                    <div class="form-style" id="contact_form">
                        <form>
                            <div class="form-group">
                                <select class="form-control input-field"
                                    (change)="onStateSelectStore($any($event.target).value)">
                                    <option value="" disabled selected hidden>State</option>
                                    <option *ngFor="let state of stateListStore" value="{{ state.refNo }}">
                                        {{ state.stateName }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onDistrictSelectStore($any($event.target).value)">
                                    <option value="" disabled selected hidden>District</option>
                                    <option *ngFor="let district of districtListStore" value="{{ district.id }}">
                                        {{ district.name }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onCitySelectStore($any($event.target).value)">
                                    <option value="" disabled selected hidden>City</option>
                                    <option *ngFor="let city of cityListStore" value="{{ city.id }}">
                                        {{ city.name }}
                                    </option>
                                </select>
                                <select class="form-control input-field"
                                    (change)="onLocationSelectStore($any($event.target).value)">
                                    <option value="" disabled selected hidden>Location</option>
                                    <option *ngFor="let loc of locListStore" value="{{ loc.id }}">
                                        {{ loc.name }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-7 col-xs-7">
                </div>
            </div>
        </div>
    </div>
</section>

<div style="margin-top: -5%">
    <div id="div_style" class="col-md-5 col-xs-12">
        <div style="background-color: antiquewhite" *ngFor="let locDetails of locationDetailsList; let i = index"
            (click)="onPositionClick(locDetails.lat, locDetails.lng)">
            <div class="col-md-6 col-xs-12" style="padding: 3px">
                <div
                    style="background-color: rgb(226, 233, 240);height: 85px;vertical-align: middle !important;padding-top: 4px;">
                    <h6 class="h6_store" style="color: #121111;">
                        {{ locDetails?.locationName }}
                    </h6>
                    <h6 class="h6_store" style="color: #403e3e;">
                        {{ locDetails?.cityName }}
                    </h6>
                    <h6 class="h6_store" style="color: #625e5e;">
                        {{ locDetails?.address }}
                    </h6>
                    <h6 class="h6_store" style="color: #625e5e;;">
                        {{ locDetails.pin }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-7 col-xs-12" style="height: 500px; background-color: beige;margin-bottom: 50px;">
        <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom" (mapReady)="mapReady($event)">
            <agm-marker *ngFor="let marker of gpsLati;let i = index" [latitude]="marker" [longitude]="gpsLong[i]"
                [markerDraggable]="newDeliveryDetails" (dragEnd)="markerDragEnd($any($event))">
            </agm-marker>
            <button type="button" [disabled]="!isShowOpenGoogle" id="Logout"
                class="toggle-button controls button open_map" (click)="onSearch()">Open with google</button>
        </agm-map>
    </div>
</div>

<!-- Career -->
<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Current Openings</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="row" id="contactRow">
    <div class="text-center" style="display: flex;flex-direction: column;align-items: center;">
        <h4
            style="font-size: 16px !important;text-align: center;letter-spacing: 0px !important;line-height: 24px !important;margin-top: -20px;">
            Join our dynamic team and kickstart your career with 1 Bake Shop! We're a forward-thinking company dedicated
            to
            innovation and excellence.
            <br> Whether you're a seasoned professional or just starting out, we're committed to helping you grow and
            develop
            your
            skills.
            <br> Browse our current job openings and take your career to the next level.<br> Apply now and become a part
            of our
            talented team!
        </h4>
    </div>
</div>

<div *ngIf="showCareer">
    <table mat-table [dataSource]="dataSourceCareer" class="mat-elevation-z8">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>POSITION</th>
            <td mat-cell *matCellDef="let element" style="width: 25%">
                {{ element.position }}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
            <td mat-cell [innerHTML]="element.description" *matCellDef="let element" style="width: 45%">
            </td>
        </ng-container>

        <ng-container matColumnDef="place">
            <th mat-header-cell *matHeaderCellDef>PLACE</th>
            <td mat-cell *matCellDef="let element" style="text-align: center !important; width: 15%">
                {{ element.place }}
            </td>
        </ng-container>

        <ng-container matColumnDef="Submit">
            <th mat-header-cell *matHeaderCellDef target="#contact_form">APPLY</th>
            <td mat-cell *matCellDef="let element; let i = index" style="text-align: center !important; width: 15%">
                <a (click)="applayCareer(element, i)" style="color: crimson">Apply</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<h4 style="text-align: center; margin-top: 20px">
    Work with us
</h4>

<section id="contact">
    <div class="container career_bg_img">
        <div class="row" id="contactRow">
            <div class="col-lg-7 col-md-7 text-center">
                <div class="form-style" id="contact_form">
                    <form [formGroup]="createCareerSetUpForm" (ngSubmit)="onCreateCareerSetUpClick()">
                        <div class="form-group">

                            <div class="input-wrap">
                                <input type="text" formControlName="name" required />
                                <label>Name</label>
                            </div>

                            <div class="input-wrap">
                                <input type="email" formControlName="email" required />
                                <label>Email</label>
                            </div>

                            <div class="input-wrap">
                                <input type="number" formControlName="phone" pattern="[6-9][0-9]{9}" required />
                                <label>Mobile No</label>
                            </div>

                            <div class="input-wrap">
                                <input type="text" formControlName="position" required />
                                <label>Position</label>
                            </div>

                            <div class="input-wrap">
                                <input type="text" formControlName="place" required />
                                <label>Place</label>
                            </div>

                            <input hidden type="file" #fileUploader
                                (change)="handleFileInputCareer($any($event.target).files)" accept="application/pdf"
                                required />

                            <div class="input-wrap">
                                <input formControlName="documentPath" (click)="fileUploader.click()"
                                    value="{{ uploadedFileNameCareer }}" required />
                                <label>Upload CV</label>
                            </div>

                            <a class="btn" id="submit_btn">
                                <div class="btn-line"></div>
                                <button type="submit" class="btn-line btn-line-shift"
                                    style="background-color: #d0324b; color: white">
                                    SUBMIT
                                </button> Send Message
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Contact-Us -->
<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Contact Us</h2>
        <div class="hr"></div>
    </div>
</div>

<section id="contact">
    <div class="row" id="contactRow">
        <div class="text-center">
            <h4 class="h4_style" style="margin-top: -4%"> Get in touch with us at 1 Bake Shop! We'd love to hear from
                you.<br> Whether
                you have a question, comment, or need assistance with one of our products or
                services, our team is here to help.<br> Use our contact form, send us an
                email, or give us a call – we're committed to responding promptly and providing the
                support you need.<br> At 1 Bake Shop, we value your feedback and look
                forward to connecting with you.<br> Reach out today and let's start the conversation!</h4>

            <ul class="contact-info">
                <li style="font-family: myFirstFont; font-size: 30px; color: #e91e63">
                    1 Bake Shop
                </li>
                <br />
                <li>Bharti Restaurants Pvt Ltd.</li>
                <br />
                <li>
                    <i class="fa fa-phone"></i>+91 674 2580000 &nbsp;&nbsp;
                    <i class="fa fa-envelope"></i><a href="mailto:info@1bakeshop.com">info&#64;1bakeshop.com</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="container  contactus_bg_img">
        <div class="row" id="contactRow">
            <div class="col-md-5 col-lg-7 col-xs-12">
                <div class="form-style" id="contact_form">
                    <form [formGroup]="createSetUpFormContactUs" (ngSubmit)="onCreateSetUpClickContactUs()">
                        <div class="form-group" style="text-align: center">
                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Feedback Type" formControlName="grivenceTypeCode"
                                            (selectionChange)="onFeedbackTypeSelect($event.value)">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let type of feedbackTypeList" [value]="type.grivenceTypeCode">{{
                                                type.grivenceTypeName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Feedback Sub Type"
                                            formControlName="subGrivenceTypeCode"
                                            (selectionChange)="onFeedbackSubTypeSelect($event.value)">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let type of feedbackSubList"
                                                [value]="type.subGrivenceTypeCode">{{ type.subGrivenceTypeName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                                        <mat-select placeholder="Feedback Name" formControlName="grivenceCode">
                                            <mat-option style="text-align: left; padding-bottom: 2%"
                                                *ngFor="let type of feedbackList" [value]="type.grivenceCode">{{
                                                type.grivenceName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Name" formControlName="name" required />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Email ID" formControlName="email" required />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput type="number" placeholder="WhatsApp No" formControlName="phone"
                                            pattern="[6-9]{1}[0-9]{9}"
                                            (keyup)="checkOtpContactUs($any($event.target).value)" required />
                                        <span class="verification-status"
                                            [ngClass]="{'verified': isDisabledSubmitContactUs, 'not-verified': !isDisabledSubmitContactUs}">
                                            {{ isDisabledSubmitContactUs ? 'Verified' : 'Verify' }}
                                        </span>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Subject" formControlName="subject" required />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                                    <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                                        <input matInput placeholder="Enter your message" formControlName="message"
                                            required />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                                <mat-card class="mat_card_with_file" fxFlex="100">
                                    <span class="span_with_file">
                                        <img [src]="getFileDataContactUs('fileImage1')" alt="Picture"
                                            *ngIf="getFileDataContactUs('fileImage1')" class="image_size" />

                                        <mat-icon *ngIf="!getFileDataContactUs('fileImage1')" class="file_icon_size">
                                            image </mat-icon>
                                    </span>
                                    <input type="file" readonly
                                        (change)="onChangeUploadFileContactUs($event,'fileImage1','image1')"
                                        accept="image/*" (click)="$any($event.target).value=null" fxFlex="85"
                                        class="input_file_size" id="image1">
                                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileDataContactUs('fileImage1')"
                                        (click)="onRemoveFileClickContactUs('fileImage1', 'image1');">
                                        clear
                                    </mat-icon>
                                </mat-card>

                                <mat-card class="mat_card_with_file" fxFlex="100">
                                    <span class="span_with_file">
                                        <video [src]="getFileDataContactUs('fileVideo1')"
                                            *ngIf="getFileDataContactUs('fileVideo1')" class="video_size" controls
                                            controlsList="nodownload">
                                            <track label="English" kind="subtitles" srclang="en" default />
                                        </video>

                                        <mat-icon *ngIf="!getFileDataContactUs('fileVideo1')" class="file_icon_size">
                                            play_arrow </mat-icon>
                                    </span>
                                    <input type="file" readonly
                                        (change)="onChangeUploadFileContactUs($event,'fileVideo1', 'video1')"
                                        accept="video/*" (click)="$any($event.target).value=null" fxFlex="85"
                                        class="input_file_size" id="video1">
                                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileDataContactUs('fileVideo1')"
                                        (click)="onRemoveFileClickContactUs('fileVideo1', 'video1')">
                                        clear
                                    </mat-icon>
                                </mat-card>
                            </div>

                            <a class="btn" id="submit_btn">
                                <div class="btn-line"></div>
                                <button type="submit" class="btn-line btn-line-shift"
                                    [disabled]="!isDisabledSubmitContactUs"
                                    style="background-color: #d0324b; color: white">
                                    Send Message
                                </button>
                                Send Message
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>