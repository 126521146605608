import { DeliveryAddress } from "./deliveryaddress";
import { MakeProductDto } from "./makeproductdto";
import { Product } from "./product";
import { ViewOfferSetUpDto } from "./viewoffersetupdto";

export class MakeToOrderSummary {
  product: Product;
  // offer:ViewOfferSetUpDto[];
  pincode: number;
  deliveryAddress: DeliveryAddress;
  qty: number;
  composition: string;
  color: string;
  shape: string;
  dimension: string;
  deliveryDate: string;
  note: string;
  customerName: string;
  customerMobile: string;
  rate: number;
  subTotal: number;
  discountAmt: number;
  taxableAmt: number;
  taxrate: number;
  taxAmt: number;
  cessRate: number;
  cessAmt: number;
  netAmount: number;
  makeProductDto: MakeProductDto;
}
