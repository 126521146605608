<div class="section-heading" style="display: flex;justify-content: center;">
    <h5>Coupon Friendly Stores</h5>
    <i mat-dialog-close class="fa fa-close close_icon"></i>
</div>

<div class="location-container">
    <div *ngFor="let location of data" class="location-card">
        <mat-card>
            <div class="location-header">
                <mat-card-title>{{ location.locationName }} | {{ location.cityName }}</mat-card-title>
            </div>
            <mat-card-content>
                <p class="card_p">{{ location.address }}</p>
            </mat-card-content>
            <!-- <div class="location-header location-footer">
                <mat-card-title>{{ location.cityName }}</mat-card-title>
            </div> -->
        </mat-card>
    </div>
</div>

<div class="show_more">
    <a href="/store">
        Show more <mat-icon class="arrow_forward">arrow_forward</mat-icon>
    </a>
</div>