<form [formGroup]="createGSTINSetUpForm" (ngSubmit)="onGSTINSetUpClick()">
  <div class="section-heading">
    <h5>BUSINESS INVOICE</h5>
  </div>
  <div style="width: 100%;text-align: center;">
    <span style="color: #e91e63;">Do you need a Business Invoice?</span>
  </div>
  <div style="text-align: center; margin-top: 2%">
    <mat-radio-group aria-label="Select an option" formControlName="gstinType">
      YES &nbsp;&nbsp;&nbsp;&nbsp;<mat-radio-button value="YES" (click)="chooseGSTIN('YES')">
      </mat-radio-button>
      <span style="margin-left: 10px">NO &nbsp;&nbsp;&nbsp;&nbsp;</span>
      <mat-radio-button value="NO" [checked]="true" (click)="chooseGSTIN('NO')">
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="width: 80%;margin: 0 auto;" *ngIf="showGSTIN">
    <mat-card class="contain_card" fxFlex="33">
      <mat-form-field class="example-full-width">
        <input required matInput placeholder="GSTIN Number" formControlName="gstin" />
      </mat-form-field>
    </mat-card>
  </div>
  <div style="text-align: center;" fxLayout="row" fxLayout.lt-sm="column">
    <button type="submit" style="margin-top: 10px; margin-bottom: 10px" id="compose-button" id="send-button"
      class="btn btn-primary">
      SUBMIT
    </button>
  </div>
</form>