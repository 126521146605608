<div class="container">
    <h2 class="h2_font_size" style="margin-top: 2%">Order Summary<br /></h2>
    <div class="hr" style="margin-bottom: 20px;"></div>

    <div *ngIf="groupProduct.length > 0" class="store-address">
        <span *ngIf="deliveryType == 'storePickup' && storeLocation">
            <img [src]="'assets/img/pickup_pink.png'" class="delivery-icon" />
            <div class="store-details">
                <h3>Pick Up Location: {{storeLocation.locationName}}</h3>
                <p>{{storeLocation.address}}</p>
            </div>
        </span>

        <span *ngIf="deliveryType == 'delivery' && customerDeliveryAddress">
            <img [src]="'assets/img/delivery_pink.svg'" class="delivery-icon" />
            <div class="store-details">
                <h3>Delivery At:
                    {{customerDeliveryAddress.shippingPlotNo}},{{customerDeliveryAddress.shippingAddress}}
                </h3>
                <p>{{customerDeliveryAddress.cityName}},{{customerDeliveryAddress.stateName}},{{customerDeliveryAddress.shippingPinCode}}
                </p>
            </div>
        </span>

    </div>


    <div *ngFor="let cart of groupProduct; let i = index" class="col-md-12 col-12" style="margin-top: 16px">
        <div class="section-header" style="background-color: var(--navBackground); color: white">
            <h1 class="h1_font_size">
                Delivery - {{ i + 1 }} on {{ cart.dateTime.substr(8, 2) + "-" + cart.dateTime.substr(5, 2) + "-" +
                cart.dateTime.substr(0, 4) }} Time Slot: {{ cart.dateTime.substr(10, 19) }}
            </h1>
        </div>

        <div class="invisible-scrollbar row odd-div"
            style="height: auto;padding-top: 1%;display: flex;border: 1px solid #ddd;">
            <div class="col-md-8 col-12">
                <div *ngFor="let product of cart.games">
                    <div class="col-md-12 col-12" *ngIf="!product.designForProductCode">
                        <div class="col-md-4 col-12" style="text-align: center">
                            <img *ngIf="!product.designProductPicture && product.subMtoType != 'Custom Cakes'"
                                style="border-radius: 4px;" src="../../../../assets/img/{{ product.product_Picture }}"
                                width="120px" height="100px"
                                onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                            <img *ngIf="!product.designProductPicture && product.subMtoType == 'Custom Cakes'"
                                style="border-radius: 4px;" [src]="product.sanitizedImageUrl" width="120px"
                                height="100px" onerror="this.src='../../../../assets/img/defaultCake.webp';" />

                            <img *ngIf="product.designProductPicture && product.subMtoType != 'Custom Cakes'"
                                style="border-radius: 4px;"
                                src="../../../../assets/img/{{ product.designProductPicture }}" width="120px"
                                height="100px" onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                            <span>
                                <img class="img-responsive img_align" *ngIf="product.food_Type == 'Veg'"
                                    src="../../../../assets/img/Veg.webp" alt="" />
                                <img class="img-responsive img_align" *ngIf="product.food_Type == 'Non-Veg'"
                                    src="../../../../assets/img/Nonveg.webp" alt="" />
                            </span>
                            <span *ngIf="product.offers >= 1"> <img class="img-responsive offer_align"
                                    src="../../../../assets/img/Offer 1.webp" alt="" />
                            </span>
                            <p style="margin-left: 10px;margin-bottom: 0;">{{ product.product_Name }}</p>
                            <p *ngIf="product.cartProductType == 'Make To Order' && (cart.totDiscount > 0 || cart.offerId)"
                                style="margin-left: 10px;margin-bottom: 0;font-size: 10px;color: red;">
                                Checked Out Offer Not Applicable To MTO</p>
                            <p *ngIf="product.designProductName" style="margin-left: 10px;margin-bottom: 0;">
                                Design: {{ product.designProductName}}</p>
                        </div>

                        <div class="col-md-8 col-12 details_font_size">
                            <div class="details_info">
                                <span class="pull-left">Order Qty</span>
                                <span *ngIf="product.cartProductType == 'Product' || product.net_Sale_Price <= 0"
                                    class="pull-right">{{ product.orderQuantity }} {{ product.product_Unit}}</span>
                                <span *ngIf="product.cartProductType == 'Make To Order' && !product.freeByProductCode"
                                    class="pull-right">{{
                                    product.orderQuantityKgs }} {{ product.product_Unit}}</span>
                                <span *ngIf="product.cartProductType == 'Make To Order' && product.freeByProductCode"
                                    class="pull-right">{{
                                    product.orderQuantity }} {{ product.product_Unit}}</span>
                            </div>
                            <!-- <p *ngIf="product.cartProductType == 'Product' || product.net_Sale_Price <= 0"
                                style="margin-bottom: 5px">
                                Order Qty: {{ product.orderQuantity }} {{ product.product_Unit}}
                            </p>
                            <p *ngIf="product.cartProductType == 'Make To Order'" style="margin-bottom: 5px">
                                Order Qty: {{ product.orderQuantityKgs }} {{ product.product_Unit}}
                            </p> -->

                            <div *ngIf="product.taxAmount > 0" class="details_info">
                                <span class="pull-left">GST</span>
                                <span class="pull-right">{{ (product.taxAmount +
                                    (product.designTaxAmount ? product.designTaxAmount : 0)) |
                                    number: "1.2-2" }}</span>
                            </div>

                            <!-- <p style="margin-bottom: 5px" *ngIf="product.taxAmount > 0">
                                GST: {{ (product.taxAmount + (product.designTaxAmount ? product.designTaxAmount : 0)) |
                                number: "1.2-2" }}
                            </p> -->

                            <div *ngIf="product.net_Sale_Price > 0" class="details_info">
                                <span class="pull-left">Price</span>
                                <span class="pull-right">
                                    {{ (product.net_Sale_Price + (product.designProductNetSalePrice ?
                                    product.designProductNetSalePrice : 0)) | number: "1.2-2"}}</span>
                            </div>

                            <div *ngIf="product.freeByProductCode" class="details_info">
                                <span class="pull-left">Price</span>
                                <span class="pull-right">
                                    <span style="text-decoration: line-through 2px red;color: #333333;">
                                        {{ (product.freeProductNetSalePrice || 0) | number: '1.2-2' }}&nbsp;
                                    </span>
                                    <span *ngIf="product.freeByProductCode" style="float: right; font-weight: bold;">
                                        <strong *ngIf="product.net_Sale_Price == 0">FREE</strong>
                                        <span
                                            *ngIf="product.net_Sale_Price > 0 && product.cartProductType == 'Product'">
                                            {{ product.orderQuantity * product.net_Sale_Price | number: "1.2-2" }}
                                        </span>
                                        <span
                                            *ngIf="product.orderQuantityKgs > 0 && product.cartProductType == 'Make To Order'">
                                            {{ product.orderQuantityKgs * product.net_Sale_Price | number: "1.2-2" }}
                                        </span>
                                    </span>
                                </span>
                            </div>

                            <!-- <p style="margin-bottom: 5px" *ngIf="product.net_Sale_Price > 0">
                                Price: {{ (product.net_Sale_Price + (product.designProductNetSalePrice ?
                                product.designProductNetSalePrice : 0)) | number: "1.2-2"
                                }}
                            </p> -->

                            <!-- <div class="details_info" style="display: flex;justify-content: center;font-weight: bold;">
                                <span class="pull-left">Delivery Schedule</span>
                            </div> -->


                            <div class="selected-shipping_dateTime" role="presentation">
                                <span class="selected-shipping_deliveryDateOfMonth"
                                    data-testid="shipping-date">{{product.deliveryDate | date: 'dd'}}</span>
                                <span class="selected-shipping_deliveryMonth">{{product.deliveryDate | date:
                                    'MMM'}}</span>
                                <div><span class="selected-shipping_deliveryWeekday">{{product.deliveryDate | date:
                                        'EEEE'}}</span>
                                    <span class="selected-shipping_timeSlot"> {{ convertToAmPm(product.deliveryTime)
                                        }}</span>
                                </div>
                                <span
                                    *ngIf="product.net_Sale_Price > 0 && !product.designForProductCode && !product.freeByProductCode"
                                    class="selected-shipping_dropArrow" (click)="openDeliveryTimeDialog(product)">
                                    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24"
                                        aria-hidden="true">
                                        <path
                                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z">
                                        </path>
                                    </svg></span>
                            </div>

                            <form [formGroup]="mesageForm">
                                <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div"
                                    *ngIf="product.isShowProductMessage == true">
                                    <mat-card class="contain_card" fxFlex="47" id="mat-card_style">
                                        <mat-form-field class="example-full-width" style="width: 89%" visivility="none">
                                            <input matInput #input placeholder="Message/Note On Cake" maxlength="40"
                                                (keyup)="messageInput($any($event.target).value, product)"
                                                formControlName="note" style="text-align: left; margin-bottom: 2px;" />
                                        </mat-form-field>
                                        <mat-hint style="font-size: 10px;">{{ input.value?.length || 0 }}/40</mat-hint>
                                    </mat-card>
                                </div>
                            </form>

                            <!-- <div class="delivery_info">
                                <div class="info_row">
                                    <span class="column">Date</span>
                                    <span class="column">Slot</span>
                                    <span class="column">Change</span>
                                </div>
                                <div class="info_row">
                                    <span class="column">{{product.deliveryDate | date: 'yyyy-MM-dd'}}</span>
                                    <span class="column">{{product.deliveryTime}}</span>
                                    <span class="column">
                                        <mat-icon style="color: black; font-size: 16px; cursor: pointer;"
                                                  matTooltip="Delivery Date & Time" 
                                                  (click)="openDeliveryTimeDialog(product)">edit
                                        </mat-icon>
                                    </span>
                                </div>
                            </div> -->




                            <!-- <p style="margin-bottom: 5px"> Delivery Date & Slot: {{product.deliveryDate | date:
                                'yyyy-MM-dd'}} {{product.deliveryTime}}
                                <mat-icon style="color: black;font-size: 16px;cursor: pointer;"
                                    matTooltip="Delivery Date & Time" (click)="openDeliveryTimeDialog(product)">edit
                                </mat-icon>
                            </p> -->
                        </div>
                        <hr *ngIf="i < cart.games.length-1" class="col-md-12 col-12"
                            style="margin-top: 5px; border-top: 1.5px dotted #000;">
                    </div>

                </div>
            </div>

            <div class="col-md-4 col-12" style="margin-top: 0px">
                <div class="section-header" style="background-color: #666; color: white">
                    <h1 class="h1_summary">
                        Summary
                    </h1>
                </div>
                <div>
                    <div class="price-info">
                        <span class="pull-left">Amount</span>
                        <span class="pull-right">{{cart.totalSaleAmt | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info" *ngIf="cart.totBasePremiumFlavourPrice > 0">
                        <span class="pull-left">Flavour Premium Price</span>
                        <span class="pull-right">{{cart.totBasePremiumFlavourPrice | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info" *ngIf="cart.totDiscount > 0">
                        <span class="pull-left">Discount</span>
                        <span class="pull-left textOverFlow" *ngIf="cart.totDiscount"
                            [matTooltip]="cart.discountApplied">{{cart.discountApplied}}</span>
                        <span class="pull-right">{{cart.totDiscount | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info">
                        <span class="pull-left">Taxble Value</span>
                        <span class="pull-right">{{cart.totTaxValue | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info" *ngIf="cart.gstAmt > 0">
                        <span class="pull-left">GST</span>
                        <span class="pull-right">{{ cart.gstAmt | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info" *ngIf="cart.cessAmt > 0">
                        <span class="pull-left">CESS</span>
                        <span class="pull-right">{{ cart.cessAmt | number: "1.2-2" }}</span>
                    </div>

                    <div class="price-info">
                        <span class="pull-left">Net Amount</span>
                        <span class="pull-right">{{cart.netAmountBeforeRoundedOff | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info" *ngIf="cart.deliveryCharge == 0">
                        <span class="pull-left">Shipping Charges</span>
                        <span class="pull-right">FREE</span>
                    </div>
                    <div class="price-info" *ngIf="cart.deliveryCharge != 0">
                        <span class="pull-left">
                            Shipping Charges</span>
                        <mat-icon style="height:18px;width:18px;font-size:18px;cursor:pointer;"
                            [matTooltip]="getDeliveryChargeTooltip(cart)">
                            info_outline
                        </mat-icon>
                        <span class="pull-right">{{cart.deliveryCharge | number: "1.2-2"}}</span>
                    </div>

                    <div class="price-info">
                        <span class="pull-left">Rounded off Amount</span>
                        <span class="pull-right">{{ cart.roundedOffAmount | number: "1.2-2" }}</span>
                    </div>

                    <span>
                        <a *ngIf="cart.totalNetAmt >= minimumOrdeSetup?.orderMinValue && isShowCheckOut"
                            class="btn2 btn-danger btn-check-out1" (click)="onPayClick(cart)"
                            style="height: 36px;background-color: #13b8aa;display: block;color: #fff;font-family: 'Paytone';">
                            Pay <strong>₹ {{ cart.grandTotal | number: "1.2-2" }}</strong>
                            <br />
                        </a>
                        <a *ngIf="cart.totalNetAmt < minimumOrdeSetup?.orderMinValue && isShowCheckOut"
                            class="btn2 btn-danger btn-check-out1" id="summary_pay_btn">
                            Add <strong>₹ {{ minimumOrdeSetup?.orderMinValue - cart.totalNetAmt | number: '1.2-2'
                                }}</strong> more to checkout

                            <!-- <br />
                            <span style="font-size: 9px; color: white; font-weight: bold">
                                Minimum Order Amount {{ minimumOrdeSetup?.orderMinValue }}</span> -->
                        </a>
                        <a *ngIf="!isShowCheckOut" class="btn2 btn-danger btn-check-out1" id="wait_btn">
                            Please wait working on offer...</a>
                    </span>
                    <br />
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="allCartTotal" class="col-md-12 col-12" style="margin-left: 20px">
        <div class="control">
            <label class="checkbox" [ngClass]="isAnimate ? 'label1' : 'label2'">
                <input type="checkbox" [(ngModel)]="isAgreedTerms" style="height: 20px; width: 15px" />
                I agree to the
                <button type="button" style="border: none; padding: 0; color: blue" (click)="openPrivacyPolicyDialog()">
                    <u>terms and conditions</u>
                </button>
            </label>
        </div>
    </div>

    <div *ngIf="allCartTotal" class="float-right" style="margin-right: 7px">
        <a *ngIf="isAllAllowed && isShowCheckOut" class="btn" id="submit_btn">
            <div class="btn-line"></div>
            <button type="button" (click)="onPayClick(groupProduct)" class="btn-line btn-line-shift"
                style="background-color: #d0324b; color: white">
                PAY<br /><strong>₹ {{ allCartTotal | number: "1.2-2" }}</strong>
            </button> Send Message
        </a>
        <a *ngIf="!isAllAllowed && isShowCheckOut" class="btn" id="submit_btn">
            <div class="btn-line"></div>
            <button type="button" class="btn-line btn-line-shift"
                style="background-color: red; color: white;font-size: 11px;cursor: not-allowed;">
                Maintain minimum balance
                <br />
                <span style="font-size: 9px; color: white; font-weight: bold">Per day / slot</span>
            </button> Send Message
        </a>
        <a *ngIf="!isShowCheckOut" class="btn" id="submit_btn">
            <div class="btn-line"></div>
            <button type="button" class="btn-line btn-line-shift"
                style="background-color: #d0324b; color: white;font-size: 8px;cursor: progress;">
                PLEASE WAIT WORKING ON OFFER...
                <br />
            </button> Send Message
        </a>
    </div>
</div>