import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Color } from "src/app/shared/models/color";
import { MakeProductDto } from "src/app/shared/models/makeproductdto";
import { MakeToOrderDetails } from "src/app/shared/models/maketoorderdetails";
import { MakeToOrderSummary } from "src/app/shared/models/maketoordersummary";
import { Product } from "src/app/shared/models/product";
import { ProductComposition } from "src/app/shared/models/productcomposition";
import { ProductShape } from "src/app/shared/models/productshape";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { ViewWebOrderCouponSetUpDto } from "src/app/shared/models/viewwebordercouponsetupdto";
import { WebOrderCustomizeGroupSetup } from "src/app/shared/models/webordercustomizegroupsetup";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { MakeToOrderConfirmationComponent } from "../make-to-order-confirmation/make-to-order-confirmation.component";
import * as moment from "moment";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { WebUserInfo } from "src/app/shared/models/web-user-info";
import { CommonService } from "src/app/shared/services/CommonService";
import { WebOrderMinimumDateTimeSetup } from "src/app/shared/models/weborderminimumdatetimesetup";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: "app-make-product",
  templateUrl: "./make-product.component.html",
  styleUrls: ["./make-product.component.scss"],
})
export class MakeProductComponent implements OnInit, OnDestroy {
  panelOpenState = false;
  private sub: any;
  product: Product;
  webUrl: string;
  productCode: number;

  couponSetUp: ViewWebOrderCouponSetUpDto;
  makeToOrderDetailsList: MakeToOrderDetails;
  productCompositionList: ProductComposition[] = [];
  productColorList: Color[] = [];
  productShapeList: ProductShape[] = [];
  customizeList: WebOrderCustomizeGroupSetup[] = [];

  createSetUpForm: UntypedFormGroup;
  calculationSetUpForm: UntypedFormGroup;

  makeToorderSummary: MakeToOrderSummary = new MakeToOrderSummary();
  offerList: ViewOfferSetUpDto[] = [];

  showColor: boolean = false;
  showComposition: boolean = false;
  showShape: boolean = false;
  showDimension: boolean = false;

  todayDate: Date = new Date();
  maxDate: string;
  minTimeInHr: string;

  rate1: any;
  taxRate1: any;
  cessRate1: any;
  minimumQty: any;
  makeProduct: MakeProductDto;
  webOrderMinimumDateTimeList: WebOrderMinimumDateTimeSetup = new WebOrderMinimumDateTimeSetup();
  productWithOffers: ProductsWithWebOrderOffers[] = [];
  offerNames: string = '';
  offerObj: any;
  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;

  qty: number;
  discount: number;
  showUpdate: boolean = false;

  companyCode: number;
  timeSlot2: string[][] = [];
  timeSlotAvl: string[] = [];

  pincode: string;
  serverUrl: string;
  currentUrl: string;
  sharingUrl: string = '';

  deliveryType: string;
  googleCustomer: GoogleCustomer;

  //Subscription
  private productSubcription: Subscription;
  private makeToOrderSubcription: Subscription;
  private couponSubcription: Subscription;
  private customizeDetailsSubcription: Subscription;
  private webOrderTimeSlotSubcription: Subscription;
  private saveWebUserInfoSubscription: Subscription;
  private webOrderMinimumDateTimeSubcription: Subscription;
  private offerSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private apiFactory: APIFactoryEndPoints,
    private commonService: CommonService,
    private productService: ProductService,
    private toastrService: ToastService,
    private navRoute: Router,
    private pincodeDialogService: PinDialogService
  ) {
    this.product = new Product();
    this.serverUrl = this.apiFactory.getServerUrl;
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.sub = this.route.params.subscribe((params) => {
      const id = params?.["id"]; // (+) converts string 'id' to a number
      const mto = params?.["mto"];
      this.openStorePickupDialog(id);
    });
    // this.getMakeToOrderDetails();
    // this.onCalculationSetUp();
    this.onCreateSetUp();
    this.getSummaryDetails();
    // this.getWebOrderTimeSlotSetUpList();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      qty: ["", Validators.required],
      composition: [""],
      color: [""],
      shape: [""],
      dimension: [""],
      deliveryDate: ["", Validators.required],
      deliveryTime: ["", Validators.required],
      note: ["", [Validators.maxLength(40)]],
      subTotal: [""],
      productPrice: [""],
      productName: [""],
      minimum_Order_Time: [""],
      basePremiumFlavourPrice: [0],
      premiumFlavourPrice: [0],
      // customerName: [''],
      // customerMobile: [''],
    });
  }

  // onCalculationSetUp() {
  //   this.calculationSetUpForm = this.formBuilder.group({
  //     rate: [''],
  //     taxrate: [''],
  //     cessrate: [''],
  //     subTotal: [''],
  //     discountAmt: [''],
  //     taxableAmt: [''],
  //     taxAmt: [''],
  //     cessAmt: [''],
  //     netAmt: [''],
  //     coupon: [''],
  //   })

  // }

  getSummaryDetails() {
    this.makeToorderSummary = this.pincodeDialogService.getMakeToOrderSummary();
    console.log(this.makeToorderSummary);
  }

  getWebOrderCustomizeDetails() {
    this.createSetUpForm.get('composition').clearValidators();

    this.customizeDetailsSubcription = this.productService.getWebOrderCustomizeList(this.currentUrl).subscribe((data) => {
      this.customizeList = data;
      console.log(this.customizeList);
      this.customizeList.forEach((element) => {
        if (element.customizableParameter == "Product Color" && element.allowCustomize == 1) {
          this.showColor = true;
        }
        if (element.customizableParameter == "Product Composition" && element.allowCustomize == 1) {
          this.showComposition = true;
          this.createSetUpForm.get('composition').setValidators(Validators.required);
        }
        if (element.customizableParameter == "Product Dimension" && element.allowCustomize == 1) {
          this.showDimension = true;
        }
        if (element.customizableParameter == "Product Shape" && element.allowCustomize == 1) {
          this.showShape = true;
        }
      });
      this.getMakeToOrderDetails();
    });
  }

  getMakeToOrderDetails() {
    this.makeToOrderSubcription = this.productService.getMakeToOrderList(this.currentUrl).subscribe((data) => {
      this.makeToOrderDetailsList = data;
      this.productCompositionList = data.productCompositions;
      this.productColorList = data.colors;
      this.productShapeList = data.productShapes;
      console.log(data);
      // this.setSpecialOrderFlavour();
    });
  }

  specialProductCodes: number[] = [856, 859, 897, 896, 835, 1332];
  setSpecialOrderFlavour() {
    console.log(this.productCode)
    if (this.specialProductCodes.includes(Number(this.productCode))) {
      if (this.productCompositionList.length > 0 && this.showComposition && !this.createSetUpForm.get("composition").value) {
        const vanillaComposition = this.productCompositionList.find(e => e.compositionName === "Vanilla");
        console.log(vanillaComposition)
        this.productCompositionList = [vanillaComposition];

        if (vanillaComposition) {
          this.createSetUpForm.get("composition").setValue(vanillaComposition.refNo);
          this.onFlavourSelect(vanillaComposition.refNo);
        }
      }
    }
  }

  getWebOrderTimeSlotSetUpList() {
    // Reset time slots
    this.timeSlotAvl = [];
    this.timeSlot2 = [];

    this.webOrderTimeSlotSubcription = this.productService.getTimeSlotList(this.apiFactory.getTimeSlotDetailsByCompanyCode, this.currentUrl).subscribe((data) => {
      // Populate available slots
      this.timeSlot2 = data.map((element) => [element.fromTime, element.toTime]);

      // Get selected and today's date
      let selectedDeliveryDate = moment(this.createSetUpForm.get("deliveryDate").value);
      let todayDate = moment(this.todayDate);

      let deliveryDateStr = selectedDeliveryDate.format("YYYY-MM-DD");
      let todayDateStr = todayDate.format("YYYY-MM-DD");

      // Use current time if today, otherwise use selected time
      let deliveryTime = deliveryDateStr === todayDateStr
        ? todayDate.format("HH:mm") // Use current time if today
        : selectedDeliveryDate.format("HH:mm");

      // Convert deliveryTime into total minutes
      let [hour, minute] = deliveryTime.split(":").map(Number);
      let totTime = hour * 60 + minute;

      // If delivery is today, filter only future slots
      if (deliveryDateStr === todayDateStr) {
        this.timeSlotAvl = this.timeSlot2
          .filter(([fromTime]) => {
            let [slotHour, slotMinute] = fromTime.split(":").map(Number);
            return totTime < slotHour * 60 + slotMinute;
          })
          .map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
      } else {
        // Show all slots for future dates
        this.timeSlotAvl = this.timeSlot2.map(([fromTime, toTime]) => `${fromTime}-${toTime}`);
      }

      // Ensure UI updates
      this.timeSlotAvl = [...this.timeSlotAvl];
    });
  }

  getWebOrderMinimumDateTimeDetails(id: number) {
    this.webOrderMinimumDateTimeSubcription = this.productService.getWebOrderMinimumDateTimeDetails(this.currentUrl).subscribe((data) => {
      this.webOrderMinimumDateTimeList = data;
      this.getProductDetail(id);
      // var maxOrderDuration = new Date();
      // maxOrderDuration.setHours(maxOrderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[0]));
      // maxOrderDuration.setMinutes(maxOrderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[1]));

      // this.maxDate = moment(new Date(maxOrderDuration)).format("YYYY-MM-DD");
      // this.product.maxDeliveryDate = this.maxDate;
    });
  }

  openStorePickupDialog(id: number) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.toastrService.showSuccessToast("Store changed sucessfully");
            let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
            this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
            this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;
            this.getProductDetail(id);
          } else if (result === 'delivery') {
            this.openPinChangeDialog(id);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        let storeLocation: WebOrderLocationSetup = JSON.parse(this.localStorage.getItem('pickup_location'));
        this.webOrderMinimumDateTimeList.deliveryTime = storeLocation?.minOrderTime;
        this.webOrderMinimumDateTimeList.maxDeliveryTime = storeLocation?.maxOrderTime;
        console.log(this.webOrderMinimumDateTimeList);
        this.getProductDetail(id);
      } else if (deliveryType === 'delivery') {
        this.openPinChangeDialog(id);
      }
    }
  }

  openPinChangeDialog(id: number) {
    this.pincode = this.localStorage.getItem("pincode_item");

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        // data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result)
        if (result?.result == true) {
          this.toastrService.showSuccessToast("Pin changed sucessfully");
          this.getWebOrderMinimumDateTimeDetails(id);
          // this.getProductDetail(id);
          // window.location.reload();
        }
      });
    } else {
      this.getWebOrderMinimumDateTimeDetails(id);
    }
  }

  getProductDetail(id: number) {
    this.productCode = id;
    this.productSubcription = this.productService.getProductById(id).subscribe((data) => {
      this.product = data;
      console.log(data);
      this.sharingUrl = getWindow().location.origin + '/make-product' + '/' + this.product.product_Code;

      var orderDuration = new Date();
      orderDuration.setHours(orderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[0]));
      orderDuration.setMinutes(orderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.deliveryTime?.split(":")[1]));

      var maxOrderDuration = new Date();
      maxOrderDuration.setHours(maxOrderDuration.getHours() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[0]));
      maxOrderDuration.setMinutes(maxOrderDuration.getMinutes() + Number.parseInt(this.webOrderMinimumDateTimeList?.maxDeliveryTime?.split(":")[1]));

      let minOrderDate = new Date();
      minOrderDate.setDate(minOrderDate.getDate() + this.product.minimum_Order_Time);

      this.maxDate = moment(maxOrderDuration).format("YYYY-MM-DD");
      this.product.maxDeliveryDate = this.maxDate;

      console.log(this.product);

      // Set todayDate to the later (greater) of orderDuration or minOrderDate
      this.todayDate = orderDuration > minOrderDate ? orderDuration : minOrderDate;

      this.createSetUpForm.patchValue({
        productPrice: this.product.net_Sale_Price,
        productName: this.product.product_Name,
        minimum_Order_Time: this.product.minimum_Order_Time,
        qty: this.product.minimum_Order_Quantity,
        subTotal: this.product.minimum_Order_Quantity * this.product.net_Sale_Price,
        deliveryDate: this.todayDate,
        // deliveryTime: this.minTimeInHr
      });
      this.onRateShow(this.product);
      this.setMtoData(id);
      this.getWebOrderCustomizeDetails();
      this.getWebOrderTimeSlotSetUpList();
      this.saveWebUserInfo();
      this.getOfferDetails();
    });
  }

  getOfferDetails() {
    this.pincode = JSON.parse(this.localStorage.getItem("pincode_item"));
    console.log(this.pincode);

    let locId: number = JSON.parse(this.localStorage.getItem("location_code")) || 0;
    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    if (!type) {
      this.fetchOfferDetails(locId);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
      .subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        locId = data === 'allowForWebSite' ? this.googleCustomer?.locationCode || 0 : data === 'allowForLocation'
          ? JSON.parse(this.localStorage.getItem('location_code')) || 0 : 0;

        this.fetchOfferDetails(locId);
      });
  }

  fetchOfferDetails(locId: number) {
    this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, [this.productCode], this.currentUrl)
      .subscribe((data) => {
        if (data.length === 0) return;

        this.productWithOffers = data;
        console.log(data);

        this.offerNames = this.productWithOffers[0].offerDetails.map(element => element.schemeName).join(', ');

        let offerData = this.productWithOffers.find(offer => offer.productCode === this.product.product_Code);
        if (offerData) {
          this.product.offerDetails = offerData.offerDetails;
          this.product.offers = offerData.offers;
        }
      });
  }

  viewOfferDetail() {
    console.log(this.productWithOffers[0])
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: this.productWithOffers[0]?.offerDetails }
    });

    dialogRef.afterClosed().subscribe(res => {

    })
  }

  dateChanged() {
    this.createSetUpForm.get("deliveryTime").reset();
    this.getWebOrderTimeSlotSetUpList();
  }

  onRateShow(product: Product) {
    console.log(product);
    this.rate1 = Number(product.net_Sale_Price);
    this.taxRate1 = product.tax.taxRate;
    this.cessRate1 = product.cess.cessRate;
    this.minimumQty = product.minimum_Order_Quantity;
    // this.calculationSetUpForm.patchValue({
    //   rate: rate1,
    //   taxrate: product.tax.taxRate,
    //   cessrate: product.cess.cessRate
    // })
  }

  onQtySelect(event: any) {
    this.discount = 0;
    this.qty = Number(event.target.value);
    // let rate = this.calculationSetUpForm.get('rate').value;
    console.log(this.qty);
    if (this.qty >= this.minimumQty) {
      let rate: number = this.rate1;
      let premiumFlavourPrice: number = this.createSetUpForm.get("premiumFlavourPrice").value;
      let subTotal: number = this.qty * (Number(rate) + Number(premiumFlavourPrice));
      let subTot = Number(subTotal);

      this.createSetUpForm.patchValue({ subTotal: subTot, });
      this.onDiscountSelect(this.discount);
    } else {
      this.createSetUpForm.get("qty").setValue(this.minimumQty);
      this.toastrService.showErrorToast("Minimum order quantity - " + this.minimumQty + "Kgs");
    }
  }

  onFlavourSelect(name: any) {
    let premiumPrice = 0;
    let basePremiumPrice = 0;
    if (name) {
      basePremiumPrice = this.productCompositionList.filter((e) => e.refNo == name)[0].premiumFlavourPrice;
      premiumPrice = basePremiumPrice + (this.taxRate1 / 100) * basePremiumPrice + (this.cessRate1 / 100) * basePremiumPrice;
    }
    this.createSetUpForm.get("basePremiumFlavourPrice").setValue(Number(basePremiumPrice));
    this.createSetUpForm.get("premiumFlavourPrice").setValue(Number(premiumPrice.toFixed(2)));
    let rate: number = this.rate1;
    let qty: number = this.createSetUpForm.get("qty").value;
    let premiumFlavourPrice: number = this.createSetUpForm.get("premiumFlavourPrice").value;
    let subTotal: number = qty * (Number(rate) + Number(premiumFlavourPrice));
    let subTot = Number(subTotal);
    this.createSetUpForm.get("subTotal").setValue(subTot);
  }

  onDiscountSelect(discount: number) {
    // let subTotal = this.calculationSetUpForm.get('subTotal').value;
    // let taxRate = this.calculationSetUpForm.get('taxrate').value;
    // let cessRate = this.calculationSetUpForm.get('cessrate').value;

    let subTotal = this.createSetUpForm.get("subTotal").value;
    let taxRate = this.taxRate1;
    let cessRate = this.cessRate1;

    let discountAmount = discount;
    let taxableAmount = subTotal - discountAmount;
    let taxAmount = (taxRate / 100) * taxableAmount;
    let cessAmount = (cessRate / 100) * taxableAmount;
    let netAmount = taxableAmount + taxAmount + cessAmount;

    // this.calculationSetUpForm.patchValue({
    //   discountAmt: discountAmt,
    //   taxableAmt: taxableAmt,
    //   taxAmt: taxAmt,
    //   cessAmt: cessAmt,
    //   netAmt: netAmt
    // })
  }

  applayCoupon() {
    let couponCode = this.calculationSetUpForm.get("coupon").value;
    let netAmount = this.calculationSetUpForm.get("netAmt").value;
    console.log(netAmount);
    this.couponSubcription = this.productService.getCouponAmountByCouponCode(this.currentUrl, couponCode).subscribe((data) => {
      this.couponSetUp = data;
      console.log(data);
      if (data != null) {
        if (netAmount >= this.couponSetUp.billingAmount) {
          this.onDiscountSelect(this.couponSetUp.couponAmount);
        } else {
          this.toastrService.showErrorToast("Coupon applicable must be purchase 500");
          this.calculationSetUpForm.patchValue({ coupon: "" });
        }
      } else {
        this.toastrService.showErrorToast("Invalid Coupon");
        this.calculationSetUpForm.patchValue({ coupon: "" });
      }
    });
  }

  addToCart(product: Product, goToCartPage: boolean) {
    if (this.createSetUpForm.invalid) {
      this.toastrService.showErrorToast("Invalid form");
      return;
    }
    // product.subTotal = this.createSetUpForm.get('subTotal').value;
    // product.orderQuantityKgs = this.createSetUpForm.get('qty').value
    // this.productService.addToCart(product);

    product.cartProductType = "Make To Order";
    product.subTotal = this.createSetUpForm.get("subTotal").value;
    product.orderQuantityKgs = this.createSetUpForm.get("qty").value;
    product.deliveryDate = moment(this.createSetUpForm.get("deliveryDate").value).format("YYYY-MM-DD");
    product.deliveryTime = this.createSetUpForm.get("deliveryTime").value;
    product.color = this.createSetUpForm.get("color").value;
    product.shape = this.createSetUpForm.get("shape").value;
    product.composition = this.createSetUpForm.get("composition").value;
    product.dimension = this.createSetUpForm.get("dimension").value;
    product.note = this.createSetUpForm.get("note").value;
    product.minimum_Order_Time = this.createSetUpForm.get("minimum_Order_Time").value;
    product.basePremiumFlavourPrice = this.createSetUpForm.get("basePremiumFlavourPrice").value;
    product.premiumFlavourPrice = this.createSetUpForm.get("premiumFlavourPrice").value;

    this.openDeliveryTypeDialog(product, goToCartPage);
  }

  openDeliveryTypeDialog(product: Product, goToCartPage: boolean) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    console.log(deliveryType)
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product, goToCartPage);
            // this.productService.addToCartWithFiles(product);

            // if (goToCartPage) {
            //   //Added to redirect to cart
            //   this.productService.navigateToCart(product);
            // }
          } else if (result === 'delivery') {
            this.openPinDialog(product, goToCartPage);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product, goToCartPage);
        // this.productService.addToCartWithFiles(product);

        // if (goToCartPage) {
        //   //Added to redirect to cart
        //   this.productService.navigateToCart(product);
        // }
      } else if (deliveryType === 'delivery') {
        this.openPinDialog(product, goToCartPage);
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean) {
    console.log(product)
    if (product.offerDetails?.length > 0) {
      this.openOfferDialog(product, goToCartPage);
    } else {
      this.productService.addToCartWithFiles(product);

      if (goToCartPage) {
        //Added to redirect to cart
        this.productService.navigateToCart(product);
      }
    }
  }

  openOfferDialog(product: Product, goToCartPage: boolean) {
    console.log(product);
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem("avct_item")) || [];
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    console.log(offers)
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    console.log(appliedOfferId)
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: "500px",
      height: "300px",
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = product.orderQuantityKgs;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
        }
        console.log(product);

        if (offerObj.schemeType == "Buy 1 get 1 Free" || offerObj.schemeType == "Buy X get Y Free") {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                console.log(data);
                // offer processing
                this.productOffer = data;
                console.log(offerObj)
                //set order qty as product QTY IN CART
                product.offerId = offerObj.offerId;
                this.productOffer.offerId = offerObj.offerId;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.taxRate = offerObj.tax?.taxRate;
                this.productOffer.cessRate = offerObj.cess?.cessRate;
                this.productOffer.rate = Number((offerObj.productPriceForCustomer / (1 + (this.productOffer.taxRate / 100) + (this.productOffer.cessRate / 100))).toFixed(2));
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer * orderQuantity;
                this.productOffer.sale_Price = this.productOffer.net_Sale_Price;
                this.productOffer.netAmountForLocation = offerObj.productPriceForLocation * orderQuantity;
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.subTotal = this.productOffer.rate * orderQuantity;
                this.productOffer.taxableAmount = this.productOffer.subTotal;
                this.productOffer.product_Name = this.productOffer.product_Name.concat("(").concat(offerObj.schemeType).concat(")");
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.max_Discount_Percentage = offerObj.max_Discount_Percentage;
                this.productOffer.hsn_Code = offerObj.hsnCode;
                this.productOffer.subGroupCode = offerObj.subGroupCode;

                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.deliveryDate = product.deliveryDate;
                this.productOffer.deliveryTime = product.deliveryTime;
                product.promotionOfferId = offerObj.offerId;

                console.log(product);
                console.log(this.productOffer);
                if (product.orderQuantity < offerObj.minQty && product.orderQuantity > offerObj.maxQtyPerBill) {
                  this.productService.addToCartWithFiles(product);
                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                } else {

                  this.productService.addToCartWithFiles(product, this.productOffer);

                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                  this.productService.addToCartWithFiles(product);
                  if (goToCartPage) {
                    //Added to redirect to cart
                    this.productService.navigateToCart(product);
                  }
                }
              }
            );
        } else {
          if (offerObj.schemeType == "Buy x get x percent off") {
            this.productDiscountSubscription = this.productService.getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  this.discountOffer = data;
                  console.log(this.discountOffer)
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  console.log(product)
                  if (product.orderQuantity < offerObj.minQty && product.orderQuantity > offerObj.maxQtyPerBill) {
                    this.productService.addToCartWithFiles(product);
                    if (goToCartPage) {
                      //Added to redirect to cart
                      this.productService.navigateToCart(product);
                    }
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                    this.productService.addToCartWithFiles(product);
                    if (goToCartPage) {
                      //Added to redirect to cart
                      this.productService.navigateToCart(product);
                    }
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithFiles(product);

                if (goToCartPage) {
                  //Added to redirect to cart
                  this.productService.navigateToCart(product);
                }
              });
          }
        }
      }
    });
  }

  openPinDialog(product: Product, goToCartPage: boolean) {
    this.pincode = this.localStorage.getItem("pincode_item");
    console.log(product);

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: "300px",
        // data: product,
        panelClass: "pin-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
          if (product.offerDetails?.length > 0) {
            this.openOfferDialog(product, goToCartPage);
          } else {
            this.productService.addToCartWithFiles(product);

            if (goToCartPage) {
              //Added to redirect to cart
              this.productService.navigateToCart(product);
            }
          }
          // if (product.shelf_Life_In_Hours >= selfLife) {
          //   this.productService.addToCart(product);
          // } else {
          //   this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          // }
        }
      });
    } else {
      let selfLife = JSON.parse(this.localStorage.getItem("min_selflife"));
      if (product.offerDetails?.length > 0) {
        this.openOfferDialog(product, goToCartPage);
      } else {
        this.productService.addToCartWithFiles(product);

        if (goToCartPage) {
          //Added to redirect to cart
          this.productService.navigateToCart(product);
        }
      }
      // if (product.shelf_Life_In_Hours >= selfLife) {
      //   this.productService.addToCart(product);
      // } else {
      //   this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      // }
    }
  }

  openDialog(product: Product) {
    this.addToCart(product, false);
  }

  setMtoData(id: number) {
    const products: Product[] = JSON.parse(this.localStorage.getItem("avct_item"));

    if (products.some((temp) => temp.product_Code == id && temp.cartProductType == "Make To Order")) {
      let itemIndex = products.findIndex((item) => item.product_Code == id);
      this.showUpdate = true;

      this.createSetUpForm.patchValue({
        qty: products[itemIndex].orderQuantityKgs,
        deliveryDate: products[itemIndex].deliveryDate,
        deliveryTime: products[itemIndex].deliveryTime,
        color: Number(products[itemIndex].color),
        shape: Number(products[itemIndex].shape),
        composition: Number(products[itemIndex].composition),
        dimension: products[itemIndex].dimension,
        note: products[itemIndex].note,
        basePremiumFlavourPrice: products[itemIndex].basePremiumFlavourPrice,
        premiumFlavourPrice: Number(products[itemIndex].premiumFlavourPrice.toFixed(2)),
        subTotal: products[itemIndex].orderQuantityKgs * (products[itemIndex].net_Sale_Price + products[itemIndex].premiumFlavourPrice),
      });
    }
  }

  onShareClick(product: Product) {
    var url = 'https://web.whatsapp.com/send'
    var text = getWindow().location.origin + '/make-product' + '/' + product.product_Code;
    console.log(text)
    window.open(url + '?text=' + text)
  }

  saveWebUserInfo() {
    let localWebUserInfo: WebUserInfo = JSON.parse(this.localStorage.getItem("web_user_info"));
    if (localWebUserInfo) {
      localWebUserInfo.productCode = this.productCode;

      this.saveWebUserInfoSubscription = this.commonService.saveWebUserInfo(this.apiFactory.inserWebUserInfo, localWebUserInfo).subscribe((data) => {
        if (data) {
        }
      });
    }
  }
}
