import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerDTO } from 'src/app/common/customerDto';
import { CustomerDeliveryAddress } from 'src/app/shared/models/customerdeliveryaddress';
import { GstSaleInvCumBosDto } from 'src/app/shared/models/gstsaleinvcumbosdto';
import { ProductOrderSummary } from 'src/app/shared/models/productordersummary';
import { ProductOrderSummaryService } from 'src/app/shared/services/product-order-summary.service';
import moment from "moment";
import { Subscription } from 'rxjs';
import { CartPaymentService } from 'src/app/shared/services/cart-payment.service';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { UserService } from 'src/app/shared/services/user.service';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { MatTableDataSource } from '@angular/material/table';
import { WalletOffers } from 'src/app/shared/models/wallet-offers';
import { WalletOfferRequestDto } from 'src/app/shared/models/wallet-offer-request-dto';
import { getWindow } from 'ssr-window';
import { HttpErrorResponse } from '@angular/common/http';
import { WebOrderLocationSetup } from 'src/app/shared/models/weborderlocationsetup';


@Component({
  selector: 'app-wallet-confirmation-dialog',
  templateUrl: './wallet-confirmation-dialog.component.html',
  styleUrls: ['./wallet-confirmation-dialog.component.scss']
})
export class WalletConfirmationDialogComponent implements OnInit {

  rightBtn: string;
  leftBtn: string;
  isShowCheckout: boolean = false;
  isShowProceed: boolean = true;

  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];
  customerDTO: CustomerDTO = new CustomerDTO();
  deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
  googleCustomer: GoogleCustomer;
  walletOfferRequestDto: WalletOfferRequestDto = new WalletOfferRequestDto();

  dataSource: MatTableDataSource<WalletOffers>;
  offerList: WalletOffers[] = [];
  offer: any;
  displayedColumns: string[] = ["offerName", "amount", "action"];
  currentUrl: string;
  storeLocation: WebOrderLocationSetup = new WebOrderLocationSetup();
  parentLocationCode: number;

  private onWebOrderInsertSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private getwalletOfferSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private dialogRef: MatDialogRef<WalletConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private productOrderSummaryService: ProductOrderSummaryService,
    private cartPaymentService: CartPaymentService,
    private apiFactory: APIFactoryEndPoints,
    private userService: UserService,
    private toastService: ToastService,
    private navRoute: Router,
  ) {
    this.currentUrl = getWindow().location.hostname
    this.dataSource = new MatTableDataSource(this.offerList);
    console.log(dialogData)
    dialogRef.disableClose = true;
    this.rightBtn = dialogData.rightBtn;
    this.leftBtn = dialogData.leftBtn;

    if (dialogData) {
      this.dataSource.data = dialogData.data;
      if (dialogData.amount <= dialogData.wallet)
        this.isShowCheckout = true;
      else
        this.isShowCheckout = false;
    }

    if (!this.rightBtn || !this.leftBtn) {
      this.rightBtn = "PROCEED";
      this.leftBtn = "CANCEL";
    }
  }
  ngOnInit(): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.storeLocation = JSON.parse(this.localStorage.getItem("pickup_location"));
    this.parentLocationCode = JSON.parse(this.localStorage.getItem("parent_location_code"));
    this.getProductOrderSummaryDetails();
  }

  proceedClick() {
    if (this.isShowCheckout)
      this.onWalletSaveClick();
    else
      this.dialogRef.close({ value: "true", wallet: this.dialogData.wallet });
  }

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(this.productOrderSummary);
    this.getWebOrders();
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);

    this.gstSaleInvcumBOSArray = this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);

    // this.getWalletOffers();
  }

  getWalletOffers() {
    this.walletOfferRequestDto.locationCode = JSON.parse(this.localStorage.getItem("location_code"));
    this.walletOfferRequestDto.email = this.googleCustomer.email;
    this.walletOfferRequestDto.amount = this.productOrderSummary.totalPrice;
    this.walletOfferRequestDto.transactionPoint = 'Web Order';
    this.walletOfferRequestDto.applicableCustomer = 'General';

    let offerAmount: number = 0;

    this.getwalletOfferSubscription = this.cartPaymentService.getWalletOffers(this.apiFactory.getCheckedOutWalletOffers, this.walletOfferRequestDto)
      .subscribe((data: WalletOffers[]) => {
        this.dataSource.data = data
        if (data.length <= 0) {
          this.toastService.showErrorToast('No Wallet Offer Available');
        } else {
          data.forEach((element: WalletOffers) => {
            this.offerList.push(element);
            offerAmount += element.walletAmount;
          });
          if (this.dialogData) {
            this.dialogData.wallet = this.dialogData.wallet + offerAmount;
            if (this.dialogData.amount <= this.dialogData.wallet)
              this.isShowCheckout = true;
            else
              this.isShowCheckout = false;
          }
        }
      })
  }

  onChangeOffer(offers: any) {
    console.log(offers);
    this.offer = offers;
  }

  onWalletSaveClick() {
    this.isShowProceed = false;
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
    let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

    if (this.gstSaleInvcumBOS.details.length != 0) {
      let momentDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
      let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
      this.gstSaleInvcumBOS.locationCode = locationCode;
      this.gstSaleInvcumBOS.invoicingLocationCode = this.parentLocationCode;
      this.gstSaleInvcumBOS.deliveryDate = formattedDate;
      this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
      this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;
      this.gstSaleInvcumBOS.url = this.currentUrl;
      this.gstSaleInvcumBOS.customerCode = this.googleCustomer.refNo;
      this.gstSaleInvcumBOS.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);

      console.log(this.gstSaleInvcumBOS);
      this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;

      this.gstSaleInvcumBOS.customer = this.customerDTO;

      if (this.gstSaleInvcumBOS.totalAmt <= this.dialogData.wallet) {
        this.gstSaleInvcumBOS.walletPayment.amount = this.gstSaleInvcumBOS.totalAmt;
      } else {
        this.gstSaleInvcumBOS.walletPayment.amount = this.dialogData.wallet;
      }
      this.dialogData.wallet = this.dialogData.wallet - this.gstSaleInvcumBOS.walletPayment.amount;
      // this.gstSaleInvcumBOS.walletPayment.amount = this.dialogData.amount;
      this.gstSaleInvcumBOS.walletPayment.fromLocationCode = locationCode;
      this.gstSaleInvcumBOS.walletPayment.appliedOfferList = this.dialogData.data;

      this.gstSaleInvcumBOS.totalAmt = Number(this.productOrderSummary.totalPrice);

      this.gstSaleInvcumBOS.gatewayPayment = null;

      console.log(this.gstSaleInvcumBOS)

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder, this.gstSaleInvcumBOS)
        .subscribe((data: GstSaleInvCumBosDto[]) => {
          console.log(data);
          if (data.length > 0) {
            let id: number[] = [];
            this.gstSaleInvcumBOS.details.forEach((element) => {
              if (element.productCode)
                id.push(element.productCode);
            });
            // data.forEach((e) => {
            //   e.details.forEach((element) => {
            //     if (element.productCode)
            //       id.push(element.productCode);
            //   });
            // });
            this.cartSetUpSubscription = this.userService.removeAllProductCart(this.apiFactory.removeAllProductFromCart, this.googleCustomer.email, id)
              .subscribe((data: any) => {
                console.log(data);
                // this.isShowProceed = true;
                this.localStorage.removeItem("orderFromTvLocation");
                this.navRoute.navigate(["/payment-success"], {
                  state: { data: "reload" },
                });
              });
          } else {
            this.toastService.showErrorToast("Server side error...");
          }
        },
          (error: HttpErrorResponse) => {
            // this.isShowProceed = true;
            this.toastService.showErrorToast("Server side error...");
          }
        );
    }

    if (this.gstSaleInvcumBOSArray.length != 0) {
      this.gstSaleInvcumBOSArray.forEach((element) => {
        let momentDate = new Date(element.details[0].deliveryDate);
        let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
        element.deliveryDate = formattedDate;
        element.deliveryTime = element.details[0].deliveryTime;
        element.locationCode = locationCode;
        element.invoicingLocationCode = this.parentLocationCode;
        element.orderFromTvLocation = orderFromTvLocation;
        element.url = this.currentUrl;
        element.customerCode = this.googleCustomer.refNo;
        element.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

        if (element.grandTotal <= this.dialogData.wallet) {
          element.walletPayment.amount = element.grandTotal;
        } else {
          element.walletPayment.amount = this.dialogData.wallet;
        }
        this.dialogData.wallet = this.dialogData.wallet - element.walletPayment.amount;
        element.walletPayment.fromLocationCode = locationCode;
        element.walletPayment.appliedOfferList = this.dialogData.data

        element.totalAmt = Number(this.productOrderSummary.totalPrice);

        element.gatewayPayment = null;
      });
      this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;

      this.gstSaleInvcumBOSArray.forEach(e => e.customer = this.customerDTO);

      console.log(this.gstSaleInvcumBOSArray);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderArray(this.apiFactory.insertWebOrderArray, this.gstSaleInvcumBOSArray)
        .subscribe((data) => {
          console.log(data);
          if (data.length > 0) {
            this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
              .subscribe((data: any) => {
                console.log(data.Res);
                // this.isShowProceed = true;
                this.navRoute.navigate(["/payment-success"], {
                  state: { data: "reload" },
                });
              });
          } else {
            this.toastService.showErrorToast("Server side error...");
          }
        },
          (error: HttpErrorResponse) => {
            // this.isShowProceed = true;
            this.toastService.showErrorToast("Server side error...");
          }
        );
    }
  }

}
