import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { EmailValidator } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
// import {
//   FacebookLoginProvider,
//   GoogleLoginProvider,
//   SocialAuthService,
// } from "angularx-social-login";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { FbResponse } from "src/app/shared/models/fbresponse";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { SocialUsers } from "src/app/shared/models/socialusers";
import { User } from "../../../../shared/models/user";
import { AuthService } from "../../../../shared/services/auth.service";
import { ToastService } from "../../../../shared/services/toastr.service";
import { UserService } from "../../../../shared/services/user.service";
import { PhoneNumberDialogComponent } from "../phone-number-dialog/phone-number-dialog.component";
import { accounts } from 'google-one-tap';
import jwt_decode from 'jwt-decode';
import { getWindow } from "ssr-window";
import { revokeGoogleSignIn, googleSignIn } from "src/google-oauth2";
import { checkLoginState, fbLogout, loginfb } from "src/facebook-oauth2";
import { environment } from "src/environments/environment";
import { SignupDialogComponent } from "../signup-dialog/signup-dialog.component";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { CartDetails } from "src/app/shared/models/cartdetails";
import { Product } from "src/app/shared/models/product";
import { WishListDetails } from "src/app/shared/models/wishlistdetails";
// import { FacebookLoginProvider, SocialAuthService } from "@abacritt/angularx-social-login";
declare var google: any;
declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [EmailValidator],
})
export class LoginComponent implements OnInit {

  socialuser = new SocialUsers();
  googleCustomer = new GoogleCustomer();
  fbCustomer = new FBCustomer();
  fbUsers = new FbResponse();
  user = {
    emailId: "",
    loginPassword: "",
  };

  errorInUserCreate = false;
  errorMessage: any;
  createUser;
  private sub: any;
  checkLogin: string;
  isSignIn: boolean = false;
  currentUrl: string;

  //Subscription
  private onSocialSetUpSubscription: Subscription;
  getProfileSubscription: Subscription;

  constructor(
    private ngZone: NgZone,
    public dialog: MatDialog,
    // private authService: AuthService,
    private userService: UserService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    // private OAuth: SocialAuthService,
    private apiFactory: APIFactoryEndPoints,
    private localStorage: LocalStorageService,
  ) {
    this.createUser = new User();
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    let id = this.route.snapshot.paramMap.get("checkout");
    this.route.params.subscribe((data) => {
      this.checkLogin = data["id"];
    });
    this.route.queryParams.subscribe(params => {
      const hasSource = 's' in params;
      if (hasSource) {
        this.checkLogin = params['s'];
      }
    });
    // this.googleAuthSDK();

    // console.log(id);
    // this.getLocationForGoogle();
    // this.getLocationForFB();

    //this.googleAuthSDK();

    // const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
    //   width: "250px",
    //   data: this.googleCustomer,
    // });
    // google.accounts.id.initialize({
    //   client_id: environment.googleClientId,
    //   callback: this.handleCredentialResponse.bind(this),
    //   auto_select: false,
    //   cancel_on_tap_outside: true,

    // });
    // // @ts-ignore
    // google.accounts.id.renderButton(
    // // @ts-ignore
    // document.getElementById("google-button"),
    //   { theme: "outline", size: "large", width: "100%" }
    // );
    // // @ts-ignore
    // google.accounts.id.prompt((notification: PromptMomentNotification) => {});
  }

  // async handleCredentialResponse(response: any) {
  //   // Here will be your response from Google.
  //   console.log(response);
  // }

  ngAfterViewInit() {
    window.onload = () => {
      if (google) {
        google.accounts.id.initialize({
          client_id: environment.googleClientId,
          callback: (response: any) => this.handleGoogleSignIn(response)
        });
        google.accounts.id.renderButton(
          document.getElementById("buttonDiv"),
          { size: "medium", type: "standard" }  // customization attributes
        );
      }
    };
    if (localStorage.getItem("signin_resp") != null && localStorage.getItem("signin_resp") != 'null' && localStorage.getItem("signin_resp") != undefined) {
      console.log("Google Sign In Resp=" + localStorage.getItem("signin_resp"));
      if (!this.isSignIn)
        this.callGoogleLogin();
    }

    if (localStorage.getItem("facebook_signin_resp") != null && localStorage.getItem("facebook_signin_resp") != 'null' && localStorage.getItem("facebook_signin_resp") != undefined) {
      console.log("FaceBook Sign In Resp=" + localStorage.getItem("facebook_signin_resp"));
      if (!this.isSignIn)
        this.callFBLogin();
    }
    // google.accounts.id.initialize({
    //   client_id: environment.googleClientId,
    //   ux_mode: 'popup',
    //   cancel_on_tap_outside: true,
    //   callback: ({ credential }) => {
    //     this.ngZone.run(() => {
    //       this._loginWithGoogle(credential);
    //     });
    //   },
    // });

    // console.log(document.getElementById("googleButton"))

    // google.accounts.id.renderButton(
    //   document.getElementById("googleButton"),
    //   {
    //     type: "standard", text: "signin_with", theme: "outline",
    //     size: "medium", width: "250"
    //   }
    // )

  }

  handleGoogleSignIn(response: any) {
    console.log(response.credential);

    // This next is for decoding the idToken to an object if you want to see the details.
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload));
    this.callLogin()
  }

  private _loginWithGoogle(token: string) {
    console.log(jwt_decode(token))
    this.callLogin();
  }

  public lat;
  public lng;
  public fbLat;
  public fbLng;
  getLocationForGoogle() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude);
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;

          this.googleCustomer.lattitude = this.lat;
          this.googleCustomer.longitude = this.lng;
        }
      },
        (error: any) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getLocationForFB() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          console.log("Latitude: " + position.coords.latitude + "Longitude: " + position.coords.longitude);
          this.fbLat = position.coords.latitude;
          this.fbLng = position.coords.longitude;

          this.fbCustomer.lattitude = this.fbLat;
          this.fbCustomer.longitude = this.fbLng;
        }
      },
        (error: any) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  //Google client Id
  // 1027135256757-9t4carlh4l1e9tqh500ihenulgcsjon3.apps.googleusercontent.com

  //Google secrete key
  // OhKmf4PTYbnLEF0qPtsLKlwb

  public signInWithGoogle() {
    //let socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
    // this.OAuth.signIn(socialPlatformProvider).then(socialuser => {
    //   if (socialuser != null) {
    //     localStorage.setItem("showSignIn","true")
    //     this.isSignIn = true;
    //     this.googleCustomer.id = socialuser.id;
    //     this.googleCustomer.name = socialuser.name;
    //     this.googleCustomer.email = socialuser.email;
    //     this.googleCustomer.firstName = socialuser.firstName;
    //     this.googleCustomer.lastName = socialuser.lastName;
    //     this.googleCustomer.idToken = socialuser.idToken;
    //     this.googleCustomer.authToken = socialuser.authToken;
    //     this.googleCustomer.provider = socialuser.provider;
    //     localStorage.setItem(
    //       "google_item",
    //       JSON.stringify(this.googleCustomer.name)
    //     );
    //     localStorage.setItem(
    //       "google_id",
    //       JSON.stringify(this.googleCustomer.id)
    //     );
    //     localStorage.setItem(
    //       "google_provider",
    //       JSON.stringify(this.googleCustomer.provider)
    //     );
    //     this.userService.setCheckLoggedIn(this.checkLogin);
    //     const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
    //       width: "250px",
    //       data: this.googleCustomer,
    //     });
    //     dialogRef.afterClosed().subscribe((result) => {
    //       console.log("The dialog was closed");
    //     });
    //   }
    //   if (null == socialuser) {
    //     this.isSignIn = false;
    //   }
    // });
  }

  //facebook clientId
  // 168211478436016

  // facebook secreat
  // 6f67e5fc0d2a79247c3849ce3703210c
  public signInWithFaceBook() {
    loginfb()
    // let fbPlatformProvider: any;
    // //fbPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    // this.OAuth.signIn(fbPlatformProvider);
    // this.OAuth.authState.subscribe((fbUsers) => {
    //   this.fbCustomer.id = fbUsers.id;
    //   this.fbCustomer.name = fbUsers.name;
    //   this.fbCustomer.email = fbUsers.email;
    //   this.fbCustomer.firstName = fbUsers.firstName;
    //   this.fbCustomer.lastName = fbUsers.lastName;
    //   this.fbCustomer.authToken = fbUsers.authToken;
    //   this.fbCustomer.provider = fbUsers.provider;
    //   localStorage.setItem("fb_item", JSON.stringify(this.fbCustomer.name));
    //   localStorage.setItem("fb_id", JSON.stringify(this.fbCustomer.id));
    //   localStorage.setItem(
    //     "fb_provider",
    //     JSON.stringify(this.fbCustomer.provider)
    //   );
    //   // localStorage.setItem("loggedIn_fb_details", JSON.stringify(this.fbCustomer));
    //   this.userService.setCheckLoggedIn(this.checkLogin);
    //   this.dialog.open(PhoneNumberDialogComponent, {
    //     // disableClose: true,
    //     width: "250px",
    //     data: this.fbCustomer,
    //   });
    // });
  }

  auth2: any;
  @ViewChild("loginRef", { static: true }) loginElement!: ElementRef;

  // callLogin(googleAuthUser: any) {
  //   this.isSignIn = false;
  //   // this.auth2.attachClickHandler(this.loginElement.nativeElement, {}, (googleAuthUser: any) => {
  //   //Print profile details in the console logs
  //   localStorage.setItem("showSignIn", "true");
  //   //let profile = googleAuthUser.getBasicProfile();
  //   // console.log("Token || " + googleAuthUser.getAuthResponse().id_token);
  //   console.log("ID: " + googleAuthUser.email);
  //   console.log("Name: " + googleAuthUser.name);
  //  // console.log("Image URL: " + googleAuthUser.picture);
  //   console.log("Email: " + googleAuthUser.email);

  //   this.isSignIn = true;
  //   this.googleCustomer.id = googleAuthUser.email;
  //   this.googleCustomer.name = googleAuthUser.name;
  //   this.googleCustomer.email = googleAuthUser.email;
  //   this.googleCustomer.firstName = googleAuthUser.firstName;
  //   this.googleCustomer.lastName = googleAuthUser.lastName;
  //   this.googleCustomer.idToken = googleAuthUser.email;
  //   this.googleCustomer.authToken = googleAuthUser.authToken;
  //   this.googleCustomer.provider =  "GOOGLE";
  //   // this.googleCustomer.provider =  googleAuthUser.provider;

  //   console.log(this.googleCustomer);

  //   // localStorage.setItem("google_loggedIn_details",JSON.stringify(this.googleCustomer);
  //   localStorage.setItem("google_email", JSON.stringify(this.googleCustomer.email));
  //   localStorage.setItem("google_item", JSON.stringify(this.googleCustomer.name));
  //   localStorage.setItem("google_id", JSON.stringify(this.googleCustomer.id));
  //   localStorage.setItem("google_provider", JSON.stringify(this.googleCustomer.provider));
  //   this.userService.setCheckLoggedIn(this.checkLogin);
  //   const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
  //     width: "250px",
  //     data: this.googleCustomer,
  //   });
  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log("The dialog was closed");
  //     // window.history.back();
  //     window.location.reload();
  //   });
  //   if (null == this.googleCustomer) {
  //     this.isSignIn = false;
  //   }
  //   // window.location.reload();
  // }

  callLogin() {
    // this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
    //   (googleAuthUser: any) => {
    //     //Print profile details in the console logs
    //     let profile = googleAuthUser.getBasicProfile();
    //     console.log('Token || ' + googleAuthUser.getAuthResponse().id_token);
    //     console.log('ID: ' + profile.getId());
    //     console.log('Name: ' + profile.getName());
    //     console.log('Image URL: ' + profile.getImageUrl());
    //     console.log('Email: ' + profile.getEmail());
    //   }, (error: any) => {
    //     alert(JSON.stringify(error, undefined, 2));
    //   });

    this.isSignIn = false;
    this.auth2.attachClickHandler(this.loginElement.nativeElement, {}, (googleAuthUser: any) => {
      //Print profile details in the console logs
      localStorage.setItem("showSignIn", "true");
      let profile = googleAuthUser.getBasicProfile();
      console.log("ID: " + profile.getId());
      console.log("Name: " + profile.getName());
      console.log("Email: " + profile.getEmail());
      // console.log(googleAuthUser);

      this.isSignIn = true;
      this.googleCustomer.id = profile.getId();
      this.googleCustomer.name = profile.getName();
      this.googleCustomer.email = profile.getEmail();
      this.googleCustomer.firstName = profile.getName();
      this.googleCustomer.lastName = profile.getName();
      this.googleCustomer.idToken = googleAuthUser.getAuthResponse().id_token;
      this.googleCustomer.authToken = googleAuthUser.getAuthResponse().access_token;
      // this.googleCustomer.authToken = profile.getAuthToken();
      this.googleCustomer.provider = "GOOGLE";
      // this.googleCustomer.provider =  googleAuthUser.provider;

      console.log(this.googleCustomer);

      // localStorage.setItem("google_loggedIn_details",JSON.stringify(this.googleCustomer);
      localStorage.setItem("google_email", JSON.stringify(this.googleCustomer.email));
      localStorage.setItem("google_item", JSON.stringify(this.googleCustomer.name));
      localStorage.setItem("google_id", JSON.stringify(this.googleCustomer.id));
      localStorage.setItem("google_provider", JSON.stringify(this.googleCustomer.provider));
      this.userService.setCheckLoggedIn(this.checkLogin);
      const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
        width: "250px",
        data: this.googleCustomer,
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log("The dialog was closed");
        // window.history.back();
        window.location.reload();
      });
      if (null == this.googleCustomer) {
        this.isSignIn = false;
      }
    }, (error: any) => {
      alert(JSON.stringify(error, undefined, 2));
    });
    // window.location.reload();
  }

  callGoogleLogin() {
    this.isSignIn = false;
    localStorage.setItem("showSignIn", "true");

    console.log("Google Sign In Resp=" + localStorage.getItem("signin_resp"));
    let profile = JSON.parse(localStorage.getItem("signin_resp"));
    console.log(profile)

    this.isSignIn = true;
    this.googleCustomer.id = profile.id;
    this.googleCustomer.name = profile.name;
    this.googleCustomer.email = profile.email;
    this.googleCustomer.firstName = profile.name;
    this.googleCustomer.lastName = profile.name;
    this.googleCustomer.provider = "GOOGLE";
    // this.googleCustomer.idToken = googleAuthUser.getAuthResponse().id_token;
    // this.googleCustomer.authToken = JSON.parse(localStorage.getItem("access_token"));

    console.log(this.googleCustomer);

    localStorage.setItem("google_email", JSON.stringify(this.googleCustomer.email));
    localStorage.setItem("google_item", JSON.stringify(this.googleCustomer.name));
    localStorage.setItem("google_id", JSON.stringify(this.googleCustomer.id));
    localStorage.setItem("google_provider", JSON.stringify(this.googleCustomer.provider));
    this.userService.setCheckLoggedIn(this.checkLogin);
    const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
      width: "250px",
      data: this.googleCustomer,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      window.location.reload();
    });
    if (null == this.googleCustomer) {
      this.isSignIn = false;
    }
    // }, (error: any) => {
    //   alert(JSON.stringify(error, undefined, 2));
    // });
    // window.location.reload();
  }

  callFBLogin() {
    this.isSignIn = false;
    localStorage.setItem("showSignIn", "true");

    // localStorage.setItem("facebook_signin_resp", '{"email":"mekalyan1997@gmail.com","name":"Kalyan Kumar","id":"377426311589500"}');
    console.log("Facebook Sign In Resp=" + localStorage.getItem("facebook_signin_resp"));
    let profile = JSON.parse(localStorage.getItem("facebook_signin_resp"));
    console.log(profile)

    this.isSignIn = true;
    this.googleCustomer.id = profile.id;
    this.googleCustomer.name = profile.name;
    this.googleCustomer.email = profile.email;
    this.googleCustomer.firstName = profile.name;
    this.googleCustomer.lastName = profile.name;
    this.googleCustomer.provider = "FACEBOOK";

    console.log(this.googleCustomer);

    localStorage.setItem("google_email", JSON.stringify(this.googleCustomer.email));
    localStorage.setItem("google_item", JSON.stringify(this.googleCustomer.name));
    localStorage.setItem("google_id", JSON.stringify(this.googleCustomer.id));
    localStorage.setItem("google_provider", JSON.stringify(this.googleCustomer.provider));
    this.userService.setCheckLoggedIn(this.checkLogin);
    const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
      width: "250px",
      data: this.googleCustomer,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      window.location.reload();
    });
    if (null == this.googleCustomer) {
      this.isSignIn = false;
    }
  }

  googleAuthSDK() {
    (<any>getWindow())["googleSDKLoaded"] = () => {
      (<any>getWindow())["gapi"].load("auth2", () => {
        this.auth2 = (<any>getWindow())["gapi"].auth2.init({
          client_id:
            "397301806852-9ljpp195h1oe69ef4tgehklh7u3lvrvu.apps.googleusercontent.com",
          plugin_name: "login",
          cookiepolicy: "single_host_origin",
          scope: "profile email",
        });
        this.callLogin();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement("script");
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, "script", "google-jssdk");
  }

  GoogleSignIn() {
    localStorage.setItem("current_url", window.location.href)
    googleSignIn();
  }
  revokeLogin() {
    localStorage.setItem("signin_resp", null)
    revokeGoogleSignIn()
  }
  FaceBookSignIn() {
    localStorage.setItem("current_url", window.location.href)
    loginfb()
  }
  FBLogout() {
    localStorage.setItem("facebook_signin_resp", null)
    fbLogout()
  }

  WhatsAppSignIn() {
    this.googleCustomer.provider = "WHATSAPP";
    this.userService.setCheckLoggedIn(this.checkLogin);

    const dialogRef = this.dialog.open(PhoneNumberDialogComponent, {
      width: "250px",
      data: this.googleCustomer,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      window.location.reload();
    });
    if (null == this.googleCustomer) {
      this.isSignIn = false;
    }
  }
  // signInWithFB(): void {
  //   this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  //   this.authService.authState.subscribe((user) => {
  //     console.log("FB Connected="+user.email)
  //     console.log("FB Connected="+user.name)
  //     console.log("FB Connected="+user.id)

  //   });
  // }

  // signOut(): void {
  //   this.authService.signOut();
  // }
}
