export class ProductCartDto {
  productCode: number;
  productName: string;
  offerId: string;
  qty: number;
  price: number;
  total: number;
  discPercent: number;
  discAmt: number;
  baseAmt: number;
  orgDtlId: number;
  netAmt: number;
  sgstRate: number;
  cgstRate: number;
  igstRate: number;
  ugstRate: number;
  cessRate: number;
  taxRate: number;
  promotionOfferId: string;
  deliveryDate: string;
  deliveryTime: string;
  premiumFlavourPrice: number = 0;
  basePremiumFlavourPrice: number = 0;
  freeByProductCode: number;
  designForProductCode: number;

  cartProductType: string;
  color: string;
  shape: string;
  dimension: string;
  composition: string;
  note: string;
  dateOfDelivery: string;
  discountRate: number = 0.0;
  productUnit: string;
  productType: string;
  maxDiscountPercentage: number;
  hsnCode: string;

  subGroupCode: number;

  offerDiscRate: number;
  offerDiscAmt: number;

  fileNames: string[];
  productPicture: string;
  rate: number;
  netAmountForLocation: number;
  totalAmount: number;
}
