import { Routes, RouterModule } from "@angular/router";
import { CareerComponent } from './career.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "career-app",
        component: CareerComponent,
      },
    ],
  },
];

export const CareerRoutes = RouterModule.forChild(routes);
