import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { WebOrderCareerSetUp } from "../models/webordercareersetup";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";

@Injectable({
  providedIn: "root",
})
export class CarrerService {
  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) {}

  getMessageBymailChimp(url: string) {
    return this.http.post(url, { responseType: "json" });
  }

  sendMessageByMailChimp(
    url: string,
    obj: UntypedFormGroup,
    file: File
  ): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "formData",
      new Blob([JSON.stringify(obj.value)], { type: "application/json" })
    );

    return this.http.post(url, formData);
  }

  getWebOrderCareerSetup(url: string, currentUrl: string) {
    return this.http.get<WebOrderCareerSetUp[]>(url + "/" + currentUrl);
  }
}
