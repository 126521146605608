import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { FBCustomer } from "src/app/shared/models/fbcustomer";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { LoginCustomerDetails } from "src/app/shared/models/logincustomerdetails";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { CartPaymentService } from "src/app/shared/services/cart-payment.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-gstin-dialog",
  templateUrl: "./gstin-dialog.component.html",
  styleUrls: ["./gstin-dialog.component.scss"],
})
export class GstinDialogComponent implements OnInit {
  createGSTINSetUpForm: UntypedFormGroup;

  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];

  showGSTIN: boolean = false;
  gstinNo: string;
  googleDetails: GoogleCustomer;
  fbDetails: FBCustomer;

  private onWebOrderInsertSubscription: Subscription;
  
  constructor(
    private localStorage:LocalStorageService,
    public dialogRef: MatDialogRef<GstinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public LoggedInData: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private userService: UserService,
    private cartPaymentService: CartPaymentService,
    private productOrderSummaryService: ProductOrderSummaryService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    // console.log(this.LoggedInData);
    this.onCreateSetUp();
    this.getProductOrderSummaryDetails();
    this.getWebOrders();
  }

  onCreateSetUp() {
    this.createGSTINSetUpForm = this.formBuilder.group({
      gstinType: ["", [Validators.required]],
      gstin: ["", [Validators.required, Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"),],],
      // gstin: [''],
    });
  }

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(this.productOrderSummary);
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);

    this.gstSaleInvcumBOSArray = this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);
  }

  chooseGSTIN(data: string) {
    if (data == "YES") {
      this.showGSTIN = true;
    } else if (data == "NO") {
      this.showGSTIN = false;
    }
  }

  onGSTINSetUpClick() {
    this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);

    if (this.showGSTIN == true) {
      if (this.gstSaleInvcumBOS.details.length != 0) {
        this.gstSaleInvcumBOS.gstin = this.createGSTINSetUpForm.get("gstin").value;
      }
      if (this.gstSaleInvcumBOSArray.length != 0) {
        this.gstSaleInvcumBOSArray.forEach((e) => {
          e.gstin = this.createGSTINSetUpForm.get("gstin").value;
        });
      }
    } else {
      if (this.gstSaleInvcumBOS.details.length != 0) {
        this.gstSaleInvcumBOS.gstin = null;
      }
      if (this.gstSaleInvcumBOSArray.length != 0) {
        this.gstSaleInvcumBOSArray.forEach((e) => {
          e.gstin = null;
        });
      }
    }

    this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
    this.dialogRef.close();
    this.navRoute.navigate(["/process-payment"]);
    // this.navRoute.navigate(["/cart-payment"]);

    this.googleDetails = JSON.parse(
      this.localStorage.getItem("google_loggedIn_details")
    );
    this.fbDetails = JSON.parse(this.localStorage.getItem("fb_loggedIn_details"));
    console.log(this.googleDetails);
    if (this.googleDetails != null) {
      let loginDeliveryAddress: LoginCustomerDetails = new LoginCustomerDetails();
      loginDeliveryAddress.firstName = this.googleDetails.firstName;
      loginDeliveryAddress.lastName = this.googleDetails.lastName;
      loginDeliveryAddress.name = this.googleDetails.name;
      loginDeliveryAddress.email = this.googleDetails.email;
      loginDeliveryAddress.phoneNo = this.googleDetails.phoneNo;
      if (this.gstSaleInvcumBOS.details.length != 0) {
        this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;
      }
      if (this.gstSaleInvcumBOSArray.length != 0) {
        this.gstSaleInvcumBOSArray.forEach((e) => {
          e.loginDeliveryAddress = loginDeliveryAddress;
        });
      }
      console.log(this.gstSaleInvcumBOS.loginDeliveryAddress);
    }
    if (this.fbDetails != null) {
      let loginDeliveryAddress: LoginCustomerDetails = new LoginCustomerDetails();
      loginDeliveryAddress.firstName = this.fbDetails.firstName;
      loginDeliveryAddress.lastName = this.fbDetails.lastName;
      loginDeliveryAddress.name = this.fbDetails.name;
      loginDeliveryAddress.email = this.fbDetails.email;
      loginDeliveryAddress.phoneNo = this.fbDetails.phoneNo;
      this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;
      if (this.gstSaleInvcumBOS.details.length != 0) {
        this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;
      }
      if (this.gstSaleInvcumBOSArray.length != 0) {
        this.gstSaleInvcumBOSArray.forEach((e) => {
          e.loginDeliveryAddress = loginDeliveryAddress;
        });
      }
    }

    // let loginDeliveryAddress: LoginCustomerDetails = new LoginCustomerDetails();
    // loginDeliveryAddress.firstName = 'Kalyan';
    // loginDeliveryAddress.lastName = 'Sahoo';
    // loginDeliveryAddress.name = 'Kalyan Sahoo';
    // loginDeliveryAddress.email = 'mekalyan1997@gmail.com'
    // loginDeliveryAddress.phoneNo = '7504577227';
    // this.gstSaleInvcumBOS.loginDeliveryAddress = loginDeliveryAddress;

    // this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder,
    //   this.gstSaleInvcumBOS)
    //   .subscribe((data) => {
    //     console.log(data);
    //   });
  }

  onNoClick(): void {
    this.dialogRef.close();
    this.productOrderSummaryService.setOrderSummary(this.productOrderSummary);
    this.gstSaleInvcumBOS.gstin = null;
    console.log(this.gstSaleInvcumBOS.gstin);
    this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
    this.navRoute.navigate(["/process-payment"]);
    // this.navRoute.navigate(["/cart-payment"]);
  }
}
