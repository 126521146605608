<br />
<section class="center-screen">
  <form #loginForm="ngForm" style="margin-top: 200px;display: flex;flex-direction: column;">
    <!-- <button type="button" class="btn waves-light mx-2 text-white" style="background-color: #dd4b39;" (click)="signInWithGoogle()">
          <i class="fa fa-google" aria-hidden="true"></i>
          Sign in with Google
        </button> -->
    <!-- <button type="button" class="login-with-google-btn" #loginRef>
          Sign in with Google
        </button> -->

    <!-- <button type="button" class="login-with-google-btn" #loginRef>
      Sign in with Google
    </button> -->

    <button type="button" class="social-button google" (click)="GoogleSignIn()">Sign in with Google</button>
    <!-- <button type="button" class="login-with-whatsapp-btn" style="margin-top: 10px;" (click)="WhatsAppSignIn()">
      Sign in with WhatsApp</button> -->
    <!-- <button (click)="revokeLogin()">Revoke Login</button> -->
    <!-- <div id="buttonDiv" style="margin-top: -30px!important">
            <p class="blue">blue</p>
        </div> -->
    <!-- <br /> OR
                <br /> -->
    <br>
    <!-- <button type="button" class="social-button facebook" (click)="signInWithFaceBook()">
      <i class="fa fa-facebook fa-2x" aria-hidden="true"></i><span class="facebook-span">
      Sign in with Facebook</span>
    </button> -->

    <!-- <button type="button" (click)="signInWithFB()" class="btn btn-primary btn-block">Signin with Facebook</button> -->

  </form>

  <!-- <div class="card-block">
    <button class="btn btn-danger" (click)="signOut()">Sign out</button>
  </div> -->
  <br />
</section>
<br />
<br />
<br />

<script>
  // window.fbAsyncInit = function() {
  //     FB.init({
  //         appId: "349415697054113",
  //         autoLogAppEvents: true,
  //         xfbml: true,
  //         version: "v13.0",
  //     });
  // };
</script>
<!-- <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js"></script> -->