<div class="div1" style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Current Openings</h2>
    <div class="hr"></div>
  </div>
</div>
<h4 class="h4_style">
  Join our dynamic team and kickstart your career with 1 Bake Shop! We're a forward-thinking company dedicated to
  innovation and excellence.
  <br> Whether you're a seasoned professional or just starting out, we're committed to helping you grow and develop your
  skills.
  <br> Browse our current job openings and take your career to the next level.<br> Apply now and become a part of our
  talented team!
</h4>
<div *ngIf="showCareer">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>POSITION</th>
      <td mat-cell *matCellDef="let element" style="width: 25%">
        {{ element.position }}
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>DESCRIPTION</th>
      <td mat-cell [innerHTML]="element.description" *matCellDef="let element" style="width: 45%">
        <!-- {{ element.description }} -->
      </td>
    </ng-container>

    <ng-container matColumnDef="place">
      <th mat-header-cell *matHeaderCellDef>PLACE</th>
      <td mat-cell *matCellDef="let element" style="text-align: center !important; width: 15%">
        {{ element.place }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Submit">
      <th mat-header-cell *matHeaderCellDef target="#contact_form">APPLY</th>
      <td mat-cell *matCellDef="let element; let i = index" style="text-align: center !important; width: 15%">
        <a (click)="applayCareer(element, i)" style="color: crimson">Apply</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<!-- <section id="contact" *ngIf="showApplay || showfile">
  <div class="container">
    <div class="row" id="contactRow">
      <div class="col-lg-7 col-md-7 text-center">
        <div class="form-style" id="contact_form">
          <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
            <div class="form-group">
              <input type="text" name="name" class="form-control input-field" placeholder="Name" formControlName="name"
                required />
              <input type="email" name="email" class="form-control input-field" placeholder="Email" required
                formControlName="email" />
              <input type="number" name="phone" class="form-control input-field" placeholder="Mobile No"
                formControlName="phone" required />

              <input hidden type="file" #fileUploader (change)="handleFileInput($event.target.files)"
                accept="application/pdf" required />
              <input class="form-control input-field" formControlName="documentPath" placeholder="Upload CV"
                (click)="fileUploader.click()" value="{{ uploadedFileName }}" required />

              <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white;">
                  SUBMIT
                </button>
                Send Message
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section> -->
<h4 style="text-align: center; margin-top: 20px">
  Work with us
  <!-- Join our dynamic team and kickstart your career with 1 Bake Shop! We're a forward-thinking company dedicated to innovation and excellence.
  <br> Whether you're a seasoned professional or just starting out, we're committed to helping you grow and develop your skills.
  <br> Browse our current job openings and take your career to the next level.<br> Apply now and become a part of our talented team! -->
</h4>
<section id="contact">
  <div class="container">
    <div class="row" id="contactRow">
      <div class="col-lg-7 col-md-7 text-center">
        <div class="form-style" id="contact_form">
          <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
            <div class="form-group">

              <div class="input-wrap">
                <input type="text" formControlName="name" required />
                <label>Name</label>
              </div>

              <div class="input-wrap">
                <input type="email" formControlName="email" required />
                <label>Email</label>
              </div>

              <div class="input-wrap">
                <input type="number" formControlName="phone" pattern="[6-9][0-9]{9}" required />
                <label>Mobile No</label>
              </div>

              <div class="input-wrap">
                <input type="text" formControlName="position" required />
                <label>Position</label>
              </div>

              <div class="input-wrap">
                <input type="text" formControlName="place" required />
                <label>Place</label>
              </div>

              <input hidden type="file" #fileUploader (change)="handleFileInput($any($event.target).files)"
                accept="application/pdf" required />

              <div class="input-wrap">
                <input formControlName="documentPath" (click)="fileUploader.click()" value="{{ uploadedFileName }}"
                  required />
                <label>Upload CV</label>
              </div>

              <!-- <input type="text" name="name" class="form-control input-field" placeholder="Name" formControlName="name"
                required />

              <input type="email" name="email" class="form-control input-field" placeholder="Email" required
                formControlName="email" />

              <input type="number" name="phone" class="form-control input-field" placeholder="Mobile No"
                formControlName="phone" pattern="[6-9][0-9]{9}" required />

              <input type="text" name="position" formControlName="position" class="form-control input-field"
                placeholder="Position" required readonly />

              <input type="text" name="place" formControlName="place" class="form-control input-field"
                placeholder="Place" required readonly />

              <input class="form-control input-field" formControlName="documentPath" placeholder="Upload CV"
                (click)="fileUploader.click()" value="{{ uploadedFileName }}" required /> -->

              <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white">
                  Submit
                </button> Send Message
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>