export class CustomerB2BDetails {
    b2bCustomer: boolean;
    minOrderValue: number;
    minDeliveryTime: string;
    maxDeliveryTime: string;
    mapToLocation: number;
    discountPercentage: number;
    latitude: string;
    longitude: string;
    fromLocationCode: number;

    customerName: string;
    customerMobile: string;
    customerEmail: string;
    contactName: string;
    contactMobile: number;
    shippingAddress: string;
    shippingPinCode: number;
    shippingLandMark: string;
    shippingPlotNo: string;
    shippingStreet: string;
    shippingCityCode: number;
    source: string;
    stateCode: number;
    status: string;
    defaultAddress: boolean;

}
