import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { UserService } from 'src/app/shared/services/user.service';
import { getWindow } from 'ssr-window';

@Component({
  selector: 'app-signup-dialog',
  templateUrl: './signup-dialog.component.html',
  styleUrls: ['./signup-dialog.component.scss']
})
export class SignupDialogComponent implements OnInit {

  profileForm: UntypedFormGroup;
  googleCustomer: GoogleCustomer;

  currentUrl: string;

  getProfileSubscription: Subscription;
  onProfileUpdateSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<SignupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public LoggedInData: any,
    private localStorage: LocalStorageService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private api: APIFactoryEndPoints,
    private toastService: ToastService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname
    this.createProfileForm();
    console.log(this.LoggedInData)
    if (this.LoggedInData) {
      this.profileForm.get('phoneNo').setValue(this.LoggedInData);
    }
    // this.getProfileDetails();
  }

  createProfileForm() {
    this.profileForm = this.formBuilder.group({
      id: [""],
      idToken: [""],
      firstName: [""],
      lastName: [""],
      name: [""],
      dob: [""],
      provider: ["WHATSAPP"],
      currentUrl: [this.currentUrl],
      spouseName: [""],
      spouseDob: [""],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phoneNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
    });
  }

  getProfileDetails() {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.getProfileSubscription = this.userService.getProfiledetails(this.api.getGoogleCustomer, this.googleCustomer.email, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data != null) {
          this.profileForm.patchValue({
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            dob: data.dob,
            email: data.email,
            phoneNo: data.phoneNo,
            spouseName: data.spouseName,
            spouseDob: data.spouseDob,
          });
        }
      });
  }

  onProfileUpdateClick() {
    if (this.profileForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }

    this.profileForm.get('name').setValue(this.profileForm.get('firstName').value + ' ' + this.profileForm.get('lastName').value);

    this.onProfileUpdateSubscription = this.userService.insertProfile(this.api.insertGoogleCustomer, this.profileForm).subscribe((data: GoogleCustomer) => {
      this.toastService.showSuccessToast("Saved Successfully");
      localStorage.setItem("google_email", JSON.stringify(data.email));
      localStorage.setItem("google_item", JSON.stringify(data.firstName + ' ' + data.lastName));
      localStorage.setItem("google_id", JSON.stringify(data.email));
      localStorage.setItem("google_provider", JSON.stringify(data.provider));

      this.googleCustomer = new GoogleCustomer();

      this.googleCustomer.id = data.id;
      this.googleCustomer.name = data.firstName + ' ' + data.lastName;
      this.googleCustomer.email = data.email;
      this.googleCustomer.firstName = data.firstName;
      this.googleCustomer.lastName = data.lastName;
      this.googleCustomer.provider = data.provider;
      this.googleCustomer.phoneNo = data.phoneNo;

      console.log(this.googleCustomer);
      // this.localStorage.setItem("google_loggedIn_details", JSON.stringify(this.googleCustomer));
      this.dialogRef.close(this.googleCustomer);
    },
      (error: HttpErrorResponse) => {
        this.toastService.showErrorToast(error.error.message);
      });
  }

}
