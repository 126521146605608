<form [formGroup]="advSearchForm" style="height: auto !important; overflow: hidden">
    <div class="section-heading">
        <h5>Filter</h5>
    </div>
    <!-- <div style="background: rgb(38, 50, 56) !important;flex-direction: row;box-sizing: border-box;display: flex;">
        <mat-toolbar-row fxFlex="95" class="advSearchToolbar">
            <div class="mat-toolbar-tools" id="advSearchHeader">
                <h2 style="display: inline; font-size: 2rem; color: white">
                    Advanced search
                </h2>
            </div>
        </mat-toolbar-row>
        <div class="cancelDiv" fxFlex="5">
            <button type="button" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" class="mat-icon-button" style="border: none" (click)="closeClick()">
        <mat-icon style="color: white; font-size: 21px">highlight_off</mat-icon>
      </button>
        </div>
    </div> -->

    <div style="margin-bottom: 5px;margin-top: 5px;height: 45%;flex-direction: row;box-sizing: border-box;display: flex;">
        <div style="width: 100%">
            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Min Price" [value]="$any(matslider).value" (keyup)="validateMinMax($any($event.target).value, 'min')" formControlName="minValue" autocomplete="off" (focus)="$any($event.target).select()" />
                    </mat-form-field>
                </mat-card>
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Max Price" [value]="$any(matslider2).value" (keyup)="validateMinMax($any($event.target).value, 'max')" formControlName="maxValue" autocomplete="off" (focus)="$any($event.target).select()" (focusout)="validate('price', $any($event.target).value)"
                        />
                    </mat-form-field>
                </mat-card>
            </div>

            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-slider (input)="onInputChange($event, 'min')" fxFlex="100" class="example-margin" id="example-margin" [max]="max - 1" [min]="min" [value]="advSearchForm.get('minValue').value" #matslider aria-labelledby="example-name-label">
                    </mat-slider>
                </mat-card>

                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-slider (input)="onInputChange($event, 'max')" fxFlex="100" class="example-margin" id="example-margin" [max]="max" [min]="$any(matslider).value" [value]="advSearchForm.get('maxValue').value" #matslider2 aria-labelledby="example-name-label">
                    </mat-slider>
                </mat-card>
            </div>

            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Min Discount %" [value]="$any(matslider3).value" (keyup)="validateNumber($any($event.target).value, 'template', 'min')" formControlName="minDisc" autocomplete="off" (focus)="$any($event.target).select()" />
                    </mat-form-field>
                </mat-card>
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-form-field>
                        <input type="text" matInput placeholder="Max Discount %" [value]="$any(matslider4).value" (keyup)="validateNumber($any($event.target).value, 'template', 'max')" formControlName="maxDisc" autocomplete="off" (focus)="$any($event.target).select()" (focusout)="validate('disc', $any($event.target).value)"
                        />
                    </mat-form-field>
                </mat-card>
            </div>

            <div style="flex-direction: row; box-sizing: border-box; display: flex">
                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-slider (input)="onInputChange($event, 'min')" fxFlex="100" class="example-margin" id="example-margin" max="98" [min]="0" [value]="advSearchForm.get('minDisc').value" #matslider3 aria-labelledby="example-name-label">
                    </mat-slider>
                </mat-card>

                <mat-card class="contain_card" style="flex: 1 1 100%; box-sizing: border-box; max-width: 49%">
                    <mat-slider (input)="onInputChange($event, 'max')" fxFlex="100" class="example-margin" id="example-margin" max="99" [min]="$any(matslider3).value" [value]="advSearchForm.get('maxDisc').value" #matslider4 aria-labelledby="example-name-label">
                    </mat-slider>
                </mat-card>
            </div>

            <div fxFlex="33" style="margin-right: 3px">
                <div fxLayout="row" fxLayout.lt-sm="column">
                    <mat-card class="contain_card" fxFlex="100">
                        <mat-form-field>
                            <mat-select ng-model="offer.o" placeholder="Select Offer" (selectionChange)="onOfferSelect($event.value)" [formControl]="offerControl">
                                <mat-option value="none">None</mat-option>
                                <mat-option *ngFor="let p of offerTypeArray" [value]="p">{{ p }}
                                </mat-option>
                                <!-- <mat-option *ngFor="let p of offer" [value]="p.buyProductCode">{{p.schemeName}}</mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </mat-card>
                </div>
            </div>

            <div fxFlex="33" style="margin-right: 3px">
                <div fxLayout="row" fxLayout.lt-sm="column">
                    <mat-card class="contain_card" fxFlex="100">
                        <mat-form-field>
                            <mat-select ng-model="offer.o" placeholder="Select Food Type" [formControl]="foodTypeControl" (selectionChange)="onFoodTypeSelect($event.value)">
                                <mat-option value="none">None</mat-option>
                                <mat-option *ngFor="let p of foodTypes" [value]="p">{{ p }}
                                </mat-option>
                                <!-- <mat-option *ngFor="let p of offer" [value]="p.buyProductCode">{{p.schemeName}}</mat-option> -->
                            </mat-select>
                        </mat-form-field>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>

    <div layout="row" class="diaolg-row">
        <button type="button" [disabled]="!disbleSearchBtn" style="float: right; margin-top: 7px !important; margin-right: 3px" id="compose-button" id="send-button" class="btn btn-primary" (click)="okClick($any(matslider).value,$any(matslider2).value,$any(matslider3).value,$any(matslider4).value)">
      SEARCH
    </button>
        <button type="button" id="compose-button" id="send-button" class="btn btn-primary" style="float: left; margin-top: 7px !important; margin-left: 3px" (click)="cancelClick()">
      RESET
    </button>
    </div>
</form>

<!-- <div mat-dialog-title class="dialog-title">
    <h2>Product Filter</h2>
    <hr>
</div>
<div class="main">
    <div class="col-md-12 col-sm-12">
        <div class="login-form">
            <form>
                <div class="form-group">
                    <label>Filter 1</label>
                    <input type="text" class="form-control" placeholder="">
                </div>
                <div class="form-group">
                    <label>Filter 2</label>
                    <input type="password" class="form-control" placeholder="">
                </div>
                <button type="button"(click)="Apply()" type="submit" class="btn btn-primary">Apply</button> &nbsp;
                <button type="button" (click)="Close()" type="submit" class="btn btn-secondary">Close</button> &nbsp;

            </form>
        </div>
    </div> -->