<div style="height: 100%; overflow-y: scroll">
  <div class="popup" id="generalTerms">
    <div class="popup-inner" style="padding: 6%; padding-top: 4%">
      <h2 style="margin-bottom: 0px; text-align: center">
        General Terms & Conditions
      </h2>
      <div style="display: block;width: 60px;height: 3px;background: red;margin: 10px auto;margin-top: 1px;"></div>
      <p style="margin-top: 20px; font: 14px/18px Roboto">
        Greetings from
        <a href="https://www.1bakeshop.com">www.1bakeshop.com.</a>
        <br />
        <br />
        We thank you for shopping with us and making us part of your good times.
        <br />
        <br />
        These terms and conditions supersede all previous representations,
        understandings, or agreements and shall prevail notwithstanding any
        variance with any other terms of any order placed or service provided by
        1bakeshop.com. All products/services and information displayed on
        www.1bakeshop.com constitute an "invitation to offer". Your order for
        purchase constitutes your "offer" which shall be subject to the terms
        and conditions as listed below. www.1bakeshop.com reserves the right to
        accept or reject your offer at any time or under any circumstances.
        <br />
        <br />
        www.1bakeshop.com is an online bakery products shopping portal by Bharti
        Restaurants Pvt. Ltd.
        <b>If you visit us at www.1bakeshop.com, you accept these Terms and
          Conditions.</b>
        Please read them carefully. In addition, when you use any current or
        future 1bakeshop.com service or visit or purchase from 1bakeshop.com,
        you will be subjected to these guidelines and conditions for such
        service or business. By using the shopping services of www.1bakeshop.com
        you agree to be bound by these terms and conditions. Terms and
        conditions mentioned here are for the purpose of your awareness and not
        intimidation. We shall do our best to deliver excellent quality
        products.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Definitions:</b>
        <br />
        <br />
        "Agreement" means the terms and conditions as detailed herein including
        all schedules, appendices, annexures, privacy policy, and will include
        the references to this agreement as amended, negated, supplemented,
        varied or replaced from time to time.
        <br />
        <br />
        "www.1bakeshop.com" / "1bakeshop" / "website" means the online shopping
        platform and the services provided by it and its affiliates owned and
        operated by Bharti Restaurants Pvt. Ltd. which provides a venue /
        platform to the Users of www.1bakeshop.com to buy the products listed on
        <a href="https://www.1bakeshop.com">www.1bakeshop.com.</a>
        <br />
        <br />
        "Product/Products" means and includes any
        goods/merchandise/products/services/offers<br />/display items that are
        uploaded /showcased/displayed on www.1bakeshop.com and the related
        description, information, procedure, processes, warranties, delivery
        schedule, etc.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Electronic Communication:</b>
        <br />
        <br />
        When you visit www.1bakeshop.com or send e-mails to us or give us your
        feedback, you are communicating with us electronically. You consent to
        receive communications from us electronically.
        <br />
        <br />
        We will communicate with you by e-mail or by posting notices on this
        website. You agree that all agreements, notices, disclosures and other
        communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Disclaimer of warranties and limitation of liability:</b>
        <br />
        <br />
        www.1bakeshop.com has made this service available to use as a matter of
        convenience.
        <br />
        <br />
        www.1bakeshop.com expressly disclaims any claim or liability arising out
        of uploading of any obscene, vulgar or pornographic images, photograph
        or a picture or altering or distorting the images available under this
        service in an obscene, vulgar or pornographic manner.
        <br />
        <br />
        www.1bakeshop.com does not warrant that this site, its servers, or
        e-mail sent from www.1bakeshop.com are free of viruses or other harmful
        components. www.1bakeshop.com will not be liable for any damages of any
        kind arising from the use of this site, including, but not limited to
        direct, indirect, incidental, punitive, and consequential damages.
        <br />
        <br />
        www.1bakeshop.com assumes no liability whatsoever for any monetary or
        other damage suffered by you on account of:<br />
        - The delay, failure, interruption, or corruption of any data or other
        information transmitted in connection with use of the Website.
        <br />
        <br />
        - Any interruption or errors in the operation of the Website. You
        expressly understand and agree that www.1bakeshop.com shall not be
        liable for any direct, indirect, incidental, special, consequential or
        exemplary damages, including but not limited to, damages for loss of
        profits, goodwill, use, data or other intangible losses.
        <br />
        <br />
        User agrees and acknowledges that User shall be solely responsible for
        User's conduct and that www.1bakeshop.com reserves the right to
        terminate your rights to use the service immediately, notwithstanding
        penal provisions under the Indian cyber laws or any other allied laws
        enacted by the government of India or any other statutory, legislative
        or regulatory authority authorized in this regard from time to time.
        <br />
        <br />
        In no event shall www.1bakeshop.com, its employees, agents, consultants,
        contracted companies be liable for any direct, indirect, punitive,
        incidental, special or consequential damages or for any damages
        whatsoever including, without limitation, damages for loss of use, data
        or profits, arising out of or in any way connected with the use or
        performance of the 1bakeshop.com site/services for interrupted
        communications, delay, lost data or lost profits arising out of or in
        connection with this agreement.
        <br />
        <br />
        www.1bakeshop.com therefore neither endorses nor offers any judgment or
        warranty and accepts no responsibility or liability for the
        authenticity/availability of any of the goods/services/or for any
        damage, loss or harm, direct or consequential or any violation of local
        or international laws that may be incurred by your visit and/or
        transaction/s on these sites.
        <br />
        <br />
        www.1bakeshop.com shall not be liable for any delay / non-delivery of
        purchased goods due to flood, fire, wars, acts of God or any cause that
        is beyond the control of
        <a href="https://www.1bakeshop.com">www.1bakeshop.com.</a>
        <br />
        <br />
        All prices, unless indicated otherwise are in Indian Rupees. The
        availability of products is subject to change without prior notice at
        the sole discretion of 1bakeshop.com.
        <br />
        <br />
        1bakeshop.com reserves the right to refuse or cancel any order placed
        for a product that is listed at an incorrect price which may be higher
        or lower than published. This shall be regardless of whether the order
        has been confirmed and/or payment been made.In the event the payment has
        been processed by 1bakeshop.com the refund amount shall be credited to
        your account and duly notified to you.
        <br />
        <br />
        1bakeshop.com will not be liable for any credit/debit card fraud because
        of the card being used fraudulently. The liability to use a credit/debit
        card or a net banking transaction fraudulently will be on the User and
        the onus to 'prove otherwise' shall be exclusively on the User.
        <br />
        <br />
        Any request for cancellation of orders once duly placed on the site,
        shall not be entertained. In the event that a non-delivery occurs on
        account of a mistake by you (i.e. wrong name or address) any extra cost
        incurred by 1bakeshop.com for re-delivery shall be claimed from the User
        placing the order.
        <br />
        <br />
        1bakeshop.com reserves the right to charge a fee for any or such
        facilities or freight or handling charges or shipping charges or
        statutory taxes.
        <br />
        <br />
        Use of the 1bakeshop.com website is available only to persons who can
        form legally binding contracts under Indian Contract Act, 1872. Persons
        who are "incompetent to contract" within the meaning of the Indian
        Contract Act, 1872 including minors, un-discharged insolvents etc. are
        not eligible to use the 1bakeshop.com website.
        <br />
        <br />

        <b style="font: 500 16px/22px Roboto">Trademark:</b>
        <br />
        <br />
        The logo / image of 1bakeshop on the home page of the website & other
        pages and as used in the communication to the User is registered by
        Bharti Restaurants Pvt. Ltd. And cannot be used or communicated or
        distributed without the specific and written permission of Bharti
        Restaurants Pvt Ltd.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">License and Site Access:</b>
        <br />
        <br />
        1bakeshop.com grants you a limited license to access and make personal
        use of this site and not to download (other than page caching) or modify
        it, or any portion of it, except in case 1bakeshop gives consent for the
        same.
        <br />
        <br />
        This license does not include any resale or commercial use of this site
        or its contents; any collection and use of any product listings,
        descriptions, or prices; any derivative use of this site or its
        contents; any downloading or copying of account information for the
        benefit of another merchant; or any use of data mining, robots, or
        similar data gathering and extraction tools.
        <br />
        <br />
        This site or any portion of this site may not be reproduced, duplicated,
        copied, sold, resold, visited, or otherwise exploited for any commercial
        purpose without express written consent of 1bakeshop.com.
        <br />
        <br />
        You are granted a limited, revocable, and non-exclusive right to create
        a hyperlink to the home page of 1bakeshop.com as long as the link does
        not portray 1bakeshop, or their products or services in a false,
        misleading, derogatory, or otherwise offensive matter or having a right
        to ownership of the same in any form.
        <br />
        <br />
        You may not use any 1bakeshop logo or other proprietary graphic or
        trademark as part of the link without express written permission.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Product Description:</b>
        <br />
        <br />
        1bakeshop and its affiliates attempt to be as accurate as possible.
        However, 1bakeshop does not warrant that product descriptions or other
        content or prices/amounts of this site is accurate, complete, reliable,
        current, or error-free.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Pricing:</b>
        <br />
        <br />
        Except where noted otherwise, the Selling Price/MRP/List Price displayed
        for products on our website represents the full retail price in
        accordance with standard industry practice; or the estimated retail
        value for a comparably featured item offered elsewhere.
        <br />
        <br />
        The final price that will be applicable to a user for purchase and
        payment will be as per the price that is displayed in the shopping cart
        of the customer which can be inclusive or exclusive of any other charges
        in terms of the usage of the website.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Payment:</b>
        <br />
        <br />
        We shall not be responsible or assume any liability, whatsoever in
        respect of any loss or damage arising directly or indirectly to you
        while using any payment method(s) available in 1bakeshop.com due to:
        <br />
        <br />
        Lack of authorization for a transaction(s)<br />
        Declining of transaction(s) for any reason
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Order Confirmation:</b>
        <br />
        <br />
        Orders in general are accepted through 1bakeshop.com and though
        email/phone on a case-to-case basis.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Design Exactness of Products:</b>
        <br />
        <br />
        The Product images are used as guideline and are representative. The
        actual product may look different.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Delivery Date & Time Slots:</b>
        <br />
        <br />
        Delivery Date and Time slot is selected by the user at the time of
        checkout as per their preferences. We strive to fulfill deliveries as
        per selected date & time slot, the same is not a guarantee. We are
        committed to deliver the products within 24 hours of the selected date &
        time slot failing which we shall refund the amount. No refund shall made
        prior to that.
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>Delivery time starts from 10 AM till 8 PM.</li>
            <li>
              Midnight Delivery is generally made between 11 PM and 12:30 AM.
            </li>
            <li>
              No refund shall be made if an order is not delivered within
              selected time slot.
            </li>
            <li>
              Only one attempt for delivery will be made. Please confirm
              availability of recipient before choosing delivery time. If
              recipient is not available at the time of delivery, second attempt
              will not be made and order will be cancelled. No refund will be
              made in such case.
            </li>
            <li>
              Delivery timings, if promised cannot be guaranteed in case of
              special circumstances like extreme weather conditions, riots,
              strikes, elections, bandhs, during rush days like Valentine’s Day,
              Mother’s Day, Rakhi, New year Eve etc.
            </li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Delivery:</b>
        <br />
        <br />
        Risk of loss for all products ordered by you shall be passed on to you
        upon shipment and 1bakeshop.com shall not be liable for any loss or
        damage to the product.
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>The recipient should check the products delivered.</li>
            <li>The recipient should refuse to receive damaged products.</li>
            <li>Any discrepancy must be reported within 2 hours of receipt.</li>
            <li>
              In case a product is badly damaged, the same may be replaced
              within 6 hours.
            </li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Non-Delivery:</b>
        <br />
        <br />
        We shall attempt to deliver your products only once. As most of the
        products are perishable, another delivery attempt can be made subject to
        availability of delivery executives for additional charge. In the event
        of non-delivery in the second attempt, the order shall be considered as
        delivered and no refund shall be made. The order shall be deemed to be
        executed in case of:
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>Wrong delivery address.</li>
            <li>Premise locked.</li>
            <li>Wrong contact number of recipient.</li>
            <li>Recipient refusing to accept delivery</li>
            <li>
              Product is delivered at the Gate / Neighbor as per recipient’s
              instructions.
            </li>
            <li>Recipient not available.</li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Important Points:</b>
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>
              All products listed on the website, their descriptions, and their
              prices are subject to change, time to time.
            </li>
            <li>
              Orders are accepted and delivered in TIME SLOTS which are
              available in your selected city.
            </li>
            <li>
              The product specifications (weight, size, colour etc.) mentioned
              with the product photos displayed are only indicative. There may
              be a slight variation in the pictures and the products delivered.
            </li>
            <li>
              The acceptance of order is subject to availability of products,
              delivery areas and time. The order may or may not be accepted. The
              order might be cancelled after assessing the circumstances and
              communicated to customer. In such case amount paid is fully
              refunded.
            </li>
            <li>
              In case we detect fraudulent behavior, we are liable to cancel the
              order at our discretion and we will not refund money in such
              cases.
            </li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Indemnity:</b>
        <br />
        <br />
        You shall indemnify and hold harmless 1bakeshop.com, its affiliates,
        directors, agents, and employees, from any claim or demand, or actions
        including reasonable attorneys’ fees, made by any third party or penalty
        imposed due to or arising out of your breach of the User Agreement and
        Terms and Conditions or your violation of any law, rules or regulations
        or the rights of a third party.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Data Protection:</b>
        <br />
        <br />
        1bakeshop.com may send information and offers of products and services
        to you from time to time. For further details relating to our policy
        relating to such offer please refer to our privacy policy.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Force Majeure:</b>
        <br />
        <br />
        1bakeshop shall have no liability to you for any interruption or delay
        in access to the Site irrespective of the cause.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Arbitration:</b>
        <br />
        <br />
        If any dispute arises between you and 1bakeshop.com during your use of
        the Website or thereafter, in connection with the validity,
        interpretation, implementation or alleged breach of any provision of the
        User Agreement and Terms and Conditions the dispute shall be first
        referred to a sole Arbitrator who shall be an independent and neutral
        third party identified by 1bakeshop. The place of arbitration shall be
        Bhubaneswar. The Arbitration & Conciliation Act, 1996, shall govern the
        arbitration proceedings. The arbitration proceedings shall be in the
        English language.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Applicable law:</b>
        <br />
        <br />
        This site is created and controlled by 1bakeshop. The laws of India
        shall apply and courts in Bhubaneswar shall have jurisdiction in respect
        of all the terms, conditions and disclaimers. 1bakeshop reserves the
        right to make changes to the website and the terms, conditions and
        disclaimers at any time and without information to the customers/users
        of the services/website of Bharti Restaurants Pvt. Ltd. Users are
        advised to regularly check for any amendments or updates to the terms
        and conditions contained herein.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Entire Agreement:</b>
        <br />
        <br />
        These Terms of Service constitute the entire agreement between the
        parties with respect to the subject matter hereof and supersedes and
        replaces all prior or contemporaneous understandings or agreements,
        written or oral, regarding such subject matter. The clauses as above
        shall survive the termination or expiry of this agreement. This
        electronic record is generated by a computer system and does not require
        any physical or digital signatures.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Our Address: </b>
        <br />
        <br />
        Bharti Restaurants (P) Ltd.<br />
        Plot No.85 & 85/A<br />
        Bhagabanpur Industrial Area<br />
        Bhubaneswar - 751019<br />
        Odisha,<br />
      </p>
    </div>
  </div>

  <div class="popup" id="cancellationPolicy">
    <div class="popup-inner" style="padding: 6%; padding-top: 4%">
      <h2 style="margin-bottom: 0px; text-align: center">
        Cancellation & Refund Policy
      </h2>
      <div style="
          display: block;
          width: 60px;
          height: 3px;
          background: red;
          margin: 10px auto;
          margin-top: 1px;
        "></div>
      <p style="margin-top: 20px; font: 14px/18px Roboto">
        <b style="font: 500 16px/22px Roboto">Cancellation Policy:</b>
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>
              Orders once confirmed cannot be cancelled and no refund shall be
              made.
            </li>
            <li>
              Same Day Delivery – Order cannot be cancelled and no refund shall
              be made.
            </li>
            <li>
              Same Day Mid Night Delivery – Order cannot be cancelled and no
              refund shall be made.
            </li>
          </ul>
        </span>
        <br />
        We shall try to fulfill same day deliveries but do not guarantee so.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Refund Policy:</b>
        <br />
        <br />
        In case an order is cancelled by 1bakeshop.com after assessing
        circumstances, full refund shall be made.
        <br />
        <br />
        Only one attempt for delivery will be made. Please confirm availability
        of recipient before choosing delivery time. If recipient is not
        available at the time of delivery, second attempt will not be made and
        order will be cancelled. No refund will be made in such case.
        <br />
        <br />
        Refunds are processed to your payment method within 15 working days.
        <br />
        <br />
        An order is accepted based on serving location and the PIN code mapped.
        Change of delivery address shall be allowed only for a serviceable area.
        All other case of delivery address change the order shall not be
        cancelled or refunded.
        <br />
        <br />
        The user must provide correct PIN code for the delivery address. The
        system checks PIN code for delivery verification and may accept order
        when PIN code entered is of serviceable area and address is different at
        the time of checkout. Our team may contact you for a resolution in this
        regard and the changed PIN code shall be final for the said order. If
        the new PIN code falls under a non-serviceable area, then the order
        cannot be cancelled and no refund shall be made.
      </p>
    </div>
  </div>

  <br />
  <br />
  <br />
  <div class="popup" id="privacyPolicy">
    <div class="popup-inner" style="padding: 6%; padding-top: 4%">
      <h2 style="margin-bottom: 0px; text-align: center">Privacy Policy</h2>
      <div style="
          display: block;
          width: 60px;
          height: 3px;
          background: red;
          margin: 10px auto;
          margin-top: 1px;
        "></div>
      <p style="margin-top: 20px; font: 14px/18px Roboto">
        <br />
        1bakeshop respects your privacy. You are advised to please read the
        Privacy Policy carefully. By accessing the services provided by
        1bakeshop.com you agree to the collection and use of your data by
        1bakeshop.com in the manner provided in this Privacy Policy.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">What log data is collected by us?</b>
        <br />
        <br />
        We will automatically receive and collect certain information in
        standard usage logs through our Web server, including
        computer-identification information obtained from "cookies," sent to
        your browser from a web server cookie stored on your hard drive an IP
        address.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">What Personally Identifiable Information is collected by
          1bakeshop.com?</b>
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>Name including first and last name.</li>
            <li>Alternate email address.</li>
            <li>ZIP/Postal code.</li>
            <li>Other information as per our registration process.</li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">What purposes personal information is used for?</b>
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>Direct our efforts for product improvement.</li>
            <li>Contact you as a survey respondent.</li>
            <li>Send you promotional materials.</li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Security:</b>
        <br />
        <br />
        Security of your personal information is important to us. We do not
        collect any credit/debit card related information.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">What Communication you may receive from us?</b>
        <br />
        <br />
        <span>
          <ul style="list-style-type: square">
            <li>
              Offers & Updates: We may send you information on products,
              services, deals, promotional offers occasionally.
            </li>
            <li>
              Emergency announcements: We shall send you service related
              announcements on occasions when it is most necessary to do so.
            </li>
          </ul>
        </span>
        <br />
        <b style="font: 500 16px/22px Roboto">Privacy policy for links to other sites:</b>
        <br />
        <br />
        This privacy policy applies only to information collated by this website
        and does not apply to other websites that are linked through our site.
        These sites are neither owned nor controlled by us and you are advised
        to refer to their respective privacy policy.
        <br />
        <br />
        <b style="font: 500 16px/22px Roboto">Legal Disclaimer:</b>
        <br />
        <br />
        We reserve the right to disclose your personally identifiable
        information as required by law and when we believe that disclosure is
        necessary to protect our rights and/or to comply with a judicial
        proceeding, court order, or legal process.
        <br />
        <br />
        If you no longer wish to receive our press releases, newsletter and
        promotional communications, you may opt-out of receiving them by
        following the instructions included in each newsletter or communication
        or by emailing us at info&#64;1bakeshop.com.
      </p>
    </div>
  </div>

  <!-- <div class="popup" id="shippingPolicy">
    <div class="popup-inner" style="padding: 6%; padding-top: 4%">
      <h2 style="margin-bottom: 0px; text-align: center">Shipping Policy</h2>
      <div style="display: block;width: 60px;height: 3px;background: red;margin: 10px auto;margin-top: 1px;"></div>
      <p style="margin-top: 20px; font: 14px/18px Roboto">
        1. Presently delivery option is available only in Bhubaneswar, Odisha,
        India.
        <br />
        2. Deliveries will be made between 11:00 A.M to 6:00 P.M on the mentioned
        date of delivery. <br />
        3.The order must be confirmed at least 24 hours in advance. <br />
        4. The images displayed on the website are indicative in nature and the
        actual product may vary in appearance, shape or design as per
        availability. <br />
        5.We reserve the right to replace the product with equal/higher value
        incase of non availability of the ordered product. <br />
        6. In case of natural calamities (floods/heavy rains/ Bandh we reserve the
        right to reschedule the delivery for another date. <br />
        7. No change of instructions is possible after an order is confirmed.
        <br />
        8. Orders once placed cannot be cancelled. <br />
        9. Delivery order will have only 1 attempt. Incase the delivery could not
        be performed during the attempt there will not be any refund to the
        customer and no redelivery would be possible for the same order. <br />
        10. The Delivery is assumed to be executed in the following case:-
        <br />&nbsp;&nbsp; a. Delivery was not possible because of wrong address.
        <br />&nbsp;&nbsp; b. Recipient was not available at the time of delivery.
        <br />&nbsp;&nbsp; c. Delivery address premises were locked.
        <br />&nbsp;&nbsp; d. The recipient was not able to be contacted due to
        technical reasons. <br />&nbsp;&nbsp; e. The recipient refuses to receive
        the product. <br />&nbsp;&nbsp; f. If delivery takes place at the
        Reception/Gate because of recipients security issues. <br />
        11. Any complaint pertaining to the ordered product must be communicated
        with in 3 hours of delivery. <br />
        12. Proof of delivery shall be available for a period of 15 days and can
        be shared in case of any dispute. <br />
        13. Orders can be cancelled at least 1 day prior to the delivery date by
        writing a mail to info@1bakeshop.com
      </p>
    </div>
  </div> -->
</div>
<div class="floating-cancel-btn">
  <p style="text-align: center">
    <a style="
        text-align: center;
        text-decoration: none;
        padding: 10px 12px;
        border-radius: 60px;
        color: #ffffff;
        background: #e91e63;
        font-size: 10px;
      " data-popup-close="popup-1" (click)="close()"><i _ngcontent-qfl-c432="" class="fa fa-close"></i></a>
  </p>
</div>