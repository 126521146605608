import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { Product } from "src/app/shared/models/product";
import { ProductThumbnail } from "src/app/shared/models/product-thumbnail";
import { ProductService } from 'src/app/shared/services/product.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { getWindow } from 'ssr-window';

@Component({
  selector: 'app-product-design-dialog',
  templateUrl: './product-design-dialog.component.html',
  styleUrls: ['./product-design-dialog.component.scss']
})
export class ProductDesignDialogComponent implements OnInit {

  currentUrl: string;
  serverUrl: string;
  designProducts: Product[] = [];
  selectedProductCode: number;
  isproductSelected: boolean = false;
  existingProductSelected: Product = new Product();
  designProductSelected: Product = new Product();

  images = [
    { url: 'https://1bakeshop.com/mobitrixcartserver/product/Enchanted%20Rainbow%20(M).webp' },
    { url: 'https://1bakeshop.com/mobitrixcartserver/product/Bouquet%20of%20Love%20(M).webp' },
    { url: 'https://1bakeshop.com/mobitrixcartserver/product/Golden%20Gala%20(M).webp' },
    // Add more image objects as needed
  ];
  currentIndex = 0;
  private intervalId: any; // Variable to hold the interval ID

  private getDesignSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ProductDesignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public productValue: Product[],
    private productService: ProductService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname;
    this.serverUrl = this.apiFactory.getServerUrl;
    // this.serverUrl = "https://1bakeshop.com/mobitrixcartserver/";
    console.log(this.productValue);
    if (this.productValue.length > 0) {
      this.existingProductSelected = this.productValue.filter(e => e.is_Default == true)[0];
      this.onProductClick(false, this.existingProductSelected);
    }
    // this.getDesignBySubGroupAndUrl();
  }

  ngOnDestroy(): void {
    this.stopAutoSlide(); // Clear the interval when the component is destroyed
  }

  startAutoSlide() {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.designProductSelected.productThumbnails.length;
    }, 2000); // 2 seconds
  }

  stopAutoSlide() {
    clearInterval(this.intervalId); // Clear the interval to stop auto-sliding
  }

  getDesignBySubGroupAndUrl() {
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, this.productValue[0].productSubGroup.refNo, this.currentUrl).subscribe((data) => {
      console.log(data);
      if (data.length > 0) {
        this.designProducts = data;
      } else {
        this.toastrService.showErrorToast('No design available');
      }
    });
  }

  onProductClick(event: boolean, product: Product): void {
    this.stopAutoSlide();
    if (product.productThumbnails.length > 0) {
      this.startAutoSlide();
      if (!product.is_Default_Design) {
        let thumbnail: ProductThumbnail = new ProductThumbnail();
        thumbnail.productCode = product.product_Code;
        thumbnail.fileName = product.product_Picture;
        thumbnail.fileType = "image";
        product.is_Default_Design = true;
        product.productThumbnails.splice(0, 0, thumbnail);
      }
    }
    console.log(product);
    if (event == false) {
      this.selectedProductCode = product.product_Code;
      this.designProductSelected = product;
      this.isproductSelected = true;
    }
    else {
      this.isproductSelected = false;
      this.selectedProductCode = product.product_Code;
    }
    // product.designForProductCode = this.productValue.product_Code;
    // product.cartProductType = "Product";
    // this.dialogRef.close({ data: product });
  }

  onConfirmClick(): void {
    console.log(this.designProductSelected);
    if (this.isproductSelected) {
      this.dialogRef.close({ data: this.designProductSelected });
    } else {
      this.dialogRef.close({ noDesign: 'No Design' });
      // this.toastrService.showErrorToast('Select one design');
    }
  }

  onNoDesignClick() {
    this.dialogRef.close({ noChange: 'No Change' });
    // if (this.isproductSelected) {
    //   this.dialogRef.close({ data: this.existingProductSelected });
    // } else {
    //   this.dialogRef.close({ noDesign: 'No Design' });
    // }
  }

  public hideRuleContent: boolean[] = [];
  lastIndex: number = 0;
  toggle(index) {
    // toggle based on index
    if (index != this.lastIndex)
      this.hideRuleContent[this.lastIndex] = false;
    this.hideRuleContent[index] = !this.hideRuleContent[index];
    this.lastIndex = index;
  }

}
