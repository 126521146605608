// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: "AIzaSyBOiJv_4fnDE_LXb3UKWO792_wt3YDYrfo",
    authDomain: "mobitrix-web.firebaseapp.com",
    databaseURL: "https://mobitrix-web.firebaseio.com",
    projectId: "mobitrix-web",
    storageBucket: "mobitrix-web.appspot.com",
    messagingSenderId: "1027135256757",
    appId: "1:1027135256757:web:2ad132193392079f8b66fc",
    vapId:"BKElmlxJSoDV_HhCqVJgr-AgswBw3nanp4O-reMl1aunVZItYCsQ5A4GIfbxj_qjLgkenNwnFZ_8zhU2OKzcCVs",
  },
  serviceWorkerScript: "src/firebase-messaging-sw.js",
  // version: require("../../package.json").version,
  production: true,
  cartApiUrl: "/mobitrixcartserver/",
  apiUrl: '/mobitrixserver/',
  oldApiUrl: '/mobitrixserverold/',
  googleClientId:"397301806852-9ljpp195h1oe69ef4tgehklh7u3lvrvu.apps.googleusercontent.com",

  redirectUri: "https://1bakeshop.com/implicit/callback",
  googleRedirectUrl:"https://1bakeshop.com"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
