import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { NameWithIdDto } from "src/app/shared/models/namewithiddto";
import { State } from "src/app/shared/models/state";
import { StoreService } from "src/app/shared/services/store.service";
import { FileService } from "src/app/common/File-Service";
import { ViewChild } from "@angular/core";
import { LocationDetailsForStore } from "src/app/shared/models/locationdetailsforstore";
import { CityWithPinPositionDto } from "src/app/shared/models/citywithpinpositiondto";
import { LocationDetailsDto } from "src/app/shared/models/locationdetailsdto";
import { MapsAPILoader } from "@agm/core";
import { ElementRef } from "@angular/core";
import { NgZone } from "@angular/core";
import { ContactService } from "src/app/shared/services/contact.service";
import { VerifyMobileNoDialogComponent } from "../../verify-mobile-no-dialog/verify-mobile-no-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { CustomerB2BDetails } from "src/app/shared/models/customer-b2-bdetails";
import { AddressService } from "src/app/shared/services/address.service";
import { LocationDto } from "src/app/shared/models/locationdto";
import { CommonService } from "src/app/shared/services/CommonService";

@Component({
  selector: 'app-b2b-on-boarding',
  templateUrl: './b2b-on-boarding.component.html',
  styleUrls: ['./b2b-on-boarding.component.scss']
})
export class B2bOnBoardingComponent implements OnInit {

  b2bForm: UntypedFormGroup;
  googleCustomer: GoogleCustomer;
  selectedLocationOnMap: any;
  latitudeMap: number;
  longitudeMap: number;
  zoom: number;
  lastVerifiedMobileNo: string;
  addressMap: string;
  private geoCoder: any;
  positionArray: LocationDetailsForStore;
  k: number = 0;
  phoneNo: string = "";
  checkLoggedIn: string;
  currentUrl: string;

  districtList: NameWithIdDto[] = [];
  cityList: NameWithIdDto[] = [];
  stateList: State[] = [];
  gpsLong: number[];
  gpsLati: number[];
  mapPosition: any = [];
  positionSearch: CityWithPinPositionDto[] = [];
  locationDetailsList: LocationDetailsDto[] = [];

  isDisabledSubmit: boolean = false;
  isMobileNumberVerified: boolean = false;
  newDeliveryDetails: boolean = true;
  show: boolean = true;
  isShowOpenGoogle: boolean = false;
  otpVerficationStatus: string = 'No';

  @ViewChild("map", { static: true }) map: any;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  customerB2bDetails: CustomerB2BDetails = new CustomerB2BDetails();
  location: LocationDto = new LocationDto();

  //Subscription
  private stateSubcription: Subscription;
  private districtListSubscription: Subscription;
  private cityListSubscription: Subscription;
  private locationListSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;
  private insertSubscription: Subscription;
  private getLocationSubscription: Subscription;
  private getUserDetailSubscription: Subscription;

  constructor(
    private storeService: StoreService,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private fileService: FileService,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private contactService: ContactService,
    private addressService: AddressService,
    private userService: UserService,
    private commonService: CommonService,
    private localStorage: LocalStorageService,
  ) {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname
    this.createB2bForm();
    this.getStateList();
    this.getGoogleMap();
    this.getClientIP();
    this.getLocationByName();
  }

  createB2bForm() {
    this.b2bForm = this.formBuilder.group({

      name: [this.googleCustomer.firstName, Validators.required],
      email: [this.googleCustomer.email, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phoneNo: [this.googleCustomer.phoneNo, [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
      stateCode: ["", Validators.required],
      districtCode: ["", Validators.required],
      cityCode: ["", Validators.required],
      pincode: ["", Validators.required],
      address: ["", Validators.required],
      plot: ["", Validators.required],
      street: ["", Validators.required],
      landMark: [""],
      latitude: ["", Validators.required],
      longitude: ["", Validators.required],
      ipAddress: [null, Validators.required],
    });
  }

  getStateList() {
    this.stateList = this.fileService.getFileData("stateList");
  }

  getLocationByName() {
    let currentUrl = this.currentUrl == 'localhost' ? '1bakeshop.com' : this.currentUrl;
    this.getLocationSubscription = this.commonService.getLocationByName(this.apiFactory.getLocationByLocName, currentUrl).subscribe((data) => {
      if (data) {
        this.location = data;
      }
    });
  }

  onStateSelect(stateCode: number) {
    this.districtListSubscription = this.storeService.getDistrictListByStateCode(this.apiFactory.getDistrictListByStateCode, stateCode)
      .subscribe((data) => {
        this.districtList = data;
      });
  }

  onDistrictSelect(districtCode: number) {
    this.cityListSubscription = this.storeService.getCityListByDistrictCode(this.apiFactory.getCityListByDistrictCode, districtCode)
      .subscribe((data) => {
        this.cityList = data;

      });
  }

  // onLocationSelect(locationCode: number) {
  //   this.isShowOpenGoogle = false;
  //   this.locationListSubscription = this.storeService.getLocationByLocationCode(this.apiFactory.getLocationWithPositionByLocationCode, locationCode)
  //     .subscribe((data) => {
  //       // this.locList = data.details;
  //       this.locationDetailsList = data.locationDetails;
  //       this.positionArray = data;
  //       this.gpsLati = [];
  //       this.gpsLong = [];
  //       this.k = 0;
  //       for (let j = 0; j < this.locationDetailsList.length; j++) {
  //         if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
  //           this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
  //           this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
  //           this.k++;
  //         }
  //       }
  //       // this.onMapReady(this.map);
  //       this.show = true;
  //     });
  // }

  onB2bSaveClick() {
    console.log(this.b2bForm.value);
    if (this.b2bForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    // if (!this.isMobileNumberVerified) {
    //   this.toastService.showErrorToast("Mobile No not Verified");
    //   return;
    // }

    this.checkLoggedIn = this.userService.getCheckLoggedIn();

    this.customerB2bDetails = new CustomerB2BDetails();
    this.customerB2bDetails.customerName = this.b2bForm.get('name').value;
    this.customerB2bDetails.customerMobile = this.b2bForm.get('phoneNo').value;
    this.customerB2bDetails.longitude = this.b2bForm.get('longitude').value;
    this.customerB2bDetails.latitude = this.b2bForm.get('latitude').value;
    this.customerB2bDetails.shippingAddress = this.b2bForm.get('address').value;
    this.customerB2bDetails.stateCode = this.b2bForm.get('stateCode').value;
    this.customerB2bDetails.shippingCityCode = this.b2bForm.get('cityCode').value;
    this.customerB2bDetails.shippingLandMark = this.b2bForm.get('landMark').value;
    this.customerB2bDetails.shippingPinCode = this.b2bForm.get('pincode').value;
    this.customerB2bDetails.shippingPlotNo = this.b2bForm.get('plot').value;
    this.customerB2bDetails.shippingStreet = this.b2bForm.get('street').value;
    this.customerB2bDetails.b2bCustomer = false;
    this.customerB2bDetails.mapToLocation = this.location ? this.location.refNo : 1;
    this.customerB2bDetails.customerEmail = this.b2bForm.get('email').value;
    this.customerB2bDetails.minOrderValue = 0;
    this.customerB2bDetails.minDeliveryTime = '00:00';
    this.customerB2bDetails.maxDeliveryTime = '00:00';
    this.customerB2bDetails.discountPercentage = 0;
    this.customerB2bDetails.longitude = this.b2bForm.get('longitude').value;
    this.customerB2bDetails.latitude = this.b2bForm.get('latitude').value;
    this.customerB2bDetails.fromLocationCode = this.location?.refNo ?? 1;
    this.customerB2bDetails.source = this.checkLoggedIn == 'qr' ? 'QR' : 'Cart';
    this.customerB2bDetails.status = "Requested";
    this.customerB2bDetails.defaultAddress = true;

    this.insertSubscription = this.addressService.insertCustomerB2bDetails(this.apiFactory.insertB2bCustomerOnboardingDetails, this.customerB2bDetails).subscribe((data) => {
      if (data) {
        this.b2bForm.reset();
      }
    })
  }

  // markerList: Marker[] = [];
  onPositionClick(lat: any, long: any) {
    this.isShowOpenGoogle = true;
    console.log(this.isShowOpenGoogle)
    this.gpsLati = [];
    this.gpsLong = [];
    if (Number(long) != 0 && Number(lat) != 0) {
      this.gpsLati[0] = Number(lat);
      this.gpsLong[0] = Number(long);

      this.latitudeMap = Number(lat);
      this.longitudeMap = Number(long);
    }
    // this.onMapReady(this.map);
  }

  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitudeMap = place.geometry.location.lat();
          this.longitudeMap = place.geometry.location.lng();
          this.zoom = 12;

          this.getAddress(this.latitudeMap, this.longitudeMap);

          this.b2bForm.patchValue({
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
          });
        });
      });
    });
  }

  onSearch() {
    let window: any;
    console.log("clicked")
    // let latlong = "";
    // this.gpsLati.forEach((e, index) => {
    //   latlong = latlong + e + "," + this.gpsLong[index] + "&";
    // })
    // latlong = latlong.substring(0,latlong.length-1)
    // console.log(latlong)
    //window.open("https://www.google.com/maps/search/?api=1&query=" + this.latitudeMap + "," + this.longitudeMap);
  }

  mapReady(event: any) {
    this.map = event;
    const input = document.getElementById('Map-Search');
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('Logout'));
  }

  markerDragEnd(event: any) {
    this.latitudeMap = event.latLng.lat();
    this.longitudeMap = event.latLng.lng();
    this.getAddress(this.latitudeMap, this.longitudeMap);
    this.b2bForm.patchValue({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    });
  }

  fetchCurrentLoc() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 12;
        this.b2bForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 10;
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.toastService.clearAllToast();
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.addressMap = results[0].formatted_address;
            let addressComponents = results[0].address_components;
            let pincode = '';

            for (let i = 0; i < addressComponents.length; i++) {
              let types = addressComponents[i].types;
              if (types.includes('postal_code')) {
                pincode = addressComponents[i].long_name;
                //console.log(pincode)

                break;
              } else {
              }
            }
          } else {
            //window.alert("No results found");
          }
        } else {
          // window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }
  getClientIP() {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const ip = xhttp.responseText;
        console.log(ip);
        this.b2bForm.get('ipAddress').setValue(ip); // Set the value directly here
      }
    };
    xhttp.open("GET", "https://api.ipify.org", true); // Ensure the URL starts with https://
    xhttp.send();
    // var xhttp = new XMLHttpRequest();
    // xhttp.onreadystatechange = function () {
    //   if (this.readyState == 4 && this.status == 200) {
    //     console.log(this.responseText);
    //     return this.responseText;
    //   }
    // }; xhttp.open("GET", "//api.ipify.org", true);
    // xhttp.send();
  }

  checkOtp(value: string) {
    let ipAddress: string = this.b2bForm.get('ipAddress').value;
    this.isMobileNumberVerified = false;
    if (value.length == 10) {
      if (this.lastVerifiedMobileNo == value) {
        this.isMobileNumberVerified = true
      }
      this.checkNumberVerfiedSubscription = this.contactService.
        checkOTPSentOrNotWithIpAddress(this.apiFactory.checkOTPSentOrNotWithIpAddressforContactus, value, ipAddress).subscribe((data: string) => {
          this.otpVerficationStatus = data;
          if (data == "Yes") {
            this.isDisabledSubmit = true;
          }
          else if (data == "No") {
            this.isDisabledSubmit = false;
          } else {
            this.toastService.showErrorToast("Maximum number of b2b onboarding per system reached, try tomorrow.");
            this.isDisabledSubmit = false;
          }
        });

      //   if (this.phoneNo == value)
      //     this.isDisabledSubmit = true;
      //   else
      //     this.isDisabledSubmit = false;
      // } else
      //   this.isDisabledSubmit = false;
    } else {
      this.isDisabledSubmit = false;
      this.otpVerficationStatus = 'No';
    }
  }

  onVerifyClick() {
    if (this.b2bForm.get("phoneNo").invalid) {
      this.toastService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.b2bForm.get("phoneNo").value,
    });
    dialogRef.afterClosed().subscribe((resp) => {
      console.log("The dialog was closed", resp);
      this.isDisabledSubmit = resp;
      this.isMobileNumberVerified = resp.result;
      this.phoneNo = resp.mblNo
      if (resp.result) {
        this.lastVerifiedMobileNo = this.phoneNo;
      }
    });
  }
}

