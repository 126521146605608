import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-nearest-location-dialog',
  templateUrl: './nearest-location-dialog.component.html',
  styleUrls: ['./nearest-location-dialog.component.scss']
})
export class NearestLocationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NearestLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.data =[
    //   {
    //     "address": "Plot No. 85, Kalinga Vihar, Industrial Area, Bhagawanpur, Bhubaneswar, Odisha 751019",
    //     "status": "Active",
    //     "refNo": 37,
    //     "longitude": "85.75393749999999",
    //     "latitude": "20.2350625",
    //     "locationName": "Bhagabanpur",
    //     "pin": "751019"
    //   },
    //   {
    //     "address": "Plot-85/85A, Bhagbanpur Industrial Area",
    //     "status": "Active",
    //     "refNo": 1,
    //     "longitude": "85.7539864120947",
    //     "latitude": "20.235023167556495",
    //     "locationName": "1 Bake Shop",
    //     "pin": "751019"
    //   },{
    //     "address": "Plot No. 85, Kalinga Vihar, Industrial Area, Bhagawanpur, Bhubaneswar, Odisha 751019",
    //     "status": "Active",
    //     "refNo": 37,
    //     "longitude": "85.75393749999999",
    //     "latitude": "20.2350625",
    //     "locationName": "Bhagabanpur",
    //     "pin": "751019"
    //   },
    //   {
    //     "address": "Plot-85/85A, Bhagbanpur Industrial Area",
    //     "status": "Active",
    //     "refNo": 1,
    //     "longitude": "85.7539864120947",
    //     "latitude": "20.235023167556495",
    //     "locationName": "1 Bake Shop",
    //     "pin": "751019"
    //   }
    // ]
    
   }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }



}
