import { Component, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { ContactService } from "src/app/shared/services/contact.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { VerifyMobileNoDialogComponent } from "../verify-mobile-no-dialog/verify-mobile-no-dialog.component";
import { getWindow } from "ssr-window";
import { FeedbackSetupWebDto } from "src/app/shared/models/feedback-setup-web-dto";
import { FileService } from "src/app/common/File-Service";
import { MapsAPILoader } from "@agm/core";
import { CommonService } from "src/app/shared/services/CommonService";
import { LocationDto } from "src/app/shared/models/locationdto";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {

  createSetUpForm: UntypedFormGroup;
  uploadedFile: File;
  isDisabledSubmit: boolean = false;
  phoneNo: string = "";
  feedbackFilesList: File[] = [];
  feedbackSetupList: FeedbackSetupWebDto[] = [];
  feedbackTypeList: FeedbackSetupWebDto[] = [];
  feedbackSubList: FeedbackSetupWebDto[] = [];
  feedbackList: FeedbackSetupWebDto[] = [];
  location: LocationDto = new LocationDto();
  currentUrl: string;

  //Subscription
  private onCreateSetUpClickSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;
  private webOrderFeedbackSubcription: Subscription;
  private getLocationSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private contactService: ContactService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private fileService: FileService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    this.currentUrl = getWindow().location.hostname;
  }

  ngOnInit() {
    this.onCreateSetUp();
    this.getWebOrderFeedbackSetUpList();
    this.getLocationByName();
    this.getGoogleMap();
    this.getClientIP();
  }

  getLocationByName() {
    this.getLocationSubscription = this.commonService.getLocationByName(this.apiFactory.getLocationByLocName, this.currentUrl).subscribe((data) => {
      if (data) {
        this.location = data;
      }
    });
  }

  getWebOrderFeedbackSetUpList() {
    // this.webOrderFeedbackSubcription = this.contactService.getWebOrderFeedbackSetup(this.apiFactory.getFeedbackSetupDetails, this.currentUrl)
    //   .subscribe((data: FeedbackSetupWebDto[]) => {
    let data: FeedbackSetupWebDto[] = this.fileService.getFileData("feedBackSetupList");
    if (data.length > 0) {
      this.feedbackSetupList = data;
      this.feedbackSetupList.forEach(element => {
        if (!this.feedbackTypeList.some(e => e.grivenceTypeCode == element.grivenceTypeCode))
          this.feedbackTypeList.push(element);
      })
    } else {
      this.toastService.showErrorToast('No data available');
    }
    // });
  }

  onFeedbackTypeSelect(feedbackTypeCode: number) {
    this.feedbackSubList = [];
    this.feedbackList = [];
    this.createSetUpForm.get('subGrivenceTypeCode').reset();
    this.createSetUpForm.get('grivenceCode').reset();
    this.feedbackSetupList.forEach(p => {
      if (!this.feedbackSubList.map(g => g.subGrivenceTypeCode).includes(p.subGrivenceTypeCode) && p.grivenceTypeCode == feedbackTypeCode) {
        this.feedbackSubList.push(p);
      }
    });
  }

  onFeedbackSubTypeSelect(feedbackSubTypeCode: number) {
    this.feedbackList = this.feedbackSetupList.filter(e => e.subGrivenceTypeCode == feedbackSubTypeCode);
  }

  onFeedbackSelect(feedbackCode: number) {
    let grivence: FeedbackSetupWebDto = this.feedbackList.find(f => f.grivenceCode == feedbackCode);
    this.createSetUpForm.get('subject').setValue(grivence.grivenceTypeName + ' - ' + grivence.subGrivenceTypeName + ' - ' + grivence.grivenceName);
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phone: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}"), Validators.maxLength(10)]],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      grivenceTypeCode: ["", Validators.required],
      subGrivenceTypeCode: ["", Validators.required],
      grivenceCode: ["", Validators.required],
      image1: [],
      video1: [],
      fileImage1: [],
      fileVideo1: [],
      ipAddress: [null, Validators.required],
      source: [],
      fromLocationCode: [],
    });
  }

  getFileData(fileName: string) {
    if (!this.createSetUpForm.get(fileName).value) {
      return false;
    } else {
      return this.createSetUpForm.get(fileName).value;
    }
  }

  onChangeUploadFile(event: any, imageFile: string, image: string) {
    if (event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        if (file.size > 10485760)
          this.toastService.showErrorToast("10Mb Max File Size..");
        else {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (event) => {
            let result = (<FileReader>event.target).result;
            this.createSetUpForm.get(imageFile).setValue(result);
            this.createSetUpForm.get(image).setValue(file);
          }
        }
      }
    }
  }

  onRemoveFileClick(file: string, image: any) {
    this.createSetUpForm.get(image).reset();
    this.createSetUpForm.get(file).reset();
    const fileInputElement = document.getElementById(image) as HTMLInputElement;
    if (fileInputElement) {
      fileInputElement.value = ''; // Clear the file input value
    }
  }

  onCreateSetUpClick() {
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }

    if (!this.isDisabledSubmit && this.otpVerficationStatus == 'No') {
      this.toastService.showErrorToast("Verify WhatsApp No");
      return;
    }
    if (!this.isDisabledSubmit && this.otpVerficationStatus == 'Exceeds') {
      this.toastService.showErrorToast("Maximum number of franchise inquiries per system reached, try tomorrow.");
      return;
    }

    const imgFile: File = this.createSetUpForm.get('image1').value;
    const videoFile: File = this.createSetUpForm.get('video1').value;
    this.createSetUpForm.get('image1').setValue(null);
    this.createSetUpForm.get('video1').setValue(null);
    this.createSetUpForm.get('fileImage1').setValue(null);
    this.createSetUpForm.get('fileVideo1').setValue(null);
    this.createSetUpForm.get('source').setValue('Web');
    this.createSetUpForm.get('fromLocationCode').setValue(this.location?.refNo ?? 1);

    this.onCreateSetUpClickSubscription = this.contactService.sendMessage(this.apiFactory.insertFeedback, this.createSetUpForm, imgFile, videoFile)
      .subscribe((data) => {
        if (this.createSetUpForm.valid) {
          this.onCreateSetUpClickSubscription = this.contactService.sendMessageByMailChimpFromContact(
            this.apiFactory.sendMessageByMailChimpFromContact, this.createSetUpForm, imgFile, videoFile).subscribe((data) => {
              this.toastService.showSuccessToast("Thank you for contacting us. We shall assist you at the earliest.");
              this.isDisabledSubmit = false;
              this.createSetUpForm.reset();
              this.feedbackSubList = [];
              this.feedbackList = [];
              this.otpVerficationStatus = 'No';
              this.getClientIP();
              this.getGoogleMap();
            });
        }
      });

    // this.onCreateSetUpClickSubscription = this.contactService.onCreateSetUp(this.apiFactory.insertContact, this.createSetUpForm)
    //   .subscribe((data) => {
    //     if (this.createSetUpForm.valid) {
    //       this.onCreateSetUpClickSubscription = this.contactService.sendMessageByMailChimpFromContact(
    //         this.apiFactory.sendMessageByMailChimpFromContact, this.createSetUpForm, this.uploadedFile).subscribe((data) => {
    //           this.toastService.showSuccessToast(
    //             "Thank you for contacting us. We shall assist you at the earliest."
    //           );
    //           this.isDisabledSubmit = false;
    //           this.createSetUpForm.reset();
    //         });
    //     }
    //   });
  }

  getClientIP() {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const ip = xhttp.responseText;
        console.log(ip);
        this.createSetUpForm.get('ipAddress').setValue(ip); // Set the value directly here
      }
    };
    xhttp.open("GET", "https://api.ipify.org", true); // Ensure the URL starts with https://
    xhttp.send();
    // var xhttp = new XMLHttpRequest();
    // xhttp.onreadystatechange = function () {
    //   if (this.readyState == 4 && this.status == 200) {
    //     console.log(this.responseText);
    //     return this.responseText;
    //   }
    // }; xhttp.open("GET", "//api.ipify.org", true);
    // xhttp.send();
  }

  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.createSetUpForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }

  onVerifyClick() {
    if (this.createSetUpForm.get("phone").invalid) {
      this.toastService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.createSetUpForm.get("phone").value,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      this.isDisabledSubmit = result;
      this.phoneNo = result.mblNo;
    });
  }
  otpVerficationStatus: string = 'No';
  checkOtp(value: string) {
    let ipAddress: string = this.createSetUpForm.get('ipAddress').value;
    console.log(ipAddress)
    if (value.length == 10) {
      this.checkNumberVerfiedSubscription = this.contactService.checkOTPSentOrNotWithIpAddress(this.apiFactory.checkOTPSentOrNotWithIpAddressforContactus, value, ipAddress).subscribe((data: string) => {
        this.otpVerficationStatus = data;
        if (data == "Yes") {
          this.isDisabledSubmit = true;
        }
        else if (data == "No") {
          this.isDisabledSubmit = false;
        } else {
          this.toastService.showErrorToast("Maximum number of franchise inquiries per system reached, try tomorrow.");
          this.isDisabledSubmit = false;
        }
      });

      //   if (this.phoneNo == value)
      //     this.isDisabledSubmit = true;
      //   else
      //     this.isDisabledSubmit = false;
      // } else
      //   this.isDisabledSubmit = false;
    } else {
      this.isDisabledSubmit = false;
      this.otpVerficationStatus = 'No';
    }
  }
}
