import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { interval, Subscription, timer } from "rxjs";
import { map, takeWhile } from "rxjs/operators";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CustomerDTO } from "src/app/common/customerDto";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { IciciTransactionStatus } from "src/app/shared/models/icicitransactionstatus";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { UpiPaymentDetails } from "src/app/shared/models/upi-payment-details";
import { UpiPayment } from "src/app/shared/models/upipayment";
import { CartPaymentService } from "src/app/shared/services/cart-payment.service";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";

// type Post = { title: string, content: string }

@Component({
  selector: "app-payment-confirmation",
  templateUrl: "./payment-confirmation.component.html",
  styleUrls: ["./payment-confirmation.component.scss"],
})
export class PaymentConfirmationComponent implements OnInit {
  progressbarValue: number;
  upiPayment: UpiPayment = new UpiPayment();
  iciciTransactionStatus: IciciTransactionStatus;

  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];
  deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
  customerDTO: CustomerDTO = new CustomerDTO();
  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  googleCustomer: GoogleCustomer;

  in: any = null;

  responseNumber: number;

  gstSaleInvBillOfSupplay: GstSaleInvCumBosDto;

  private onUpiSetUpSubscription: Subscription;
  private onWebOrderInsertSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private sub;

  constructor(
    private localStorage: LocalStorageService,
    private productOrderSummaryService: ProductOrderSummaryService,
    private apiFactory: APIFactoryEndPoints,
    private navRoute: Router,
    private toastService: ToastService,
    private cartPaymentService: CartPaymentService,
    private userService: UserService
  ) { }
  //  interval:number;
  value: number = 0;

  ngOnInit() {
    // this.doWork();
    this.googleCustomer = JSON.parse(
      this.localStorage.getItem("google_loggedIn_details")
    );
    this.getWebOrders();

    this.click();
    // this.getUpiPayment();
  }

  getUpiPayment() {
    this.upiPayment = this.cartPaymentService.getUpiPayment();
    console.log(this.upiPayment);
  }

  getWebOrders1() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);

    this.gstSaleInvcumBOSArray =
      this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);
  }

  click() {
    // this.sub && this.sub.unsubscribe(); //to unsubscribe if not
    this.in = interval(4000).subscribe((x) => {
      this.upiPayment = this.cartPaymentService.getUpiPayment();
      console.log(this.upiPayment);
      this.onUpiSetUpSubscription = this.cartPaymentService
        .getUpiTransactionDetails(
          this.apiFactory.getUpiTransactionDetails,
          this.upiPayment.merchantTranId,
          this.upiPayment.locId
        )
        .subscribe((data) => {
          this.iciciTransactionStatus = data;
          console.log(this.iciciTransactionStatus);
          if (this.iciciTransactionStatus.status == "SUCCESS") {
            let locationCode: number = JSON.parse(
              this.localStorage.getItem("location_code")
            );
            let stateCode: number = JSON.parse(
              this.localStorage.getItem("state_code")
            );

            if (this.gstSaleInvcumBOS.details.length != 0) {
              let momentDate = new Date(
                this.gstSaleInvcumBOS.details[0].deliveryDate
              );
              let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
              this.gstSaleInvcumBOS.locationCode = locationCode;
              this.gstSaleInvcumBOS.deliveryDate = formattedDate;
              this.gstSaleInvcumBOS.deliveryTime =
                this.gstSaleInvcumBOS.details[0].deliveryTime;

              this.productOrderSummaryService.setWebOrder(
                this.gstSaleInvcumBOS
              );

              console.log(this.gstSaleInvcumBOS);
              this.deliveryAddress =
                this.gstSaleInvcumBOS.customerDeliveryAddress;

              this.customerDTO.customerName =
                this.gstSaleInvcumBOS.loginDeliveryAddress.name;
              this.customerDTO.customerMobile =
                this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
              this.customerDTO.customerEmail =
                this.gstSaleInvcumBOS.loginDeliveryAddress.email;
              this.customerDTO.stateCode = stateCode;
              this.customerDTO.fromLocationCode = locationCode;
              this.customerDTO.location.refNo = locationCode;

              this.gstSaleInvcumBOS.customer = this.customerDTO;

              this.gstSaleInvcumBOS.upipayment.amount =
                this.productOrderSummary.totalPrice;
              this.gstSaleInvcumBOS.upipayment.fromLocationCode = locationCode;
              let upiPaymentDetails = new UpiPaymentDetails();
              upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
              upiPaymentDetails.transStatus =
                this.iciciTransactionStatus.status;
              upiPaymentDetails.transDate =
                this.iciciTransactionStatus.txnInitDate;
              upiPaymentDetails.merchantTranId =
                this.iciciTransactionStatus.merchantTranId;
              // upiPaymentDetails.transAmt = this.productOrderSummary.totalPrice;
              // upiPaymentDetails.transStatus = "true";
              // upiPaymentDetails.transDate = "2022-06-17 15:09:33";
              // upiPaymentDetails.merchantTranId = "000034251617";
              this.gstSaleInvcumBOS.upipayment.upiPaymentDetails.push(
                upiPaymentDetails
              );

              this.onWebOrderInsertSubscription = this.cartPaymentService
                .insertWebOrder(
                  this.apiFactory.insertWebOrder,
                  this.gstSaleInvcumBOS
                )
                .subscribe((data) => {
                  console.log(data);
                  if (data != null) {
                    let id: number[] = [];
                    data.details.forEach((element) => {
                      if (element.productCode)
                        id.push(element.productCode);
                    });
                    this.cartSetUpSubscription = this.userService
                      .removeAllProductCart(
                        this.apiFactory.removeAllProductFromCart,
                        this.googleCustomer.email,
                        id
                      )
                      .subscribe((data: any) => {
                        console.log(data);
                        // window.location.reload();
                        this.navRoute.navigate(["/payment-success"], {
                          state: { data: "reload" },
                        });
                      });
                  } else {
                    this.toastService.showErrorToast("Server side error...");
                  }
                });
            }

            if (this.gstSaleInvcumBOSArray.length != 0) {
              this.gstSaleInvcumBOSArray.forEach((element) => {
                let momentDate = new Date(element.details[0].deliveryDate);
                let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
                element.deliveryDate = formattedDate;
                element.deliveryTime = element.details[0].deliveryTime;
                element.locationCode = locationCode;

                element.upipayment.amount = element.grandTotal;
                element.upipayment.fromLocationCode = locationCode;
                let upiPaymentDetails = new UpiPaymentDetails();
                upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
                upiPaymentDetails.transStatus =
                  this.iciciTransactionStatus.status;
                upiPaymentDetails.transDate =
                  this.iciciTransactionStatus.txnInitDate;
                upiPaymentDetails.merchantTranId =
                  this.iciciTransactionStatus.merchantTranId;
                // upiPaymentDetails.transAmt = element.grandTotal;
                // upiPaymentDetails.transStatus = "true";
                // upiPaymentDetails.transDate = "2022-06-17 15:09:33";
                // upiPaymentDetails.merchantTranId = "000034251617";
                element.upipayment.upiPaymentDetails.push(upiPaymentDetails);
              });
              this.deliveryAddress =
                this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

              this.customerDTO.customerName =
                this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
              this.customerDTO.customerMobile =
                this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
              this.customerDTO.customerEmail =
                this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
              this.customerDTO.stateCode = stateCode;
              this.customerDTO.fromLocationCode = locationCode;
              this.customerDTO.location.refNo = locationCode;

              this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

              console.log(this.gstSaleInvcumBOSArray);

              this.onWebOrderInsertSubscription = this.cartPaymentService
                .insertWebOrderArray(
                  this.apiFactory.insertWebOrderArray,
                  this.gstSaleInvcumBOSArray
                )
                .subscribe((data) => {
                  console.log(data);
                  if (data.length > 0) {
                    this.cartSetUpSubscription = this.userService
                      .emptyProductCart(
                        this.apiFactory.emptyProductCart,
                        this.googleCustomer.email
                      )
                      .subscribe((data: any) => {
                        console.log(data.Res);
                        // window.location.reload();
                        this.navRoute.navigate(["/payment-success"], {
                          state: { data: "reload" },
                        });
                      });
                  } else {
                    this.toastService.showErrorToast("Server side error...");
                  }
                });
            }

            // this.finishInterval();
            //Here Set MerchantTransId
            // this.gst.merchnatTranId = this.iciciTransactionStatus.merchantTranId;
            console.log(this.gstSaleInvcumBOS);
            //Here Insert WebOrder Api Call
            // this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder, this.webOrder).subscribe((data) => {
            //   this.responseNumber = data;
            //   console.log(this.responseNumber);
            // })
            // this.in.unsubscribe();
            // this.sub.unsubscribe();
            // this.onUpiSetUpSubscription.unsubscribe();
            // this.onWebOrderInsertSubscription.unsubscribe();
            // this.localStorage.removeItem("avct_item");

            // this.navRoute.navigate(["/payment-success"]);
          } else if (this.iciciTransactionStatus.status == "FAIL") {
            // this.webOrder.merchnatTranId = "TID000120210607T181322";
            console.log(this.gstSaleInvcumBOS);
            // this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiFactory.insertWebOrder,this.gstSaleInvcumBOS)
            //   .subscribe((data) => {
            //     this.gstSaleInvBillOfSupplay = data;
            //     console.log(this.gstSaleInvBillOfSupplay);
            //   });
            this.in.unsubscribe();
            this.sub.unsubscribe();
            this.onUpiSetUpSubscription.unsubscribe();
            // this.onWebOrderInsertSubscription.unsubscribe();
            // this.localStorage.removeItem("avct_item");
            this.navRoute.navigate(["/payment-fail"]);
          } else {
          }
        });
    });
    this.sub = this.interval(600000).subscribe((res) => {
      this.value = res;
    });

    setTimeout(() => {
      this.in.unsubscribe();
      this.sub.unsubscribe();
      this.onUpiSetUpSubscription.unsubscribe();
      this.navRoute.navigate(["/payment-fail"]);
    }, 600000);
  }
  interval(timeToWait) {
    const initial = new Date().getTime();
    return timer(0, 200).pipe(
      map(() => new Date().getTime()),
      takeWhile((res) => res <= initial + timeToWait, true),
      map((now) => {
        const porc = (100 * (now - initial)) / timeToWait;
        console.log(porc);
        return porc < 100 ? Math.round(porc) : 100;
      })
    );
  }

  finishInterval() {
    // setTimeout(()=>{ clearInterval(this.interval)}, 600000)
    this.sub.unsubscribe();
    this.onUpiSetUpSubscription.unsubscribe();
    this.onWebOrderInsertSubscription.unsubscribe();
  }

  // name = 'Angular';
  // posts: Observable<Post[]> = of([{ title: 'Simulating HTTP Requsts', content: 'This is off the hook!!' }]).pipe(delay(3000));
}
