import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DialogPosition, MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { AdvanceSearchDTO } from "src/app/shared/models/advance-search-dto";
import { ProductGroupWithIdAndName } from "src/app/shared/models/productgroupwithidandname";
import { ProductSubGroupDtoWithIdName } from "src/app/shared/models/productsubgroupdtowithidname";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { getWindow } from "ssr-window";
import { Product } from "../../../../shared/models/product";
import { AuthService } from "../../../../shared/services/auth.service";
import { ProductService } from "../../../../shared/services/product.service";
import { PinDialogComponent } from "../pin-dialog/pin-dialog.component";
import { ProductFilterComponent } from "../product-filter/product-filter/product-filter.component";
import { MatrixDto } from "src/app/shared/models/matrix-dto";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { ProductDesignDialogComponent } from "../product-design-dialog/product-design-dialog.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ProductsWithStock } from "src/app/shared/models/products-with-stock";
import { OfferDetailsDialogComponent } from "../offer-details-dialog/offer-details-dialog.component";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { OfferDialogComponent } from "../offer-dialog/offer-dialog.component";
import { BulkDiscountDto } from "src/app/shared/models/bulkdiscountdto";
import { Tax } from "src/app/shared/models/tax";
import { Cess } from "src/app/shared/models/cess";
import { FileService } from "src/app/common/File-Service";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductSubGroup } from "src/app/shared/models/productsubgroup";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { StorePickupDialogComponent } from "../store-pickup-dialog/store-pickup-dialog.component";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

  matrixList: MatrixDto[] = [];
  allGroupList: ProductGroupWithIdAndName[] = [];
  allSubGroupList: ProductSubGroup[] = [];
  allProductList: Product[] = [];

  productList: Product[] = new Array(1200);
  loading = false;
  webUrl: string;
  brands = ['All', 'Apple', 'Realme', 'Nokia', 'Motorolla'];
  selectedBrand: 'All';

  searchForm: UntypedFormGroup;
  createSetUpForm: UntypedFormGroup;
  groupList: ProductGroupWithIdAndName[] = [];
  subGroupList: ProductSubGroupDtoWithIdName[] = [];
  show: boolean = false;
  subGroupShow: boolean = false;
  pincode: string;
  productWithOffers: ProductsWithWebOrderOffers[];

  page = 1; //demo
  pageNo: number = 0;

  selfLife: any;
  disbleBtn: boolean = false;
  advanceSearchData: AdvanceSearchDTO = new AdvanceSearchDTO();
  companyCode: number;
  designProducts: Product[] = [];
  productWithStock: ProductsWithStock[] = [];

  groupClickId: number;
  subGroupClickId: number;

  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;
  offerObj: any;

  serverUrl: string;
  currentUrl: string;
  sharingUrl: string;

  // FILTER //

  offerControl = new UntypedFormControl();
  locationCode: number;
  minPrice: number;
  maxPrice: number;
  minDiscRate: number;
  maxDiscRate: number;
  min: number;
  max: number;
  offer: any;
  offerTypeArray: string[] = [];
  flavoursArray: string[] = [];
  foodTypes: string[] = ["Veg", "Non-Veg"];
  taxList: Tax[] = [];
  cessList: Cess[] = [];
  resultProductList: Product[] = [];
  values: string[] = [];
  foodType: string[] = [];
  openfilter: boolean = false;
  searchBtnForDisc: boolean = true;
  cleatBtnDisc: boolean = false;
  radioStatus: boolean;
  checkedStatus: boolean;
  checkedValues: string[] = [];
  selectedValues: string[] = [];
  category: string;
  code: number[] = [];
  productCode: number[] = [];
  filter: string;
  deliveryType: string;
  googleCustomer: GoogleCustomer;

  //Subscription
  private productSubcription: Subscription;
  private groupSubcription: Subscription;
  private subGroupSubscription: Subscription;
  private productSubscription: Subscription;
  private webOrderBestProductSubcription: Subscription;
  private offerSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private checkAllowStockSubscription: Subscription;
  private stockSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;
  private searchProductsByObjSub: Subscription;
  private getFoodTypeSubscription: Subscription;
  private productMinMaxPriceSubscription: Subscription;
  private promotionMasterSubscription: Subscription;
  private getTaxSubscription: Subscription;
  private getCessSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    public authService: AuthService,
    private productService: ProductService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private formBuilder: UntypedFormBuilder,
    private pincodeDialogService: PinDialogService,
    private fileService: FileService,
    private route: ActivatedRoute,
    private navRoute: Router,
  ) {
    this.serverUrl = this.apiFactory.getServerUrl;
    this.locationCode = JSON.parse(this.localStorage.getItem('location_code'));
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.sharingUrl = getWindow().location.origin + '/product' + '/';
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem('company_code'));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.route.queryParams.subscribe(params => {
      const hasCategory = 'category' in params;
      const hasCode = 'code' in params;
      const hasFilter = 'filter' in params;

      if (hasCategory || hasCode || hasFilter) {
        this.localStorage.removeItem('filterProductItems');
        this.getAllProducts();
        this.category = params['category'];
        this.code = params['code'] ? params['code'].split(',').map(Number) : []; // Convert to an array of numbers
        this.filter = params['filter'];

        if (this.filter != 'offers') {
          this.filterProducts();
        }
      } else {
        this.getAllProducts();
      }
    });
  }

  filterProducts(): void {
    this.productList = this.allProductList;
    this.groupClickId = null;
    this.subGroupClickId = null;


    if (this.code.length > 0) {
      if (this.category == 'Group') {
        this.productList = this.productList.filter(product =>
          this.code.includes(product.productSubGroup.productGroup.refNo)
        );
      } else if (this.category == 'Matrix') {
        this.productList = this.productList.filter(product =>
          this.code.includes(product.productSubGroup.productGroup.productMatrix.refNo)
        );
      }
    }
    if (this.filter) {
      this.productCode.push(...this.productList.map(product => product.product_Code));
      this.productList = this.applyIndexFilter(this.filter);
    }
    this.matrixList.forEach(e => e.collapsed = (this.productList.map(p => p.productSubGroup.productGroup.productMatrix.refNo).includes(e.refNo)));
    this.selectedFilters.matrixGroups = [];
    this.selectedFilters.groupSubGroups = [];
    this.productList.forEach(p => {
      if (!this.selectedFilters.matrixGroups.map(m => m).includes(p.productSubGroup.productGroup.refNo)) {
        this.selectedFilters.matrixGroups.push(p.productSubGroup.productGroup.refNo);
      }
      if (!this.selectedFilters.groupSubGroups.map(g => g.refNo).includes(p.productSubGroup.refNo)) {
        this.selectedFilters.groupSubGroups.push(p.productSubGroup);
      }
    });
    this.selectedFilters.showClearAll = true;
  }

  applyIndexFilter(filter: string): any[] {
    switch (filter) {
      case 'under220':
        this.isCollapsed['price'] = !this.isCollapsed['price'];
        this.selectedFilters.cleatBtnPrice = true;
        this.selectedFilters.priceRange = { min: 1, max: 220 };
        this.searchForm.get('minValue').setValue(this.selectedFilters.priceRange.min);
        this.searchForm.get('maxValue').setValue(this.selectedFilters.priceRange.max);
        return this.productList.filter(product => product.net_Sale_Price <= 220 && product.productSubGroup.productGroup.productMatrix.refNo === 4);
      case 'under400':
        this.isCollapsed['price'] = !this.isCollapsed['price'];
        this.selectedFilters.cleatBtnPrice = true;
        this.selectedFilters.priceRange = { min: 1, max: 400 };
        this.searchForm.get('minValue').setValue(this.selectedFilters.priceRange.min);
        this.searchForm.get('maxValue').setValue(this.selectedFilters.priceRange.max);
        return this.productList.filter(product => product.net_Sale_Price <= 400 && product.productSubGroup.productGroup.productMatrix.refNo === 4);
      case 'combos':
        return this.productList.filter(product => product.productSubGroup.productGroup.productMatrix.refNo === 4 || product.productSubGroup.productGroup.productMatrix.refNo === 38);
      case 'partybox':
        return this.productList.filter(product => product.productSubGroup.productGroup.productMatrix.refNo === 70);
      case 'bulkbuy':
        return this.productList.filter(product => product.productSubGroup.productSubGroupName === 'Bulk Buy');
      case 'offers':
        return this.productList = this.allProductList.filter(product => this.productCode.includes(product.product_Code))
      default:
        return this.productList;
    }
  }

  getMatrixList() {
    this.matrixList = this.fileService.getFileData("matrixList");
    this.allGroupList = this.fileService.getFileData("allGroupList");
    this.allProductList = this.fileService.getFileData("allProductList");

    let matrixId: number = JSON.parse(this.localStorage.getItem("matrix_code"));
    let groupId: number = JSON.parse(this.localStorage.getItem("prod_group_code"));
    this.groupClickId = matrixId;
    this.subGroupClickId = groupId;

    if (groupId) {
      this.getGroupByMatrixCode(matrixId);
      this.onGroupSelect(groupId);
    } else if (matrixId) {
      this.onMatrixSelect(matrixId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    // this.groupSubcription = this.productService.getMatrixList(this.currentUrl).subscribe((data) => {
    //   this.matrixList = data;
    // });
  }

  onMatrixSelect(matrixCode: number) {
    this.groupClickId = matrixCode;
    this.subGroupClickId = null;
    this.localStorage.setItem('matrix_code', JSON.stringify(matrixCode));
    this.localStorage.removeItem('prod_group_code');

    this.loading = true;
    this.productList = this.allProductList.filter(e => e.productSubGroup?.productGroup?.productMatrix?.refNo == matrixCode);
    this.show = true;
    this.loading = false;
    var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    this.getOfferDetails();

    // this.productSubscription = this.productService.getProductDetailsByMatrix(this.apiFactory.getProductDetailsByMatrixCode, matrixCode, this.currentUrl)
    //   .subscribe((data) => {
    //     this.productList = data;
    //     console.log(this.productList)
    //     this.productListForFilters = data;
    //     this.displayProduct = data;
    //     this.show = true;
    //     this.loading = false;
    //     var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    //     var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    //     console.log(`Max: ${Max}`);
    //     console.log(`Min: ${Min}`);
    //     this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    //     this.getOfferDetails();
    //   });

    this.getGroupByMatrixCode(matrixCode);
  }

  getGroupByMatrixCode(matrixCode: number) {
    this.groupList = this.allGroupList.filter(
      (e) => e.productMatrixCode == matrixCode
    );
    this.subGroupShow = true;

    // this.subGroupSubscription = this.productService.getGroupListByMatrix(this.apiFactory.getGroupDetailsByMatrix, this.currentUrl, matrixCode)
    //   .subscribe((data) => {
    //     this.groupList = data;
    //     this.subGroupShow = true;
    //   });
  }

  getGroupList() {
    this.selfLife = this.localStorage.getItem('min_selflife');
    let groupId: number = JSON.parse(
      this.localStorage.getItem('prod_group_code')
    );
    let subgroupId: number = JSON.parse(
      this.localStorage.getItem('prod_subgroup_code')
    );

    if (subgroupId != undefined) {
      this.getSubGroupByGroupCode(groupId);
      this.onSubGroupSelect(subgroupId);
    } else if (groupId != undefined) {
      this.onGroupSelect(groupId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    this.groupSubcription = this.productService
      .getGroupList(this.currentUrl)
      .subscribe((data) => {
        this.groupList = data;
      });
  }

  onGroupSelect(prodGroupId: number) {
    this.subGroupClickId = prodGroupId;
    this.localStorage.setItem('prod_group_code', JSON.stringify(prodGroupId));
    this.localStorage.removeItem('prod_subgroup_code');

    this.loading = true;
    this.show = true;
    this.productList = this.allProductList.filter(e => e.productSubGroup.productGroup.refNo == prodGroupId);
    this.show = true;
    this.loading = false;
    var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    console.log(`Max: ${Max}`);
    console.log(`Min: ${Min}`);
    this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    this.getOfferDetails();

    // this.productSubscription = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    //     this.productList = data;
    //     this.productListForFilters = data;
    //     this.displayProduct = data;
    //     this.show = true;
    //     this.loading = false;
    //     var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    //     var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    //     console.log(`Max: ${Max}`);
    //     console.log(`Min: ${Min}`);
    //     this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    //     this.getOfferDetails();
    //   });

    // this.getSubGroupByGroupCode(prodGroupId);
  }

  getSubGroupByGroupCode(prodGroupId: number) {
    this.subGroupSubscription = this.productService
      .getSubGroupDetailsByGroupCode(
        this.apiFactory.getSubGroupDetailsByGroupCode,
        prodGroupId
      )
      .subscribe((data) => {
        this.subGroupList = data;
        this.subGroupShow = true;
      });
  }

  onSubGroupSelect(prodSubGroupId: number) {
    this.localStorage.setItem(
      'prod_subgroup_code',
      JSON.stringify(prodSubGroupId)
    );

    this.loading = true;
    this.productSubscription = this.productService
      .getProductDetailsBySubGroupCode(
        this.apiFactory.getProductDetailsBySubGroupCode,
        prodSubGroupId
      )
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
        this.getOfferDetails();
      });
  }

  // getAllProducts() {
  //   this.loading = true;
  //   this.pageNo = this.pageNo + 1;
  //   this.productSubcription = this.productService
  //     .getProducts(this.pageNo)
  //     .subscribe((data) => {
  //       this.productList = data;
  //       this.productListForFilters = data;
  //       this.displayProduct = data;
  //       this.loading = false;
  //     });
  // }

  getWebOrderBestProductSetUpList() {
    this.webOrderBestProductSubcription = this.productService
      .getWebOrderBestProductSetup(
        this.apiFactory.getBestProducts,
        this.currentUrl
      )
      .subscribe((data) => {
        this.productList = data;
        this.show = true;
        this.loading = false;
        console.log(this.productList);
        // this.getOfferDetails();
      });
  }

  getOfferDetails() {
    let productId: number[] = this.allProductList.map(element => element.product_Code);
    if (productId.length === 0) return;

    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    const fetchOfferDetails = (locId: number) => {
      this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, productId, this.currentUrl)
        .subscribe((data: any) => {
          this.processOfferDetails(data);
        });
    };

    if (!type) {
      fetchOfferDetails(0);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
      .subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        let locId = 0;
        if (data === 'allowForWebsite') {
          locId = this.googleCustomer?.locationCode || 0;
        } else if (data === 'allowForLocation') {
          locId = JSON.parse(this.localStorage.getItem('location_code')) || 0;
        }
        fetchOfferDetails(locId);
      });
  }

  processOfferDetails(data: any) {
    this.productWithOffers = data;
    console.log(this.productWithOffers);

    this.offerTypeArray = [];

    if (this.productWithOffers.length > 0) {
      this.productWithOffers.forEach(element => {
        element.offerDetails.forEach(offerDetails => {
          if (!this.offerTypeArray.includes(offerDetails.schemeType)) {
            this.offerTypeArray.push(offerDetails.schemeType);
          }
        });
      });
    }

    [this.allProductList, this.productList].forEach(productList => {
      productList.forEach(element => {
        let arr = this.productWithOffers.filter(offer => offer.productCode === element.product_Code);
        if (arr.length !== 0) {
          element.offerDetails = arr[0].offerDetails;
          element.offers = arr[0].offers;
        }
      });
    });

    if (this.filter === 'offers') {
      this.isCollapsed['offer'] = !this.isCollapsed['offer'];
      this.selectedFilters.offers = ['Buy X get Y Free'];
      this.productList = this.allProductList.filter(product =>
        product.offerDetails?.some(offerDetails => offerDetails.schemeType === 'Buy X get Y Free')
      );
      this.productCode = this.productList.map(product => product.product_Code);
      if (this.category) {
        this.filterProducts();
      }

    }
    if (!this.filter && this.selectedFilters.offers.length > 0) {
      this.applyFilters();
      this.expandAndCollapseAllFilters();
    }
    console.log(this.productList);
  }

  specialProductCodes: number[] = [901, 902, 743, 744, 746, 747, 745, 742];
  addFavourite(product: Product) {
    if (this.specialProductCodes.includes(Number(product.product_Code))) {
      this.toastrService.showWarningToast('Coming soon.');
      return;
    }
    const a: Product[] = JSON.parse(this.localStorage.getItem('avf_item')) || [];
    product.cartProductType = 'Product';
    this.productService.addFavouriteProduct(product);
  }

  addToCart(product: Product, goToCartPage: boolean) {
    console.log(product);
    if (this.specialProductCodes.includes(Number(product.product_Code))) {
      this.toastrService.showWarningToast('Coming soon.');
      return;
    }
    this.openDeliveryTypeDialog(product, goToCartPage);
  }

  openDeliveryTypeDialog(product: Product, goToCartPage: boolean) {
    let deliveryType = JSON.parse(this.localStorage.getItem('delivery_type'));
    console.log(deliveryType)
    if (!deliveryType) {
      const dialogRef = this.dialog.open(StorePickupDialogComponent, {
        width: '300px',
        autoFocus: false,
        panelClass: 'store-dialog-container'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
        if (result) {
          if (result === 'storePickup') {
            this.setStorePickup(product, goToCartPage);
          } else if (result === 'delivery') {
            this.openPinDialog(product, goToCartPage);
          }
        }
      });
    } else {
      if (deliveryType === 'storePickup') {
        this.setStorePickup(product, goToCartPage);
      } else if (deliveryType === 'delivery') {
        this.openPinDialog(product, goToCartPage);
      }
    }
  }

  setStorePickup(product: Product, goToCartPage: boolean) {
    let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
    if (product.shelf_Life_In_Hours >= selfLife) {
      product.cartProductType = 'Product';
      this.getDesignBySubGroupAndUrl(product, goToCartPage);
    } else {
      this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
    }
  }

  openPinDialog(product: Product, goToCartPage: boolean) {
    this.pincode = this.localStorage.getItem('pincode_item');
    console.log(product);

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: '300px',
        // data: product,
        panelClass: 'pin-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          // this.getGroupList();
          let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = 'Product';
            // this.productService.addToCart(product);
            // this.getDesignBySubGroupAndUrl(product);
            this.getProductWithStock(product, goToCartPage);
          } else {
            this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          }
          this.getOfferDetails();
        }
      });
    } else {
      // this.getGroupList();
      let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = 'Product';
        // this.productService.addToCart(product);
        // this.getDesignBySubGroupAndUrl(product);
        this.getProductWithStock(product, goToCartPage);
      } else {
        this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      }
    }
  }

  getProductWithStock(product: Product, goToCartPage: boolean) {
    if (this.checkAllowStockSubscription) return;

    let locId = JSON.parse(this.localStorage.getItem('location_code'));
    if (locId) {
      this.checkAllowStockSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, locId, this.currentUrl)
        .subscribe((data: any) => {
          this.checkAllowStockSubscription = null;
          if (data) {
            if (product.product_Code) {
              this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, [product.product_Code], locId)
                .subscribe((data: any) => {
                  this.productWithStock = data;
                  var arr = this.productWithStock.filter((p) => p.product_Code == product.product_Code);
                  if (arr.length != 0) {
                    product.systemStock = arr[0].systemStock;
                  }
                  if (product.cartProductType == 'Product' && product.minimum_Order_Quantity > product.systemStock) {
                    this.toastrService.showErrorToast(product.product_Name + ' is out of stock, stock available ' + product.systemStock);
                    return;
                  }
                  this.getDesignBySubGroupAndUrl(product, goToCartPage);
                },
                  (error: HttpErrorResponse) => {
                    this.toastrService.showErrorToast('Something wrong..');
                  }
                );
            } else {
            }
          } else {
            this.getDesignBySubGroupAndUrl(product, goToCartPage);
          }
        },
          (error: HttpErrorResponse) => {
            this.toastrService.showErrorToast('Something wrong..');
          }
        );
    } else {
      this.toastrService.showErrorToast("Location can't place order");
    }
  }

  getDesignBySubGroupAndUrl(product: Product, goToCartPage: boolean) {
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem('avct_item')) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
        return;
      }
    }
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, product.productSubGroup.refNo, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data.length > 0) {
          this.designProducts = data;
          const cartProducts: Product[] = JSON.parse(this.localStorage.getItem('avct_item')) || [];
          let designIndex = cartProducts.findIndex((e) => e.designForProductCode === product.product_Code);
          if (designIndex >= 0) {
            this.designProducts.map((e) => (e.is_Default = false));
            this.designProducts.filter((item) => item.product_Code == cartProducts[designIndex].product_Code).map((e) => (e.is_Default = true));
          }
          this.openDesignDialog(product, this.designProducts, goToCartPage);
        } else {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null, goToCartPage);
          else {
            this.productService.addToCart(product);

            if (goToCartPage) {
              this.productService.navigateToCart(product);
            }
          }
          // this.toastrService.showErrorToast('No design available');
        }
      });
  }

  openDesignDialog(product: Product, designProducts: Product[], goToCartPage: boolean) {
    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: '430px',
      height: '410px',
      maxWidth: '90vw',
      autoFocus: false,
      data: designProducts,
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      if (result) {
        console.log(result.data);
        console.log(result.noDesign);
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = 'Product';
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, design, goToCartPage);
          else {
            this.productService.addToCartDesign(product, design);

            if (goToCartPage) {
              this.productService.navigateToCart(product);
            }
          }
        } else if (result?.noDesign) {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null, goToCartPage);
          else {
            this.productService.addToCart(product);

            if (goToCartPage) {
              this.productService.navigateToCart(product);
            }
          }
        }
      }
    });
  }

  openOfferDialog(product: Product, design: Product, goToCartPage: boolean) {
    console.log(product);
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem('avct_item')) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      if (goToCartPage) {
        this.productService.navigateToCart(product);
      }
      else {
        this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
        return;
      }
    }
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    let appliedOfferId: string = product.offerId
      ? product.offerId
      : product.offerDetails[0].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: '500px',
      height: '300px',
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = offerObj.minQty;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
          if (product.cartProductType == 'Product') {
            product.basePremiumFlavourPrice = 0;
            product.premiumFlavourPrice = 0;
          }
        }
        console.log(product);

        // if (design) {
        //   this.productService.addToCartDesign(product, design);
        // }
        // else if (product.offerDetails[0].minQty > 1) {
        //   this.productService.addToCart(product);
        // }

        if (
          offerObj.schemeType == 'Buy 1 get 1 Free' ||
          offerObj.schemeType == 'Buy X get Y Free'
        ) {
          this.productOfferSubscription = this.productService
            .getProductOfferDetails(
              this.apiFactory.productOfferDetails,
              offerObj.offerId,
              orderQuantity
            )
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CART
                this.productOffer.product_Name = this.productOffer.product_Name
                  .concat('(')
                  .concat(offerObj.schemeType)
                  .concat(')');
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;

                // main product tax and net amount calculations
                product.discountAmt = 0;
                product.discountRate = 0;
                var taxableAmt =
                  product.sale_Price - (product.sale_Price * 0) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                console.log(product);
                console.log(this.productOffer);
                this.productService.addToCartWithOffer(product, this.productOffer, design);

                if (goToCartPage) {
                  this.productService.navigateToCart(product);
                }
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == 'Buy x get x percent off') {
            this.productDiscountSubscription = this.productService
              .getProductDiscountDetails(
                this.apiFactory.productDiscountDetails,
                offerObj.offerId,
                orderQuantity
              )
              .subscribe(
                (data) => {
                  console.log(data);
                  this.discountOffer = data;
                  product.discountAmt =
                    (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt =
                    product.sale_Price -
                    (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  this.productService.addToCartWithOffer(product, null, design);

                  if (goToCartPage) {
                    this.productService.navigateToCart(product);
                  }
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService
              .getProductBulkDiscountDetails(
                this.apiFactory.productBulkOfferDetails,
                offerObj.offerId
              )
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt =
                  (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt =
                  product.sale_Price -
                  (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithOffer(product, null, design);

                if (goToCartPage) {
                  this.productService.navigateToCart(product);
                }
              });
          }
        }

      } else {
        // if (design)
        //   this.productService.addToCartDesign(product, design);
        // else
        //   this.productService.addToCart(product);
      }
    });
  }

  // openPinDialog() {
  //   this.pincode = this.localStorage.getItem("pincode_item");

  //   if (this.pincode == null) {
  //     const dialogRef = this.dialog.open(PinDialogComponent, {
  //       width: "300px",
  //       // data: product,
  //       panelClass: "pin-dialog-container",
  //     });

  //     dialogRef.afterClosed().subscribe((result) => {
  //       if (result?.result == true) {
  //         this.getGroupList();
  //       }
  //     });
  //   } else {
  //     this.getGroupList();
  //   }
  // }

  openFilterDialog(): void {
    this.advanceSearchData.module = 'WebProduct';

    const dialogPosition: DialogPosition = {
      top: '250px',
      right: '0px',
    };
    const dialogRef = this.dialog.open(ProductFilterComponent, {
      width: '220px',
      position: dialogPosition,
      data: {
        advSearchInput: this.advanceSearchData,
        disbleBtn: this.disbleBtn,
      },
      panelClass: 'custom-container',
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data) {
        let list = data.resultProductList;
        if (list.length >= 0) {
          this.productList = list;
          this.getOfferDetails();
        }
        this.disbleBtn = data.disbleBtn;

        this.advanceSearchData = data.advSearchInput;
      }
    });
  }

  openDialog(product: Product) {
    this.pincode = this.localStorage.getItem('pincode_item');

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: '300px',
        data: product,
        panelClass: 'pin-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
      });
    } else {
      this.addToCart(product, false);
    }
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    console.log(offers);
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers },
    });

    dialogRef.afterClosed().subscribe((res) => { });
  }

  onShareClick(product: Product) {
    var url = 'https://web.whatsapp.com/send';
    var text =
      getWindow().location.origin + '/product' + '/' + product.product_Code;
    console.log(text);
    window.open(url + '?text=' + text);
  }

  getFoodType() {
    this.getFoodTypeSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getFoodType)
      .subscribe((data) => {
        if (data.length > 0) {
          this.foodTypes = data;
        }
      });
  }

  getAllTax() {
    this.getTaxSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllTax)
      .subscribe((data) => {
        if (data.length > 0) {
          this.taxList = data;
        }
      });
  }

  getAllCess() {
    this.getCessSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllCess)
      .subscribe((data) => {
        if (data.length > 0) {
          this.cessList = data;
        }
      });
  }

  //  FILTER   //
  selectedFilters = {
    priceRange: { min: 0, max: 1000 },
    flavours: [],
    foodTypes: [],
    offers: [],
    matrixGroups: [],
    groupSubGroups: [],
    showClearAll: false,
    cleatBtnPrice: false
  };

  isCollapsed = {
    price: true,
    flavour: true,
    foodType: true,
    offer: true
  };
  activeSort: string = '';

  getAllProducts() {
    this.matrixList = this.fileService.getFileData("matrixList");
    this.allGroupList = this.fileService.getFileData("allGroupList");
    this.allProductList = this.fileService.getFileData("allProductList");
    this.matrixList.forEach(e => e.groupList = this.allGroupList.filter((f) => f.productMatrixCode == e.refNo));
    this.allProductList.forEach(element => {
      if (!this.allSubGroupList.some(e => e.refNo == element.productSubGroup.refNo)) {
        this.allSubGroupList.push(element.productSubGroup)
      }
    });
    this.productList = this.allProductList;
    this.max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    this.min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.show = true;
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.searchFormSetUp();
    this.getOfferDetails();
    this.setLocalStorageData();
  }

  setLocalStorageData() {
    if (this.localStorage.getItem('filterProductItems')) {
      this.selectedFilters = JSON.parse(this.localStorage.getItem('filterProductItems'));
      this.searchForm.patchValue({ minValue: this.selectedFilters.priceRange.min, maxValue: this.selectedFilters.priceRange.max });
      if (this.selectedFilters.offers.length == 0) {
        this.applyFilters();
        this.expandAndCollapseAllFilters();
      }
    }
  }

  expandAndCollapseAllFilters() {
    if (this.selectedFilters.showClearAll) {
      this.selectedFilters.flavours.length > 0 ? this.toggleSection('flavour') : '';
      this.selectedFilters.foodTypes.length > 0 ? this.toggleSection('foodType') : '';
      this.selectedFilters.offers.length > 0 ? this.toggleSection('offer') : '';
      this.selectedFilters.priceRange.min != this.min || this.selectedFilters.priceRange.max != this.max ? this.toggleSection('price') : '';
      if (this.selectedFilters.groupSubGroups.length > 0)
        this.matrixList.forEach(e => e.collapsed = this.selectedFilters.groupSubGroups.some(f => f.productGroup.productMatrix.refNo == e.refNo));
    } else {
      this.isCollapsed.price = true;
      this.isCollapsed.flavour = true;
      this.isCollapsed.foodType = true;
      this.isCollapsed.offer = true;
      this.matrixList.forEach(e => e.collapsed = false);
    }
  }

  searchFormSetUp() {
    this.searchForm = this.formBuilder.group({
      minValue: [this.min],
      maxValue: [this.max]
    });
  }

  onFilterChange(type: string, value: string, event: any) {
    if (event.target.checked) {
      this.selectedFilters[type].push(value);
    } else {
      const index = this.selectedFilters[type].indexOf(value);
      if (index > -1) {
        this.selectedFilters[type].splice(index, 1);
      }
    }
    this.applyFilters();
  }

  validateMinMax(value: string, type: string) {
    if (type === 'min' && +value > +this.searchForm.get('maxValue').value) {
      this.searchForm.get('minValue').setValue(+this.searchForm.get('maxValue').value - 1);
    } else if (type === 'max' && +value < +this.searchForm.get('minValue').value) {
      this.searchForm.get('maxValue').setValue(+this.searchForm.get('minValue').value + 1);
    }
    this.selectedFilters.cleatBtnPrice = false;
  }

  validate(callOrigin: string, key: string) {
    if (callOrigin === 'price') {
      if (Number.parseInt(this.searchForm.get('minValue').value) > Number.parseInt(key)) {
        key = (Number.parseInt(this.searchForm.get('minValue').value) + 1).toString();
        this.searchForm.get('maxValue').setValue(key);
      }
    }
  }

  applyPriceInput(type: string) {
    if (type === 'price') {
      this.selectedFilters.priceRange.min = this.searchForm.get('minValue').value;
      this.selectedFilters.priceRange.max = this.searchForm.get('maxValue').value;
      this.selectedFilters.cleatBtnPrice = true;
      this.applyFilters();
    }
  }

  clearPriceInput() {
    this.searchForm.get('minValue').reset();
    this.searchForm.get('maxValue').reset();
    this.selectedFilters.cleatBtnPrice = false;
    this.searchForm.patchValue({ minValue: this.min, maxValue: this.max });
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.applyFilters();
  }

  onOfferCheckBoxClick(event: boolean, value: string) {
    if (event) {
      this.selectedFilters.offers.push(value);
    } else {
      const index = this.selectedFilters.offers.indexOf(value);
      if (index > -1) {
        this.selectedFilters.offers.splice(index, 1);
      }
      this.removeRelatedGroups(value);
    }
    this.applyFilters();
  }

  removeRelatedGroups(value: string) {
    const affectedProducts = this.allProductList.filter(product =>
      product.offerDetails?.some(offerDetails => offerDetails.schemeType === value)
    );

    const affectedGroups = new Set(affectedProducts.map(p => p.productSubGroup.productGroup.refNo));
    const affectedSubGroups = new Set(affectedProducts.map(p => p.productSubGroup.refNo));

    this.selectedFilters.matrixGroups = this.selectedFilters.matrixGroups.filter(g => !affectedGroups.has(g));
    this.selectedFilters.groupSubGroups = this.selectedFilters.groupSubGroups.filter(g => !affectedSubGroups.has(g.refNo));
  }

  onFoodTypeCheckBoxClick(event: boolean, value: string) {
    if (event) {
      this.selectedFilters.foodTypes.push(value);
    } else {
      const index = this.selectedFilters.foodTypes.indexOf(value);
      if (index > -1) {
        this.selectedFilters.foodTypes.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  onGroupChange(event: boolean, groupCode: number) {
    if (event) {
      this.selectedFilters.matrixGroups.push(groupCode);
    } else {
      const index = this.selectedFilters.matrixGroups.indexOf(groupCode);
      if (index > -1) {
        this.selectedFilters.matrixGroups.splice(index, 1);
      }
    }
    let existingSubGroups = this.selectedFilters.groupSubGroups.map(obj => obj.refNo);
    this.selectedFilters.groupSubGroups = this.allSubGroupList.filter(
      f => this.selectedFilters.matrixGroups.includes(f.productGroup.refNo) && (f.productGroup.refNo == groupCode || existingSubGroups.includes(f.refNo)));
    this.applyFilters();
  }

  applyFilters() {
    this.selectedFilters.showClearAll = (this.selectedFilters.cleatBtnPrice || this.selectedFilters.flavours.length > 0 || this.selectedFilters.foodTypes.length > 0 || this.selectedFilters.offers.length > 0 || this.selectedFilters.matrixGroups.length > 0 || this.selectedFilters.groupSubGroups.length > 0)
    this.productList = this.allProductList.filter(product => {
      const matchesPrice = product.net_Sale_Price >= this.selectedFilters.priceRange.min && product.net_Sale_Price <= this.selectedFilters.priceRange.max;
      const matchesFlavour = this.selectedFilters.flavours.length === 0 || this.selectedFilters.flavours.includes(product.composition);
      const matchesFoodType = this.selectedFilters.foodTypes.length === 0 || this.selectedFilters.foodTypes.includes(product.food_Type);
      const matchesOffer = this.selectedFilters.offers.length === 0 || product.offerDetails?.some(offerDetails => this.selectedFilters.offers.includes(offerDetails.schemeType));
      const matchesMatrixGroup = this.selectedFilters.matrixGroups.length === 0 || this.selectedFilters.matrixGroups.includes(product.productSubGroup.productGroup.refNo);
      const matchesGroupSubGroup = this.selectedFilters.groupSubGroups.length === 0 || this.selectedFilters.groupSubGroups.map(e => e.refNo).includes(product.productSubGroup.refNo);
      return matchesPrice && matchesFlavour && matchesFoodType && matchesOffer && matchesMatrixGroup && matchesGroupSubGroup;
    });
    this.localStorage.setItem('filterProductItems', JSON.stringify(this.selectedFilters));
  }

  toggleSection(section: string): void {
    this.isCollapsed[section] = !this.isCollapsed[section];
  }

  removeSubGroupFilter(subGroupCode: number): void {
    // Remove the selected subgroup
    this.selectedFilters.groupSubGroups = this.selectedFilters.groupSubGroups.filter(group => group.refNo !== subGroupCode);

    // Update the selected matrix groups based on remaining subgroups
    // this.updateGroupCheckboxes();

    this.selectedFilters.matrixGroups = this.selectedFilters.matrixGroups.filter(
      group => this.selectedFilters.groupSubGroups.map(sub => sub.productGroup.refNo).includes(group));

    // Apply filters after removal
    this.applyFilters();
  }

  updateGroupCheckboxes(): void {
    // Create a set to hold groups with remaining subgroups
    const updatedMatrixGroups = new Set<number>();

    // Iterate over each matrix and group list
    this.matrixList.forEach(matrix => {
      matrix.groupList.forEach(group => {
        // If any subgroup in selectedFilters is part of this group, add it to the set
        if (this.selectedFilters.groupSubGroups.some(subGroup => subGroup.productGroup.refNo === group.refNo)) {
          updatedMatrixGroups.add(group.refNo);
        }
      });
    });

    // Update the selected matrix groups
    this.selectedFilters.matrixGroups = Array.from(updatedMatrixGroups);
  }

  isFilterChecked(type: string, value: any) {
    return this.selectedFilters[type].includes(value);
  }

  clearAllFilters(): void {
    this.selectedFilters = {
      priceRange: { min: 0, max: 1000 },
      flavours: [],
      foodTypes: [],
      offers: [],
      matrixGroups: [],
      groupSubGroups: [],
      showClearAll: false,
      cleatBtnPrice: false
    };
    this.selectedFilters.cleatBtnPrice = false;
    this.searchForm.patchValue({ minValue: this.min, maxValue: this.max });
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.applyFilters();
    this.expandAndCollapseAllFilters();
  }

  sortProducts(criteria: string) {
    this.activeSort = criteria;
    if (criteria === 'lowToHigh') {
      this.productList.sort((a, b) => a.net_Sale_Price - b.net_Sale_Price);
    } else if (criteria === 'highToLow') {
      this.productList.sort((a, b) => b.net_Sale_Price - a.net_Sale_Price);
    } else if (criteria === 'recommended') {
      // Implement your recommended sorting logic here
    }
    const sortData = [];
    this.productList.forEach(e => {
      sortData.push(e)
    })
    this.productList = sortData;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewport.checkViewportSize();
  }
}
