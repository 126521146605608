export class WebOrderDeliveryCheckDto {

    deliveryDate: string;
    deliverySlot: string;
    maxServices: number;
    totalOrder: number;
    status: string;
    url: string;
    locId: number;
    deliveryDates: string[] = [];
    deliverySlots: string[] = [];
    deliveryDateTime: string[] = [];
}
