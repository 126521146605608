<form [formGroup]="createPhoneSetUpForm" (ngSubmit)="onPhoneSetUpClick()">
  <div class="section-heading">
    <h5>Verify WhatsApp No</h5>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 13%; margin-right: 13%; margin-top: 2%">
    <mat-card class="contain_card" fxFlex="33">
      <mat-form-field class="example-full-width">
        <!-- <input required matInput maxlength="10" placeholder="WhatsApp No. For Communication"
          formControlName="phoneNo" /> -->
        <input type="tel" matInput maxlength="10" placeholder="WhatsApp Number" formControlName="phoneNo"
          (keypress)="validateNumber($event)" (keyup)="checkMobileNo($any($event.target).value)" required />
      </mat-form-field>
    </mat-card>
  </div>

  <div *ngIf="showHideSendOtpDiv" fxLayout="row" fxLayout.lt-sm="column" class="btn_div">
    <button *ngIf="isShowWait" type="button" style="margin-top: 10px;" id="compose-button" id="send-button"
      class="btn btn-primary" (click)="sendOtpClick()">
      SEND OTP
    </button>
    <button *ngIf="!isShowWait" type="button" style="margin-top: 10px;" id="compose-button" id="send-button"
      class="btn btn-primary">
      WAIT..
    </button>
  </div>

  <div *ngIf="showHideOtpDiv" fxLayout="row" fxLayout.lt-sm="column"
    style="margin-left: 13%; margin-right: 13%; margin-top: 2%">
    <mat-card class="contain_card" fxFlex="33">
      <mat-form-field class="example-full-width">
        <input required matInput maxlength="6" placeholder="Enter Otp" (keyup)="checkOtp($any($event.target).value)" />
      </mat-form-field>
    </mat-card>
    <div style="margin-top: 5px;margin-bottom: 5px;text-align: center;font-size: 11px;">
      Didn't Receive OTP?
      <span style="text-align: center; color: #4285f4; cursor: pointer" (click)="reSendOtpClick()">RESEND OTP</span>
    </div>
  </div>

  <div *ngIf="showHideSubmitDiv" fxLayout="row" fxLayout.lt-sm="column" class="btn_div">
    <!-- <div fxLayout="row" fxLayout.lt-sm="column" class="btn_div"> -->
    <button type="submit" style="margin-top: 10px;" id="compose-button" id="send-button" class="btn btn-primary">
      PROCEED
    </button>
  </div>
</form>