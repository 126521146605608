import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { CouponDto } from 'src/app/shared/models/coupon-dto';
import { FBCustomer } from 'src/app/shared/models/fbcustomer';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';
import { RedeemableProductDetailsDto } from 'src/app/shared/models/redeemable-product-details-dto';
import { MyCouponService } from 'src/app/shared/services/my-coupon.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { NearestLocationDialogComponent } from '../nearest-location-dialog/nearest-location-dialog.component';
import { AgreedTermsDialogComponent } from '../agreed-terms-dialog/agreed-terms-dialog.component';
import { WebSocketService } from 'src/app/common/WebSocketService';

@Component({
  selector: 'app-my-coupon',
  templateUrl: './my-coupon.component.html',
  styleUrls: ['./my-coupon.component.scss']
})
export class MyCouponComponent implements OnInit {

  googleCustomer: GoogleCustomer;
  fbCustomer: FBCustomer;
  couponList: CouponDto[] = [];
  couponHistoryList: CouponDto[] = [];
  originalCouponList: CouponDto[] = [];
  redeemableProductDetailsDto: RedeemableProductDetailsDto[] = [];
  qrData: string;
  locationList: any[] = [];
  latitudeMap: number | null = null;
  longitudeMap: number | null = null;
  isAgreedTerms: boolean = false;
  wait: boolean = false;
  activeSort: string = '';
  homeCount: number = 0;
  redeemedCount: number = 0;
  expiredCount: number = 0;

  //Subscription
  private onViewSetUpSubscription: Subscription;
  private getCouponHistorySubscription: Subscription;
  private getDynamicCouponCodeSubscription: Subscription;
  private getLocationByLatAndLngSubscription: Subscription;
  private getCouponTermsAgreedSubscription: Subscription;
  private onRatingSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private myCouponService: MyCouponService,
    private apiFactory: APIFactoryEndPoints,
    public dialog: MatDialog,
    private router: Router,
    private toastService: ToastService,
    private webSocketService: WebSocketService
  ) {
  }

  ngOnInit(): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.fbCustomer = JSON.parse(this.localStorage.getItem("fb_loggedIn_details"));
    if (this.googleCustomer == null) {
      this.router.navigate(["/login", "qr"]);
      return;
    }
    this.getCouponTermsAgreed();
    this.setCurrentLocation();
    this.subscribeToNotifications('my-coupon-' + this.googleCustomer.email);
  }

  getCouponTermsAgreed() {
    this.getCouponTermsAgreedSubscription = this.myCouponService.getCouponTermsAgreed(this.apiFactory.getCouponTermsAgreed, this.googleCustomer.email)
      .subscribe((data: boolean) => {
        console.log(data);
        this.isAgreedTerms = data;
        if (!data) {
          this.openPrivacyPolicyDialog();
        } else {
          this.viewCouponDetails();
          this.getCouponHistory();
        }
      });
  }

  viewCouponDetails() {
    this.wait = false;
    var customerEmail: string;
    if (this.googleCustomer != null) {
      customerEmail = this.googleCustomer.email;
    } else {
      customerEmail = this.fbCustomer.email;
    }

    this.onViewSetUpSubscription = this.myCouponService.getCouponDetailsByLoggedInUser(this.apiFactory.getCouponDetails, customerEmail)
      .subscribe((data: CouponDto[]) => {
        this.wait = true;
        this.couponList = data;
        this.couponList.forEach(e => e.qrData = e.couponCode);
        this.originalCouponList = [...this.couponList];
        this.homeCount = this.couponList.length;
      });
  }

  getCouponHistory(status?: string) {
    this.wait = false;
    var customerEmail: string;
    if (this.googleCustomer != null) {
      customerEmail = this.googleCustomer.email;
    } else {
      customerEmail = this.fbCustomer.email;
    }

    this.getCouponHistorySubscription = this.myCouponService.getCouponDetailsByLoggedInUser(this.apiFactory.getCouponHistory, customerEmail)
      .subscribe((data: CouponDto[]) => {
        this.wait = true;
        this.couponHistoryList = data;
        this.couponHistoryList.forEach(e => e.qrData = e.couponCode);
        if (status === 'redeemed') {
          this.couponList = this.couponHistoryList.filter(coupon => coupon.status === 'Redeemed');
          // Ensure rating is set and determine if rating has been submitted
          this.couponList.forEach(coupon => {
            coupon.rating = coupon.rating || 0; // Default to 0 if no rating
            coupon.isRated = coupon.rating > 0; // If rating exists, set isRated to true
          });
          this.originalCouponList = [...this.couponList];
          this.redeemedCount = this.couponList.length;
        } else if (status === 'expired') {
          this.couponList = this.couponHistoryList.filter(coupon => coupon.status === 'Expired');
          this.originalCouponList = [...this.couponList];
          this.expiredCount = this.couponList.length;
        } else {
          this.redeemedCount = this.couponHistoryList.filter(coupon => coupon.status === 'Redeemed').length;
          this.expiredCount = this.couponHistoryList.filter(coupon => coupon.status === 'Expired').length;
        }
      });
  }

  activeTab: string = 'home';
  filterCoupons(tab: string) {
    this.activeTab = tab;
    this.activeSort = '';
    if (tab === 'home') {
      this.viewCouponDetails();
    } else {
      this.getCouponHistory(tab);
    }
  }

  onRedeemClick(selectedCoupon: CouponDto) {
    if (this.activeTab !== 'expired') {
      // Close all other coupons
      this.couponList.forEach(coupon => {
        if (coupon !== selectedCoupon) {
          coupon.displayProducts = false;
        }
      });

      // Toggle the selected coupon
      selectedCoupon.displayProducts = !selectedCoupon.displayProducts;

      // If opening the coupon, update details
      if (selectedCoupon.displayProducts) {
        this.redeemableProductDetailsDto = selectedCoupon.productDetails;
        selectedCoupon.qrData = selectedCoupon.couponCode;
        selectedCoupon.selectedCoupon = selectedCoupon;
        selectedCoupon.currentPage = 1;
        selectedCoupon.itemsPerPage = 1;
        selectedCoupon.totalPages =
          this.activeTab === 'home' ? selectedCoupon.selectedCoupon.productDetails.length : 1;

        this.updatePaginatedProducts(selectedCoupon);
      }
    }
  }


  onQrBtnClick(coupon: CouponDto, product: RedeemableProductDetailsDto): void {
    // if (this.latitudeMap && this.longitudeMap) {
    this.getDynamicCouponCodeSubscription = this.myCouponService.getDynamicCouponCode(this.apiFactory.getDynamicCouponCode, coupon.couponCode)
      .subscribe((data: any) => {
        coupon.qrData = data + '&' + product.productCode;
        product.selected = true;

        // Reset other products
        coupon.productDetails
          .filter(e => e.productCode !== product.productCode)
          .forEach(f => {
            f.selected = false;
            clearInterval(f.interval); // Clear the timer for unselected products
            f.timer = 10; // Reset the timer
          });

        // Start timer for the selected product
        this.startTimer(coupon, product);
      });
    // } else {
    //   this.toastService.showErrorToast('Location not available. Please allow location access in your browser settings.');
    // }
  }

  onQRCodeClick(product: RedeemableProductDetailsDto): void {
    console.log(product)
    // Switch back to product view
    product.selected = false;
    clearInterval(product.interval); // Clear the timer
    product.timer = 10; // Reset the timer
  }

  startTimer(coupon: CouponDto, product: RedeemableProductDetailsDto): void {
    product.timer = 10;
    product.interval = setInterval(() => {
      if (product.timer > 0) {
        product.timer--;
      } else {
        // Auto switch back to product view
        clearInterval(product.interval);
        product.selected = false;
        product.timer = 10; // Reset timer for next selection
        coupon.qrData = coupon.couponCode; // Reset QR data
      }
    }, 1000);
  }

  convertTo12Hour(time: string): string {
    const [hours, minutes] = time.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const adjustedHours = hours % 12 || 12; // Convert 0 to 12
    return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
  }

  private setCurrentLocation(): void {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Permission granted and location retrieved
          this.latitudeMap = position.coords.latitude;
          this.longitudeMap = position.coords.longitude;
          const accuracy = position.coords.accuracy; // Accuracy in meters
          console.log('Latitude:', this.latitudeMap, 'Longitude:', this.longitudeMap, 'Accuracy:', accuracy, 'meters');
          // this.getLocationByLatAndLng();
        },
        (error) => {
          // Handle errors
          switch (error.code) {
            case error.PERMISSION_DENIED:
              this.toastService.showErrorToast('Location permission denied. Please allow location access in your browser settings.');
              break;
            case error.POSITION_UNAVAILABLE:
              this.toastService.showErrorToast('Location information is unavailable. Please try again later.');
              break;
            case error.TIMEOUT:
              this.toastService.showErrorToast('The request to get user location timed out. Please try again.');
              break;
            default:
              this.toastService.showErrorToast('An unknown error occurred. Please try again.');
              break;
          }
        },
        {
          enableHighAccuracy: true, // Request high accuracy mode
          timeout: 30000, // Set a timeout (in milliseconds)
          maximumAge: 0 // Do not use a cached position
        }
      );
    } else {
      this.toastService.showErrorToast('Geolocation is not supported by your browser.');
    }
  }

  getLocationByLatAndLng(coupon: CouponDto): void {
    if (this.latitudeMap && this.longitudeMap) {
      this.getLocationByLatAndLngSubscription = this.myCouponService.getLocationByLatAndLng(this.apiFactory.getNearestLocationsByLatAndLng, this.latitudeMap.toString(), this.longitudeMap.toString(), coupon.couponCode)
        .subscribe((data: any) => {
          console.log(data);
          if (data.length > 0) {
            // this.toastService.showSuccessToast('Welcome to ' + data.locationName + '!');
            this.locationList = data;
            this.onMapClick();
          } else {
            this.toastService.showErrorToast('Stores not found.');
            // this.latitudeMap = null;
            // this.longitudeMap = null;
          }
        });
    } else {
      this.toastService.showErrorToast('Location not available. Please allow location access in your browser settings.');
    }
  }

  onMapClick() {
    if (this.locationList.length > 0) {
      const dialogRef = this.dialog.open(NearestLocationDialogComponent, {
        width: '400px',
        height: '400px',
        autoFocus: false,
        panelClass: 'store-dialog-container',
        data: this.locationList
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog closed with result:', result);
      });
    } else {
      this.toastService.showErrorToast('Stores not found.');
    }
  }

  getUniqueTemplateNames(): string[] {
    const templateNames = this.originalCouponList.map(coupon => coupon.templateName);
    return Array.from(new Set(templateNames));
  }

  sortProducts(criteria: string) {
    const currentMonth = new Date().getMonth(); // Current month (0 = January, 11 = December)
    const currentYear = new Date().getFullYear(); // Current year

    this.activeSort = criteria;

    if (criteria === 'all') {
      // Reset to the original list
      this.couponList = [...this.originalCouponList];
    } else if (criteria === 'thisMonth') {
      // Filter coupons for the current month and year
      this.couponList = this.originalCouponList
        .filter(coupon => {
          const couponDate = new Date(coupon.toDate);
          return couponDate.getMonth() === currentMonth && couponDate.getFullYear() === currentYear;
        })
    } else {
      // Filter coupons by templateName
      this.couponList = this.originalCouponList.filter(coupon => coupon.templateName === criteria);
    }
  }

  openPrivacyPolicyDialog() {
    const dialogRef = this.dialog.open(AgreedTermsDialogComponent, {
      width: "700px",
      height: "650px",
      data: "couponPolicy",
      autoFocus: false,
      panelClass: ["animate__animated", "animate__slideInUp"],
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.isAgreedTerms = result?.isClose;
      console.log(this.isAgreedTerms);
      this.wait = true;
      if (this.isAgreedTerms) {
        this.viewCouponDetails();
      }
    });
  }


  updatePaginatedProducts(coupon: CouponDto) {
    const startIndex = (coupon.currentPage - 1) * coupon.itemsPerPage;
    const endIndex = startIndex + coupon.itemsPerPage;
    if (this.activeTab === 'home') {
      coupon.paginatedProducts = coupon.selectedCoupon.productDetails.slice(startIndex, endIndex);
    } else {
      coupon.paginatedProducts = [coupon.selectedCoupon.productDetail];
    }
    console.log(coupon.paginatedProducts);
  }

  nextPage(coupon: CouponDto) {
    if (coupon.currentPage < coupon.totalPages) {
      coupon.currentPage++;
      this.updatePaginatedProducts(coupon);
    }
  }

  previousPage(coupon: CouponDto) {
    if (coupon.currentPage > 1) {
      coupon.currentPage--;
      this.updatePaginatedProducts(coupon);
    }
  }


  submitRating(coupon: CouponDto) {
    console.log('Rating submitted:', coupon);
    if (coupon.isRated) {
      return; // Prevent multiple submissions
    }
    // Add your logic to handle the rating submission
    this.onRatingSubscription = this.myCouponService.saveRating(this.apiFactory.couponRatingInsert, coupon.couponCode, coupon.rating)
      .subscribe((data) => {
        coupon.isRated = true;
        this.toastService.showSuccessToast('Rating submitted successfully!');
      });
  }

  subscribeToNotifications(id: any) {
    let stompClient = this.webSocketService.connect(); // Get the Stomp client
    stompClient.onConnect = (frame) => {
      console.log("Connected: " + frame);
      stompClient.subscribe(`/topic/${id}/notification`, (message) => {
        // alert(message.body);
        this.toastService.showSuccessToast(message.body);
        this.filterCoupons('redeemed');
      });
    };

    stompClient.onStompError = (frame) => {
      console.error("STOMP Error:", frame);
    };

    stompClient.activate(); // Activate the WebSocket connection
  }
}
