import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { MakeProductDto } from "src/app/shared/models/makeproductdto";
import { MakeToOrderSummary } from "src/app/shared/models/maketoordersummary";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { getWindow } from "ssr-window";

@Component({
  selector: "app-make-to-order-pin-dialog",
  templateUrl: "./make-to-order-pin-dialog.component.html",
  styleUrls: ["./make-to-order-pin-dialog.component.scss"],
})
export class MakeToOrderPinDialogComponent implements OnInit {
  createSetUpForm: UntypedFormGroup;
  pincode: number;

  makeToOrderSummary: MakeToOrderSummary = new MakeToOrderSummary();
  offerSetUpDto: ViewOfferSetUpDto[] = [];

  offerSetUp: ViewOfferSetUpDto = new ViewOfferSetUpDto();
  
  makeProduct: MakeProductDto = new MakeProductDto();
  showToast: boolean = false;
  locationSetupList: WebOrderLocationSetup[] = [];
  showClickButton: boolean = true;
  showSubmitBtn: boolean = false;
  currentUrl: string;
  url: string;

  //Subscription
  private onCreateSetUpClickSubscription: Subscription;
  private getCompanySubscription: Subscription;
  private onLocationDetailsSubscription: Subscription;

  constructor(
    private localStorage:LocalStorageService,
    public dialogRef: MatDialogRef<MakeToOrderPinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public makeToOrderSummaryValue: any,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private pincodeDialogService: PinDialogService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.url = getWindow().location.hostname
    this.onCreateSetUp();
    console.log(this.makeToOrderSummaryValue);
    this.currentUrl = this.navRoute.url.replace("/", "");
  }
  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      pincode: ["", Validators.required],
    });
  }

  choosePincode() {
    this.pincode = this.createSetUpForm.get("pincode").value;
    this.showToast = false;

    if (this.pincode.toString().length === 6) {
      this.onLocationDetailsSubscription = this.pincodeDialogService
        .getLocationDetailsByPincode(this.pincode, this.url)
        .subscribe((data) => {
          console.log(data);
          if (data.length > 0) {
            this.showClickButton = false;
            this.showSubmitBtn = true;
            this.showToast = false;
            this.locationSetupList = data;
            this.locationSetupList.forEach((element) => {
              if (element.address.length > 40) {
                let firstAdd: string = "";
                const adds = element.address.split(",");
                if (adds.length > 0) {
                  for (let i = 0; i < adds.length; i++) {
                    if (firstAdd.length + adds[i].length < 40) {
                      firstAdd += adds[i] + ", ";
                    }
                  }
                }
                if (firstAdd.length > 0) {
                  element.address1 = firstAdd;
                  element.address2 = element.address.substring(
                    firstAdd.length - 1,
                    element.address.length
                  );
                }
              } else {
                element.address1 = element.address;
              }
            });
          } else {
            this.showClickButton = true;
            this.showToast = true;
            this.locationSetupList = [];
            // this.toastService.showErrorToast('We are not serving to this pincode');
          }
        });
    }
  }

  onCreateSetUpClick(locData: WebOrderLocationSetup) {
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }

    // this.getCompanySubscription = this.productService.getCompanyByLocId(this.apiFactory.getCompanyByLocId, locData.locationCode)
    // .subscribe((data) => {
    //   console.log(data)
    //   this.localStorage.setItem("company_code", JSON.stringify(data.refNo));
    // });

    this.localStorage.setItem("location_code", JSON.stringify(locData.locationCode));
    this.localStorage.setItem("state_code", JSON.stringify(locData.stateCode));

    this.getCompanySubscription = this.productService
      .getCompanyByLocId(
        this.apiFactory.getCompanyByLocId,
        locData.locationCode
      )
      .subscribe((data) => {
        console.log(data);
        this.localStorage.setItem("company_code", JSON.stringify(data.refNo));
      });

    this.pincode = this.createSetUpForm.get("pincode").value;
    this.onCreateSetUpClickSubscription = this.productService
      .checkPincode(this.apiFactory.checkPincode, this.pincode, this.url)
      .subscribe((data) => {
        // this.offerSetUpDto = data;
        if (data == true) {
          this.makeToOrderSummary = this.makeToOrderSummaryValue;
          this.makeToOrderSummary.pincode = this.pincode;
          this.pincodeDialogService.setMTOPincode(this.pincode);
          // this.makeToOrderSummary.offer=this.offerSetUpDto;
          console.log(this.makeToOrderSummary);
          this.pincodeDialogService.setMakeToOrderSummary(
            this.makeToOrderSummary
          );
          this.localStorage.setItem("pincode_item", JSON.stringify(this.pincode));
          this.navRoute.navigate([this.currentUrl]);
          // this.localStorage.setItem("min_selflife", JSON.stringify(locData.minSelfLife));
          this.dialogRef.close();
          // this.navRoute.navigate(['/make-to-order-address/make-to-order-address-app']);
          // this.navRoute.navigate([
          //   "/make-product",
          //   this.makeToOrderSummary.product.product_Code,
          // ]);
        } else {
          this.toastService.showErrorToast("Invalid Pincode");
          this.createSetUpForm.patchValue({ pincode: "" });
        }
        this.dialogRef.close({ result: data });
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
