import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { from, Observable } from "rxjs";
import { event } from "jquery";
@Injectable({
  providedIn: "root",
})
export class HttpinterceptorInterceptor implements HttpInterceptor {

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const name = 'example';
    return next.handle(httpRequest.clone({ 
      setHeaders: {
        platform: "Web Order",
        source: "localhost",
        X_Correlation_Id: this.calcCorrelationId(),
        apiKey: "HE&7@WZT1JN8@8RSNT#4M7SOKUEQ4Z",
        client_Id: "332232uy32",
        'Access-Control-Allow-Origin': '*'
      }

     }));
  }

  // intercept(
  //   req: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   return from(this.handleAccess(req, next));
  // }
  // private async handleAccess(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Promise<HttpEvent<any>> {
  //   request = request.clone({
  //     setHeaders: {
  //       platform: "Web",
  //       source: "1bakeshop.com",
  //       X_Correlation_Id: this.calcCorrelationId(),
  //       apiKey: "HE&7@WZT1JN8@8RSNT#4M7SOKUEQ4Z",
  //       client_Id: "332232uy32",
  //     },
  //   });
  //   if (event instanceof HttpResponse) {
  //     console.log("MMMMMMMMMMMMMMMMMMMMM" + event);
  //   }
  //   return next.handle(request).toPromise();
  // }
  calcCorrelationId() {
    // see http://www.ietf.org/rfc/rfc4122.txt
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
  }
}
