<div class="container" *ngIf="show" style="margin-top: 20px">
    <div class="row">
        <div class="col-md-12">
            <div class="container">
                <app-card-loader [loop]="3" [height]="800" *ngIf="loading"></app-card-loader>
                <div class="row" *ngIf="!loading">
                    <div class="col-lg-2 col-md-3 col-sm-3 col-xs-6" style="padding: 0;"
                        *ngFor="let product of productList | filterByBrand: selectedBrand">
                        <div class="product_detaiils">
                            <div class="item">
                                <div class="mb-r">
                                    <!--Card-->
                                    <div *ngIf="product.type == 'product'" class="card card-cascade wider">
                                        <!--Card image-->
                                        <a>
                                            <div class="view overlay hm-white-slight">
                                                <img src="../../../../assets/img/{{ product.product_Picture }}"
                                                    class="img-fluid" alt="Image not found" width="360px" height="300px"
                                                    onerror="this.src='../../../../assets/img/defaultCake.webp';"
                                                    [routerLink]="['/product', product.product_Code]" />
                                                <img *ngIf="product.food_Type == 'Veg'" class="img-responsive img_align"
                                                    src="../../../../assets/img/Veg.webp" alt="" />
                                                <img *ngIf="product.food_Type == 'Non-Veg'"
                                                    class="img-responsive img_align"
                                                    src="../../../../assets/img/Nonveg.webp" alt="" />

                                                <img *ngIf="product.offers >= 1" class="img-responsive offer_align"
                                                    src="../../../../assets/img/Offer 1.webp" alt=""
                                                    (click)="viewOfferDetail(product.offerDetails)"
                                                    matTooltip="Offers" />
                                            </div>
                                        </a>

                                        <div style="display: flex;justify-content: space-between;">
                                            <a placement="top" matTooltip="Add to Wishlist" container="body"
                                                (click)="addFavourite(product)">
                                                <i class="fa fa-heart"
                                                    style="font-size: 17px; color: #e91e63 !important"></i>
                                            </a>
                                            <mat-icon [matMenuTriggerFor]="menu" id="icon_align"
                                                matTooltip="Share">share</mat-icon>
                                            <mat-menu #menu="matMenu">
                                                <share-buttons theme="circles-dark"
                                                    [include]="['copy','whatsapp','facebook','email','telegram']"
                                                    [showIcon]="true" [showText]="false"
                                                    [url]="sharingUrl+product.product_Code"
                                                    [description]="product.product_Name" class="pt-5">
                                                </share-buttons>
                                            </mat-menu>
                                        </div>

                                        <div class="card-body text-center">
                                            <p class="card-title product_name">
                                                <strong style="height: 80%;">
                                                    <a [routerLink]="['/product', product.product_Code]"
                                                        routerLinkActive="router-link-active">
                                                        {{product.product_Name }}</a>
                                                </strong>
                                                <strong class="price_style">
                                                    <span>₹ {{ product.net_Sale_Price | number: "1.2-2"
                                                        }}</span>
                                                </strong>

                                            </p>

                                            <p>
                                                <span>{{product.portion_Weight }}</span>
                                            </p>

                                            <div class="card-footer">
                                                <span class="left">
                                                    <!-- <a placement="top" matTooltip="Add to Wishlist"
                                                        container="body" (click)="addFavourite(product)">
                                                        <i class="fa fa-heart"
                                                            style="font-size: 17px; color: #e91e63 !important"></i>
                                                    </a> -->

                                                    <!-- <a placement="top" matTooltip="Add to Cart" container="body"
                                                        (click)="addToCart(product, false)">
                                                        <i class="fa fa-shopping-cart"
                                                            style="margin-left: 6px;color: #e91e63 !important;font-size: 19px;"></i>
                                                    </a> -->
                                                    <button type="button" class="add_to_cart_btn"
                                                        (click)="addToCart(product, false)">Add to Cart</button>
                                                </span>

                                                <span class="right" style="margin-right: -25px; margin-top: 1px;">

                                                    <!-- <a placement="top" matTooltip="Add to Cart" container="body"
                                                        (click)="addToCart(product)">
                                                        <i class="fa fa-shopping-cart"
                                                            style="margin-left: 6px;color: #e91e63 !important;font-size: 19px;"></i>
                                                    </a> -->
                                                    <button type="button" class="add_to_cart_btn"
                                                        (click)="addToCart(product, true)">Buy Now</button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="product.type == 'make-product'" class="card card-cascade wider">
                                        <a>
                                            <div class="view overlay hm-white-slight">
                                                <img src="../../../../assets/img/{{ product.product_Picture }}"
                                                    class="img-fluid" alt="Image not found"
                                                    onerror="this.src='../../../../assets/img/defaultCake.webp';"
                                                    [routerLink]="['/make-product', product.product_Code]" />
                                                <img *ngIf="product.food_Type == 'Veg'" class="img-responsive img_align"
                                                    src="../../../../assets/img/Veg.webp" alt="" />
                                                <img *ngIf="product.food_Type == 'Non-Veg'"
                                                    class="img-responsive img_align"
                                                    src="../../../../assets/img/Nonveg.webp" alt="" />
                                            </div>
                                        </a>

                                        <div style="display: flex;justify-content: space-between;">
                                            <a placement="top" mdbTooltip="Add to Wishlist" container="body"
                                                (click)="addFavourite(product)">
                                                <i class="fa fa-heart"
                                                    style="font-size: 17px; color: #e91e63 !important"></i>
                                            </a>
                                            <mat-icon [matMenuTriggerFor]="menu" id="icon_align">share</mat-icon>
                                            <mat-menu #menu="matMenu">
                                                <share-buttons theme="circles-dark"
                                                    [include]="['copy','whatsapp','facebook','email','telegram']"
                                                    [showIcon]="true" [showText]="false"
                                                    [url]="sharingUrl+product.product_Code"
                                                    [description]="product.product_Name" class="pt-5">
                                                </share-buttons>
                                            </mat-menu>
                                        </div>

                                        <div class="card-body text-center">
                                            <p class="card-title product_name">
                                                <strong style="height: 80%;">
                                                    <a [routerLink]="['/make-product', product.product_Code]"
                                                        routerLinkActive="router-link-active">
                                                        {{product.product_Name }}</a>
                                                </strong>
                                                <strong class="price_style">
                                                    <span>₹ {{ product.net_Sale_Price | number: "1.2-2"
                                                        }}</span>
                                                </strong>
                                            </p>

                                            <p>
                                                <span>{{product.portion_Weight }}</span>
                                            </p>

                                            <div class="card-footer">
                                                <span class="left">
                                                    <!-- <a placement="top" mdbTooltip="Add to Wishlist"
                                                        container="body" (click)="addFavourite(product)">
                                                        <i class="fa fa-heart"
                                                            style="font-size: 17px; color: #e91e63 !important"></i>
                                                    </a> -->
                                                </span>

                                                <span class="right" style="margin-right: -25px">
                                                    <!-- <a placement="top" mdbTooltip="Add to Cart" container="body"
                                                        [routerLink]="['/make-product', product.product_Code]">
                                                        <i class="fa fa-shopping-cart"
                                                            style="margin-left: 6px;color: #e91e63 !important;font-size: 19px;"></i>
                                                    </a> -->
                                                    <button type="button" class="add_to_cart_btn"
                                                        [routerLink]="['/make-product', product.product_Code]">Buy
                                                        Now</button>

                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>