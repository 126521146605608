import { Location } from "../shared/models/location";
import { LocationDto } from "../shared/models/locationdto";

export class CustomerDTO {
    customerEmail: String;
    fromLocationCode: number;
    customerMobile: String;
    customerName: String;
    stateCode: number;
    location: LocationDto = new LocationDto()
}