<div style="margin-top: 3%;">
    <div class="section-heading" style="height: 0px;">
        <h2 class="h2_font_size">B2B OnBoarding</h2>
        <div class="hr"></div>
    </div>
</div>

<section id="contact">
    <div class="container">
        <div class="row" id="contactRow">
            <div class="col-lg-7 col-md-7 text-center">
                <div class="form-style" id="contact_form">
                    <form [formGroup]="b2bForm" (ngSubmit)="onB2bSaveClick()" class="formStyle">
                        <div class="form-group">
                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 100%;">
                                    <mat-form-field>
                                        <mat-label>Name</mat-label>
                                        <input type="text" matInput formControlName="name" required />
                                    </mat-form-field>
                                </mat-card>
                            </div>
                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Email</mat-label>
                                        <input type="Email" matInput formControlName="email" required readonly/>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Mobile No</mat-label>
                                        <input type="number" matInput formControlName="phoneNo"
                                            pattern="[6-9]{1}[0-9]{9}" (keyup)="checkOtp($any($event.target).value)"
                                            maxlength="10" required readonly/>
                                        <!-- <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'No'" class="clearBtn"
                                            (click)="onVerifyClick()">Verify</span>
                                        <span *ngIf="isDisabledSubmit && otpVerficationStatus == 'Yes'"
                                            class="clearBtn1">Verified</span>
                                        <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'Exceeds'"
                                            class="clearBtn2">Exceeds</span> -->
                                    </mat-form-field>
                                </mat-card>
                                <!-- <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Mobile No</mat-label>
                                        <input type="number" matInput formControlName="phoneNo" required />
                                    </mat-form-field>
                                </mat-card> -->
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="State" formControlName="stateCode"
                                            (selectionChange)="onStateSelect($event.value)">
                                            <mat-option *ngFor="let state of stateList" [value]="state.refNo">
                                                {{ state.stateName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="District" formControlName="districtCode"
                                            (selectionChange)="onDistrictSelect($event.value)">
                                            <mat-option *ngFor="let district of districtList" [value]="district.id">
                                                {{ district.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field class="selectOption">
                                        <mat-select placeholder="City" formControlName="cityCode">
                                            <mat-option *ngFor="let city of cityList" [value]="city.id">
                                                {{ city.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Pincode</mat-label>
                                        <input type="number" matInput formControlName="pincode" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Address</mat-label>
                                        <input type="text" matInput formControlName="address" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Building/Plot</mat-label>
                                        <input type="text" matInput formControlName="plot" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Street</mat-label>
                                        <input type="text" matInput formControlName="street" />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Land Mark</mat-label>
                                        <input type="text" matInput formControlName="landMark" />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div style="display: flex">
                                <mat-card class="form-control input-field" style="width: 50%; margin-right: 1%">
                                    <mat-form-field>
                                        <mat-label>Latitude</mat-label>
                                        <input type="text" matInput formControlName="latitude" readonly />
                                    </mat-form-field>
                                </mat-card>

                                <mat-card class="form-control input-field" style="width: 50%">
                                    <mat-form-field>
                                        <mat-label>Longitude</mat-label>
                                        <input type="text" matInput formControlName="longitude" readonly />
                                    </mat-form-field>
                                </mat-card>
                            </div>

                            <div>
                                <div>
                                    <div style="padding-bottom: 2px">
                                        <input type="text" class="form-control"
                                            (keydown.enter)="$any($event).preventDefault()"
                                            placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                            spellcheck="off" type="text" #search [disabled]="!newDeliveryDetails" />
                                        <mat-icon class="fetch_icon" (click)="fetchCurrentLoc()"
                                            matTooltip="Fetch current location">my_location
                                        </mat-icon>
                                    </div>
                                    <agm-map [latitude]="latitudeMap" [longitude]="longitudeMap" [zoom]="zoom">
                                        <agm-marker [latitude]="latitudeMap" [longitude]="longitudeMap"
                                            [markerDraggable]="newDeliveryDetails"
                                            (dragEnd)="markerDragEnd($any($event))">
                                        </agm-marker>
                                    </agm-map>
                                </div>
                            </div>

                            <a class="btn" id="submit_btn">
                                <div class="btn-line"></div>
                                <button type="submit" class="btn-line btn-line-shift"
                                    style="background-color: #d0324b; color: white">
                                    Submit
                                </button> Send Message
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>