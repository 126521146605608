export class DeliveryFeedBack {
  feedbackDate: string;
  assignedNo: number;
  feedbackFor: string;
  feedbackBy: string;
  toLocation: number;
  toDeliveryBoy: number;
  locationCode: number;
  servicemanCode: number;
  orderNo: string;
}
