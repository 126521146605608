import { Component, ElementRef, Inject, OnInit, ViewChild, } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, } from "@angular/material/dialog";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrivacyPolicyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.scrollToElementById(this.data);
  }

  close() {
    document.getElementsByClassName("animate__animated")[0].classList.remove("animate__slideInUp");
    document.getElementsByClassName("animate__animated")[0].classList.add("animate__slideOutDown");
    setTimeout(() => {
      this.dialog.closeAll();
    }, 700);
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
  }
}
