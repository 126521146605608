<div class="mbr-section mbr-section__container mbr-section__container--middle">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 text-xs-center">
                <h3 class="mbr-section-title">Delivery Address<br></h3>
            </div>
        </div>
    </div>
</div>
<form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
    <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 420px;">
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required
                matInput placeholder="Name" formControlName="customerName"/>
            </mat-form-field>
        </mat-card>
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required
                matInput placeholder="Contact No" formControlName="contactNo"/>
            </mat-form-field>
        </mat-card>
         <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required
                matInput placeholder="Pincode" formControlName="pincode" />
            </mat-form-field>
        </mat-card>

    </div>  
    <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 420px;">
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="example-full-width">
                <input required
                matInput placeholder="City" formControlName="cityName"/>
            </mat-form-field>
        </mat-card>
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="selectOption" >
                <mat-select placeholder="State" formControlName="stateId" (selectionChange)="onStateSelect($event.value)">
                    <mat-option *ngFor="let state of stateList" [value]="state.refNo">
                        {{state.stateName}}
                      </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card>
        <mat-card class="contain_card" fxFlex="33">
            <mat-form-field class="selectOption">
                <mat-select placeholder="District" formControlName="districtId">
                    <mat-option *ngFor="let district of districtList" [value]="district.id">
                        {{district.name}}
                      </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-card>
    </div>   
    <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 420px;">
        <mat-card class="contain_card" fxFlex="33" style="height: 150px;left: 0%;
        width: 580px;"> 
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5" placeholder="Address" formControlName="address" maxlength="200"></textarea>
        </mat-card>

    </div>  
    <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 670px;
        margin-top: 5px;">
            <button type="submit" id="compose-button" id="send-button"
                class="btn btn-danger hidden">Submit</button>
    </div>


</form>    