<div style="margin-top: 3%">
    <div class="section-heading">
        <h2 class="h2_font_size">Feedback</h2>
        <div class="hr"></div>
    </div>
</div>

<div class="feedback-container">
    <mat-icon *ngIf="feedbackData.length > 0" class="sortbar_icon" [matMenuTriggerFor]="menu">filter_list</mat-icon>
    <mat-menu #menu="matMenu">
        <div class="sortbar_tabs_icon">
            <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'lowToHigh' }"
                type="button" (click)="sortProducts('lowToHigh')">Oldest to Newest</button>
            <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'highToLow' }"
                type="button" (click)="sortProducts('highToLow')">Newest to Oldest</button>
        </div>
    </mat-menu>



    <div *ngFor="let feedback of feedbackData; let i = index" class="feedback-card"
        [class.new-status]="feedback.status === 'New'" [class.under-review-status]="feedback.status === 'Under Review'"
        [class.closed-status]="feedback.status === 'Closed(Can\'t Pursue)'"
        [class.resolved-status]="feedback.status === 'Resolved'" [class.expanded]="expandedIndex === i"
        (click)="toggleCard(i)">

        <div class="card-header">
            <p><strong>Issue No:</strong> {{ feedback.refNo }}</p>
            <p><strong>Date:</strong> {{ feedback.createdDate | date: 'mediumDate' }}</p>
            <p><strong>Subject:</strong> {{ feedback.subject }}</p>
            <p><strong>Status:</strong> {{ feedback.status }}</p>
            <span class="toggle-icon">
                <!-- Toggle icon based on expand/collapse state -->
                <i *ngIf="expandedIndex === i" class="icon-minus">-</i>
                <i *ngIf="expandedIndex !== i" class="icon-plus">+</i>
            </span>
        </div>
        <div *ngIf="expandedIndex === i" class="card-details">
            <p><strong>Name:</strong> {{ feedback.name }}</p>
            <p><strong>Email:</strong> {{ feedback.email }}</p>
            <p><strong>Phone:</strong> {{ feedback.phone }}</p>
            <p><strong>Subject:</strong> {{ feedback.subject }}</p>
            <p><strong>Category:</strong> {{ feedback.grievanceTypeSetup?.grievanceType}}</p>
            <p><strong>Sub-Category:</strong> {{ feedback.subGrievanceTypeSetup?.subGrievanceName }}</p>
            <p><strong>Feedback:</strong> {{feedback.grievanceSetup?.grievanceName }}</p>
            <p><strong>Message:</strong> {{ feedback.message }}</p>
            <p *ngIf="feedback.reasonForResolved"><strong>Reason for Resolved:</strong> {{ feedback.reasonForResolved }}
            </p>
            <p *ngIf="feedback.reasonForReview"><strong>Reason for <span>{{feedback.status === 'Under Review' ?
                        'Review:': 'Close:'}}</span></strong> {{ feedback.reasonForReview }}</p>
            <p>
                <strong>Video File:</strong>
                <span class="view-link" (click)="getVideo(feedback, $event)">View Video</span>
            </p>
            <p>
                <strong>Image File:</strong>
                <span class="view-link" (click)="getImage(feedback, $event)">View Image</span>
            </p>

        </div>
    </div>
</div>