import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { getWindow } from "ssr-window";

@Component({
  selector: "app-verify-mobile-no-dialog",
  templateUrl: "./verify-mobile-no-dialog.component.html",
  styleUrls: ["./verify-mobile-no-dialog.component.scss"],
})
export class VerifyMobileNoDialogComponent implements OnInit {
  createPhoneSetUpForm: UntypedFormGroup;
  phoneNo: string;
  randomString: string = "";
  showHideSendOtpDiv: boolean = true;
  showHideOtpDiv: boolean = false;

  currentUrl: string;
  currentPath: string;

  //Subscription
  private whatsAppOptInByMobileNoSubscription: Subscription;
  private whatsAppOTPByMobileNoSubscription: Subscription;
  private getWhatsAppOTPResponseSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public dialogRef: MatDialogRef<VerifyMobileNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public mobileNo: any,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname
    console.log(this.mobileNo);
    this.onCreateSetUp();
    this.createPhoneSetUpForm.patchValue({ phoneNo: this.mobileNo });
    this.currentPath = window.location.pathname.split('/').pop();
    console.log(this.currentPath);
  }

  onCreateSetUp() {
    this.createPhoneSetUpForm = this.formBuilder.group({
      phoneNo: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
    });
  }

  generateUniqueID() {
    var s = [];
    var hexDigits = "0123456789";
    for (var i = 0; i < 6; i++) {
      let num: number = Math.floor(1 + Math.random() * 9);
      s[i] = hexDigits.substr(num, 1);
    }
    var uuid = s.join("");
    return uuid;
  }

  showResend: boolean = false;
  errorText: string = '';
  sendOtpClick() {
    this.randomString = this.generateUniqueID();
    this.phoneNo = this.createPhoneSetUpForm.get("phoneNo").value;

    this.showHideSendOtpDiv = false;
    this.showHideOtpDiv = true;

    if (this.currentPath === 'delete-user-data') {
      this.handleNewOtpRequest();
    } else {
      this.getWhatsAppOTPResponseSubscription = this.userService.getWhatsAppOTPResponseByMobileNo(this.apiFactory.getWhatsAppOTPResponseByMobileNo, this.phoneNo)
        .subscribe((data) => {
          if (data && data.length === 0) {
            this.handleNewOtpRequest();
          } else {
            this.handleExistingOtpRequest(data);
          }
        });
    }
  }

  handleNewOtpRequest() {
    this.whatsAppOptInByMobileNoSubscription = this.userService.sendWhatsAppOptInByMobileNo(this.apiFactory.sendWhatsAppOptInByMobileNo, this.currentUrl, this.phoneNo)
      .subscribe((data: any) => {
        if (data) {
          this.sendOtp();
        }
      });
  }

  sendOtp() {
    this.whatsAppOTPByMobileNoSubscription = this.userService.sendWhatsAppOTPByMobileNo(this.apiFactory.sendWhatsAppOTPByMobileNo, this.currentUrl, this.phoneNo, this.randomString)
      .subscribe((data: any) => {
        if (data && data.eventType !== 'FAILED') {
          this.showHideSendOtpDiv = false;
          this.showHideOtpDiv = true;
          this.errorText = '';
          this.toastService.showSuccessToast("OTP Sent");
        } else {
          this.sendOtpClick();
        }
      });
  }

  handleExistingOtpRequest(data: any[]) {
    if (data.length === 1 && data[0].eventType === 'FAILED') {
      this.showResend = true;
      this.showHideOtpDiv = true;
      this.errorText = this.showResend ? 'Failed' : '';
    } else if (data.length >= 1 && data[0].eventType !== 'FAILED') {
      this.showResend = false;
      this.showHideOtpDiv = true;
    } else if (data.length >= 1) {
      const hoursDifference = this.calculateHoursDifference(data[0].lastOperationDate, data[1].lastOperationDate);
      this.showResend = hoursDifference >= 48;
      this.showHideOtpDiv = false;
      console.log(hoursDifference);

      if (!this.showResend) {
        const currentTime = new Date();
        const firstDate = new Date(data[0].lastOperationDate);
        const elapsedHoursSinceFirstDate = this.calculateHoursDifference(firstDate.toISOString(), currentTime.toISOString());
        const leftHours = (48 - elapsedHoursSinceFirstDate).toFixed(2);

        console.log(leftHours);

        this.errorText = 'Try again after ' + Number(leftHours).toFixed(0) + ' hours';
        this.dialogRef.close({ result: false, mblNo: this.phoneNo });
        this.toastService.showErrorToast(this.errorText);
      }

    }
  }

  calculateHoursDifference(date1String: string, date2String: string): number {
    const date1 = new Date(date1String);
    const date2 = new Date(date2String);

    const timeDifference = Math.abs(date1.getTime() - date2.getTime());
    return timeDifference / (1000 * 60 * 60);
  }


  // showResend: boolean = false;
  // sendOtpClick() {
  //   this.randomString = this.generateUniqueID();
  //   this.phoneNo = this.createPhoneSetUpForm.get("phoneNo").value;

  //   this.showHideSendOtpDiv = false;
  //   this.showHideOtpDiv = true;

  //   this.getWhatsAppOTPResponseSubscription = this.userService.getWhatsAppOTPResponseByMobileNo(this.apiFactory.getWhatsAppOTPResponseByMobileNo, this.phoneNo)
  //     .subscribe((data) => {
  //       if (data && data.length == 0) {
  //         this.whatsAppOptInByMobileNoSubscription = this.userService.sendWhatsAppOptInByMobileNo(this.apiFactory.sendWhatsAppOptInByMobileNo, this.currentUrl, this.phoneNo)
  //           .subscribe((data: any) => {
  //             if (data) {
  //               this.whatsAppOTPByMobileNoSubscription = this.userService.sendWhatsAppOTPByMobileNo(this.apiFactory.sendWhatsAppOTPByMobileNo, this.currentUrl, this.phoneNo, this.randomString)
  //                 .subscribe((data: any) => {
  //                   if (data) {
  //                     this.showHideSendOtpDiv = false;
  //                     this.showHideOtpDiv = true;
  //                     this.toastService.showSuccessToast("OTP Sent");
  //                   }
  //                 });
  //             }
  //           });
  //       } else {
  //         if (data.length == 1 && data[0].eventType == 'FAILED') {
  //           this.showResend = true;
  //         }
  //         else if (data.length >= 1 && data[0].eventType != 'FAILED') {
  //           this.showResend = false;
  //         } else if (data.length >= 1) {
  //           const date1 = new Date(data[0].lastOperationDate);
  //           const date2 = new Date(data[1].lastOperationDate);

  //           // Calculate the difference in milliseconds
  //           const timeDifference = Math.abs(date1.getTime() - date2.getTime());

  //           // Convert the difference to hours
  //           const hoursDifference = timeDifference / (1000 * 60 * 60);

  //           // Check if the difference is 48 hours or more
  //           if (hoursDifference >= 48) {
  //             this.showResend = true;
  //           } else {
  //             this.showResend = false;
  //           }
  //         }
  //       }
  //     });
  // }

  reSendOtpClick() {
    this.whatsAppOptInByMobileNoSubscription = this.userService
      .sendWhatsAppOTPByMobileNo(
        this.apiFactory.sendWhatsAppOTPByMobileNo,
        this.currentUrl,
        this.phoneNo,
        this.randomString
      )
      .subscribe((data: any) => {
        if (data) {
          this.toastService.showSuccessToast("OTP Resent");
        }
      });
  }

  checkOtp(value: string) {
    console.log(this.randomString);
    if (value.length == 6) {
      if (this.randomString == value) {
        this.showHideOtpDiv = false;
        this.dialogRef.close({ result: true, mblNo: this.phoneNo });
        this.toastService.showSuccessToast("OTP Verfied");
      } else {
        this.showHideOtpDiv = true;
        this.toastService.showErrorToast("Invalid OTP");
      }
    }
  }
}
