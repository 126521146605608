import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HttpinterceptorInterceptor } from "./httpinterceptor.interceptor";
import { NoAccessComponent } from "./shared/components/no-access/no-access.component";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { CareerComponent } from "./views/pages/career/career.component";
import { ContactComponent } from "./views/pages/contact/contact.component";
import { FranchaiseComponent } from "./views/pages/franchaise/franchaise.component";
import { InvoiceHistoryComponent } from "./views/pages/invoice-history/invoice-history.component";
import { MakeCakeComponent } from "./views/pages/make-cake/make-cake.component";
import { CartPaymentComponent } from "./views/pages/product/cart-payment/cart-payment.component";
import { CartProductsComponent } from "./views/pages/product/cart-products/cart-products.component";
import { FavouriteProductsComponent } from "./views/pages/product/favourite-products/favourite-products.component";
import { MakeProductComponent } from "./views/pages/product/make-product/make-product.component";
import { PaymentConfirmationComponent } from "./views/pages/product/payment-confirmation/payment-confirmation.component";
import { PaymentFailComponent } from "./views/pages/product/payment-fail/payment-fail.component";
import { PaymentHistoryComponent } from "./views/pages/product/payment-history/payment-history.component";
import { PaymentStatusComponent } from "./views/pages/product/payment-status/payment-status.component";
import { PaymentSuccessComponent } from "./views/pages/product/payment-success/payment-success.component";
import { ProcessPaymentComponent } from "./views/pages/product/process-payment/process-payment.component";
import { ProductDeliveryAddressComponent } from "./views/pages/product/product-delivery-address/product-delivery-address.component";
import { ProductDetailComponent } from "./views/pages/product/product-detail/product-detail.component";
import { ProductListComponent } from "./views/pages/product/product-list/product-list.component";
import { ProductOrderSummaryComponent } from "./views/pages/product/product-order-summary/product-order-summary.component";
import { WalletTransactionComponent } from "./views/pages/product/wallet-transaction/wallet-transaction.component";
import { ProfileComponent } from "./views/pages/profile/profile.component";
import { StoreComponent } from "./views/pages/store/store.component";
import { SearchProductListComponent } from "./views/pages/product/search-product-list/search-product-list.component";
import { LoginComponent } from "./views/base/index/login/login.component";
import { DeleteUserDataComponent } from "./views/pages/product/delete-user-data/delete-user-data.component";
import { PrivacyPolicyPageComponent } from "./views/pages/product/privacy-policy-page/privacy-policy-page.component";
import { HomeComponent } from "./views/pages/product/home/home.component";
import { CustomizeProductComponent } from "./views/pages/product/customize-product/customize-product.component";
import { MyCouponComponent } from "./views/pages/product/my-coupon/my-coupon.component";
import { MakePaymentComponent } from "./views/pages/product/make-payment/make-payment.component";
import { MakePaymentStatusComponent } from "./views/pages/product/make-payment-status/make-payment-status.component";
import { FeedbackDetailsComponent } from "./views/pages/product/feedback-details/feedback-details.component";
import { B2bOnBoardingComponent } from "./views/pages/product/b2b-on-boarding/b2b-on-boarding.component";

const routes: Routes = [
  {
    path: "",
    children: [
      // {
      //   path: "",
      //   loadChildren: () =>
      //     import("./views/base/index/index.module").then((m) => m.IndexModule),
      // },
      // {
      //   path: "products",
      //   component: ProductListComponent

      //   // loadChildren: () =>
      //   //   import("./views/pages/product/product.module").then(
      //   //     (m) => m.ProductModule
      //   //   ),
      // },
      {
        path: "products",
        component: ProductListComponent,
      },
      {
        path: "product/:id",
        component: ProductDetailComponent,
      },
      {
        path: "contact-us",
        component: ContactComponent,
        // loadChildren: () =>
        //   import("./views/pages/contact/contact.module").then(
        //     (m) => m.ContactModule
        //   ),
      },
      {
        path: "franchaise",
        component: FranchaiseComponent,
        // loadChildren: () =>
        //   import("./views/pages/franchaise/franchaise.module").then(
        //     (m) => m.FranchaiseModule
        //   ),
      },
      {
        path: "franchise",
        component: FranchaiseComponent,
      },
      {
        path: "career",
        component: CareerComponent,
        // loadChildren: () =>
        //   import("./views/pages/career/career.module").then(
        //     (m) => m.CareerModule
        //   ),
      },
      // {
      //   path: "invoice-history",
      //   loadChildren: () =>
      //     import("./views/pages/invoice-history/invoice-history.module").then(
      //       (m) => m.InvoiceHistoryModule
      //     ),
      // },
      // {
      //   path: "profile",
      //   loadChildren: () =>
      //     import("./views/pages/profile/profile.module").then(
      //       (m) => m.ProfileModule
      //     ),
      // },
      {
        path: "store",
        component: StoreComponent,
        // loadChildren: () =>
        //   import("./views/pages/store/store.module").then((m) => m.StoreModule),
      },
      {
        path: "address",
        loadChildren: () =>
          import("./views/pages/address/address.module").then(
            (m) => m.AddressModule
          ),
      },
      // {
      //   path: "order-summary",
      //   loadChildren: () =>
      //     import("./views/pages/order-summary/order-summary.module").then(
      //       (m) => m.OrderSummaryModule
      //     ),
      // },
      {
        path: "payment",
        loadChildren: () =>
          import("./views/pages/payment/payment.module").then(
            (m) => m.PaymentModule
          ),
      },
      // {
      //   path: "make-to-order",
      //   component: MakeCakeComponent
      //   // loadChildren: () =>
      //   //   import("./views/pages/make-cake/make-cake.module").then(
      //   //     (m) => m.MakeCakeModule
      //   //   ),
      // },
      {
        path: "cake-catalogue",
        component: MakeCakeComponent,
      },
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: 'login/:id',
        component: LoginComponent
      },
      {
        path: "make-to-order-address",
        loadChildren: () =>
          import(
            "./views/pages/make-to-order-address/make-to-order-address.module"
          ).then((m) => m.MakeToOrderAddressModule),
      },
      {
        path: "make-to-order-address",
        loadChildren: () =>
          import(
            "./views/pages/make-to-order-address/make-to-order-address.module"
          ).then((m) => m.MakeToOrderAddressModule),
      },
      {
        path: "make-to-order-summary",
        loadChildren: () =>
          import(
            "./views/pages/make-to-order-summary/make-to-order-summary.module"
          ).then((m) => m.MakeToOrderSummaryModule),
      },
      {
        path: "make-to-order-payment",
        loadChildren: () =>
          import(
            "./views/pages/make-to-order-payment/make-to-order-payment.module"
          ).then((m) => m.MakeToOrderPaymentModule),
      },
      {
        path: "favourite-products",
        component: FavouriteProductsComponent,
      },
      {
        path: "cart-items",
        component: CartProductsComponent,
      },
      {
        path: "order-summary",
        component: ProductOrderSummaryComponent,
      },
      {
        path: 'order-summary/:id',
        component: ProductOrderSummaryComponent
      },
      {
        path: "cart-payment",
        component: CartPaymentComponent,
      },
      {
        path: "delivery-address",
        component: ProductDeliveryAddressComponent,
      },
      {
        path: "payment-confirmation",
        component: PaymentConfirmationComponent,
      },
      {
        path: "payment-success",
        component: PaymentSuccessComponent,
      },
      {
        path: "payment-fail",
        component: PaymentFailComponent,
      },
      {
        path: "make-product/:id",
        component: MakeProductComponent,
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "invoice-history",
        component: InvoiceHistoryComponent,
      },
      {
        path: "process-payment",
        component: ProcessPaymentComponent,
      },
      {
        path: "payment-history",
        component: PaymentHistoryComponent,
      },
      {
        path: "payment-status",
        component: PaymentStatusComponent,
      },
      {
        path: "wallet",
        component: WalletTransactionComponent,
      },
      {
        path: "search-products",
        component: SearchProductListComponent,
      },
      {
        path: "search-product",
        component: SearchProductListComponent,
      },
      {
        path: "delete-user-data",
        component: DeleteUserDataComponent,
      },
      {
        path: "privacy-policy",
        component: PrivacyPolicyPageComponent,
      },
      {
        path: "home",
        component: HomeComponent,
      },
      {
        path: "customized-product",
        component: CustomizeProductComponent,
      },
      {
        path: "my-coupon",
        component: MyCouponComponent,
      },
      {
        path: "make-payment",
        component: MakePaymentComponent,
      },
      {
        path: "make-payment-status",
        component: MakePaymentStatusComponent,
      },
      {
        path: "feedback",
        component: FeedbackDetailsComponent,
      },
      {
        path: "b2b-onboarding",
        component: B2bOnBoardingComponent,
      },
    ],
  },
  { path: "no-access", component: NoAccessComponent },
  { path: "**", redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorInterceptor,
      multi: true,
    },
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
