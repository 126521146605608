import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
// import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { SharedModule } from "src/app/shared/shared.module";
import { MakeCakeComponent } from "./make-cake.component";
import { MakeCakeRoutes } from "./make-cake.routing";
import { BrowserModule } from "@angular/platform-browser";
import { MatMenuModule } from "@angular/material/menu";
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [
    MatListModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
   // LeafletModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    CommonModule,
    SharedModule,
    MakeCakeRoutes,
    BrowserModule,
    ShareButtonsModule,
    ShareIconsModule,
    MatMenuModule,
    MatCheckboxModule,
  ],
  declarations: [MakeCakeComponent],
})
export class MakeCakeModule {}
