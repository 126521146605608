import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { ViewOfferSetUpDto } from 'src/app/shared/models/viewoffersetupdto';

@Component({
  selector: 'app-offer-details-dialog',
  templateUrl: './offer-details-dialog.component.html',
  styleUrls: ['./offer-details-dialog.component.scss']
})
export class OfferDetailsDialogComponent implements OnInit {

  promotionOfferData: any[] = [];
  serverUrl: string;

  constructor(
    public dialogRef: MatDialogRef<OfferDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private apiFactory: APIFactoryEndPoints,
  ) {
    if (dialogData.offerData) {
      this.promotionOfferData = dialogData.offerData;
      this.promotionOfferData[0].index = this.promotionOfferData.length == 1;
    } else {
      this.promotionOfferData = [];
    }
    console.log(this.promotionOfferData)
  }

  ngOnInit(): void {
    this.serverUrl = this.apiFactory.getServerUrl;
  }

  okay() {
    this.dialogRef.close({ data: this.promotionOfferData })
  }

}
