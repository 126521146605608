export class AdvanceSearchDTO {
  minPrice: number;
  maxPrice: number;
  offer: number[];
  locationCode: number;
  minDiscRate: number;
  maxDiscRate: number;
  module: string;
  food_Type: string[];
}
