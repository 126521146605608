import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { NameWithIdDto } from "src/app/shared/models/namewithiddto";
import { State } from "src/app/shared/models/state";
import { WebOrderFranchiseSetup } from "src/app/shared/models/web-order-franchise-setup";
import { FranchiseService } from "src/app/shared/services/franchise.service";
import { StoreService } from "src/app/shared/services/store.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { UserService } from "src/app/shared/services/user.service";
import { getWindow } from "ssr-window";
import { VerifyMobileNoDialogComponent } from "../verify-mobile-no-dialog/verify-mobile-no-dialog.component";
import { City } from "src/app/shared/models/city";
import { FileService } from "src/app/common/File-Service";
import { LocationDetailsForStore } from "src/app/shared/models/locationdetailsforstore";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { MapsAPILoader } from "@agm/core";
import { CommonService } from "src/app/shared/services/CommonService";

@Component({
  selector: "app-franchaise",
  templateUrl: "./franchaise.component.html",
  styleUrls: ["./franchaise.component.scss"],
})
export class FranchaiseComponent implements OnInit {
  createSetUpForm: UntypedFormGroup;
  maxDate = new Date();
  uploadedFile: File;
  isDisabledSubmit: boolean = false;
  phoneNo: string = "";
  stateList: State[][] = [];
  districtList: NameWithIdDto[][] = [];
  cityList: City[][] = [];

  franchiseTypeList: string[] = ['Cloud Bakery', 'Franchise Store', 'Kiosk'];
  franchiseSetupList: WebOrderFranchiseSetup[] = [];
  masterFranchiseList: WebOrderFranchiseSetup[] = [];
  franchiseStoreList: WebOrderFranchiseSetup[] = [];
  kioskList: WebOrderFranchiseSetup[] = [];
  currentUrl: string;
  isShowMasterFranchise: boolean = false;
  isShowFranchiseStore: boolean = false;
  isShowKiosk: boolean = false;
  visitors: number;
  isReadonly: boolean = false;
  genders: string[] = ["Male", "Female", "Other"];
  maritialStatus: string[] = ["Single", "Married"];
  courseList: string[] = ["Vocational", "Under Graduate/Secondary School", "Graduate", "Post Graduate/Mastes"];
  occupationList: string[] = ["Student", "SelfEmployed", "GOVT", "PVT", "Others"];
  currentDate: Date = new Date();
  cities: City[] = [];
  franchiseTypes: string[] = [];

  selectedStateCode: number;
  selectedDistrictCode: number[];
  cityControl = new UntypedFormControl();
  districtControl = new UntypedFormControl();
  cityControl2 = new UntypedFormControl();
  districtControl2 = new UntypedFormControl();

  franchiseFaqs: any = [];

  //Subscription
  private onCreateSetUpClickSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;
  private stateSubcription: Subscription;
  private districtListSubscription: Subscription;
  private webOrderFranchiseSubcription: Subscription;
  private cityListSubscription: Subscription;
  private getVisitorCountSubscription: Subscription;
  private getClientIPSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private storeService: StoreService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private userService: UserService,
    private franchaiseService: FranchiseService,
    public dialog: MatDialog,
    private fileService: FileService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private mapsAPILoader: MapsAPILoader,
  ) {
    const today = new Date();
    this.currentDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  }

  ngOnInit() {
    this.currentUrl = getWindow().location.hostname;
    this.onCreateSetUp();
    this.getWebOrderFranchiseSetUpList();
    this.getGoogleMap();
    this.getClientIP();
    // this.getStateList();
    this.getVisitorCount();


    // jQuery(function ($) {
    //   $(document).ready(function () {
    //     "use strict";
    //     var offset = 100;
    //     $(window).scroll(function () {
    //       if ($(this).scrollTop() < offset) {
    //         $(".back-to-top1").fadeIn(400);
    //       } else {
    //         $(".back-to-top1").fadeOut(400);
    //       }
    //     });
    //   });
    // });
  }

  getSanitizedAnswer(answer: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml("A. " + answer);
  }

  getWebOrderFranchiseSetUpList() {
    this.franchiseFaqs = this.fileService.getFileData("franchiseFaqs")
    // this.webOrderFranchiseSubcription = this.franchaiseService.getWebOrderFranchiseSetup(this.apiFactory.getFranchiseSetupDetails, this.currentUrl)
    //   .subscribe((data) => {
    let data: WebOrderFranchiseSetup[] = this.fileService.getFileData("franchiseSetupList");
    if (data.length > 0) {
      this.franchiseSetupList = data;
      console.log(this.franchiseSetupList);

      this.franchiseSetupList.forEach(element => {
        if (element.franchiseType == this.franchiseTypeList[0]) {
          this.masterFranchiseList.push(element);
          this.isShowMasterFranchise = true;
        }
        else if (element.franchiseType == this.franchiseTypeList[1]) {
          this.franchiseStoreList.push(element);
          this.isShowFranchiseStore = true;
        }
        else if (element.franchiseType == this.franchiseTypeList[2]) {
          this.kioskList.push(element);
          this.isShowKiosk = true;
        }
      })
      this.franchiseSetupList.forEach(item => {
        item.cities.forEach(e => {
          if (!this.cities.map(f => f.refNo).includes(e.refNo))
            this.cities.push(e);
        })
        this.cities.sort((a, b) => (a.cityName.toLocaleUpperCase() > b.cityName.toLocaleUpperCase()) ? 1 : ((b.cityName.toLocaleUpperCase() > a.cityName.toLocaleUpperCase()) ? -1 : 0))
        if (!this.franchiseTypes.map(f => f).includes(item.franchiseType))
          this.franchiseTypes.push(item.franchiseType);
      });
      if (this.franchiseTypes.length == 1)
        this.createSetUpForm.get('franchiseType').setValue(this.franchiseTypes[0]);
      this.getStateList();
    }
    // });
  }

  getStateList() {
    // this.stateSubcription = this.storeService.getStateList().subscribe((data) => {
    let data: State[] = this.fileService.getFileData("stateList");
    if (data.length > 0) {
      this.stateList[0] = data.filter(e => this.masterFranchiseList.map(f => f.stateCode).includes(e.refNo));
      this.stateList[1] = data.filter(e => this.franchiseStoreList.map(f => f.stateCode).includes(e.refNo));
      this.stateList[2] = data.filter(e => this.kioskList.map(f => f.stateCode).includes(e.refNo));
    }
    // });
  }

  onStateSelect(stateCode: number, type: string) {
    if (type == this.franchiseTypeList[1])
      this.districtControl.reset();
    else if (type == this.franchiseTypeList[2])
      this.districtControl2.reset();

    this.selectedStateCode = stateCode;
    // this.districtListSubscription = this.storeService.getDistrictByStateCode(this.apiFactory.getDistrictByStateCode, stateCode)
    //   .subscribe((data) => {
    let data: LocationDetailsForStore = this.fileService.getFileData("locationDetailsForStore");

    let stateWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && e.stateCode == stateCode);
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = stateWiseList;
      this.districtList[0] = data.details.filter(e => this.masterFranchiseList.map(f => f.districtCode).includes(e.id));
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = stateWiseList;
      this.districtList[1] = data.details.filter(e => this.franchiseStoreList.map(f => f.districtCode).includes(e.id));
    }
    else if (type == this.franchiseTypeList[2]) {

      this.kioskList = stateWiseList;
      this.districtList[2] = data.details.filter(e => this.kioskList.map(f => f.districtCode).includes(e.id));
    }
    // });
  }

  onDistrictSelect(districtCode: number, type: string, index: number) {
    if (type == this.franchiseTypeList[1])
      this.cityControl.reset();
    else if (type == this.franchiseTypeList[2])
      this.cityControl2.reset();

    this.selectedDistrictCode = districtCode == 0 ? this.districtList[index].map(e => e.id) : [districtCode];
    // this.cityListSubscription = this.storeService.getCitiesByDistrictCode(this.apiFactory.getCitiesByDistrictCode, this.selectedDistrictCode)
    //   .subscribe((data) => {
    let data: City[] = this.fileService.getFileData("city");
    let cityWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && (districtCode == 0 ? e.stateCode == this.selectedStateCode : e.districtCode == districtCode));
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = cityWiseList;
      this.cityList[0] = data.filter(e => this.masterFranchiseList.map(f => f.cityCode).includes(e.refNo));
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = cityWiseList;
      this.cityList[1] = data.filter(e => this.franchiseStoreList.map(f => f.cityCode).includes(e.refNo));
    }
    else if (type == this.franchiseTypeList[2]) {
      this.kioskList = cityWiseList;
      this.cityList[2] = data.filter(e => this.kioskList.map(f => f.cityCode).includes(e.refNo));
    }
    // });
  }

  onCitySelect(cityCode: number, type: string) {
    let cityWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && (cityCode == 0 ? this.selectedDistrictCode.includes(e.districtCode) : e.cityCode == cityCode));
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = cityWiseList;
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = cityWiseList;
    }
    else if (type == this.franchiseTypeList[2]) {
      this.kioskList = cityWiseList;
    }
  }

  onCityClick(selectedCity: WebOrderFranchiseSetup) {
    console.log(selectedCity);
    this.createSetUpForm.patchValue({
      cityApplied: this.cities.filter(e => e.refNo == selectedCity.cityCode)[0]?.cityName,
      franchiseType: selectedCity.franchiseType,
      areaApplied: selectedCity.cityName,
    })
    this.scrollToElementById("name");
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      name: [null, [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]],
      gender: [null, Validators.required],
      dob: [null, Validators.required],
      maritialStatus: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern("[6-9]\\d{9}"), Validators.maxLength(10)]],
      email: [null, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      education: [null, Validators.required],
      occupation: [null, Validators.required],
      franchiseType: [null, Validators.required],
      cityApplied: [''],
      areaApplied: [null, Validators.required],
      latitude: [null, Validators.required],
      longitude: [null, Validators.required],
      ipAddress: [null, Validators.required],
    });
  }

  onCreateSetUpClick() {
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid form");
      return;
    }
    if (!this.createSetUpForm.get('latitude').value && !this.createSetUpForm.get('longitude').value) {
      this.toastService.showErrorToast("You don't have location permission");
      return;
    }
    if (!this.isDisabledSubmit && this.otpVerficationStatus == 'No') {
      this.toastService.showErrorToast("Verify WhatsApp No");
      return;
    }
    if (!this.isDisabledSubmit && this.otpVerficationStatus == 'Exceeds') {
      this.toastService.showErrorToast("Maximum number of franchise inquiries per system reached, try tomorrow.");
      return;
    }

    this.onCreateSetUpClickSubscription = this.franchaiseService.onCreateSetUp(this.apiFactory.insertFranchise, this.createSetUpForm)
      .subscribe((data) => {
        if (data && data == 'Success') {
          if (this.createSetUpForm.valid) {
            this.onCreateSetUpClickSubscription = this.franchaiseService.sendMessageByMailChimpFromFranchaise(this.apiFactory.sendMessageByMailChimpFromFranchaise, this.createSetUpForm, this.uploadedFile)
              .subscribe((data) => {
                this.toastService.showSuccessToast("Thank you for your intrest. We shall talk to you soon.");
                this.isDisabledSubmit = false;
                this.otpVerficationStatus = 'No';
                this.createSetUpForm.reset();
                this.getClientIP();
                this.getGoogleMap();
              });
          }
        } else {
          this.toastService.showErrorToast(data);
        }
      });
  }

  onVerifyClick() {
    if (this.createSetUpForm.get("phone").invalid) {
      this.toastService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.createSetUpForm.get("phone").value,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result && result.result) {
        this.isDisabledSubmit = result;
        this.phoneNo = result.mblNo;
      }
    });
  }

  otpVerficationStatus: string = 'No';
  checkOtp(mblNo: string) {
    let ipAddress: string = this.createSetUpForm.get('ipAddress').value;
    if (mblNo.length == 10) {
      this.checkNumberVerfiedSubscription = this.franchaiseService.checkOTPSentOrNotWithIpAddress(this.apiFactory.checkOTPSentOrNotWithIpAddress, mblNo, ipAddress).subscribe((data: string) => {
        this.otpVerficationStatus = data;
        if (data == "Yes") {
          this.isDisabledSubmit = true;
        }
        else if (data == "No") {
          this.isDisabledSubmit = false;
        }
        else {
          this.toastService.showErrorToast("Maximum number of franchise inquiries per system reached, try tomorrow.");
          this.isDisabledSubmit = false;
        }
      });
    } else {
      this.isDisabledSubmit = false;
      this.otpVerficationStatus = 'No';
    }
  }

  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.createSetUpForm.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }

  getClientIP() {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        const ip = xhttp.responseText;
        console.log(ip);
        this.createSetUpForm.get('ipAddress').setValue(ip); // Set the value directly here
      }
    };
    xhttp.open("GET", "https://api.ipify.org", true); // Ensure the URL starts with https://
    xhttp.send();
    // var xhttp = new XMLHttpRequest();
    // xhttp.onreadystatechange = function () {
    //   if (this.readyState == 4 && this.status == 200) {
    //     console.log(this.responseText);
    //     return this.responseText;
    //   }
    // }; xhttp.open("GET", "//api.ipify.org", true);
    // xhttp.send();
  }

  getVisitorCount() {
    this.getVisitorCountSubscription = this.userService.getVisitorCount(this.apiFactory.getVisitorCount, "franchise").subscribe((data: any) => {
      console.log("get Vistor Count franchise:::" + data);
      this.visitors = data;
    });
  }

  isExpand: boolean = false;
  onExpandClick(value) {
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
    this.isReadonly = true;
  }
}


