<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Payment History</h2>
    <div class="hr"></div>
  </div>
</div>

<div id="table-container" style="width: 96% !important;overflow: auto;padding: 2% 0;margin-left: 2%;margin-right: 2%;">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0"
    style="border-collapse: collapse; width: 100%">
    <caption></caption>

    <ng-container matColumnDef="dateTime">
      <th scope="col" mat-header-cell *matHeaderCellDef>Date & Time</th>
      <td mat-cell *matCellDef="let element" id="text_align">
        {{ element.dateAndTime | date: 'yyyy-MM-dd HH:mm:ss'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th scope="col" mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element" id="number_align">
        {{ element.amount }}
      </td>
    </ng-container>

    <ng-container matColumnDef="transId">
      <th scope="col" mat-header-cell *matHeaderCellDef>Transaction Id</th>
      <td mat-cell *matCellDef="let element" id="text_align">
        {{ element.transactionId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="bankTransId">
      <th scope="col" mat-header-cell *matHeaderCellDef>Bank Transaction Id</th>
      <td mat-cell *matCellDef="let element" id="text_align">
        <span *ngIf="element.bankTransactionId != 'null'"> {{ element.bankTransactionId }}</span>
        <span *ngIf="!element.bankTransactionId || element.bankTransactionId == 'null'"> Not Available</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th scope="col" mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element" id="text_align">
        {{ element.transactionsStatus }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>