<form [formGroup]="createSetUpForm">
    <div class="section-heading">
        <h5>Delivery Pincode</h5>
    </div>
    <!-- <h5>Delivery Pincode</h5> -->
    <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 12px;margin-right: 12px;">
        <input type="text" style="width: 40%;margin-left: 30%;text-align: center;" name="pincode" class="form-control input-field" maxlength="6" placeholder="Pin Code" formControlName="pincode" (keyup)="choosePincode()" required />
    </div>

    <!-- <div class="row" fxLayout="row" fxLayout.lt-sm="column" *ngIf="showClickButton">
    <input type="button" value="Proceed" (click)="choosePincode()" class="btn btn-primary" style="left: 37%;" />
  </div> -->

    <!-- <div
    *ngFor="let item of locationSetupList"
    class="row"
    fxLayout="row"
    fxLayout.lt-sm="column"
    style="margin-top: 2%;"
  >
    &nbsp;&nbsp;&nbsp;&nbsp; <input type="radio" /> &nbsp;&nbsp;&nbsp;&nbsp;
    <span>{{ item.location.locationName }}</span>
  </div> -->
    <div style="height: 30px;" *ngIf="showToast">
        <p class="h7">Delivery to this pincode {{pincode}} is currently not available</p>
    </div>

    <div class="section-heading" style="height: 30px;" *ngIf="!showClickButton">
        <h6 style="margin-top: 15px;">Select Location</h6>
    </div>
    <div class="button-box col-lg-12" *ngFor="let item of locationSetupList" style="display: flex;margin-top: 5px;margin-bottom: 10px;height: 125px;">
        <div class="button-wrapper">
            <!-- <a href="mylink.php" class="btn btn-info" role="button">{{ item.location.locationName }}</a> -->
            <a class="btn" id="submit_btn">
                <!-- <div class="btn-line"></div> -->
                <div class="btn-line btn-line-shift" style="cursor: default;height: 120px;">
                    <div style="color: #121111;font-size: 12px;">{{ item.locationName }}</div>
                    <div style="text-align: center;color: #4a3737;font-size: 12px;top: -5px;">{{ item.address1 }}</div>
                    <div style="text-align: center;color: #4a3737;font-size: 12px;">{{ item.address2 }}</div>
                    <div style="text-align: center;color: #4a3737;font-size: 12px;">{{ item.pincode }}</div>
                    <div style="position: absolute;
                    right: 0px; bottom: 5px;
                    width: 200px;
                    height:40px;">
                        <button (click)="onCreateSetUpClick(item)" type="button" type="button" class="section-heading" style="
                        border: none;
                        color: white;
                        padding: 5px 16px;
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        font-size: 16px;
                        margin: 4px 2px;
                        font-family: Roboto;
                        border-radius: 10%;
                        cursor: pointer;bottom: 10px;">Proceed
                            <mat-icon style="color: white;font-size: 20px;vertical-align: middle;">arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
                Send Message
            </a>
        </div>
    </div>
    <!-- <div class="row">
    <div class="col-md-12">
      <div class="card" *ngFor="let item of locationSetupList">
        <h3>{{ item.location.locationName }}</h3>
      </div>
    </div>
  </div> -->

    <!-- <div class="row" fxLayout="row" fxLayout.lt-sm="column" *ngIf="showSubmitBtn">
    <a class="btn" id="submit_btn" style="left: 30%;">
      <div class="btn-line"></div>
      <button type="button"
        type="submit"
        class="btn-line btn-line-shift"
        style="background-color: #d0324b; color: white;"
      >
        Submit
      </button>
      Send Message
    </a>
  </div> -->
</form>




<!-- <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
  <div class="section-heading">
    <h5>Delivery Pincode</h5>
  </div>
  <div class="row" fxLayout="row" fxLayout.lt-sm="column" style="margin-left: 12px;margin-right: 12px;">
    <input type="text" name="pincode" style="width: 40%;margin-left: 30%;text-align: center;"
      class="form-control input-field" maxlength="6" placeholder="Pin Code" formControlName="pincode" required />
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" style="margin-right: 12px;">
    <a class="btn" id="submit_btn" style="left: 30%;">
      <div class="btn-line"></div>
      <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white;">
        Proceed
      </button>
      Send Message
    </a>
  </div>
</form> -->