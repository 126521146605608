import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WalletTransaction } from "../models/wallet-transaction";
import { WebUserInfo } from "../models/web-user-info";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  paymentStatusData: any;

  constructor(private http: HttpClient) { }

  saveFCMRegistrationData(getUsersaveTokenIdLocId: any, fCMMessageRegistration: any) {
    return this.http.post(getUsersaveTokenIdLocId, fCMMessageRegistration, {
      responseType: "json",
    });
  }

  getCCAvenueCred(url: string, order_id: string, amount: string) {
    return this.http.get(url + "/" + order_id + "/" + amount, {
      responseType: "text",
    });
  }

  saveWalletTransaction(url: string, walletTransaction: WalletTransaction) {
    return this.http.post<any>(url, walletTransaction, {
      responseType: "json",
    });
  }

  getWalletTransaction(url: string, email: string) {
    return this.http.get<any>(url + "/" + email, {
      responseType: "json",
    });
  }

  getClientIP(getClientIP: string) {
    return this.http.get(getClientIP, {
      responseType: "text",
    });
  }

  saveWebUserInfo(url: string, webUserInfo: WebUserInfo) {
    return this.http.post<any>(url, webUserInfo, {
      responseType: "json",
    });
  }

  checkPaytmStatus(url: string, transNo: string) {
    return this.http.get<any>(url + "/" + transNo)
  }

  getLocationByName(url: string, name: string) {
    return this.http.get<any>(url + "/" + name)
  }
  
}
