import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import moment from "moment";
import { Moment } from "moment";
import { interval, Subscription, timer } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CustomerDTO } from "src/app/common/customerDto";
import { CustomerDeliveryAddress } from "src/app/shared/models/customerdeliveryaddress";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { GstSaleInvCumBosDto } from "src/app/shared/models/gstsaleinvcumbosdto";
import { ProductOrderSummary } from "src/app/shared/models/productordersummary";
import { UpiCodeResponse } from "src/app/shared/models/upicoderesponse";
import { UpiPayment } from "src/app/shared/models/upipayment";
import { CartPaymentService } from "src/app/shared/services/cart-payment.service";
import { CommonService } from "src/app/shared/services/CommonService";
import { ProductOrderSummaryService } from "src/app/shared/services/product-order-summary.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { map, takeWhile } from "rxjs/operators";
import { IciciTransactionStatus } from "src/app/shared/models/icicitransactionstatus";
import { UpiPaymentDetails } from "src/app/shared/models/upi-payment-details";
import { UserService } from "src/app/shared/services/user.service";
import { WalletConfirmationDialogComponent } from "../wallet-confirmation-dialog/wallet-confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { WalletTransaction } from "src/app/shared/models/wallet-transaction";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { getWindow } from "ssr-window";
import { WalletOfferRequestDto } from "src/app/shared/models/wallet-offer-request-dto";
import { WalletOffers } from "src/app/shared/models/wallet-offers";
import { CCAvenueDataFormat } from "src/app/shared/models/CCAvenueDataFormat";
import { GatewayPaymentSetup } from "src/app/shared/models/gateway-payment-setup";
import { WebOrderLocationSetup } from "src/app/shared/models/weborderlocationsetup";

@Component({
  selector: "app-process-payment",
  templateUrl: "./process-payment.component.html",
  styleUrls: ["./process-payment.component.scss"],
})
export class ProcessPaymentComponent implements OnInit {

  encRequest: string;
  accessCode: string = "AVJV96KA85BA58VJAB";
  @ViewChild("form") form: ElementRef;
  randomString: string;
  productOrderSummary: ProductOrderSummary = new ProductOrderSummary();
  amount: string;
  walletBalance: number = 0;
  avlWalletAmt: number = 0;
  payerVa: string = "";
  orderId: string;
  merchantTranId: string;
  merchantTnxId: string;
  upiCodeResponse: UpiCodeResponse;
  qrCodeUpi: string;
  in: any = null;
  inSub: any = null;
  private sub;
  value: number = 0;
  upiPayment: UpiPayment = new UpiPayment();
  iciciTransactionStatus: IciciTransactionStatus;
  isShowUpi: boolean = false;
  showQrCodeLabel: boolean = true;
  showQrCode: boolean = false;
  disableQrBtn: boolean = true;
  disableVpaBtn: boolean = true;
  isApiCall: boolean = true;

  createSetUpForm: UntypedFormGroup;

  gstSaleInvcumBOS: GstSaleInvCumBosDto = new GstSaleInvCumBosDto();
  gstSaleInvcumBOSArray: GstSaleInvCumBosDto[] = [];
  customerDTO: CustomerDTO = new CustomerDTO();
  deliveryAddress: CustomerDeliveryAddress = new CustomerDeliveryAddress();
  googleCustomer: GoogleCustomer;

  walletTransaction: WalletTransaction[] = [];
  isWalletUsed: boolean = false;
  isShowPaymentBtn: boolean = true;
  availabeAmount: number = 0;
  usedWallet: number = 0;
  walletOfferRequestDto: WalletOfferRequestDto = new WalletOfferRequestDto();
  offerList: WalletOffers[] = [];
  cCAvenueurl: string;
  currentUrl: string;
  storeLocation: WebOrderLocationSetup = new WebOrderLocationSetup();
  parentLocationCode: number;

  private productMinMaxPriceSubscription: Subscription;
  private onWebOrderInsertSubscription: Subscription;
  private onCreateSetUpSubscription: Subscription;
  private qrCodeSubscription: Subscription;
  private cartSetUpSubscription: Subscription;
  private onUpiSetUpSubscription: Subscription;
  private onViewWalletSubscription: Subscription;
  private getWalletSetupSubscription: Subscription;
  private getProfileSubscription: Subscription;
  private getwalletOfferSubscription: Subscription;
  private sendRequestGatewaySubscription: Subscription;
  private getGatewaySetupSubscription: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    private navRoute: Router,
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private toastService: ToastService,
    private productOrderSummaryService: ProductOrderSummaryService,
    private apiUrl: APIFactoryEndPoints,
    private userService: UserService,
    private cartPaymentService: CartPaymentService
  ) {

  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    console.log(this.googleCustomer)
    this.storeLocation = JSON.parse(this.localStorage.getItem("pickup_location"));
    this.parentLocationCode = JSON.parse(this.localStorage.getItem("parent_location_code"));
    this.onCreateSetUp();
    this.getProductOrderSummaryDetails();
  }

  getProfileDetails() {
    this.getProfileSubscription = this.userService.getProfiledetails(this.apiUrl.getGoogleCustomer, this.googleCustomer.email, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data) {
          this.getWalletSetupByUrl(data);
        }
      });
  }

  getWalletSetupByUrl(customer: any) {
    this.getWalletSetupSubscription = this.commonService.getWalletTransaction(this.apiUrl.getWalletSetupByUrl, this.currentUrl)
      .subscribe((data: any) => {
        console.log(data);
        let dobDate: Date = null;
        let anivDate: Date = null;
        let transDate: Date = null;
        let currDate: Date = new Date();
        let diffdays: number = 365;
        if (data) {
          this.walletTransaction.forEach(element => {
            if (element.specialOffer != null)
              transDate = new Date(element.transactionDate);
          })
          if (transDate)
            diffdays = Math.floor((Date.UTC(currDate.getFullYear(), currDate.getMonth(), currDate.getDate()) - Date.UTC(transDate.getFullYear(), transDate.getMonth(), transDate.getDate())) / (1000 * 60 * 60 * 24));

          if (diffdays >= (365 - data.eventBeforeDays)) {
            if (data.eventType == "Birthday")
              dobDate = new Date(customer.dob);
            else if (data.eventType == "Anniversary")
              anivDate = new Date(customer.anniversaryDate);
            else if (data.eventType == "Both") {
              dobDate = new Date(customer.dob);
              anivDate = new Date(customer.anniversaryDate);
            }

            if (dobDate && dobDate.getMonth() == currDate.getMonth() && (dobDate.getDate() - data.eventBeforeDays) <= currDate.getDate() && dobDate.getDate() >= currDate.getDate()) {
              this.walletBalance = this.walletBalance + data.eventCreditAmount;
              this.gstSaleInvcumBOS.walletPayment.specialOfferAmount = data.eventCreditAmount;
              this.gstSaleInvcumBOS.walletPayment.specialOffer = "Birthday";
            }
            else if (anivDate && anivDate.getMonth() == currDate.getMonth() && (anivDate.getDate() - data.eventBeforeDays) <= currDate.getDate() && anivDate.getDate() >= currDate.getDate()) {
              this.walletBalance = this.walletBalance + data.eventCreditAmount;
              this.gstSaleInvcumBOS.walletPayment.specialOfferAmount = data.eventCreditAmount;
              this.gstSaleInvcumBOS.walletPayment.specialOffer = "Anniversary";
            }
          }
        }
      });
  }

  getProductOrderSummaryDetails() {
    this.productOrderSummary = this.productOrderSummaryService.getOrderSummary();
    console.log(this.productOrderSummary);
    if (this.productOrderSummary == undefined) {
      this.navRoute.navigate(["/cart-items"]);
    } else {
      console.log(this.productOrderSummary.totalPrice.toFixed(2));
      this.amount = this.productOrderSummary.totalPrice.toFixed(2);
      this.availabeAmount = Number(this.amount);
      this.getWebOrders();
    }
    // this.amount = "800";
    // this.availabeAmount = Number(this.amount);
    // this.getWebOrders();
  }

  getWebOrders() {
    this.gstSaleInvcumBOS = this.productOrderSummaryService.getWebOrder();
    console.log(this.gstSaleInvcumBOS);

    this.gstSaleInvcumBOSArray = this.productOrderSummaryService.getWebOrderArray();
    console.log(this.gstSaleInvcumBOSArray);

    this.getWalletHistory();
  }

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      payerVa: ["", [Validators.required, Validators.pattern("[A-Za-z0-9.]*@[a-z]*")]],
      amount: [""],
      locId: [""],
      paymentType: [""],
    });
  }

  payWithCCavenue() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    this.orderId = this.getRandomString();
    // this.getCCAvenueData();

    this.getGatewaySetupSubscription = this.cartPaymentService.getGatewaySetup(this.apiUrl.getGatewayPaymentSetup, locationCode, "CCAvenue").subscribe((data: GatewayPaymentSetup) => {
      if (data) {
        this.getCCAvenueData(data);
      }
    });

    // this.getTempdata();
    // this.getTransactionDetailsCCAvenue();

    // this.form.nativeElement.submit();
    // console.log(this.productOrderSummaryService.getWebOrder());
    // this.navRoute.navigate(["/payment-status"]);
  }

  getTempdata() {
    this.onWebOrderInsertSubscription = this.cartPaymentService.getTempData(this.apiUrl.getWebOrderTemp, "WVYUBN1686038276828")
      .subscribe((data) => {
        console.log(data);
      });
  }

  getCCAvenueData(data: GatewayPaymentSetup) {
    let ccAvenue: CCAvenueDataFormat = new CCAvenueDataFormat();
    ccAvenue.amount = this.availabeAmount;
    ccAvenue.orderId = this.orderId;
    ccAvenue.merchantId = data.merchantId;
    ccAvenue.source = "1bakeshop.com";
    var fiveMinutesLater = new Date();
    console.log(fiveMinutesLater)
    fiveMinutesLater.setMinutes(fiveMinutesLater.getMinutes() + 5);
    console.log(fiveMinutesLater)

    ccAvenue.validTill = moment(fiveMinutesLater).format("yyyy-MM-DDTHH:mm:ss");

    let addressData: CustomerDeliveryAddress;
    let loginEmail: string;
    if (this.gstSaleInvcumBOS.details.length != 0) {
      addressData = this.gstSaleInvcumBOS.customerDeliveryAddress;
      loginEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
    } else if (this.gstSaleInvcumBOSArray.length != 0) {
      addressData = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;
      loginEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
    }

    if (addressData) {
      ccAvenue.name = addressData.contactName;
      ccAvenue.billing_name = addressData.contactName;
      ccAvenue.billing_address = addressData.shippingAddress;
      ccAvenue.billing_city = addressData.cityName;
      ccAvenue.billing_state = addressData.stateName;
      ccAvenue.billing_zip = addressData.shippingPinCode.toString();
      ccAvenue.billing_country = "India";
      ccAvenue.billing_tel = addressData.contactMobile.toString();
      ccAvenue.billing_email = loginEmail;
    }
    console.log(JSON.stringify(ccAvenue))
    this.sendRequestGatewaySubscription = this.commonService.checkPaytmStatus(this.apiUrl.sendGatewayPaymentRequest, JSON.stringify(ccAvenue)).subscribe((encdata: any) => {
      if (encdata) {
        console.log(encdata);
        this.cCAvenueurl = "https://payment.mobitrix.net/ccavenue?data=" + encdata.encResp + "&access=" + data.accessCode
          + "&orderid=" + ccAvenue.orderId + "&sourceLogo=https://1bakeshop.com/assets/img/logo.webp";
        console.log(this.cCAvenueurl);
        this.getTransactionDetailsCCAvenue();
      }
      else {
        this.toastService.showErrorToast('Server side error');
      }
    });
    // this.orderId = this.getRandomString();
    // this.productMinMaxPriceSubscription = this.commonService.getCCAvenueCred(this.apiUrl.ccAvenuePayment, this.orderId, this.availabeAmount.toString())
    //   .subscribe((data: any) => {
    //     this.encRequest = data;
    //     this.getTransactionDetailsCCAvenue();
    //   });
  }
  getTransactionDetailsCCAvenue() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
    let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

    if (this.gstSaleInvcumBOS.details.length != 0) {
      let momentDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
      let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
      this.gstSaleInvcumBOS.locationCode = locationCode;
      this.gstSaleInvcumBOS.invoicingLocationCode = this.parentLocationCode;
      this.gstSaleInvcumBOS.deliveryDate = formattedDate;
      this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
      this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;
      this.gstSaleInvcumBOS.url = this.currentUrl;
      this.gstSaleInvcumBOS.customerCode = this.googleCustomer.refNo;
      this.gstSaleInvcumBOS.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
      console.log(this.gstSaleInvcumBOS);

      this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;
      this.gstSaleInvcumBOS.customer = this.customerDTO;

      this.gstSaleInvcumBOS.walletPayment.amount = this.usedWallet;
      this.gstSaleInvcumBOS.walletPayment.fromLocationCode = locationCode;

      this.gstSaleInvcumBOS.totalAmt = Number(this.amount);

      // this.gstSaleInvcumBOS.gatewayPayment.amount = data["amount"];
      // this.gstSaleInvcumBOS.gatewayPayment.fromLocationCode = locationCode;
      // this.gstSaleInvcumBOS.gatewayPayment.paymentType = "CCAvenue";
      // this.gstSaleInvcumBOS.gatewayPayment.gatewayPaymentResponseCode = data["ref_no"];

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderTemp(this.apiUrl.insertWebOrderTemp, this.orderId, [this.gstSaleInvcumBOS])
        .subscribe((data) => {
          console.log(data);
          window.location.href = this.cCAvenueurl;

        });

    }

    if (this.gstSaleInvcumBOSArray.length != 0) {
      this.gstSaleInvcumBOSArray.forEach((element) => {
        let momentDate = new Date(element.details[0].deliveryDate);
        let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
        element.deliveryDate = formattedDate;
        element.deliveryTime = element.details[0].deliveryTime;
        element.locationCode = locationCode;
        element.invoicingLocationCode = this.parentLocationCode;
        element.orderFromTvLocation = orderFromTvLocation;
        element.url = this.currentUrl;
        element.customerCode = this.googleCustomer.refNo;
        element.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

        element.walletPayment.amount = this.usedWallet;
        element.walletPayment.fromLocationCode = locationCode;

        element.totalAmt = Number(this.amount);

        // element.gatewayPayment.amount = data["amount"];
        // element.gatewayPayment.fromLocationCode = locationCode;
        // element.gatewayPayment.paymentType = "CCAvenue";
        // element.gatewayPayment.gatewayPaymentResponseCode = data["ref_no"];
      });
      this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;

      this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

      console.log(this.gstSaleInvcumBOSArray);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderTemp(this.apiUrl.insertWebOrderTemp, this.orderId, this.gstSaleInvcumBOSArray)
        .subscribe((data) => {
          console.log(data);
          //this.sendUpiRequest(this.orderId);
          window.location.href = this.cCAvenueurl;
        });
    }
  }

  payWithUpi() {
    this.isShowUpi = true;
  }

  //For send payment request click
  onSendNotificationClick() {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));

    this.createSetUpForm.patchValue({
      // amount: this.amount,
      amount: this.availabeAmount,
      payerVa: this.payerVa,
      locId: locationCode,
      paymentType: "B2C",
    });
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastService.showErrorToast("Invalid");
      return;
    }

    this.onCreateSetUpSubscription = this.cartPaymentService.sendUpiPayment(this.apiUrl.sendUpiPayment, this.createSetUpForm).subscribe((data) => {
      console.log(data);
      this.merchantTranId = data;
      this.disableQrBtn = false;
      this.disableVpaBtn = false;
      if (this.merchantTranId != null) {
        this.cartPaymentService.setUpiPayment(this.merchantTranId, locationCode);
        this.gstSaleInvcumBOS.locationCode = locationCode;
        this.gstSaleInvcumBOS.invoicingLocationCode = this.parentLocationCode;
        this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
        this.saveTempDataForUpi(this.merchantTranId);
        // this.click();
      } else {
        this.toastService.showErrorToast("Payment Failed..!");
      }
    });
  }

  onQrCodeBtnClick() {
    var locId: number = JSON.parse(this.localStorage.getItem("location_code"));
    var paymentType = "B2C";
    var amount = Number(this.availabeAmount);
    // var amount = Number(this.amount);

    this.qrCodeSubscription = this.cartPaymentService.getQrCode(this.apiUrl.getQrForUpi, locId, amount, paymentType).subscribe((data: any) => {
      if (null != data) {
        this.upiCodeResponse = data;
        this.qrCodeUpi = this.upiCodeResponse.qrString;
        this.merchantTnxId = this.upiCodeResponse.merchantTnxId;
        this.showQrCodeLabel = false;
        this.showQrCode = true;
        this.disableVpaBtn = false;
        this.disableQrBtn = false;
        if (this.merchantTnxId != null) {
          this.cartPaymentService.setUpiPayment(this.merchantTnxId, locId);
          this.gstSaleInvcumBOS.locationCode = locId;

          this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
          this.saveTempDataForUpi(this.merchantTnxId);
          //this.click();
        }
      } else {
        this.showQrCodeLabel = true;
        this.showQrCode = false;
        this.toastService.showErrorToast("Server side error");
      }
    });
  }

  saveTempDataForUpi(orderId: string) {
    let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
    let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
    let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

    if (this.gstSaleInvcumBOS.details.length != 0) {
      let momentDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
      let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
      this.gstSaleInvcumBOS.locationCode = locationCode;
      this.gstSaleInvcumBOS.locationCode = this.parentLocationCode;
      this.gstSaleInvcumBOS.deliveryDate = formattedDate;
      this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
      this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;
      this.gstSaleInvcumBOS.url = this.currentUrl;
      this.gstSaleInvcumBOS.customerCode = this.googleCustomer.refNo;
      this.gstSaleInvcumBOS.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

      this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
      console.log(this.gstSaleInvcumBOS);

      this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;
      this.gstSaleInvcumBOS.customer = this.customerDTO;

      this.gstSaleInvcumBOS.walletPayment.amount = this.usedWallet;
      this.gstSaleInvcumBOS.walletPayment.fromLocationCode = locationCode;

      this.gstSaleInvcumBOS.totalAmt = Number(this.amount);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderTemp(this.apiUrl.insertWebOrderTemp, orderId, [this.gstSaleInvcumBOS])
        .subscribe((data) => {
          console.log(data);
          // this.click();
          this.sendUpiRequest(orderId);
        });
    }

    if (this.gstSaleInvcumBOSArray.length != 0) {
      this.gstSaleInvcumBOSArray.forEach((element) => {
        let momentDate = new Date(element.details[0].deliveryDate);
        let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
        element.deliveryDate = formattedDate;
        element.deliveryTime = element.details[0].deliveryTime;
        element.locationCode = locationCode;
        element.invoicingLocationCode = this.parentLocationCode;
        element.orderFromTvLocation = orderFromTvLocation;
        element.url = this.currentUrl;
        element.customerCode = this.googleCustomer.refNo;
        element.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

        element.walletPayment.amount = this.usedWallet;
        element.walletPayment.fromLocationCode = locationCode;

        element.totalAmt = Number(this.amount);

      });
      this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

      this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
      this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
      this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
      this.customerDTO.stateCode = stateCode;
      this.customerDTO.fromLocationCode = locationCode;
      this.customerDTO.location.refNo = locationCode;
      this.customerDTO.location.stateCode = stateCode;

      this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

      console.log(this.gstSaleInvcumBOSArray);

      this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderTemp(this.apiUrl.insertWebOrderTemp, orderId, this.gstSaleInvcumBOSArray)
        .subscribe((data) => {
          console.log(data);
          // this.click();
          this.sendUpiRequest(orderId);
        });
    }
  }

  sendUpiRequest(merchantTranId: string) {
    const eventSource = new EventSource(this.apiUrl.initiateNotification + merchantTranId);
    eventSource.addEventListener('message', (e) => {
      console.log(e.data);
      console.log(JSON.stringify(e.data));
      if (JSON.stringify(e.data).includes('Success :')) {
        console.log(JSON.stringify(e.data).split(":")[1].split("====")[0]);
        if (this.gstSaleInvcumBOS.details.length != 0) {
          let pids: string = JSON.stringify(e.data).split(":")[1].split("====")[0];
          console.log(pids);
          let id: number[] = [];
          pids.split(",").forEach(element => {
            id.push(Number(element));
          });
          this.cartSetUpSubscription = this.userService
            .removeAllProductCart(this.apiUrl.removeAllProductFromCart, this.googleCustomer.email, id).subscribe((data: any) => {
              console.log(data);
              this.navRoute.navigate(["/payment-success"], {
                state: { data: "reload" },
              });
            });
        }

        if (this.gstSaleInvcumBOSArray.length != 0) {
          this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiUrl.emptyProductCart, this.googleCustomer.email)
            .subscribe((data: any) => {
              console.log(data.Res);
              this.navRoute.navigate(["/payment-success"], {
                state: { data: "reload" },
              });
            });
        }
      }
      else {
        console.log(JSON.stringify(e.data));
        this.navRoute.navigate(["/payment-fail"]);
      }
      eventSource.close();
    });
  }

  click() {
    // this.sub && this.sub.unsubscribe(); //to unsubscribe if not
    // this.in = interval(25000).subscribe((x) => {
    //   this.upiPayment = this.cartPaymentService.getUpiPayment();
    //   console.log(this.upiPayment);
    //   this.in.unsubscribe();
    //   this.getTransactionDetailsUpi2();
    //   // this.getTransactionDetailsUpi();
    // });

    // this.sub = this.interval(120000).subscribe((res) => {
    //   this.value = res;
    // });

    // setTimeout(() => {
    //   this.in.unsubscribe();
    //   this.sub.unsubscribe();
    //   this.onUpiSetUpSubscription.unsubscribe();
    //   // this.inSub.unsubscribe();
    //   this.isApiCall = false;
    //   this.navRoute.navigate(["/payment-fail"]);
    // }, 120000);
  }

  getTransactionDetailsUpi2() {
    if (this.isApiCall)
      this.onUpiSetUpSubscription = this.cartPaymentService.getUpiTransactionDetails(this.apiUrl.getUpiTransactionDetails, this.upiPayment.merchantTranId, this.upiPayment.locId)
        .subscribe((data) => {
          this.iciciTransactionStatus = data;
          console.log(this.iciciTransactionStatus);
          if (this.iciciTransactionStatus) {
            if (this.iciciTransactionStatus.status == "SUCCESS") {
              this.in.unsubscribe();

              if (this.gstSaleInvcumBOS.details.length != 0) {
                let id: number[] = [];
                data.details.forEach((element) => {
                  if (element.productCode)
                    id.push(element.productCode);
                });
                this.cartSetUpSubscription = this.userService
                  .removeAllProductCart(this.apiUrl.removeAllProductFromCart, this.googleCustomer.email, id).subscribe((data: any) => {
                    console.log(data);
                    this.navRoute.navigate(["/payment-success"], {
                      state: { data: "reload" },
                    });
                  });
              }

              if (this.gstSaleInvcumBOSArray.length != 0) {
                this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiUrl.emptyProductCart, this.googleCustomer.email)
                  .subscribe((data: any) => {
                    console.log(data.Res);
                    this.navRoute.navigate(["/payment-success"], {
                      state: { data: "reload" },
                    });
                  });
              }
            } else if (this.iciciTransactionStatus.status == "FAIL") {
              console.log(this.gstSaleInvcumBOS);
              this.in.unsubscribe();
              this.sub.unsubscribe();
              this.onUpiSetUpSubscription.unsubscribe();
              this.navRoute.navigate(["/payment-fail"]);
            } else {
            }
          } else {
            this.inSub = interval(25000).subscribe((x) => {
              this.inSub.unsubscribe();
              this.getTransactionDetailsUpi2();
            });
          }
        });
  }

  getTransactionDetailsUpi() {
    if (this.isApiCall)
      this.onUpiSetUpSubscription = this.cartPaymentService.getUpiTransactionDetails(this.apiUrl.getUpiTransactionDetails, this.upiPayment.merchantTranId, this.upiPayment.locId)
        .subscribe((data) => {
          this.iciciTransactionStatus = data;
          console.log(this.iciciTransactionStatus);
          if (this.iciciTransactionStatus) {
            if (this.iciciTransactionStatus.status == "SUCCESS") {
              this.in.unsubscribe();

              let locationCode: number = JSON.parse(this.localStorage.getItem("location_code"));
              let stateCode: number = JSON.parse(this.localStorage.getItem("state_code"));
              let orderFromTvLocation: number = JSON.parse(this.localStorage.getItem("orderFromTvLocation"));

              if (this.gstSaleInvcumBOS.details.length != 0) {
                let momentDate = new Date(this.gstSaleInvcumBOS.details[0].deliveryDate);
                let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
                this.gstSaleInvcumBOS.locationCode = locationCode;
                this.gstSaleInvcumBOS.invoicingLocationCode = this.parentLocationCode;
                this.gstSaleInvcumBOS.deliveryDate = formattedDate;
                this.gstSaleInvcumBOS.deliveryTime = this.gstSaleInvcumBOS.details[0].deliveryTime;
                this.gstSaleInvcumBOS.orderFromTvLocation = orderFromTvLocation;
                this.gstSaleInvcumBOS.url = this.currentUrl;
                this.gstSaleInvcumBOS.customerCode = this.googleCustomer.refNo;
                this.gstSaleInvcumBOS.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

                this.productOrderSummaryService.setWebOrder(this.gstSaleInvcumBOS);
                console.log(this.gstSaleInvcumBOS);

                this.deliveryAddress = this.gstSaleInvcumBOS.customerDeliveryAddress;

                this.customerDTO.customerName = this.gstSaleInvcumBOS.loginDeliveryAddress.name;
                this.customerDTO.customerMobile = this.gstSaleInvcumBOS.loginDeliveryAddress.phoneNo;
                this.customerDTO.customerEmail = this.gstSaleInvcumBOS.loginDeliveryAddress.email;
                this.customerDTO.stateCode = stateCode;
                this.customerDTO.fromLocationCode = locationCode;
                this.customerDTO.location.refNo = locationCode;
                this.customerDTO.location.stateCode = stateCode;
                this.gstSaleInvcumBOS.customer = this.customerDTO;

                this.gstSaleInvcumBOS.upipayment.amount = this.productOrderSummary.totalPrice;
                this.gstSaleInvcumBOS.upipayment.fromLocationCode = locationCode;
                let upiPaymentDetails = new UpiPaymentDetails();
                upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
                upiPaymentDetails.transStatus = this.iciciTransactionStatus.status;
                upiPaymentDetails.transDate = moment(new Date(this.iciciTransactionStatus.txnInitDate)).format("YYYY-MM-DD HH:mm:ss");
                upiPaymentDetails.merchantTranId = this.iciciTransactionStatus.merchantTranId;
                this.gstSaleInvcumBOS.upipayment.upiPaymentDetails.push(upiPaymentDetails);

                this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrder(this.apiUrl.insertWebOrder, this.gstSaleInvcumBOS)
                  .subscribe((data) => {
                    console.log(data);
                    if (data != null) {
                      let id: number[] = [];
                      data.details.forEach((element) => {
                        if (element.productCode)
                          id.push(element.productCode);
                      });
                      this.cartSetUpSubscription = this.userService.removeAllProductCart(this.apiUrl.removeAllProductFromCart, this.googleCustomer.email, id)
                        .subscribe((data: any) => {
                          console.log(data);
                          this.navRoute.navigate(["/payment-success"], {
                            state: { data: "reload" },
                          });
                        });
                    } else {
                      this.toastService.showErrorToast("Server side error...");
                    }
                  });
              }

              if (this.gstSaleInvcumBOSArray.length != 0) {
                this.gstSaleInvcumBOSArray.forEach((element) => {
                  let momentDate = new Date(element.details[0].deliveryDate);
                  let formattedDate = moment(new Date(momentDate)).format("YYYY-MM-DD");
                  element.deliveryDate = formattedDate;
                  element.deliveryTime = element.details[0].deliveryTime;
                  element.locationCode = locationCode;
                  element.invoicingLocationCode = this.parentLocationCode;
                  element.orderFromTvLocation = orderFromTvLocation;
                  element.url = this.currentUrl;
                  element.customerCode = this.googleCustomer.refNo;
                  element.deliveryAddressRefNo = this.productOrderSummary.deliveryAddressRefNo;

                  element.upipayment.amount = element.grandTotal;
                  element.upipayment.fromLocationCode = locationCode;
                  let upiPaymentDetails = new UpiPaymentDetails();
                  upiPaymentDetails.transAmt = this.iciciTransactionStatus.amount;
                  upiPaymentDetails.transStatus = this.iciciTransactionStatus.status;
                  upiPaymentDetails.transDate = moment(new Date(this.iciciTransactionStatus.txnInitDate)).format("YYYY-MM-DD HH:mm:ss");
                  upiPaymentDetails.merchantTranId = this.iciciTransactionStatus.merchantTranId;
                  element.upipayment.upiPaymentDetails.push(upiPaymentDetails);
                });
                this.deliveryAddress = this.gstSaleInvcumBOSArray[0].customerDeliveryAddress;

                this.customerDTO.customerName = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.name;
                this.customerDTO.customerMobile = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.phoneNo;
                this.customerDTO.customerEmail = this.gstSaleInvcumBOSArray[0].loginDeliveryAddress.email;
                this.customerDTO.stateCode = stateCode;
                this.customerDTO.fromLocationCode = locationCode;
                this.customerDTO.location.refNo = locationCode;
                this.customerDTO.location.stateCode = stateCode;

                this.gstSaleInvcumBOSArray[0].customer = this.customerDTO;

                console.log(this.gstSaleInvcumBOSArray);

                this.onWebOrderInsertSubscription = this.cartPaymentService.insertWebOrderArray(this.apiUrl.insertWebOrderArray, this.gstSaleInvcumBOSArray)
                  .subscribe((data) => {
                    console.log(data);
                    if (data.length > 0) {
                      this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiUrl.emptyProductCart, this.googleCustomer.email)
                        .subscribe((data: any) => {
                          console.log(data.Res);
                          this.navRoute.navigate(["/payment-success"], {
                            state: { data: "reload" },
                          });
                        });
                    } else {
                      this.toastService.showErrorToast("Server side error...");
                    }
                  });
              }
            } else if (this.iciciTransactionStatus.status == "FAIL") {
              console.log(this.gstSaleInvcumBOS);
              this.in.unsubscribe();
              this.sub.unsubscribe();
              this.onUpiSetUpSubscription.unsubscribe();
              this.navRoute.navigate(["/payment-fail"]);
            } else {
            }
          } else {
            this.inSub = interval(25000).subscribe((x) => {
              this.inSub.unsubscribe();
              this.getTransactionDetailsUpi();
            });
          }
        });
  }

  interval(timeToWait) {
    const initial = new Date().getTime();
    return timer(0, 200).pipe(
      map(() => new Date().getTime()),
      takeWhile((res) => res <= initial + timeToWait, true),
      map((now) => {
        const porc = (100 * (now - initial)) / timeToWait;
        // console.log(porc);
        return porc < 100 ? Math.round(porc) : 100;
      })
    );
  }

  getWalletHistory() {
    this.onViewWalletSubscription = this.commonService.getWalletTransaction(this.apiUrl.getWalletTransactionByEmail, this.googleCustomer.email)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.walletTransaction = data;
          data.forEach(element => {
            if (element.status == "Credit") {
              this.walletBalance = Number((Number(this.walletBalance.toFixed(2)) + Number(element.credits.toFixed(2))).toFixed(2));
            }
            else {
              this.walletBalance = Number((Number(this.walletBalance.toFixed(2)) - Number(element.credits.toFixed(2))).toFixed(2));
            }
          })
        } else {
          this.walletBalance = 0;
        }
        this.avlWalletAmt = this.walletBalance;
        this.getWalletOffers();
        // this.getProfileDetails();
      });
  }

  ifNullDoZero(value: number) {
    if (!value) {
      value = 0;
    }
    return value;
  }

  getWalletOffers() {
    this.walletOfferRequestDto.locationCode = JSON.parse(this.localStorage.getItem("location_code"));
    this.walletOfferRequestDto.email = this.googleCustomer.email;
    this.walletOfferRequestDto.amount = this.productOrderSummary.totalPrice - this.ifNullDoZero(this.productOrderSummary.shippingCharge);
    this.walletOfferRequestDto.transactionPoint = 'Web Order';
    this.walletOfferRequestDto.applicableCustomer = 'General';
    this.walletOfferRequestDto.transactionType = 'Web Order';

    this.getwalletOfferSubscription = this.cartPaymentService.getWalletOffers(this.apiUrl.getCheckedOutWalletOffers, this.walletOfferRequestDto)
      .subscribe((data: WalletOffers[]) => {
        this.offerList = data;
        this.offerList.forEach(element => {
          this.walletBalance += Number(element.walletAmount.toFixed(2));
        });
        this.walletBalance = Number(this.walletBalance.toFixed(2));
      })
  }

  walletClick() {
    if (this.walletBalance > 0) {
      const dialogRef = this.dialog.open(WalletConfirmationDialogComponent, {
        width: "500px",
        height: "300px",
        data: { amount: Number(this.amount), wallet: this.walletBalance, avlWalletAmt: this.avlWalletAmt, data: this.offerList }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          console.log(result)
          this.isWalletUsed = result.value;
          // this.walletBalance = result.wallet;
          if (Number(this.amount) <= this.walletBalance) {
            this.usedWallet = Number(this.amount);
            this.walletBalance = this.walletBalance - this.usedWallet;
            this.availabeAmount = 0;
          }
          else {
            this.usedWallet = this.walletBalance;
            this.walletBalance = this.walletBalance - this.usedWallet;
            this.availabeAmount = Number(this.amount) - this.usedWallet;
          }
          if (this.gstSaleInvcumBOS.details.length != 0) {
            this.gstSaleInvcumBOS.walletPayment.appliedOfferList = this.offerList;
          }
          if (this.gstSaleInvcumBOSArray.length != 0) {
            this.gstSaleInvcumBOSArray.forEach((element) => {
              element.walletPayment.appliedOfferList = this.offerList;
            });
          }
        }
        else {
          this.isWalletUsed = false;
        }
      });
    }
    else {
      this.toastService.showErrorToast('You have not sufficiant wallet balance.')
    }
  }

  getRandomString() {
    var myDate = moment(new Date()).format("YYYYMMDDHHmmssSSS");
    var moduleName = "WEBORD";
    var locId = JSON.parse(this.localStorage.getItem("location_code"));
    let fornattedLoc = locId < 10 ? '000' + '' + locId : locId < 100 ? '00' + '' + locId : locId < 1000 ? '0' + '' + locId : locId;
    var randomstring = moduleName + fornattedLoc + myDate;
    console.log(randomstring)
    return randomstring;
    // var myDate = new Date();
    // var result = myDate.getTime();
    // var characters = "ABCDEFGHIJKLMNOPQRSTUVWZYZ";
    // var lenString = 6;
    // var randomstring = "";
    // for (var i = 0; i < lenString; i++) {
    //   var rnum = Math.floor(Math.random() * characters.length);
    //   randomstring += characters.substring(rnum, rnum + 1);
    // }
    // return "WEB"+randomstring + result;
  }
}
