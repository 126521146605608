import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxScannerQrcodeComponent, ScannerQRCodeResult } from 'ngx-scanner-qrcode';

@Component({
  selector: 'app-image-preview-dialog',
  templateUrl: './image-preview-dialog.component.html',
  styleUrls: ['./image-preview-dialog.component.scss']
})
export class ImagePreviewDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ImagePreviewDialogComponent>,
  ) {
    // console.log(data.image)
    // console.log(data.video)
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    // this.camerPermission1();
  }

  ngOnDestroy(): void {
  }

  hide() {
    this.dialogRef.close()
  }
  @ViewChild('action') action!: NgxScannerQrcodeComponent;
  isScannerVisible: boolean = false;
   openScanner() {
      this.isScannerVisible = true;
    
      const playDeviceFacingBack = (devices: any[]) => {
        // Find the back camera
        const device = devices.find((f) =>
          /back/gi.test(f.label)
        ); // Default Back Facing Camera
        const deviceId = device ? device.deviceId : devices[0].deviceId;
    
        navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: { exact: deviceId }
          }
        }).then((stream) => {
          this.action.playDevice(deviceId);
          this.action.start();
        }).catch((error) => {
          console.error("Error accessing media devices.", error);
        });
      };
    
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        playDeviceFacingBack(devices);
      }).catch((error) => {
        console.error("Error enumerating devices.", error);
      });
    }
  
    public onEvent(e: ScannerQRCodeResult[], action?: any): void {
      if (e && e.length > 0) {
        const result = e[0].value; // Assuming you want the first result
        alert(`QR Code Result: ${result}`);
        if (action) {
          action.pause();
          this.closeScanner(); // Pause the scanner after a successful scan
        }
      }
    }
  
    closeScanner() {
      this.isScannerVisible = false;
      if (this.action) {
        this.action.stop();
         // Stop the scanner
      }
      this.action = null;
      this.hide();
    }
  
    camerPermission1() {
      document.title = "1 Bake Shop | Online Bakery";
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          // Permission granted, you can now use the stream
          alert('Camera permission granted.');
          // Do something with the stream, e.g., display it in a video element
          const video = document.querySelector('video');
          if (video) {
            video.srcObject = stream;
          }
          this.openScanner();
        })
        .catch((err) => {
          // Permission denied or an error occurred
          alert('Camera permission denied or an error occurred: ' + err);
        });
    }

}
