import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
// import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { SharedModule } from "src/app/shared/shared.module";
import { MakeToOrderAddressComponent } from "./make-to-order-address.component";
import { MakeToOrderAddressRoutes } from "./make-to-order-address.routing";
@NgModule({
  imports: [
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatSelectModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    //LeafletModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    CommonModule,
    SharedModule,
    MakeToOrderAddressRoutes,
  ],
  declarations: [MakeToOrderAddressComponent],
})
export class MakeToOrderAddressModule {}
