export class WebUserInfo {

    refNo: number;
    intiatedFrom: string;
    clientIp: string;
    cleintBrowser: string;
    cleintDevice: string;
    cleintBrowserVersion: string;
    sessionId: string;
    inTime: string;
    outTime: string;
    searchedPinCodeArea: string;
    emailId: string;
    productCode: number;
    route: string;
    locality: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    latitude: string;
    longitude: string;

}
