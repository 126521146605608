<div
  class="preview-dialog"
  style="
    overflow-x: hidden;
    overflow-y: hidden !important;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  "
>
  <div class="preview-title-bar">
    <button
      mat-button
      (click)="onCloseDialog()"
      class="preview-close-icon"
      style="line-height: 10px; background-color: #263238; border: none"
    >
      <mat-icon class="icon_white large_icon">highlight_off</mat-icon>
    </button>
    <h2 mat-dialog-title class="preview-title">Print Preview</h2>
  </div>
  <div class="preview-body" style="width: 500px !important">
    <object
      #pdfViewer
      type="application/pdf"
      style="
        width: 100%;
        height: 100%;
        overflow: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
      "
    ></object>
  </div>
  <div
    class="custom_sticky_footer"
    style="display: flex; box-sizing: border-box; flex-direction: row"
  >
    <div fxFlex="100" style="max-width: 100%; flex: 1 1 100%">
      <button id="btnn" (click)="print()">
        PRINT<mat-icon class="icon-style">print</mat-icon>
      </button>
      <button id="btnn" (click)="downloadPdf()" style="float: left">
        DOWNLOAD<mat-icon class="icon-style">save_alt</mat-icon>
      </button>
    </div>
  </div>
</div>
