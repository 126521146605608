<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">Contact Us</h2>
    <div class="hr"></div>
  </div>
</div>

<section id="contact">
  <div class="row" id="contactRow">
    <div class="text-center">
      <h4 class="h4_style" style="margin-top: -4%"> Get in touch with us at 1 Bake Shop! We'd love to hear from you.<br>
        Whether
        you have a question, comment, or need assistance with one of our products or
        services, our team is here to help.<br> Use our contact form, send us an
        email, or give us a call – we're committed to responding promptly and providing the
        support you need.<br> At 1 Bake Shop, we value your feedback and look
        forward to connecting with you.<br> Reach out today and let's start the conversation!</h4>

      <ul class="contact-info">
        <li style="font-family: myFirstFont; font-size: 30px; color: #e91e63">
          1 Bake Shop
        </li>
        <br />
        <li>Bharti Restaurants Pvt Ltd.</li>
        <br />
        <li>
          <i class="fa fa-phone"></i>+91 7326983789 &nbsp;&nbsp;
          <i class="fa fa-envelope"></i><a href="mailto:info@1bakeshop.com">info&#64;1bakeshop.com</a>
        </li>
      </ul>
    </div>
  </div>

  <div class="container">
    <div class="row" id="contactRow">
      <div class="col-md-5 col-lg-7 col-xs-12">
        <div class="form-style" id="contact_form">
          <!-- <div id="contact_results"></div> -->
          <form [formGroup]="createSetUpForm" (ngSubmit)="onCreateSetUpClick()">
            <div class="form-group" style="text-align: center">
              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                    <input matInput placeholder="Name" formControlName="name" required />
                  </mat-form-field>
                </mat-card>

                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                    <input matInput placeholder="Email ID" formControlName="email" required />
                  </mat-form-field>
                </mat-card>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                    <input matInput type="number" placeholder="WhatsApp No" formControlName="phone"
                      pattern="[6-9]{1}[0-9]{9}" (keyup)="checkOtp($any($event.target).value)" maxlength="10"
                      required />
                    <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'No'"
                    class="clearBtn" (click)="onVerifyClick()">Verify</span>
                    <span *ngIf="isDisabledSubmit && otpVerficationStatus == 'Yes'"
                    class="clearBtn1">Verified</span>
                    <!-- <span class="verification-status"
                      [ngClass]="{'verified': isDisabledSubmit, 'not-verified': !isDisabledSubmit}">
                      {{ isDisabledSubmit ? 'Verified' : 'Verify' }}
                    </span> -->
                    <span *ngIf="!isDisabledSubmit && otpVerficationStatus == 'Exceeds'"
                    class="clearBtn2">Exceeds</span>
                  </mat-form-field>
                </mat-card>

                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                    <mat-select placeholder="Feedback Category" formControlName="grivenceTypeCode"
                      (selectionChange)="onFeedbackTypeSelect($event.value)">
                      <mat-option style="text-align: left; padding-bottom: 2%" *ngFor="let type of feedbackTypeList"
                        [value]="type.grivenceTypeCode">{{ type.grivenceTypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-card>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                    <mat-select placeholder="Feedback Sub-Category" formControlName="subGrivenceTypeCode"
                      (selectionChange)="onFeedbackSubTypeSelect($event.value)">
                      <mat-option style="text-align: left; padding-bottom: 2%" *ngFor="let type of feedbackSubList"
                        [value]="type.subGrivenceTypeCode">{{ type.subGrivenceTypeName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-card>

                <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="selectOption" style="width: 100%" visivility="none">
                    <mat-select placeholder="Feedback" formControlName="grivenceCode"
                      (selectionChange)="onFeedbackSelect($event.value)">
                      <mat-option style="text-align: left; padding-bottom: 2%" *ngFor="let type of feedbackList"
                        [value]="type.grivenceCode">{{ type.grivenceName }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </mat-card>
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <!-- <mat-card class="contain_card" fxFlex="47" style="width: 49%">
                  <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                    <input matInput placeholder="Subject" formControlName="subject" required readonly=""/>
                  </mat-form-field>
                </mat-card> -->

                <mat-card class="contain_card" fxFlex="99" style="width: 99%">
                  <mat-form-field class="example-full-width" style="width: 100%" visivility="none">
                    <input matInput placeholder="Input/Comment" formControlName="message" required />
                  </mat-form-field>
                </mat-card>


              </div>



              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <mat-card class="mat_card_with_file" fxFlex="100">
                  <span class="span_with_file">
                    <img [src]="getFileData('fileImage1')" alt="Picture" *ngIf="getFileData('fileImage1')"
                      class="image_size" />

                    <mat-icon *ngIf="!getFileData('fileImage1')" class="file_icon_size"> image </mat-icon>
                  </span>
                  <input type="file" readonly (change)="onChangeUploadFile($event,'fileImage1','image1')"
                    accept="image/*" (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size"
                    id="image1">
                  <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileImage1')"
                    (click)="onRemoveFileClick('fileImage1', 'image1');">
                    clear
                  </mat-icon>
                </mat-card>

                <mat-card class="mat_card_with_file" fxFlex="100">
                  <span class="span_with_file">
                    <video [src]="getFileData('fileVideo1')" *ngIf="getFileData('fileVideo1')" class="video_size"
                      controls controlsList="nodownload">
                      <track label="English" kind="subtitles" srclang="en" default />
                    </video>

                    <mat-icon *ngIf="!getFileData('fileVideo1')" class="file_icon_size"> play_arrow </mat-icon>
                  </span>
                  <input type="file" readonly (change)="onChangeUploadFile($event,'fileVideo1', 'video1')"
                    accept="video/*" (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size"
                    id="video1">
                  <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileVideo1')"
                    (click)="onRemoveFileClick('fileVideo1', 'video1')">
                    clear
                  </mat-icon>
                </mat-card>


                <!-- <div class="col-md-4 col-sm-4">
                  <mat-card class="mat_card_with_file" fxFlex="100">
                    <span class="span_with_file">
                      <img [src]="getFileData('fileImage2')" alt="Picture" *ngIf="getFileData('fileImage2')"
                        class="image_size" />

                      <mat-icon *ngIf="!getFileData('fileImage2')" class="file_icon_size"> image </mat-icon>
                    </span>
                    <input type="file" readonly (change)="onChangeUploadFile($event,'fileImage2')" accept="image/*"
                      (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size">
                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileImage2')"
                      (click)="onRemoveFileClick('fileImage2', 'image2');">
                      clear
                    </mat-icon>
                  </mat-card>
                </div>

                <div class="col-md-4 col-sm-4">
                  <mat-card class="mat_card_with_file" fxFlex="100">
                    <span class="span_with_file">
                      <img [src]="getFileData('fileImage3')" alt="Picture" *ngIf="getFileData('fileImage3')"
                        class="image_size" />

                      <mat-icon *ngIf="!getFileData('fileImage3')" class="file_icon_size"> image </mat-icon>
                    </span>
                    <input type="file" readonly (change)="onChangeUploadFile($event,'fileImage3')" accept="image/*"
                      (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size">
                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileImage3')"
                      (click)="onRemoveFileClick('fileImage3', 'image3');">
                      clear
                    </mat-icon>
                  </mat-card>
                </div> -->
              </div>

              <!-- Video -->
              <!-- <div class="row">
                <div class="col-md-4 col-sm-4">
                  <mat-card class="mat_card_with_file" fxFlex="100">
                    <span class="span_with_file">
                      <video [src]="getFileData('fileVideo1')" *ngIf="getFileData('fileVideo1')" class="video_size"
                        controls controlsList="nodownload">
                        <track label="English" kind="subtitles" srclang="en" default />
                      </video>

                      <mat-icon *ngIf="!getFileData('fileVideo1')" class="file_icon_size"> play_arrow </mat-icon>
                    </span>
                    <input type="file" readonly (change)="onChangeUploadFile($event,'fileVideo1')" accept="video/*"
                      (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size">
                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileVideo1')"
                      (click)="onRemoveFileClick('fileVideo1', 'video1')">
                      clear
                    </mat-icon>
                  </mat-card>
                </div>
                <div class="col-md-4 col-sm-4">
                  <mat-card class="mat_card_with_file" fxFlex="100">
                    <span class="span_with_file">
                      <video [src]="getFileData('fileVideo2')" *ngIf="getFileData('fileVideo2')" class="video_size"
                        controls controlsList="nodownload">
                        <track label="English" kind="subtitles" srclang="en" default />
                      </video>

                      <mat-icon *ngIf="!getFileData('fileVideo2')" class="file_icon_size"> play_arrow </mat-icon>
                    </span>
                    <input type="file" readonly (change)="onChangeUploadFile($event,'fileVideo2')" accept="video/*"
                      (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size">
                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileVideo2')"
                      (click)="onRemoveFileClick('fileVideo2', 'video2')">
                      clear
                    </mat-icon>
                  </mat-card>
                </div>
                <div class="col-md-4 col-sm-4">
                  <mat-card class="mat_card_with_file" fxFlex="100">
                    <span class="span_with_file">
                      <video [src]="getFileData('fileVideo3')" *ngIf="getFileData('fileVideo3')" class="video_size"
                        controls controlsList="nodownload">
                        <track label="English" kind="subtitles" srclang="en" default />
                      </video>

                      <mat-icon *ngIf="!getFileData('fileVideo3')" class="file_icon_size"> play_arrow </mat-icon>
                    </span>
                    <input type="file" readonly (change)="onChangeUploadFile($event,'fileVideo3')" accept="video/*"
                      (click)="$any($event.target).value=null" fxFlex="85" class="input_file_size">
                    <mat-icon class="cancel_icon_for_remove" *ngIf="getFileData('fileVideo3')"
                      (click)="onRemoveFileClick('fileVideo3', 'video3')">
                      clear
                    </mat-icon>
                  </mat-card>
                </div>
              </div> -->


              <!-- <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <input type="text" name="name" class="form-control input-field" style="width: 49%" placeholder="Name"
                  formControlName="name" required />

                <input type="email" name="email" class="form-control input-field" style="width: 49%"
                  placeholder="Email ID" required formControlName="email" />
              </div>

              <div fxLayout="row" fxLayout.lt-sm="column" class="flex_div">
                <div class="text-container">
                  <input type="number" name="phone" class="form-control input-field" placeholder="WhatsApp No"
                    formControlName="phone" pattern="[6-9]{1}[0-9]{9}" (keyup)="checkOtp($any($event.target).value)"
                    required />
                  <span *ngIf="!isDisabledSubmit" class="clearBtn" (click)="onVerifyClick()">Verify</span>
                  <span *ngIf="isDisabledSubmit" class="clearBtn1">Verified</span>
                </div>

                <input type="text" name="subject" class="form-control input-field" placeholder="Subject"
                  formControlName="subject" required />
              </div>

              <textarea name="message" id="message" class="textarea-field form-control" rows="4"
                placeholder="Enter your message" formControlName="message" maxlength="200" required></textarea> -->

              <a class="btn" id="submit_btn">
                <div class="btn-line"></div>
                <button type="submit" class="btn-line btn-line-shift" style="background-color: #d0324b; color: white">
                  Send Message
                </button>
                Send Message
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>