function setData() {
    // console.log("3290832098092389032890328093232908309 80328"+localStorage.getItem("matrixList"))
    // localStorage.removeItem("matrixList");
    // localStorage.removeItem("allGroupList");
    // localStorage.removeItem("allProductList");
    // localStorage.removeItem("makeToOrderGroupList");
    // localStorage.removeItem("allSubGroupListMto");
    // localStorage.removeItem("allProductListMto");
    // localStorage.removeItem("franchiseSetupList");
    // localStorage.removeItem("stateList");
    // localStorage.removeItem("city");
    // localStorage.removeItem("locationWithLongLat");
    // localStorage.removeItem("locationDetailsForStore");
    // localStorage.removeItem("careerSetupList");
    // localStorage.removeItem("feedBackSetupList");

    // localStorage.setItem("matrixList",readFile("matrixList"));
    // localStorage.setItem("allGroupList",readFile("allGroupList"));
    // localStorage.setItem("allProductList",readFile("allProductList"));
    // localStorage.setItem("makeToOrderGroupList",readFile("makeToOrderGroupList"));
    // localStorage.setItem("allSubGroupListMto",readFile("allSubGroupListMto"));
    // localStorage.setItem("allProductListMto",readFile("allProductListMto"));
    // localStorage.setItem("franchiseSetupList",readFile("franchiseSetupList"));
    // localStorage.setItem("stateList",readFile("stateList"));
    // localStorage.setItem("city",readFile("city"));
    // localStorage.setItem("locationWithLongLat",readFile("locationWithLongLat"));
    // localStorage.setItem("locationDetailsForStore",readFile("locationDetailsForStore"));
    // localStorage.setItem("careerSetupList",readFile("careerSetupList"));
    // localStorage.setItem("feedBackSetupList",readFile("feedBackSetupList"));
}
function readFile(dataType, url) {
    let fullData = "NA";
    let xhr = new XMLHttpRequest();
    if (dataType == 'franchiseFaqs')
        xhr.open('GET', 'assets/txt/allfaqs.txt', false);
    else {
        xhr.open('GET', 'assets/txt/all-data-in-static-db-' + url + '.txt', false);
    }
    try {
        xhr.send();
        if (xhr.status === 200) {
            fullData = splitToGetData(xhr.response, dataType);
        }
    } catch (err) {
    }
    return fullData;
}
function splitToGetData(dataIn, dataType) {
    let remainingData = "";
    let end = 0, start = 0;
    remainingData = dataIn.split("let " + dataType + " =")[1]
    //console.log(remainingData)
    start = dataIn.indexOf("let " + dataType + " =") + dataType.length + 7;
    if (dataType == "locationDetailsForStore") {
        end = remainingData.indexOf("};");
    }
    else {
        end = remainingData.indexOf("];");
    }
    let retDt = dataIn.substr(start, end);
    return retDt;
}
module.exports = { setData, readFile }