import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';
//declare var toastr: any;
@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private toastr: ToastrService) { }

  success(title: any, msg: any) {
    this.toastr.success(msg, title);
  }
  info(title: any, msg: any) {
    this.toastr.info(msg, title);
  }
  warning(title: any, msg?: any) {
    this.toastr.warning(msg, title);
  }
  error(title: any, msg: any) {
    this.toastr.error(msg, title);
  }

  wait(title: any, msg: any) {
    this.toastr.info(msg, title, { timeOut: 3000 });
  }

  showSuccessToast(message: string) {
    this.toastr.success(message, '', {});
  }

  showErrorToast(message: string) {
    this.toastr.error(message, '', {});
  }

  showWarningToast(message: string) {
    this.toastr.warning(message, '', {});
  }

  clearAllToast() {
    this.toastr.clear();
  }

}
