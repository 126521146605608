import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-payment-status",
  templateUrl: "./payment-status.component.html",
  styleUrls: ["./payment-status.component.scss"],
})
export class PaymentStatusComponent implements OnInit {
  
  googleCustomer: GoogleCustomer;
  private cartSetUpSubscription: Subscription;

  constructor(
    private localStorage:LocalStorageService,
    private route: ActivatedRoute,
    private apiFactory: APIFactoryEndPoints,
    private navRoute: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.route.queryParams.subscribe((data) => {
      if (data) {
        console.log("data" + data);
        this.removeCartProducts(data);
      }
    });
  }

  removeCartProducts(data: Params) {
    if (data["status"] != null) {
      if (data["status"] == "Success") {
        if (data["ids"] == "all") {
          this.cartSetUpSubscription = this.userService.emptyProductCart(this.apiFactory.emptyProductCart, this.googleCustomer.email)
            .subscribe((data: any) => {
              console.log(data.Res);
              // window.location.reload();
              this.navRoute.navigate(["/payment-success"], {
                state: { data: "reload" },
              });
            });
        } else {
          this.cartSetUpSubscription = this.userService.removeAllProductCart(this.apiFactory.removeAllProductFromCart, this.googleCustomer.email, data["ids"])
            .subscribe((data: any) => {
              console.log(data);
              // window.location.reload();
              this.navRoute.navigate(["/payment-success"], {
                state: { data: "reload" },
              });
            });
        }
      }
    }
  }
}
