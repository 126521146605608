import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { Product } from 'src/app/shared/models/product';
import { WebOrderDeliveryTimeSlotSetup } from 'src/app/shared/models/web-order-delivery-time-slot-setup';
import { ToastService } from 'src/app/shared/services/toastr.service';

@Component({
  selector: 'app-delivery-time-dialog',
  templateUrl: './delivery-time-dialog.component.html',
  styleUrls: ['./delivery-time-dialog.component.scss']
})
export class DeliveryTimeDialogComponent implements OnInit {

  product: Product = new Product();
  webOrderDeliveryTimeSlotSetup: WebOrderDeliveryTimeSlotSetup[] = [];

  constructor(
    public dialogRef: MatDialogRef<DeliveryTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private toastService: ToastService,
  ) {
    console.log(dialogData)
    if (dialogData && dialogData.product)
      this.product = dialogData.product;
    if (dialogData && dialogData.deliveryTimeSlotSetup)
      this.dateChanged()
  }

  ngOnInit(): void {
  }

  dateChanged() {
    this.product.deliveryDate = moment(this.product.deliveryDate).format("YYYY-MM-DD");
    if (this.product.defaultDate == this.product.deliveryDate) {
      this.webOrderDeliveryTimeSlotSetup = this.dialogData.deliveryTimeSlotSetup.filter(e => this.product.defaultTimeSlotAvl.includes(e.timeSlot));
    }
    else {
      this.webOrderDeliveryTimeSlotSetup = this.dialogData.deliveryTimeSlotSetup;
    }

    if (!this.webOrderDeliveryTimeSlotSetup.some(e => e.timeSlot == this.product.deliveryTime)) {
      this.product.deliveryTime = this.webOrderDeliveryTimeSlotSetup[0]?.timeSlot;
    }

  }

  onConfirmClick() {
    this.product.deliveryDate = moment(this.product.deliveryDate).format("YYYY-MM-DD");
    if (this.product.deliveryTime) {
      this.dialogRef.close({ data: this.product });
    }
    else {
      this.toastService.showErrorToast('Choose Delivery Slot');
    }
  }

}
