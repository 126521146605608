import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { OrderSummary } from "src/app/shared/models/ordersummary";
import { Product } from "src/app/shared/models/product";
import { AddressService } from "src/app/shared/services/address.service";
import { PaymentService } from "src/app/shared/services/payment.service";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-order-summary",
  templateUrl: "./order-summary.component.html",
  styleUrls: ["./order-summary.component.scss"],
})
export class OrderSummaryComponent implements OnInit {
  orderSummary: OrderSummary = new OrderSummary();
  product: Product = new Product();
  webUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private pincodeDialogService: PinDialogService,
    private addressService: AddressService,
    private apiFactory: APIFactoryEndPoints,
    private toastService: ToastService,
    private productService: ProductService,
    private navRoute: Router,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.webUrl = environment.cartApiUrl;
    this.getSummaryDetails();
  }

  getSummaryDetails() {
    this.orderSummary = this.pincodeDialogService.getOrderSummary();
    console.log(this.orderSummary);
    this.product = this.orderSummary.product;
  }

  checkPaymentOption(product: Product) {
    this.paymentService.setProduct(product);
    this.navRoute.navigate(["/payment/payment-app"]);
  }
}
