<div class="container-fluid px-0" id="bg-div">
    <div class="row justify-content-center">
        <div class="col-lg-9 col-12">
            <div class="card card0">
                <div class="d-flex" id="wrapper">
                    <!-- Sidebar -->
                    <div class="bg-light border-right" id="sidebar-wrapper">
                        <div class="sidebar-heading pt-5 pb-4"><strong>PAY WITH</strong></div>
                        <div class="list-group list-group-flush"> <a data-toggle="tab" href="#menu1" id="tab1"
                                class="tabs list-group-item bg-light">
                                <div class="list-div my-2">
                                    <div class="fa fa-cash"></div> &nbsp;&nbsp; Cash On Delivery
                                </div>
                            </a>
                            <a data-toggle="tab" href="#menu2" id="tab2" class="tabs list-group-item active1">
                                <div class="list-div my-2">
                                    <div></div> &nbsp;&nbsp; UPI
                                </div>
                            </a>
                        </div>
                    </div>
                    <div id="page-content-wrapper">
                        <div class="row justify-content-center">
                            <div class="text-center" id="test">Pay</div>
                        </div>
                        <div class="tab-content">
                            <div id="menu1" class="tab-pane">
                                <div class="row justify-content-center">
                                    <div class="col-11">
                                        <div class="form-card">
                                            <h3 class="mt-0 mb-4 text-center">Cash On Delivery(COD)</h3>
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-12"> <input type="submit"
                                                            value="Pay {{product.net_Sale_Price | number : '1.2-2'}}"
                                                            class="btn btn-success placeicon"> </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="menu2" class="tab-pane in active">
                                <div class="row justify-content-center">
                                    <div class="col-11">
                                        <div class="form-card">
                                            <h3 class="mt-0 mb-4 text-center">Enter your UPI details to pay</h3>
                                            <form>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="input-group"> <input type="text" > <label>UPI</label> </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12"> <input type="submit"
                                                            value="Pay {{product.net_Sale_Price | number : '1.2-2'}}"
                                                            class="btn btn-success placeicon"> </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>