import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { Product } from '../models/product';
import { AuthService } from './auth.service';
import { ToastService } from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  product:Product;

  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) { }

  setProduct(product: Product) {
    this.product = product;
  }
  getProduct() {
    return this.product;
  }
}
