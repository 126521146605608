<div style="margin-top: 3%">
  <div class="section-heading">
    <h2 class="h2_font_size">My Coupons</h2>
    <div class="hr" style="padding: 4px;"></div>
  </div>
</div>

<!-- Navigation bar with three buttons -->
<div class="nav-bar">
  <button class="nav-btn" [ngClass]="{ 'nav-btn-active': activeTab === 'home' }" (click)="filterCoupons('home')">
    AVAILABLE
    <div class="coupon-message">{{homeCount}} Coupons</div>
  </button>
  <button class="nav-btn" [ngClass]="{ 'nav-btn-active': activeTab === 'redeemed' }"
    (click)="filterCoupons('redeemed')">
    REDEEMED
    <div class="coupon-message">{{redeemedCount}} Coupons</div>
  </button>
  <button class="nav-btn" [ngClass]="{ 'nav-btn-active': activeTab === 'expired' }" (click)="filterCoupons('expired')">
    EXPIRED
    <div class="coupon-message">{{expiredCount}} Coupons</div>
  </button>
</div>

<div *ngIf="wait && originalCouponList.length > 0" [ngClass]="couponList.length > 0 ? 'couponDiv' : 'couponDiv_height'">
  <mat-icon *ngIf="originalCouponList.length > 0" class="sortbar_icon" [matMenuTriggerFor]="menu">filter_list</mat-icon>
  <mat-menu #menu="matMenu">
    <div class="sortbar_tabs_icon">
      <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'lowToHigh' }" type="button"
        (click)="sortProducts('all')">All</button>
      <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === 'highToLow' }" type="button"
        (click)="sortProducts('thisMonth')">This Month</button>

      <ng-container *ngFor="let templateName of getUniqueTemplateNames()">
        <button class="sortbar_tab_btn" [ngClass]="{ 'sortbar_tab_btn_active': activeSort === templateName }"
          type="button" (click)="sortProducts(templateName)">{{ templateName }}</button>
      </ng-container>
    </div>
  </mat-menu>

  <div *ngFor="let coupon of couponList" class="col-md-4 col-sm-6 col-xs-12"
    [ngClass]="coupon.displayProducts ? 'margin_buttom_open' : 'margin_buttom_close'">
    <!-- <img src="assets/img/coupons/{{coupon.templateName}}.webp" alt="Image" class="img_style" /> -->
    <div class="btnStyle coupon-background"
      [style.backgroundImage]="'url(assets/img/coupons/' + coupon.templateName + '.webp)'"
      (click)="onRedeemClick(coupon)">
      <div class="couponCodeDiv">
        <div class="view overlay hm-white-slight">
        </div>
      </div>

      <div class="couponNameDiv">
        <div class="text product_Name_Style">{{coupon.couponName}}</div>

        <div class="offer_div_style">
          <span *ngIf="activeTab != 'expired'" class="offer_style">
            MRP <span class="price-cancelled">
              ₹ {{ (activeTab === 'home' ? coupon.productDetails[0]?.netSalePrice : coupon.productDetail?.netSalePrice)
              | number: '1.2-2' }}
            </span>
          </span>

          <span *ngIf="coupon.discountApplicable > 0" class="offer_style">{{coupon.discountApplicable}}
            % Off</span>
          <span *ngIf="coupon.getForValue > 0" class="offer_style">Get
            for ₹{{coupon.getForValue}}</span>
        </div>

        <div class="date_time_div">
          <span class="date_time_style">
            Date:{{coupon.fromDate | date: 'dd MMM yyyy'}} - {{coupon.toDate | date: 'dd MMM yyyy'}}</span>
          <span class="date_time_style">Time:{{convertTo12Hour(coupon.fromTime)}} -
            {{convertTo12Hour(coupon.toTime)}}</span>
        </div>
      </div>

      <span class="blinking-text" *ngIf="activeTab != 'expired'">
        <mat-icon class="pink-icon" *ngIf="!coupon.displayProducts" id="btn_style">add_circle
        </mat-icon>
        <mat-icon *ngIf="coupon.displayProducts" id="btn_style">remove_circle
        </mat-icon>
      </span>
    </div>

    <div class="productListDiv" *ngIf="coupon.displayProducts && activeTab != 'expired'">
      <div class="productDiv">
        <div *ngFor="let product of coupon.paginatedProducts" class="product_div_style">
          <div class="product_details">
            <div style="height: 100%;" class="item">
              <div style="height: 100%;" class="mb-r">
                <div style="height: 100%;" class="card card-cascade wider">
                  <a style="height: 79%;">
                    <!-- Product view -->
                    <div class="view overlay hm-white-slight">
                      <img src="../../../../assets/img/{{product.productPicture}}" class="img-fluid"
                        alt="Image not found" width="360px" height="300px"
                        onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                      <img *ngIf="product.foodType == 'Veg'" class="img-responsive img_align"
                        src="../../../../assets/img/Veg.webp" alt="" />
                      <img *ngIf="product.foodType == 'Non-Veg'" class="img-responsive img_align"
                        src="../../../../assets/img/Nonveg.webp" alt="" />
                    </div>
                  </a>

                  <div class="text-center">
                    <div class="card-title product_name">
                      <span style="height: 26px;">{{product.productName}}</span>

                      <div *ngIf="coupon.discountApplicable > 0">
                        <strong class="price_style">
                          <span style="font-size: 9px;float: left;margin-left: 3px;">
                            <del>₹ {{ product.netSalePrice | number: "1.2-2"}}</del></span>
                        </strong>
                        <strong class="price_style">
                          <span style="font-size: 9px;float: right;margin-right: 3px;">
                            ₹ {{ product.netSalePrice - coupon.discountApplicable / 100 * product.netSalePrice |
                            number: "1.2-2"}}
                          </span>
                        </strong>
                      </div>
                      <div *ngIf="coupon.getForValue > 0">
                        <strong class="price_style">
                          <span style="font-size: 9px;float: left;margin-left: 3px;">
                            <del>₹ {{ product.netSalePrice | number: "1.2-2"}}</del></span>
                        </strong>
                        <strong class="price_style">
                          <span style="font-size: 9px;float: right;margin-right: 3px;">
                            ₹ {{ coupon.getForValue | number: "1.2-2"}}
                          </span>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="qr_details">
            <button type="button" *ngIf="!product.selected && activeTab != 'redeemed' && coupon.valid"
              class="generate_qr_btn" [disabled]="!coupon.valid" [ngClass]="{'disabled-blur': !coupon.valid}"
              (click)="onQrBtnClick(coupon, product)">
              Generate QR
            </button>
            <div *ngIf="coupon.valid && product.selected" style="text-align: center;" (click)="onQRCodeClick(product)">
              <qrcode [qrdata]="coupon.qrData" [width]="83" [backgroundColor]="'transparent'"></qrcode>
            </div>
            <div>
              <p *ngIf="product.selected" class="timer">{{ product.timer }}</p>
            </div>
            <div *ngIf="!coupon.valid" style="text-align: center;">
              <p class="invalid_coupon">{{coupon.reason}}</p>
            </div>
            <div *ngIf="activeTab === 'redeemed'">
              <p class="redeemed-info-item-date">Date: {{ product.redeemedDate | date: 'dd MMM yyyy, HH:mm' }}</p>
              <p class="redeemed-info-item">Location: {{ product.locationName }}</p>
              <p class="redeemed-info-item">Cashier: {{ product.userName }}</p>
            </div>
          </div>

          <div *ngIf="activeTab != 'redeemed'" class="qr_details">
            <button type="button" class="generate_qr_btn store_btn" (click)="getLocationByLatAndLng(coupon)">
              Coupon Friendly Stores
            </button>
          </div>

          <!-- <div *ngIf="coupon.valid && product.selected" class="qr_details">
            <div style="text-align: center;" (click)="onQRCodeClick(product)">
              <qrcode [qrdata]="coupon.qrData" [width]="100" [backgroundColor]="'transparent'"></qrcode>
            </div>
          </div> -->
          <div *ngIf="!coupon.valid && activeTab === 'redeemed'" class="qr_details">
            <!-- <div style="text-align: center;">
              <p class="invalid_coupon">{{coupon.reason}}</p>
            </div> -->

            <!-- Rate Us Section -->
            <div *ngIf="activeTab === 'redeemed'" class="rate-us-section">
              <ngb-rating [(rate)]="coupon.rating" [max]="5" [readonly]="coupon.isRated">
                <ng-template let-fill="fill">
                  <span class="custom-star">
                    <i class="fa" [ngClass]="{'fa-smile': fill > 50, 'fa-smile-o': fill <= 50}"></i>
                  </span>
                </ng-template>
              </ngb-rating>

              <!-- Show the Submit button only if the rating is not yet submitted -->
              <button *ngIf="!coupon.isRated" class="submit-rating-btn" (click)="submitRating(coupon)">
                Rate Us
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="activeTab != 'redeemed'" style="width: 100%;display: flex; flex: 0 0 auto;align-items: center;">
          <div class="pagination-controls" style="text-align: center;width: 33.33%;">
            <i class="fas fa-chevron-left" (click)="previousPage(coupon)"
              [ngClass]="{'disabled-icon': coupon.currentPage === 1}"></i>
            <span>{{ coupon.currentPage }}/{{ coupon.totalPages }}</span>
            <i class="fas fa-chevron-right" (click)="nextPage(coupon)"
              [ngClass]="{'disabled-icon': coupon.currentPage === coupon.totalPages}"></i>
          </div>

          <div *ngIf="coupon.totalPages > 1">
            <p class="coupon_info text-black">Select any 1 product out of {{coupon.totalPages}} options to redeem</p>
          </div>
        </div>

        <!-- <div *ngIf="activeTab != 'redeemed'">
          <p *ngIf="coupon.allowWithCategories" class="coupon_info grey">Redeem 1 coupon/day from any category.
            {{coupon.allowWithCategories}} coupon can be redeemed extra</p>
          <p class="coupon_info pink">Redeem {{coupon.redemptionsPerDay}} {{coupon.couponName}} daily between
            {{coupon.fromDate | date: 'dd MMM yy'}} and {{coupon.toDate | date: 'dd MMM yy'}}</p>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="locationList.length > 0" class="add-cake-button">
  <button type="button" mat-fab color="primary" class="fab-button" (click)="onMapClick()">
    <mat-icon>store</mat-icon>
  </button>
</div> -->

<div class="col-md-12">
  <h4 *ngIf="wait && couponList.length === 0 && isAgreedTerms" class="empty">
    <i class="i-style">
      No <span>{{activeTab === 'home' ? '' : (activeTab === 'redeemed' ? 'Redeemed' : 'Expired')}}</span> Coupons Are
      Currently Available
    </i>
  </h4>
  <h4 *ngIf="!wait" class="empty"><i class="i-style">Loading...</i></h4>
  <h4 *ngIf="wait && !isAgreedTerms" class="empty"><i class="i-style">Agree to the
      <button type="button" style="border: none; padding: 0; color: blue" (click)="openPrivacyPolicyDialog()">
        <u>terms and conditions</u>
      </button>
      to get Coupons
    </i>
  </h4>
</div>