<div>
    <div style="height: 30px;text-align: center;background-color: #c7304a;">
        <h5>Confirmation</h5>
    </div>

    <div class="msg_div">
        <div>
            <h4>{{ dialogData.heading }}</h4>
            <h4>{{ dialogData.message }}</h4>
        </div>
    </div>
    <div class="options">
        <button mat-stroked-button color="primary" [mat-dialog-close]="true" class="confirm_btn"
            style="float: right; width: 110px !important;margin-right: 15px!important;margin-left: 15px!important;">
            {{rightBtn}} </button>
        <button mat-stroked-button color="primary" [mat-dialog-close]="false" class="confirm_btn"
            style="float: right; width: 91px !important;">
            {{leftBtn}} </button>
        <button *ngIf="showCancel" mat-stroked-button color="primary" class="confirm_btn" [mat-dialog-close]
            style="width: 91px !important; margin-left: 12px;"> Cancel </button>
    </div>
</div>