import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { ProductGroupWithIdAndName } from "../models/productgroupwithidandname";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";

@Injectable({
  providedIn: "root",
})
export class MakeCakeService {
  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) {}

  getMakeToOrderProductList(currentUrl: string) {
    return this.http.get<ProductGroupWithIdAndName[]>(
      this.apiFactory.getMakeToOrderDetails + "/" + currentUrl
    );
  }
}
