import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { GstSaleInvCumBosDto } from "../models/gstsaleinvcumbosdto";
import { ProductOrderSummary } from "../models/productordersummary";
import { UpiPayment } from "../models/upipayment";
import { WebOrderBillingSetup } from "../models/weborderbillingsetup";
import { WebOrderUserSetup } from "../models/weborderusersetup";
import { AuthService } from "./auth.service";
import { ToastService } from "./toastr.service";
import { CustomerDeliveryAddress } from "../models/customerdeliveryaddress";

@Injectable({
  providedIn: "root",
})
export class ProductOrderSummaryService {
  productOrderSummary: ProductOrderSummary;
  merchantTranId: string;
  locId: number;
  upiPayment: UpiPayment = new UpiPayment();
  gstSaleInvCumBosDto: GstSaleInvCumBosDto;
  gstSaleInvCumBosDtoArray: GstSaleInvCumBosDto[];
  customerDeliveryAddress: CustomerDeliveryAddress;
  serverUrl: string;


  constructor(
    private authService: AuthService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private http: HttpClient
  ) { this.serverUrl = this.apiFactory.getServerUrl; }

  setOrderSummary(productOrderSummary: ProductOrderSummary) {
    this.productOrderSummary = productOrderSummary;
  }
  getOrderSummary() {
    return this.productOrderSummary;
  }

  setWebOrder(gstSaleInvCumBosDto: GstSaleInvCumBosDto) {
    this.gstSaleInvCumBosDto = gstSaleInvCumBosDto;
  }
  getWebOrder() {
    return this.gstSaleInvCumBosDto;
  }

  setWebOrderArray(gstSaleInvCumBosDto: GstSaleInvCumBosDto[]) {
    this.gstSaleInvCumBosDtoArray = gstSaleInvCumBosDto;
  }
  getWebOrderArray() {
    return this.gstSaleInvCumBosDtoArray;
  }

  setCustomerDeliveryAddress(customerDeliveryAddress: CustomerDeliveryAddress) {
    this.customerDeliveryAddress = customerDeliveryAddress;
  }
  getCustomerDeliveryAddress() {
    return this.customerDeliveryAddress;
  }

  getLocationCodeForBillingDetails(companyCode: number): Observable<any> {
    return this.http.get<WebOrderBillingSetup>(
      this.apiFactory.getLocationForBilling + "/" + companyCode
    );
  }

  getWebOrderInvoiceDetails(url: string, module: string, invNo: string, locCode: any, currentUrl: string) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "text/plain; charset=utf-8"
    );
    return this.http.get<string>(
      url + "/" + module + "/" + invNo + "/" + locCode + "/" + currentUrl,
      { headers, responseType: "arraybuffer" as "json" }
    );

    //return this.http.get<any>(url + '/' + location + '/' + invNo + '/' + module, { responseType: 'arraybuffer' as 'json' });
  }

  getWebOrderUserSetupDetails(): Observable<any> {
    return this.http.get<WebOrderUserSetup>(
      this.apiFactory.getWebOrderUserSetUpDetails
    );
  }
}
