function statusChangeCallback(response) {  // Called with the results from FB.getLoginStatus().
    if (response.status === 'connected') {   // Logged into your webpage and Facebook.


    } else {                                 // Not logged into your webpage or we are unable to tell.

    }
}


exports.checkLoginState = function () {               // Called when a person is finished with the Login Button.
    FB.getLoginStatus(function (response) {   // See the onlogin handler
        statusChangeCallback(response);
    });
}


// window.fbAsyncInit = function () {
//     FB.init({
//         appId: '349415697054113',       ///1054345689194751
//         cookie: true,                     // Enable cookies to allow the server to access the session.
//         xfbml: true,
//         version: 'v2.7'
//     });


//     FB.getLoginStatus(function (response) {   // Called after the JS SDK has been initialized.
//         statusChangeCallback(response);        // Returns the login status.
//     });
// };

function testAPI() {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
    console.log('Welcome!  Fetching your information.... ');
    FB.api('/me', function (response) {
        console.log('Successful login for: ' + response.name);
        document.getElementById('status').innerHTML =
            'Thanks for logging in, ' + response.name + '!';
    });
}

exports.loginfb = function () {
    FB.init({
        appId: '6890336221092025',       ///349415697054113 (Old One Not Working)
        cookie: true,                     // Enable cookies to allow the server to access the session.
        xfbml: true,
        version: 'v2.7'
    });
    FB.login(function (responses) {
        if (responses.authResponse) {
            FB.api('/me', { locale: 'tr_TR', fields: 'email,name' }, function (response) {

                FB.getLoginStatus(function (response) {
                    statusChangeCallback(response);
                });
                console.log("Full Response" + JSON.stringify(response))


                localStorage.setItem("facebook_signin_resp", JSON.stringify(response))

                if (response != null) {
                    window.location.href = localStorage.getItem("current_url")
                }

                // alert('Your name is ' + response.name);
                // alert('Your Id ' + response.id);
                // alert('Your status is ' + response.email);
            });
        }
    }, { scope: 'public_profile,email' });

}

exports.fbLogout = function () {
    FB.init({
        appId: '6890336221092025',       ///349415697054113,1054345689194751 (Old One Not Working)
        cookie: true,                     // Enable cookies to allow the server to access the session.
        xfbml: true,
        version: 'v19.0'
    });
    FB.AppEvents.logPageView();
    try {
        if (FB.getAccessToken() != null) {
            FB.logout(function(response) {
               alert("Success")
            });
        } else {
          
        }
    } catch (err) {
       
    }
}