import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-make-payment-status',
  templateUrl: './make-payment-status.component.html',
  styleUrls: ['./make-payment-status.component.scss']
})
export class MakePaymentStatusComponent implements OnInit {

  orderId: string;
  status: string;
  statusMessage: string;
  statusClass: string

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    // Get the query params from the URL
    this.route.queryParams.subscribe(params => {
      this.orderId = params['order_id'];
      this.status = params['status'];

      // Set the message based on the status
      this.setStatusMessage();
    });
  }

  setStatusMessage() {
    // Log the status to check for leading/trailing spaces
    console.log('Received status: ' + this.status);

    // Use trim() and convert to lowercase for consistent comparison
    const normalizedStatus = this.status.trim().toLowerCase();

    if (normalizedStatus === 'success') {
      this.statusMessage = 'Your payment was successful.';
      this.statusClass = 'success';
    } else if (normalizedStatus === 'failed') {
      this.statusMessage = 'Your payment has failed. Please try again.';
      this.statusClass = 'failed';
    } else {
      this.statusMessage = 'Unknown status. Please contact support.';
      this.statusClass = 'failed';
    }
  }

  onTryAgain() {
    this.router.navigate(['/make-payment']);  // Navigate to the make-payment page
  }
}
