import { Component, ElementRef, NgZone, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { PositioningService } from "angular-bootstrap-md/lib/free/utils/positioning/positioning.service";
// import * as L from "leaflet";
// import { icon, latLng, Map, Marker, marker, tileLayer } from "leaflet";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { CityWithPinPositionDto } from "src/app/shared/models/citywithpinpositiondto";
import { LocationDetailsDto } from "src/app/shared/models/locationdetailsdto";
import { LocationDetailsForStore } from "src/app/shared/models/locationdetailsforstore";
import { LocationWithLongLat } from "src/app/shared/models/LocationWithLongLat";
import { NameWithIdDto } from "src/app/shared/models/namewithiddto";
import { State } from "src/app/shared/models/state";
import { StoreService } from "src/app/shared/services/store.service";
import { MapsAPILoader } from "@agm/core";
import { FileService } from "src/app/common/File-Service";
import { getWindow } from "ssr-window";

var markers = [];
@Component({
  selector: "app-store",
  templateUrl: "./store.component.html",
  styleUrls: ["./store.component.scss"],
})
export class StoreComponent implements OnInit {
  k: number = 0;
  show: boolean = true;
  locationList: Location[] = [];
  createSetUpForm: UntypedFormGroup;
  stateList: State[] = [];
  locationDetailsForStore: LocationDetailsForStore;
  districtList: NameWithIdDto[] = [];
  cityList: NameWithIdDto[] = [];
  locList: NameWithIdDto[] = [];
  locationDetailsList: LocationDetailsDto[] = [];

  positionArray: LocationDetailsForStore;
  gpsLong: number[];
  gpsLati: number[];

  mapPosition: any = [];

  positionSearch: CityWithPinPositionDto[] = [];

  myControl1 = new UntypedFormControl();
  filteredOptions1: Observable<CityWithPinPositionDto[]>;
  locationWithLongLat: LocationWithLongLat[] = [];

  //For Google Map
  selectedLocationOnMap: any;
  latitudeMap: number;
  longitudeMap: number;
  zoom: number;
  addressMap: string;
  private geoCoder: any;
  newDeliveryDetails: boolean = false;
  isShowOpenGoogle: boolean = false;
  currentUrl: string = '';
  @ViewChild("map", { static: true }) map: any;
  @ViewChild("search")
  public searchElementRef: ElementRef;

  //Subscription
  private locationSubcription: Subscription;
  private stateSubcription: Subscription;
  private districtListSubscription: Subscription;
  private cityListSubscription: Subscription;
  private locationListSubscription: Subscription;
  private searchNameSubscription: Subscription;
  // streetMaps = tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
  //   detectRetina: true,
  // });
  // wMaps = tileLayer("http://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png", {
  //   detectRetina: true,
  // });
  // // Set the initial set of displayed layers (we could also use the leafletLayers input binding for this)
  // // options = {
  // //   layers: [this.streetMaps],
  // //   zoom: 6,
  // //   center: latLng([20.462123, 85.881286]),
  // // };
  // myIcon = L.icon({
  //   iconUrl: "assets/img/mapmarker.png",
  //   iconSize: [25, 41],
  //   iconAnchor: [13, 41],
  //   popupAnchor: [-3, -76],
  //   shadowSize: [68, 95],
  //   shadowAnchor: [22, 94],
  // });

  // map: Map;
  // onMapReady(map: Map) {
  //   this.markerList.forEach((element) => {
  //     element.removeFrom(map);
  //   });
  //   this.markerList = [];
  //   for (let i = 0; i < this.gpsLong.length; i += 1) {
  //     let marker = L.marker([this.gpsLati[i], this.gpsLong[i]], {
  //       icon: this.myIcon,
  //     });
  //     this.markerList.push(marker);
  //     marker.addTo(map);
  //   }
  //   this.map = map;
  // }

  constructor(
    private storeService: StoreService,
    private formBuilder: UntypedFormBuilder,
    private apiFactory: APIFactoryEndPoints,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private fileService: FileService,
  ) { }

  ngOnInit() {
    this.getAllLocations();
    // this.getStateList();
    this.onCreateSetUp();
    this.getGoogleMap();
    this.currentUrl = getWindow().location.hostname
  }

  getAllLocations() {
    this.gpsLong = [];
    this.gpsLati = [];
    // this.districtListSubscription = this.storeService.getAllLocations(this.apiFactory.getAllLocations).subscribe((data: any) => {
    //   console.log(data)
    //   this.locationWithLongLat = data;
    this.locationDetailsList = this.fileService.getFileData("locationDetailsForStore").locationDetails;
    console.log(this.locationDetailsList)
    // for (let j = 0; j < this.locationWithLongLat.length; j++) {
    //   this.locationDetailsList[j] = new LocationDetailsDto();
    //   this.locationDetailsList[j].locationName = this.locationWithLongLat[j].locationName;
    //   this.locationDetailsList[j].address = this.locationWithLongLat[j].address;
    //   this.locationDetailsList[j].pin = this.locationWithLongLat[j].pin;
    //   this.locationDetailsList[j].cityName = this.locationWithLongLat[j].cityName;
    //   this.locationDetailsList[j].lng = this.locationWithLongLat[j].longitude;
    //   this.locationDetailsList[j].lat = this.locationWithLongLat[j].latitude;
    // }

    for (let j = 0; j < this.locationDetailsList.length; j++) {
      if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
        this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
        this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
        this.k++;
      }
    }
    this.getStateList();
    // this.onMapReady(this.map);
    // });
  }

  //create
  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({});
  }

  getStateList() {
    this.stateList = this.fileService.getFileData("stateList");
    // this.stateSubcription = this.storeService.getStateList().subscribe((data) => {
    //   this.stateList = data;
    // });
  }

  onStateSelect(stateCode: number) {
    this.isShowOpenGoogle = false;
    this.districtListSubscription = this.storeService.getDistrictByStateCode(this.apiFactory.getDistrictByStateCode, stateCode, this.currentUrl)
      .subscribe((data) => {
        this.districtList = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  onDistrictSelect(districtCode: number) {
    this.isShowOpenGoogle = false;
    this.cityListSubscription = this.storeService.getCityByDistrictCode(this.apiFactory.getCityByDistrictCode, districtCode, this.currentUrl)
      .subscribe((data) => {
        this.cityList = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  onCitySelect(cityCode: number) {
    this.isShowOpenGoogle = false;
    this.locationListSubscription = this.storeService.getLocationByCityCode(this.apiFactory.getLocationByCityCode, cityCode, this.currentUrl).subscribe((data) => {
      this.locList = data.details;
      this.locationDetailsList = data.locationDetails;
      this.positionArray = data;
      this.gpsLati = [];
      this.gpsLong = [];
      this.k = 0;
      for (let j = 0; j < this.locationDetailsList.length; j++) {
        if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
          this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
          this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
          this.k++;
        }
      }
      // this.onMapReady(this.map);
      this.show = true;
    });
  }

  onLocationSelect(locationCode: number) {
    this.isShowOpenGoogle = false;
    this.locationListSubscription = this.storeService.getLocationByLocationCode(this.apiFactory.getLocationWithPositionByLocationCode, locationCode, this.currentUrl)
      .subscribe((data) => {
        // this.locList = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  // markerList: Marker[] = [];
  onPositionClick(lat: any, long: any) {
    this.isShowOpenGoogle = true;
    console.log(this.isShowOpenGoogle)
    this.gpsLati = [];
    this.gpsLong = [];
    if (Number(long) != 0 && Number(lat) != 0) {
      this.gpsLati[0] = Number(lat);
      this.gpsLong[0] = Number(long);

      this.latitudeMap = Number(lat);
      this.longitudeMap = Number(long);
    }
    // this.onMapReady(this.map);
  }

  //Map
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: { formatted_address: string; }[], status: string) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.addressMap = results[0].formatted_address;
          } else {
            //window.alert("No results found");
          }
        } else {
          //window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      // let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      // autocomplete.addListener("place_changed", () => {
      //   this.ngZone.run(() => {
      //     let place: google.maps.places.PlaceResult = autocomplete.getPlace();

      //     if (place.geometry === undefined || place.geometry === null) {
      //       return;
      //     }

      //     this.latitudeMap = place.geometry.location.lat();
      //     this.longitudeMap = place.geometry.location.lng();
      //     this.zoom = 12;

      //     this.createSetUpForm.patchValue({
      //       latitude: place.geometry.location.lat(),
      //       longitude: place.geometry.location.lng(),
      //     });
      //   });
      // });
    });
  }

  markerDragEnd(event: any) {
    this.latitudeMap = event.coords.lat;
    this.longitudeMap = event.coords.lng;
    this.getAddress(this.latitudeMap, this.longitudeMap);
    this.createSetUpForm.patchValue({
      latitude: event.coords.lat,
      longitude: event.coords.lng,
    });
  }

  onSearch() {
    let window: any;
    console.log("clicked")
    // let latlong = "";
    // this.gpsLati.forEach((e, index) => {
    //   latlong = latlong + e + "," + this.gpsLong[index] + "&";
    // })
    // latlong = latlong.substring(0,latlong.length-1)
    // console.log(latlong)
    //window.open("https://www.google.com/maps/search/?api=1&query=" + this.latitudeMap + "," + this.longitudeMap);
  }

  mapReady(event: any) {
    this.map = event;
    const input = document.getElementById('Map-Search');
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('Logout'));
  }
}
