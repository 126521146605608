<div style="height: 88%; overflow-y: scroll">
    <div class="popup" id="couponPolicy">
        <div class="popup-inner" style="padding: 6%; padding-top: 4%">
            <h2 class="h2_title">1Bakeshop Coupon Program</h2>
            <div style="display: block;width: 60px;height: 3px;background: red;margin: 10px auto;margin-top: 1px;">
            </div>
            <p class="p_content">
                <br />
                <b class="b_content">1. Eligibility</b>
                <br /><br />
                1.1. The 1Bakeshop Coupon Program (the "Program") is open to individuals who sign up on our website,
                (www.1bakeshop.com).
                <br /><br />
                1.2. Participants must be at least 18 years old and have a valid email address & mobile number.
                <br /><br />
                1.3. Employees of 1Bakeshop, its affiliates, subsidiaries, advertising and promotion agencies, and their
                immediate family members are not eligible to participate.
                <br /><br />
                <b class="b_content">2. Registration</b>
                <br /><br />
                2.1. To participate, customers must provide accurate and complete registration information, including:
                <br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;* Valid email address
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;* Name
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;* Contact information (optional)
                <br /><br />
                2.2. Customers are responsible for maintaining the confidentiality of their account information and
                password.
                <br /><br />
                <b class="b_content">3. Coupon Distribution</b>
                <br /><br />
                3.1. Registered customers will receive product offers with discounted coupons every month, automatically
                loaded to their coupon account.
                <br /><br />
                3.2. Coupons will not be sent via email, mobile number or address.
                <br /><br />
                3.3. 1Bakeshop reserves the right to modify, suspend, or terminate the Program at any time without
                notice.
                <br /><br />
                <b class="b_content">4. Coupon Redemption</b>
                <br /><br />
                4.1. Coupons can be redeemed by visiting a nearest 1Bakeshop store.
                <br /><br />
                4.2. Customers must be present and show the coupon at the time of purchase.
                <br /><br />
                4.3. Coupons are redeemable only for the specified product(s) and cannot be exchanged for cash or other
                products.
                <br /><br />
                <b class="b_content">5. Coupon Limitations</b>
                <br /><br />
                5.1. Only one (1) coupon can be redeemed per day.
                <br /><br />
                5.2. Coupons cannot be combined with other offers, discounts, or promotions.
                <br /><br />
                5.3. Coupons are non-transferable and have no cash value.
                <br /><br />
                5.4. Coupons are valid for a limited time, as specified on the coupon.
                <br /><br />
                <b class="b_content">6. Program Changes</b>
                <br /><br />
                6.1. 1Bakeshop reserves the right to modify, suspend, or terminate the Program at any time without
                notice.
                <br /><br />
                6.2. Changes to the Program will be posted on our website.
                <br /><br />
                <b class="b_content">7. Data Protection</b>
                <br /><br />
                7.1. By registering, customers agree to 1Bakeshop's Privacy Policy, available on our website.
                <br /><br />
                7.2. Customers' personal data may be used for the purpose of administering the Program and for marketing
                purposes.
                <br /><br />
                <b class="b_content">8. Governing Law</b>
                <br /><br />
                8.1. These Terms & Conditions shall be governed by and construed in accordance with the laws of Odisha,
                India.
                <br /><br />
                8.2. Any disputes arising out of or in connection with these Terms & Conditions shall be resolved
                through arbitration.
                <br /><br />
                8.3. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to
                the exclusive jurisdiction of the courts in Bhubaneswar.
                <br /><br />
                <b class="b_content">9. Liability</b>
                <br /><br />
                9.1. 1Bakeshop shall not be liable for any damages, losses, or expenses arising from or in connection
                with the Program.
                <br /><br />
                9.2. Customers participate in the Program at their own risk.
                <br /><br />
                <b class="b_content">10. Contact Us</b>
                <br /><br />
                10.1. For questions or concerns about the Program, please visit our website www.1bakeshop.com.
                <br /><br />
                <b class="b_content">11. Acceptance</b>
                <br /><br />
                11.1. By participating in the Program, customers acknowledge that they have read, understood, and agree
                to be bound by these Terms & Conditions.
                <br /><br />
                <b class="b_content">12. Entire Agreement</b>
                <br /><br />
                12.1. These Terms & Conditions constitute the entire agreement between 1Bakeshop and customers regarding
                the Program.
                <br /><br />
                <b class="b_content">13. Severability</b>
                <br /><br />
                13.1. If any provision of these Terms & Conditions is held to be invalid or unenforceable, the remaining
                provisions shall remain in full force and effect.
                <br /><br />
                By participating in the Program, customers acknowledge that they have read, understood, and agree to be
                bound by these Terms & Conditions.
            </p>
        </div>
    </div>
</div>

<!-- Checkbox and Proceed button -->
<div class="col-md-12 col-12">
    <div class="control">
        <label class="checkbox" style="margin-left: 20px">
            <input type="checkbox" class="input_checkbox" id="agree" [(ngModel)]="isAgreed" />
            I agree to the Terms & Conditions</label>
        <div style="text-align: center;">
            <button type="button" class="proceed-btn" [disabled]="!isAgreed" (click)="proceed()">Proceed</button>
        </div>
    </div>
</div>

<div class="floating-cancel-btn">
    <p style="text-align: center">
        <a class="closse_btn" (click)="close(false)">
            <i class="fa fa-close"></i>
        </a>
    </p>
</div>