import { Component, OnInit } from '@angular/core';
import { Router, Event } from '@angular/router';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loadingprogress: boolean = false
  progressValue: number = 0;
  stopProgress: boolean;
  progressColor: string;
  progressTimer: NodeJS.Timeout;
  width: number = 10;

  constructor(
    private loaderService: LoaderService,
    private router: Router
  ) {
    // Subscribe to loader service
    this.loaderService.isLoading.subscribe((isLoading) => {
      this.loadingprogress = isLoading;
    });

    // Subscribe to router events for navigation changes
    this.router.events.subscribe((event: Event) => {
      this.navigationObserver(event);
    });
  }

  ngOnInit() {
  }
  

  private navigationObserver(event: Event): void {

    // if (event instanceof NavigationStart) {
    //   this.progressTimer = setInterval(() => {
    //     this.loading();
    //   }, 100);
    // }
    // if (event instanceof NavigationEnd) {
    //   this.progressValue = window.innerWidth;
    //   this.stopProgress = true;
    // }
  }
}