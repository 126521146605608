import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CouponDto } from '../models/coupon-dto';
import { RedeemableProductDetailsDto } from '../models/redeemable-product-details-dto';
import { FeedbackDetailsDto } from '../models/feedback-details-dto';
import { HandleError, HttpErrorHandlerService } from './http-error-handler.service';
import { catchError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyCouponService {

  private handleError: HandleError

  constructor(
    private http: HttpClient,
    httpErrorHandle: HttpErrorHandlerService
  ) {
    this.handleError = httpErrorHandle.createHandleError('MastercontainerService');
  }

  getCouponDetailsByLoggedInUser(url: string, customerEmail: string) {
    return this.http.get<CouponDto[]>(url + "/" + customerEmail);
  }

  getRedeemAvailableProductsCoupon(url: string, couponCode: string) {
    return this.http.get<RedeemableProductDetailsDto[]>(url + '/' + couponCode);
  }

  getFeedbackDetailsByEmail(url: string, customerEmail: string) {
    return this.http.get<FeedbackDetailsDto[]>(url + "/" + customerEmail);
  }

  getImage(url: string, img: String): any {
    return this.http.get<any>(url + img, { responseType: 'arraybuffer' as 'json' })
      .pipe(
        catchError(this.handleError('getImage', "SORRY"))
      );
  }

  getDynamicCouponCode(url: string, code: string) {
    return this.http.get<any>(url + "/" + code);
  }

  getLocationByLatAndLng(url: string, lat: string, lng: string, couponCode: string) {
    return this.http.get<any>(url + "/" + lat + "/" + lng + "/" + couponCode);
  }

  saveAgreedTerms(url: string, data: any) {
    return this.http.post(url, data);
  }

  getCouponTermsAgreed(url: string, email: string) {
    return this.http.get<boolean>(url + "/" + email);
  }

  saveRating(url: string, couponCode: string, rating: number) {
    return this.http.patch(url + "/" + couponCode + "/" + rating, null);
  }
}
