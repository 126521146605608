import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { APIFactoryEndPoints } from 'src/app/common/apifactory-end-points';
import { FileService } from 'src/app/common/File-Service';
import { LocalStorageService } from 'src/app/common/LocalStorageService';
import { AdvanceSearchDTO } from 'src/app/shared/models/advance-search-dto';
import { MatrixDto } from 'src/app/shared/models/matrix-dto';
import { Product } from 'src/app/shared/models/product';
import { ProductGroupWithIdAndName } from 'src/app/shared/models/productgroupwithidandname';
import { ProductsWithWebOrderOffers } from 'src/app/shared/models/productswithweborderoffers';
import { ViewOfferSetUpDto } from 'src/app/shared/models/viewoffersetupdto';
import { ProductService } from 'src/app/shared/services/product.service';
import { ToastService } from 'src/app/shared/services/toastr.service';
import { getWindow } from 'ssr-window';
import { OfferDetailsDialogComponent } from '../offer-details-dialog/offer-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PinDialogComponent } from '../pin-dialog/pin-dialog.component';
import { ProductsWithStock } from 'src/app/shared/models/products-with-stock';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductDesignDialogComponent } from '../product-design-dialog/product-design-dialog.component';
import { OfferDialogComponent } from '../offer-dialog/offer-dialog.component';
import { BulkDiscountDto } from 'src/app/shared/models/bulkdiscountdto';
import { MakeCakeService } from 'src/app/shared/services/make-cake.service';
import { ProductSubGroupDtoWithIdName } from 'src/app/shared/models/productsubgroupdtowithidname';
import { MakeProductDto } from 'src/app/shared/models/makeproductdto';
import { ActivatedRoute } from '@angular/router';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { VerifyMobileNoDialogComponent } from '../../verify-mobile-no-dialog/verify-mobile-no-dialog.component';
import { State } from 'src/app/shared/models/state';
import { NameWithIdDto } from 'src/app/shared/models/namewithiddto';
import { City } from 'src/app/shared/models/city';
import { WebOrderFranchiseSetup } from 'src/app/shared/models/web-order-franchise-setup';
import { LocationDetailsForStore } from 'src/app/shared/models/locationdetailsforstore';
import { UserService } from 'src/app/shared/services/user.service';
import { LocationDetailsDto } from 'src/app/shared/models/locationdetailsdto';
import { LocationWithLongLat } from 'src/app/shared/models/LocationWithLongLat';
import { StoreService } from 'src/app/shared/services/store.service';
import { MapsAPILoader } from '@agm/core';
import { WebOrderCareerSetUp } from 'src/app/shared/models/webordercareersetup';
import { CarrerService } from 'src/app/shared/services/carrer.service';
import { FeedbackSetupWebDto } from 'src/app/shared/models/feedback-setup-web-dto';
import { ContactService } from 'src/app/shared/services/contact.service';
import { GoogleCustomer } from 'src/app/shared/models/googlecustomer';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  matrixListProduct: MatrixDto[] = [];
  allGroupListProduct: ProductGroupWithIdAndName[] = [];
  allProductList: Product[] = [];
  productListProduct: Product[] = new Array(1200);
  displayProduct: Product[] = new Array(1200);
  productListForFilters: Product[] = new Array(1200);
  productWithStock: ProductsWithStock[] = [];
  designProducts: Product[] = [];

  groupListProduct: ProductGroupWithIdAndName[] = [];
  productWithOffers: ProductsWithWebOrderOffers[];
  offerTypeArrayProduct: string[] = [];

  groupClickIdProduct: number;
  subGroupClickIdProduct: number;
  foodTypes: string[] = ["Veg", "Non-Veg"];
  pincode: string;

  searchFormProduct: UntypedFormGroup;
  advanceSearchData: AdvanceSearchDTO = new AdvanceSearchDTO();

  locationCode: number;
  min: number;
  max: number;
  minPrice: number;
  maxPrice: number;
  minDiscRate: number;
  maxDiscRate: number;
  currentUrl: string;
  sharingUrlProduct: string;

  subGroupShow: boolean = false;
  loadingProduct = false;
  show: boolean = false;

  searchBtnForPriceProduct: boolean = true;
  cleatBtnPriceProduct: boolean = false;
  searchBtnForDiscProduct: boolean = true;
  cleatBtnDiscProduct: boolean = false;
  openfilterProduct: boolean = false;
  selectedBrand: 'All';

  offerObj: any;
  productOffer: Product;
  discountOffer: number;
  bulkDiscountOffer: BulkDiscountDto;
  deliveryType: string;
  googleCustomer: GoogleCustomer;

  //Subscription
  private webOrderBestProductSubcription: Subscription;
  private offerSubscription: Subscription;
  private getDesignSubscription: Subscription;
  private checkAllowStockSubscription: Subscription;
  private stockSubscription: Subscription;
  private productOfferSubscription: Subscription;
  private productDiscountSubscription: Subscription;
  private productBulkDiscountSubscription: Subscription;

  private makeToOrderGroupSubcription: Subscription;

  //Make To Order

  constructor(
    private localStorage: LocalStorageService,
    private fileService: FileService,
    private productService: ProductService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private makecakeService: MakeCakeService,
    private route: ActivatedRoute,
    private franchaiseService: FranchiseService,
    private userService: UserService,
    private storeService: StoreService,
    private mapsAPILoader: MapsAPILoader,
    private careerService: CarrerService,
    private contactService: ContactService,
  ) {
    this.locationCode = JSON.parse(this.localStorage.getItem('location_code'));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));
    this.route.queryParams.subscribe((paramMap) => {
      if (paramMap["locCode"])
        this.localStorage.setItem("orderFromTvLocation", JSON.stringify(Number(paramMap["locCode"])));
      else
        this.localStorage.setItem("orderFromTvLocation", JSON.stringify(0));

      console.log(this.localStorage.getItem("orderFromTvLocation"));
    });
  }

  ngOnInit(): void {
    this.currentUrl = getWindow().location.hostname;
    this.sharingUrlProduct = getWindow().location.origin + '/product' + '/';
    this.sharingUrlMto = getWindow().location.origin + '/make-product' + '/';
    this.searchFormProductSetUp();
    this.getMatrixListProduct();
    this.searchFormMtoSetUp();
    this.getMakeToOrderGroupList();
    this.onCreateSetUp();
    this.getWebOrderFranchiseSetUpList();
    this.getAllLocationsStore();
    this.getGoogleMap();
    this.onCareerCreateSetUp();
    this.getWebOrderCareerSetUpList();
    this.onCreateSetUpContactUs();
    this.getWebOrderFeedbackSetUpList();
  }

  searchFormProductSetUp() {
    this.searchFormProduct = this.formBuilder.group({
      minValue: [this.min],
      maxValue: [this.max],
      minDisc: [1, Validators.required],
      maxDisc: [99, Validators.required],
    });
  }

  getMatrixListProduct() {
    this.matrixListProduct = this.fileService.getFileData("matrixList");
    this.allGroupListProduct = this.fileService.getFileData("allGroupList");
    this.allProductList = this.fileService.getFileData("allProductList");

    let matrixId: number = JSON.parse(this.localStorage.getItem("matrix_code"));
    let groupId: number = JSON.parse(this.localStorage.getItem("prod_group_code"));
    this.groupClickIdProduct = matrixId;
    this.subGroupClickIdProduct = groupId;

    if (groupId) {
      this.getGroupByMatrixCode(matrixId);
      this.onGroupSelectProduct(groupId);
    } else if (matrixId) {
      this.onMatrixSelectProduct(matrixId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }
  }

  onMatrixSelectProduct(matrixCode: number) {
    this.groupClickIdProduct = matrixCode;
    this.subGroupClickIdProduct = null;
    this.localStorage.setItem('matrix_code', JSON.stringify(matrixCode));
    this.localStorage.removeItem('prod_group_code');

    this.loadingProduct = true;
    this.productListProduct = this.allProductList.filter(e => e.productSubGroup?.productGroup?.productMatrix?.refNo == matrixCode);
    this.productListForFilters = this.productListProduct;
    this.displayProduct = this.productListProduct;
    this.show = true;
    this.loadingProduct = false;
    var Max = this.productListProduct.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productListProduct.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.searchFormProduct.patchValue({ minValue: Min, maxValue: Max });
    this.getOfferDetails();
    this.getGroupByMatrixCode(matrixCode);
  }

  getGroupByMatrixCode(matrixCode: number) {
    this.groupListProduct = this.allGroupListProduct.filter((e) => e.productMatrixCode == matrixCode);
    this.subGroupShow = true;
  }

  onGroupSelectProduct(prodGroupId: number) {
    this.subGroupClickIdProduct = prodGroupId;
    this.localStorage.setItem('prod_group_code', JSON.stringify(prodGroupId));
    this.localStorage.removeItem('prod_subgroup_code');

    this.loadingProduct = true;
    this.show = true;
    this.productListProduct = this.allProductList.filter(e => e.productSubGroup.productGroup.refNo == prodGroupId);
    this.productListForFilters = this.productListProduct;
    this.displayProduct = this.productListProduct;
    this.show = true;
    this.loadingProduct = false;
    var Max = this.productListProduct.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productListProduct.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    console.log(`Max: ${Max}`);
    console.log(`Min: ${Min}`);
    this.searchFormProduct.patchValue({ minValue: Min, maxValue: Max });
    this.getOfferDetails();
  }

  getOfferDetails() {
    let locId: number = JSON.parse(this.localStorage.getItem('location_code'));
    let productId: number[] = this.productListProduct.map((element) => element.product_Code);

    if (productId.length > 0) {
      const type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

      // If no delivery type is set, proceed with the offer details directly
      if (!type) {
        this.fetchOfferDetails(locId, productId);
        return;
      }

      // Fetch the offer eligibility using getOfferAllowFor
      this.offerSubscription = this.productService.getOfferAllowFor(
        this.apiFactory.getOfferAllowFor,
        this.currentUrl,
        type
      ).subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        // Set location based on the response
        locId = data === 'allowForWebSite'
          ? this.googleCustomer?.locationCode || 0
          : data === 'allowForLocation'
            ? JSON.parse(this.localStorage.getItem('location_code')) || 0
            : 0;

        // Proceed with fetching offer details after determining the location
        this.fetchOfferDetails(locId, productId);
      });
    }
  }

  fetchOfferDetails(locId: number, productId: number[]) {
    this.offerSubscription = this.productService.getOfferDetails(
      this.apiFactory.getOfferDetails,
      locId,
      productId,
      this.currentUrl
    ).subscribe((data: any) => {
      if (data.length > 0) {
        this.productWithOffers = data;
        this.offerTypeArrayProduct = [];

        this.productWithOffers.forEach((offer) => {
          offer.offerDetails.forEach((offerDetails) => {
            if (!this.offerTypeArrayProduct.includes(offerDetails.schemeType)) {
              this.offerTypeArrayProduct.push(offerDetails.schemeType);
            }
          });
        });

        this.productListProduct.forEach((element) => {
          const arr = this.productWithOffers.filter((offer) => offer.productCode === element.product_Code);
          if (arr.length) {
            Object.assign(element, arr[0]); // Merge offerDetails and offers directly
          }
        });
      }
    });
  }

  getWebOrderBestProductSetUpList() {
    this.webOrderBestProductSubcription = this.productService.getWebOrderBestProductSetup(this.apiFactory.getBestProducts, this.currentUrl)
      .subscribe((data) => {
        this.productListProduct = data;
        this.productListForFilters = data;
        this.displayProduct = data;
        this.show = true;
        this.loadingProduct = false;
        this.getOfferDetails();
      });
  }

  validateMinMaxProduct(key: string, callOrigin: string) {
    this.searchBtnForPriceProduct = true;
    this.cleatBtnPriceProduct = false;
    console.log(this.searchBtnForPriceProduct);
    key = this.validateNumberForPrice(key, 'ts', '');

    if (callOrigin === 'min') {
      if (Number.parseInt(key) > this.max) {
        key = (this.max - 1).toString();

        this.searchFormProduct.get('minValue').setValue(key);
        this.advanceSearchData.minPrice = Number.parseInt(key);
      }
      this.searchFormProduct.get('maxValue').setValue(Number.parseInt(key) + 1);
      this.advanceSearchData.maxPrice = Number.parseInt(key) + 1;
    } else if (callOrigin === 'max') {
      if (Number.parseInt(key) > this.max) {
        key = this.max.toString();

        this.searchFormProduct.get('maxValue').setValue(key);
        this.advanceSearchData.maxPrice = Number.parseInt(key);
      }
    }
  }

  validateNumberForPrice(key: string, callOrigin: string, target: string) {
    if (Number.parseInt(key) <= 0) {
      key = '1';
    }

    let reg = null;
    if (callOrigin === 'template') {
      reg = /^\d{0,2}(?:[.,]\d{1,2})?$/;
    } else {
      reg = /^\d*(?:[.,]\d{1,2})?$/;
    }

    if (!reg.test(key)) {
      let count = 0;
      if (key.includes('.')) {
        count++;
      }

      let lastIndex = key.length - 1;
      if (count == 1 && key.charAt(lastIndex) == '.') {
      } else {
        key = key.substring(0, lastIndex);
      }
    }

    if (callOrigin === 'template') {
      if (target === 'min') {
        this.searchFormProduct.get('minDisc').patchValue(key);
      } else if (target === 'max') {
        this.searchFormProduct.get('maxDisc').patchValue(key);
      }
    } else {
      return key;
    }
  }

  validateNumberProduct(key: string, callOrigin: string, target: string) {
    this.searchBtnForDiscProduct = true;
    this.cleatBtnDiscProduct = false;
    if (Number.parseInt(key) <= 0) {
      key = '1';
    }

    let reg = null;
    if (callOrigin === 'template') {
      reg = /^\d{0,2}(?:[.,]\d{1,2})?$/;
    } else {
      reg = /^\d*(?:[.,]\d{1,2})?$/;
    }

    if (!reg.test(key)) {
      let count = 0;
      if (key.includes('.')) {
        count++;
      }

      let lastIndex = key.length - 1;
      if (count == 1 && key.charAt(lastIndex) == '.') {
      } else {
        key = key.substring(0, lastIndex);
      }
    }

    if (callOrigin === 'template') {
      if (target === 'min') {
        this.searchFormProduct.get('minDisc').patchValue(key);
      } else if (target === 'max') {
        this.searchFormProduct.get('maxDisc').patchValue(key);
      }
    } else {
      return key;
    }
  }

  validateProduct(callOrigin: string, key: string) {
    if (callOrigin === 'price') {
      if (
        Number.parseInt(this.searchFormProduct.get('minValue').value) >
        Number.parseInt(key)
      ) {
        key = (
          Number.parseInt(this.searchFormProduct.get('minValue').value) + 1
        ).toString();
        this.searchFormProduct.get('maxValue').setValue(key);
      }
    } else if (callOrigin === 'disc') {
      if (
        Number.parseInt(this.searchFormProduct.get('minDisc').value) >
        Number.parseInt(key)
      ) {
        key = (
          Number.parseInt(this.searchFormProduct.get('minDisc').value) + 1
        ).toString();
        this.searchFormProduct.get('maxDisc').setValue(key);
      }
    }
  }

  buttonClickForSearchProduct(type: String) {
    console.log(this.searchFormProduct);
    if (type == 'price') {
      this.minPrice = this.searchFormProduct.get('minValue').value;
      this.maxPrice = this.searchFormProduct.get('maxValue').value;
    }
    if (type == 'disc') {
      this.maxDiscRate = Number(this.searchFormProduct.get('maxDisc').value);
      this.minDiscRate = Number(this.searchFormProduct.get('minDisc').value);
    }
    if (type == null) {
      this.minDiscRate = null;
      this.maxDiscRate = null;
    }
    this.advanceSearchData.locationCode = this.locationCode;
    this.advanceSearchData.module = 'WebProduct';

    if (this.maxPrice && this.maxPrice != 0) {
      this.advanceSearchData.maxPrice = this.maxPrice;
    } else {
      this.advanceSearchData.maxPrice = this.max;
    }
    if (this.minPrice != 0) {
      this.advanceSearchData.minPrice = this.minPrice;
    }
    if (this.minDiscRate != 0) {
      this.advanceSearchData.minDiscRate = this.minDiscRate;
    }
    if (this.maxDiscRate != 0) {
      this.advanceSearchData.maxDiscRate = this.maxDiscRate;
    }

    this.productListProduct = this.productListForFilters.filter(
      (element) =>
        element.net_Sale_Price >= this.minPrice &&
        element.net_Sale_Price <= this.maxPrice
    );
    this.getOfferDetails();
    if (type == 'price') {
      this.searchBtnForPriceProduct = false;
      this.cleatBtnPriceProduct = true;
    }
    if (type == 'disc') {
      this.searchBtnForDiscProduct = false;
      this.cleatBtnDiscProduct = true;
    }
    this.openfilterProduct = false;
  }

  clearPriceInputProduct() {
    this.searchFormProduct.get('minValue').reset();
    this.searchFormProduct.get('maxValue').reset();
    this.cleatBtnPriceProduct = false;
    this.searchBtnForPriceProduct = true;
    this.productListProduct = this.displayProduct;
  }

  clearDiscInputProduct() {
    this.searchFormProduct.get('minDisc').reset();
    this.searchFormProduct.get('maxDisc').reset();
    this.cleatBtnDiscProduct = false;
    this.searchBtnForDiscProduct = true;
    this.productListProduct = this.displayProduct;
    this.searchFormProduct.patchValue({ minDisc: 0, maxDisc: 10 });
  }

  productListForFilter3: Product[] = new Array(1200);
  selectedValues: string[] = [];
  radioStatusProduct: boolean;
  checkedStatusProduct: boolean;
  onChangeCheckBoxClickProduct(event: boolean, value: string) {
    this.productListForFilter3 = [];
    if (event == false) {
      this.selectedValues = this.selectedValues.filter((item) => item !== value);
    } else {
      // Add value if checkbox is checked
      this.selectedValues.push(value);
    }
    this.productListForFilter3 = this.productListForFilters.filter(function (
      element
    ) {
      return element.offerDetails?.some(function (offerDetails) {
        return offerDetails.schemeType == value;
      });
    });
    this.productListProduct = this.productListForFilter3;
    if (this.selectedValues.length == 0) {
      this.productListProduct = this.displayProduct;
    }
  }

  productListForFilter2: Product[] = new Array(1200);
  checkedValues: string[] = [];
  onCheckBoxClickProduct(event: boolean, value: string) {
    this.productListForFilter2 = [];
    if (event == false) {
      this.checkedValues = this.checkedValues.filter((item) => item !== value);
    } else {
      this.checkedValues.push(value);
    }
    this.productListForFilters.forEach((element) => {
      this.checkedValues?.forEach((value) => {
        if (element.food_Type == value) {
          this.productListForFilter2.push(element);
        }
      });
    });
    this.productListProduct = this.productListForFilter2;
    if (this.checkedValues.length == 0) {
      this.productListProduct = this.displayProduct;
    }
  }

  viewOfferDetailProduct(offers: ViewOfferSetUpDto[]) {
    console.log(offers);
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers },
    });

    dialogRef.afterClosed().subscribe((res) => { });
  }

  addFavouriteProduct(product: Product) {
    const a: Product[] = JSON.parse(this.localStorage.getItem('avf_item')) || [];
    product.cartProductType = 'Product';
    this.productService.addFavouriteProduct(product);
  }

  addToCartProduct(product: Product) {
    console.log(product);
    this.openPinDialog(product);
  }

  openPinDialog(product: Product) {
    this.pincode = this.localStorage.getItem('pincode_item');

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(PinDialogComponent, {
        width: '300px',
        panelClass: 'pin-dialog-container',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result?.result == true) {
          let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
          if (product.shelf_Life_In_Hours >= selfLife) {
            product.cartProductType = 'Product';
            this.getProductWithStock(product);
          } else {
            this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
          }
          this.getOfferDetails();
        }
      });
    } else {
      let selfLife = JSON.parse(this.localStorage.getItem('min_selflife'));
      if (product.shelf_Life_In_Hours >= selfLife) {
        product.cartProductType = 'Product';
        this.getProductWithStock(product);
      } else {
        this.toastrService.showErrorToast("Cannot add to cart, shelf life is under " + selfLife + " hours.");
      }
    }
  }

  getProductWithStock(product: Product) {
    if (this.checkAllowStockSubscription) return;

    let locId = JSON.parse(this.localStorage.getItem('location_code'));
    if (locId) {
      this.checkAllowStockSubscription = this.productService.checkAllowStockByLocAndUrl(this.apiFactory.checkAllowStockByLocAndUrl, locId, this.currentUrl)
        .subscribe(
          (data: any) => {
            this.checkAllowStockSubscription = null;
            if (data) {
              if (product.product_Code) {
                this.stockSubscription = this.productService.getStockDetails(this.apiFactory.getStockDetails, [product.product_Code], locId)
                  .subscribe(
                    (data: any) => {
                      this.productWithStock = data;
                      var arr = this.productWithStock.filter(
                        (p) => p.product_Code == product.product_Code
                      );
                      if (arr.length != 0) {
                        product.systemStock = arr[0].systemStock;
                      }
                      if (product.cartProductType == 'Product' && product.minimum_Order_Quantity > product.systemStock) {
                        this.toastrService.showErrorToast(product.product_Name + ' is out of stock, stock available ' + product.systemStock);
                        return;
                      }
                      this.getDesignBySubGroupAndUrl(product);
                    },
                    (error: HttpErrorResponse) => {
                      this.toastrService.showErrorToast('Something wrong..');
                    }
                  );
              } else {
              }
            } else {
              this.getDesignBySubGroupAndUrl(product);
            }
          },
          (error: HttpErrorResponse) => {
            this.toastrService.showErrorToast('Something wrong..');
          }
        );
    } else {
      this.toastrService.showErrorToast("Location can't place order");
    }
  }

  getDesignBySubGroupAndUrl(product: Product) {
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem('avct_item')) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
      return;
    }
    this.getDesignSubscription = this.productService.getWebOrderDesigns(this.apiFactory.getWebOrderDesignBySubgroupAndUrl, product.productSubGroup.refNo, this.currentUrl)
      .subscribe((data) => {
        console.log(data);
        if (data.length > 0) {
          this.designProducts = data;
          const cartProducts: Product[] =
            JSON.parse(this.localStorage.getItem('avct_item')) || [];
          let designIndex = cartProducts.findIndex(
            (e) => e.designForProductCode === product.product_Code
          );
          if (designIndex >= 0) {
            this.designProducts.map((e) => (e.is_Default = false));
            this.designProducts
              .filter(
                (item) =>
                  item.product_Code == cartProducts[designIndex].product_Code
              )
              .map((e) => (e.is_Default = true));
          }
          this.openDesignDialog(product, this.designProducts);
        } else {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null);
          else
            this.productService.addToCart(product);
        }
      });
  }

  openDesignDialog(product: Product, designProducts: Product[]) {
    document.body.classList.add('dialog-open');
    const dialogRef = this.dialog.open(ProductDesignDialogComponent, {
      width: '430px',
      height: '410px',
      maxWidth: '90vw',
      autoFocus: false,
      data: designProducts,
      panelClass: 'custom-dialog-container',
    });

    dialogRef.afterClosed().subscribe((result) => {
      document.body.classList.remove('dialog-open');
      if (result) {
        if (result?.data) {
          let design: Product = result?.data;
          design.designForProductCode = product.product_Code;
          design.cartProductType = 'Product';
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, design);
          else
            this.productService.addToCartDesign(product, design);
        } else if (result?.noDesign) {
          if (product.offerDetails?.length > 0)
            this.openOfferDialog(product, null);
          else
            this.productService.addToCart(product);
        }
      }
    });
  }

  openOfferDialog(product: Product, design: Product) {
    console.log(product);
    const cartProducts: Product[] = JSON.parse(this.localStorage.getItem('avct_item')) || [];
    if (cartProducts.some((temp) => temp.product_Code == product.product_Code)) {
      this.toastrService.showErrorToast(product.product_Name + ' is allready added to cart');
      return;
    }
    let offers: ViewOfferSetUpDto[] = product.offerDetails;
    let appliedOfferId: string = product.offerId ? product.offerId : product.offerDetails[0].offerId;
    const dialogRef = this.dialog.open(OfferDialogComponent, {
      width: '500px',
      height: '300px',
      data: { offers, appliedOfferId },
    });

    dialogRef.afterClosed().subscribe((offerObj) => {
      console.log(offerObj);
      if (offerObj) {
        this.offerObj = offerObj;
        //conditions
        var orderQuantity;
        var freeByProductCode;
        var cartProductType;

        if (product) {
          product.orderQuantity = offerObj.minQty;
          orderQuantity = product.orderQuantity;
          freeByProductCode = product.product_Code;
          cartProductType = product.cartProductType;
          if (product.cartProductType == 'Product') {
            product.basePremiumFlavourPrice = 0;
            product.premiumFlavourPrice = 0;
          }
        }
        console.log(product);

        if (offerObj.schemeType == 'Buy 1 get 1 Free' || offerObj.schemeType == 'Buy X get Y Free') {
          this.productOfferSubscription = this.productService.getProductOfferDetails(this.apiFactory.productOfferDetails, offerObj.offerId, orderQuantity)
            .subscribe(
              (data) => {
                // offer processing
                this.productOffer = data;
                //set order qty as product QTY IN CART
                this.productOffer.product_Name = this.productOffer.product_Name.concat('(').concat(offerObj.schemeType).concat(')');
                this.productOffer.orderQuantity = orderQuantity;
                this.productOffer.net_Sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.sale_Price = offerObj.productPriceForCustomer;
                this.productOffer.discountAmt = 0;
                this.productOffer.discountRate = 0;
                this.productOffer.freeProductNetSalePrice = offerObj.netSalePrice;
                this.productOffer.freeByProductCode = freeByProductCode;
                this.productOffer.cartProductType = cartProductType;
                this.productOffer.product_Type = this.productOffer.product_Type;
                this.productOffer.product_Unit = this.productOffer.product_Unit;
                this.productOffer.food_Type = this.productOffer.food_Type;
                this.productOffer.hsn_Code = this.productOffer.hsn_Code;

                // main product tax and net amount calculations
                product.discountAmt = 0;
                product.discountRate = 0;
                var taxableAmt =
                  product.sale_Price - (product.sale_Price * 0) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                console.log(product);
                this.productService.addToCartWithOffer(product, this.productOffer, design);
              },
              (err) => {
                if (err.error) {
                  this.toastrService.showErrorToast(err.error.message);
                }
              }
            );
        } else {
          if (offerObj.schemeType == 'Buy x get x percent off') {
            this.productDiscountSubscription = this.productService
              .getProductDiscountDetails(this.apiFactory.productDiscountDetails, offerObj.offerId, orderQuantity)
              .subscribe(
                (data) => {
                  console.log(data);
                  this.discountOffer = data;
                  product.discountAmt = (this.discountOffer / 100) * product.sale_Price;
                  product.discountRate = this.discountOffer;
                  var taxableAmt = product.sale_Price - (product.sale_Price * this.discountOffer) / 100;
                  taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                  var cess = product.cess.cessRate;
                  var cessAmt = (cess / 100) * taxableAmt;
                  var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                  var tot = cgst + sgst;
                  product.promotionOfferId = offerObj.offerId;
                  product.offerId = offerObj.offerId;
                  product.taxAmount = tot; //Tax Amount
                  product.cgstAmt = cgst;
                  product.sgstAmt = sgst;
                  product.cessAmount = cessAmt;
                  product.net_Sale_Price = taxableAmt + tot + cessAmt;
                  this.productService.addToCartWithOffer(product, null, design);
                },
                (err) => {
                  if (err.error) {
                    this.toastrService.showErrorToast(err.error.message);
                  }
                }
              );
          } else {
            this.productBulkDiscountSubscription = this.productService.getProductBulkDiscountDetails(this.apiFactory.productBulkOfferDetails, offerObj.offerId)
              .subscribe((data) => {
                this.bulkDiscountOffer = data;
                product.discountAmt = (this.bulkDiscountOffer.discount / 100) * product.sale_Price;
                product.discountRate = this.bulkDiscountOffer.discount;
                var taxableAmt = product.sale_Price - (product.sale_Price * this.bulkDiscountOffer.discount) / 100;
                taxableAmt = taxableAmt + product.basePremiumFlavourPrice;
                var cess = product.cess.cessRate;
                var cessAmt = (cess / 100) * taxableAmt;
                var cgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var sgst = (product.tax.taxRate / 2 / 100) * taxableAmt;
                var tot = cgst + sgst;
                product.promotionOfferId = offerObj.offerId;
                product.offerId = offerObj.offerId;
                product.taxAmount = tot; //Tax Amount
                product.cgstAmt = cgst;
                product.sgstAmt = sgst;
                product.cessAmount = cessAmt;
                product.net_Sale_Price = taxableAmt + tot + cessAmt;
                this.productService.addToCartWithOffer(product, null, design);
              });
          }
        }
      } else {
      }
    });
  }

  //Make To Order
  makeToOrderGroupList: ProductGroupWithIdAndName[] = [];
  allSubGroupListMto: ProductSubGroupDtoWithIdName[] = [];
  allProductListMto: Product[] = [];
  subGroupListMto: ProductSubGroupDtoWithIdName[] = [];
  showMto: boolean = false;
  subGroupShowMto: boolean = false;
  loadingMto = false;
  selfLifeMto: any;
  groupClickIdMto: number;
  subGroupClickIdMto: number;
  makeProduct: MakeProductDto = new MakeProductDto();
  productListMto: Product[] = new Array(1200);
  productListForFiltersMto: Product[] = new Array(1200);
  displayProductMto: Product[] = new Array(1200);
  searchFormMto: UntypedFormGroup;
  cleatBtnPriceMto: boolean = false;
  selectedValuesMto: string[] = [];
  searchBtnForPriceMto: boolean = true;
  minPriceMto: number;
  maxPriceMto: number;
  minDiscRateMto: number;
  maxDiscRateMto: number;
  minMto: number;
  maxMto: number;
  searchBtnForDiscMto: boolean = true;
  offerTypeArray: string[] = [];
  offerMto: any;
  advanceSearchDataMto: AdvanceSearchDTO = new AdvanceSearchDTO();
  cleatBtnDiscMto: boolean = false;
  openfilterMto: boolean = false;
  productListForFilter2Mto: Product[] = new Array(1200);
  checkedValuesMto: string[] = [];
  checkedStatusMto: boolean;
  selectedIndexMto: any;
  sharingUrlMto: string = '';

  private subGroupSubscription: Subscription;
  private productSubscriptionMto: Subscription;
  private subGroupSubscriptionMto: Subscription;

  searchFormMtoSetUp() {
    this.searchFormMto = this.formBuilder.group({
      minValue: [this.min],
      maxValue: [this.max],
      minDisc: [1, Validators.required],
      maxDisc: [99, Validators.required]
    });
  }

  getMakeToOrderGroupList() {
    this.makeToOrderGroupList = this.fileService.getFileData("makeToOrderGroupList");
    this.allSubGroupListMto = this.fileService.getFileData("allSubGroupListMto");
    this.allProductListMto = this.fileService.getFileData("allProductListMto");

    this.selfLifeMto = this.localStorage.getItem("min_selflife");

    let groupId: number = JSON.parse(this.localStorage.getItem("mto_group_code"));
    let subgroupId: number = JSON.parse(this.localStorage.getItem("mto_subgroup_code"));
    this.groupClickIdMto = groupId;
    this.subGroupClickIdMto = subgroupId;

    if (subgroupId != undefined) {
      this.getSubGroupByGroupCode(groupId);
      this.onSubGroupSelectMto(subgroupId);
    } else if (groupId != undefined) {
      this.onGroupSelectMto(groupId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    // this.makeToOrderGroupSubcription = this.makecakeService.getMakeToOrderProductList(this.currentUrl).subscribe((data) => {
    //   this.makeToOrderGroupList = data;
    // });
  }

  getSubGroupByGroupCode(prodGroupId: number) {
    this.subGroupListMto = this.allSubGroupListMto.filter(e => e.productGroupCode == prodGroupId);
    this.subGroupShowMto = true;

    // this.subGroupSubscription = this.productService.getSubGroupDetailsByGroupCode(this.apiFactory.getSubGroupDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    //     this.subGroupListMto = data;
    //     this.subGroupShowMto = true;
    //   });
  }

  onSubGroupSelectMto(prodSubGroupId: number) {
    this.localStorage.setItem("mto_subgroup_code", JSON.stringify(prodSubGroupId));
    this.subGroupClickIdMto = prodSubGroupId;

    this.makeProduct.subGroupId = prodSubGroupId;
    this.loadingMto = true;

    this.productListMto = this.allProductListMto.filter(e => e.productSubGroup.refNo == prodSubGroupId);
    this.productListForFiltersMto = this.productListMto;
    this.displayProductMto = this.productListMto;
    this.showMto = true;
    this.loadingMto = false;
    var Max = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.searchFormMto.patchValue({ minValue: Min, maxValue: Max });


    // this.productSubscriptionMto = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsBySubGroupCode, prodSubGroupId)
    //   .subscribe((data) => {
    //     this.productListMto = data;
    //     this.productListForFiltersMto = data;
    //     this.displayProductMto = data;
    //     this.showMto = true;
    //     this.loadingMto = false;
    //     var Max = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    //     var Min = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    //     this.searchFormMto.patchValue({ minValue: Min, maxValue: Max });
    //   });
  }

  onGroupSelectMto(prodGroupId: number) {
    this.localStorage.setItem("mto_group_code", JSON.stringify(prodGroupId));
    this.groupClickIdMto = prodGroupId;

    this.loadingMto = true;

    this.productListMto = this.allProductListMto.filter(e => e.productSubGroup.productGroup.refNo == prodGroupId);
    this.productListForFiltersMto = this.productListMto;
    this.displayProductMto = this.productListMto;
    this.showMto = true;
    this.loadingMto = false;
    var Max = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.searchFormMto.patchValue({ minValue: Min, maxValue: Max });

    // this.productSubscriptionMto = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    //     this.productListMto = data;
    //     this.productListForFiltersMto = data;
    //     this.displayProductMto = data;
    //     this.showMto = true;
    //     this.loadingMto = false;
    //     var Max = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    //     var Min = this.productListMto.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    //     this.searchFormMto.patchValue({ minValue: Min, maxValue: Max });
    //   });

    this.makeProduct.groupId = prodGroupId;
    this.getSubGroupByGroupCodeMto(prodGroupId);
  }

  getSubGroupByGroupCodeMto(prodGroupId: number) {
    this.subGroupListMto = this.allSubGroupListMto.filter(e => e.productGroupCode == prodGroupId);
    this.subGroupShowMto = true;
    // this.subGroupSubscriptionMto = this.productService.getSubGroupDetailsByGroupCode(this.apiFactory.getSubGroupDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    //     this.subGroupListMto = data;
    //     this.subGroupShowMto = true;
    //   });
  }

  validateMinMaxMto(key: string, callOrigin: string) {
    this.searchBtnForPriceProduct = true;
    this.cleatBtnPriceMto = false;
    key = this.validateNumberForPriceMto(key, "ts", "");

    if (callOrigin === "min") {
      if (Number.parseInt(key) > this.max) {
        key = (this.max - 1).toString();

        this.searchFormMto.get("minValue").setValue(key);
        this.advanceSearchData.minPrice = Number.parseInt(key);
      }
      this.searchFormMto.get("maxValue").setValue(Number.parseInt(key) + 1);
      this.advanceSearchDataMto.maxPrice = Number.parseInt(key) + 1;
    } else if (callOrigin === "max") {
      if (Number.parseInt(key) > this.max) {
        key = this.max.toString();

        this.searchFormMto.get("maxValue").setValue(key);
        this.advanceSearchDataMto.maxPrice = Number.parseInt(key);
      }
    }
  }

  validateNumberForPriceMto(key: string, callOrigin: string, target: string) {
    if (Number.parseInt(key) <= 0) {
      key = "1";
    }

    let reg = null;
    if (callOrigin === "template") {
      reg = /^\d{0,2}(?:[.,]\d{1,2})?$/;
    } else {
      reg = /^\d*(?:[.,]\d{1,2})?$/;
    }

    if (!reg.test(key)) {
      let count = 0;
      if (key.includes(".")) {
        count++;
      }

      let lastIndex = key.length - 1;
      if (count == 1 && key.charAt(lastIndex) == ".") {
      } else {
        key = key.substring(0, lastIndex);
      }
    }

    if (callOrigin === "template") {
      if (target === "min") {
        this.searchFormMto.get("minDisc").patchValue(key);
      } else if (target === "max") {
        this.searchFormMto.get("maxDisc").patchValue(key);
      }
    } else {
      return key;
    }
  }

  validateMto(callOrigin: string, key: string) {
    if (callOrigin === "price") {
      if (Number.parseInt(this.searchFormMto.get("minValue").value) >= Number.parseInt(key)) {
        key = (Number.parseInt(this.searchFormMto.get("minValue").value) + 1).toString();
        this.searchFormMto.get("maxValue").setValue(key);
      }
    } else if (callOrigin === "disc") {
      if (Number.parseInt(this.searchFormMto.get("minDisc").value) > Number.parseInt(key)) {
        key = (Number.parseInt(this.searchFormMto.get("minDisc").value) + 1).toString();
        this.searchFormMto.get("maxDisc").setValue(key);
      }
    }
  }

  validateNumberMto(key: string, callOrigin: string, target: string) {
    this.searchBtnForDiscMto = true;
    this.cleatBtnDiscMto = false;
    if (Number.parseInt(key) <= 0) {
      key = "1";
    }

    let reg = null;
    if (callOrigin === "template") {
      reg = /^\d{0,2}(?:[.,]\d{1,2})?$/;
    } else {
      reg = /^\d*(?:[.,]\d{1,2})?$/;
    }

    if (!reg.test(key)) {
      let count = 0;
      if (key.includes(".")) {
        count++;
      }

      let lastIndex = key.length - 1;
      if (count == 1 && key.charAt(lastIndex) == ".") {
      } else {
        key = key.substring(0, lastIndex);
      }
    }

    if (callOrigin === "template") {
      if (target === "min") {
        this.searchFormMto.get("minDisc").patchValue(key);
      } else if (target === "max") {
        this.searchFormMto.get("maxDisc").patchValue(key);
      }
    } else {
      return key;
    }
  }

  buttonClickForSearchMto(type: string) {
    if (type == 'price') {
      this.minPriceMto = this.searchFormMto.get('minValue').value;
      this.maxPriceMto = this.searchFormMto.get('maxValue').value;
    }
    if (type == 'disc') {
      this.maxDiscRateMto = Number(this.searchFormMto.get('maxDisc').value);
      this.minDiscRateMto = Number(this.searchFormMto.get('minDisc').value);
    }
    if (type == null) {
      this.minDiscRateMto = null;
      this.maxDiscRateMto = null;
    }

    this.advanceSearchDataMto.locationCode = this.locationCode;
    this.advanceSearchDataMto.module = "WebMTO";
    this.advanceSearchDataMto.offer = [];

    if (this.maxPriceMto && this.maxPriceMto != 0) {
      this.advanceSearchDataMto.maxPrice = this.maxPriceMto;
    } else {
      this.advanceSearchDataMto.maxPrice = this.maxMto;
    }
    if (this.minPriceMto != 0) {
      this.advanceSearchDataMto.minPrice = this.minPriceMto;
    }
    if (this.minDiscRateMto != 0) {
      this.advanceSearchData.minDiscRate = this.minDiscRateMto;
    }
    if (this.maxDiscRateMto != 0) {
      this.advanceSearchData.maxDiscRate = this.maxDiscRateMto;
    }

    this.productListMto = this.productListForFiltersMto.filter(element => element.net_Sale_Price >= this.minPriceMto && element.net_Sale_Price <= this.maxPriceMto);

    if (type == 'price') {
      this.searchBtnForPriceMto = false;
      this.cleatBtnPriceMto = true;
    }
    if (type == 'disc') {
      this.searchBtnForDiscMto = false;
      this.cleatBtnDiscMto = true;
    }
    this.openfilterMto = false;
  }

  clearPriceInputMto() {
    this.searchFormMto.get('minValue').reset();
    this.searchFormMto.get('maxValue').reset();
    this.cleatBtnPriceMto = false;
    this.searchBtnForPriceMto = true;
    this.productListMto = this.displayProductMto;
  }

  clearDiscInputMto() {
    this.searchFormMto.get('minDisc').reset();
    this.searchFormMto.get('maxDisc').reset();
    this.cleatBtnDiscMto = false;
    this.searchBtnForDiscMto = true;
    this.productListMto = this.displayProductMto;
    this.searchFormMto.patchValue({ minDisc: 0, maxDisc: 10 });
  }

  onChangeCheckBoxClickMto(event, value: string) {
    if (event == false) {
      this.selectedValuesMto = this.selectedValuesMto.filter(item => item !== value);
    } else {
      this.selectedValuesMto.push(value);
    }
    this.advanceSearchDataMto.offer = [];
    this.offerMto.forEach((element) => {
      if (this.selectedValuesMto.includes(element.schemeType)) {
        this.advanceSearchDataMto.offer.push(element.buyProductCode);
      }
    });
    this.buttonClickForSearchMto(null);
  }

  onCheckBoxClickMto(event: boolean, value: string) {
    this.productListForFilter2Mto = []
    if (event == false) {
      this.checkedValuesMto = this.checkedValuesMto.filter(item => item !== value);
    } else {
      this.checkedValuesMto.push(value);
    }
    this.productListForFiltersMto.forEach((element) => {
      this.checkedValuesMto?.forEach((value) => {
        if (element.food_Type == value) {
          this.productListForFilter2Mto.push(element)
        }
      })
    })
    this.productListMto = this.productListForFilter2Mto;
    if (this.checkedValuesMto.length == 0) {
      this.productListMto = this.displayProductMto;
    }
  }

  public setRowMto(_index: number) {
    this.makeProduct.selectedProduct = _index;
    this.selectedIndexMto = _index;
  }

  addFavouriteMto(product: Product) {
    product.cartProductType = "Make To Order";
    this.productService.addFavouriteProduct(product);
  }

  //Franchise
  createSetUpForm: UntypedFormGroup;
  uploadedFile: File;
  isDisabledSubmit: boolean = false;
  phoneNo: string = "";
  stateList: State[][] = [];
  districtList: NameWithIdDto[][] = [];
  cityList: City[][] = [];
  genders: string[] = ["Male", "Female", "Other"];
  currentDate: Date = new Date();
  maritialStatus: string[] = ["Single", "Married"];
  courseList: string[] = ["Vocational", "Under Graduate/Secondary School", "Graduate", "Post Graduate/Mastes"];
  occupationList: string[] = ["Student", "SelfEmployed", "GOVT", "PVT", "Others"];
  franchiseTypes: string[] = [];
  cities: City[] = [];
  isShowMasterFranchise: boolean = false;
  isShowFranchiseStore: boolean = false;
  isShowKiosk: boolean = false;
  visitors: number;
  franchiseTypeList: string[] = ['Cloud Bakery', 'Franchise Store', 'Kiosk'];
  franchiseSetupList: WebOrderFranchiseSetup[] = [];
  masterFranchiseList: WebOrderFranchiseSetup[] = [];
  franchiseStoreList: WebOrderFranchiseSetup[] = [];
  kioskList: WebOrderFranchiseSetup[] = [];
  cityControl = new UntypedFormControl();
  districtControl = new UntypedFormControl();
  cityControl2 = new UntypedFormControl();
  districtControl2 = new UntypedFormControl();
  selectedStateCode: number;
  selectedDistrictCode: number[];
  isReadonly: boolean = false;

  private onCreateFranchieSubscription: Subscription;
  private checkNumberVerfiedSubscription: Subscription;
  private getVisitorCountSubscription: Subscription;

  onCreateSetUp() {
    this.createSetUpForm = this.formBuilder.group({
      name: ["", Validators.required],
      gender: [],
      dob: ["", Validators.required],
      maritialStatus: [],
      phone: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
      email: [null, Validators.compose([Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      education: ["", Validators.required],
      occupation: [],
      franchiseType: [""],
      cityApplied: ["", Validators.required],
      areaApplied: [""],
    });
  }

  getWebOrderFranchiseSetUpList() {
    // this.webOrderFranchiseSubcription = this.franchaiseService.getWebOrderFranchiseSetup(this.apiFactory.getFranchiseSetupDetails, this.currentUrl)
    //   .subscribe((data) => {
    let data: WebOrderFranchiseSetup[] = this.fileService.getFileData("franchiseSetupList");
    if (data.length > 0) {
      this.franchiseSetupList = data;
      console.log(this.franchiseSetupList);

      this.franchiseSetupList.forEach(element => {
        if (element.franchiseType == this.franchiseTypeList[0]) {
          this.masterFranchiseList.push(element);
          this.isShowMasterFranchise = true;
        }
        else if (element.franchiseType == this.franchiseTypeList[1]) {
          this.franchiseStoreList.push(element);
          this.isShowFranchiseStore = true;
        }
        else if (element.franchiseType == this.franchiseTypeList[2]) {
          this.kioskList.push(element);
          this.isShowKiosk = true;
        }
      })
      this.franchiseSetupList.forEach(item => {
        item.cities.forEach(e => {
          if (!this.cities.map(f => f.refNo).includes(e.refNo))
            this.cities.push(e);
        })
        this.cities.sort((a, b) => (a.cityName.toLocaleUpperCase() > b.cityName.toLocaleUpperCase()) ? 1 : ((b.cityName.toLocaleUpperCase() > a.cityName.toLocaleUpperCase()) ? -1 : 0))
        if (!this.franchiseTypes.map(f => f).includes(item.franchiseType))
          this.franchiseTypes.push(item.franchiseType);
      });
      if (this.franchiseTypes.length == 1)
        this.createSetUpForm.get('franchiseType').setValue(this.franchiseTypes[0]);
      this.getStateList();
    }
    // });
  }

  getStateList() {
    // this.stateSubcription = this.storeService.getStateList().subscribe((data) => {
    let data: State[] = this.fileService.getFileData("stateList");
    if (data.length > 0) {
      this.stateList[0] = data.filter(e => this.masterFranchiseList.map(f => f.stateCode).includes(e.refNo));
      this.stateList[1] = data.filter(e => this.franchiseStoreList.map(f => f.stateCode).includes(e.refNo));
      this.stateList[2] = data.filter(e => this.kioskList.map(f => f.stateCode).includes(e.refNo));
    }
    // });
  }

  onStateSelect(stateCode: number, type: string) {
    if (type == this.franchiseTypeList[1])
      this.districtControl.reset();
    else if (type == this.franchiseTypeList[2])
      this.districtControl2.reset();

    this.selectedStateCode = stateCode;
    // this.districtListSubscription = this.storeService.getDistrictByStateCode(this.apiFactory.getDistrictByStateCode, stateCode)
    //   .subscribe((data) => {
    let data: LocationDetailsForStore = this.fileService.getFileData("locationDetailsForStore");
    let stateWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && e.stateCode == stateCode);
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = stateWiseList;
      this.districtList[0] = data.details.filter(e => this.masterFranchiseList.map(f => f.districtCode).includes(e.id));
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = stateWiseList;
      this.districtList[1] = data.details.filter(e => this.franchiseStoreList.map(f => f.districtCode).includes(e.id));
    }
    else if (type == this.franchiseTypeList[2]) {
      this.kioskList = stateWiseList;
      this.districtList[2] = data.details.filter(e => this.kioskList.map(f => f.districtCode).includes(e.id));
    }
    // });
  }

  onDistrictSelect(districtCode: number, type: string, index: number) {
    if (type == this.franchiseTypeList[1])
      this.cityControl.reset();
    else if (type == this.franchiseTypeList[2])
      this.cityControl2.reset();

    this.selectedDistrictCode = districtCode == 0 ? this.districtList[index].map(e => e.id) : [districtCode];
    // this.cityListSubscription = this.storeService.getCitiesByDistrictCode(this.apiFactory.getCitiesByDistrictCode, this.selectedDistrictCode)
    //   .subscribe((data) => {
    let data: City[] = this.fileService.getFileData("city");
    let cityWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && (districtCode == 0 ? e.stateCode == this.selectedStateCode : e.districtCode == districtCode));
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = cityWiseList;
      this.cityList[0] = data.filter(e => this.masterFranchiseList.map(f => f.cityCode).includes(e.refNo));
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = cityWiseList;
      this.cityList[1] = data.filter(e => this.franchiseStoreList.map(f => f.cityCode).includes(e.refNo));
    }
    else if (type == this.franchiseTypeList[2]) {
      this.kioskList = cityWiseList;
      this.cityList[2] = data.filter(e => this.kioskList.map(f => f.cityCode).includes(e.refNo));
    }
    // });
  }

  onCitySelect(cityCode: number, type: string) {
    let cityWiseList = this.franchiseSetupList.filter(e => e.franchiseType == type && (cityCode == 0 ? this.selectedDistrictCode.includes(e.districtCode) : e.cityCode == cityCode));
    if (type == this.franchiseTypeList[0]) {
      this.masterFranchiseList = cityWiseList;
    }
    else if (type == this.franchiseTypeList[1]) {
      this.franchiseStoreList = cityWiseList;
    }
    else if (type == this.franchiseTypeList[2]) {
      this.kioskList = cityWiseList;
    }
  }

  onCityClick(selectedCity: WebOrderFranchiseSetup) {
    console.log(selectedCity);
    this.createSetUpForm.patchValue({
      cityApplied: this.cities.filter(e => e.refNo == selectedCity.cityCode)[0]?.cityName,
      franchiseType: selectedCity.franchiseType,
      areaApplied: selectedCity.cityName,
    })
    this.scrollToElementById("name");
  }

  onCreateSetUpClick() {
    console.log(this.createSetUpForm);
    if (this.createSetUpForm.invalid) {
      this.toastrService.showErrorToast("Invalid form");
      return;
    }

    this.onCreateFranchieSubscription = this.franchaiseService.onCreateSetUp(this.apiFactory.insertFranchise, this.createSetUpForm)
      .subscribe((data) => {
        if (this.createSetUpForm.valid) {
          this.onCreateFranchieSubscription = this.franchaiseService.sendMessageByMailChimpFromFranchaise(this.apiFactory.sendMessageByMailChimpFromFranchaise, this.createSetUpForm, this.uploadedFile)
            .subscribe((data) => {
              this.toastrService.showSuccessToast("Thank you for your intrest. We shall talk to you soon.");
              this.isDisabledSubmit = false;
              this.createSetUpForm.reset();
            });
        }
      });
  }

  onVerifyClick() {
    if (this.createSetUpForm.get("phone").invalid) {
      this.toastrService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.createSetUpForm.get("phone").value,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      this.isDisabledSubmit = result;
      this.phoneNo = result.mblNo;
    });
  }

  checkOtp(value: string) {
    console.log(this.phoneNo);
    if (value.length == 10) {
      this.checkNumberVerfiedSubscription = this.franchaiseService.checkOTPSentOrNot(this.apiFactory.checkOTPSentOrNot, value).subscribe((data: string) => {
        if (data == "Yes") {
          this.isDisabledSubmit = true;
        }
        if (data == "No") {
          this.isDisabledSubmit = false;
        }
      });
    }
  }

  getVisitorCount() {
    this.getVisitorCountSubscription = this.userService.getVisitorCount(this.apiFactory.getVisitorCount, "franchise").subscribe((data: any) => {
      console.log("get Vistor Count franchise:::" + data);
      this.visitors = data;
    });
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    const element = document.getElementById(id);
    return element;
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView({ behavior: "smooth" });
    this.isReadonly = true;
  }

  //Store
  gpsLong: number[];
  gpsLati: number[];
  locationWithLongLat: LocationWithLongLat[] = [];
  locationDetailsList: LocationDetailsDto[] = [];
  k: number = 0;
  isShowOpenGoogle: boolean = false;
  stateListStore: State[] = [];

  private districtListSubscription: Subscription;
  private cityListSubscription: Subscription;
  private locationListSubscription: Subscription;

  getAllLocationsStore() {
    this.gpsLong = [];
    this.gpsLati = [];
    // this.districtListSubscription = this.storeService.getAllLocations(this.apiFactory.getAllLocations).subscribe((data: any) => {
    //   console.log(data)
    //   this.locationWithLongLat = data;
    this.locationWithLongLat = this.fileService.getFileData("locationWithLongLat");
    for (let j = 0; j < this.locationWithLongLat.length; j++) {
      this.locationDetailsList[j] = new LocationDetailsDto();
      this.locationDetailsList[j].locationName = this.locationWithLongLat[j].locationName;
      this.locationDetailsList[j].address = this.locationWithLongLat[j].address;
      this.locationDetailsList[j].pin = this.locationWithLongLat[j].pin;
      this.locationDetailsList[j].cityName = this.locationWithLongLat[j].cityName;
      this.locationDetailsList[j].lng = this.locationWithLongLat[j].longitude;
      this.locationDetailsList[j].lat = this.locationWithLongLat[j].latitude;
    }

    for (let j = 0; j < this.locationWithLongLat.length; j++) {
      if (Number(this.locationWithLongLat[j].latitude) != 0 && Number(this.locationWithLongLat[j].longitude) != 0) {
        this.gpsLati[this.k] = Number(this.locationWithLongLat[j].latitude);
        this.gpsLong[this.k] = Number(this.locationWithLongLat[j].longitude);
        this.k++;
      }
    }
    this.getStateListStore();
  }

  getStateListStore() {
    this.stateListStore = this.fileService.getFileData("stateList");
    // this.stateSubcription = this.storeService.getStateList().subscribe((data) => {
    //   this.stateListStore = data;
    // });
  }

  districtListStore: NameWithIdDto[] = [];
  cityListStore: NameWithIdDto[] = [];
  locListStore: NameWithIdDto[] = [];
  positionArray: LocationDetailsForStore;

  onStateSelectStore(stateCode: number) {
    this.isShowOpenGoogle = false;
    this.districtListSubscription = this.storeService.getDistrictByStateCode(this.apiFactory.getDistrictByStateCode, stateCode, this.currentUrl)
      .subscribe((data) => {
        this.districtListStore = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  onDistrictSelectStore(districtCode: number) {
    this.isShowOpenGoogle = false;
    this.cityListSubscription = this.storeService.getCityByDistrictCode(this.apiFactory.getCityByDistrictCode, districtCode, this.currentUrl)
      .subscribe((data) => {
        this.cityListStore = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  onCitySelectStore(cityCode: number) {
    this.isShowOpenGoogle = false;
    this.locationListSubscription = this.storeService.getLocationByCityCode(this.apiFactory.getLocationByCityCode, cityCode, this.currentUrl).subscribe((data) => {
      this.locListStore = data.details;
      this.locationDetailsList = data.locationDetails;
      this.positionArray = data;
      this.gpsLati = [];
      this.gpsLong = [];
      this.k = 0;
      for (let j = 0; j < this.locationDetailsList.length; j++) {
        if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
          this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
          this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
          this.k++;
        }
      }
      // this.onMapReady(this.map);
      this.show = true;
    });
  }

  onLocationSelectStore(locationCode: number) {
    this.isShowOpenGoogle = false;
    this.locationListSubscription = this.storeService.getLocationByLocationCode(this.apiFactory.getLocationWithPositionByLocationCode, locationCode, this.currentUrl)
      .subscribe((data) => {
        // this.locList = data.details;
        this.locationDetailsList = data.locationDetails;
        this.positionArray = data;
        this.gpsLati = [];
        this.gpsLong = [];
        this.k = 0;
        for (let j = 0; j < this.locationDetailsList.length; j++) {
          if (Number(this.locationDetailsList[j].lat) != 0 && Number(this.locationDetailsList[j].lng) != 0) {
            this.gpsLati[this.k] = Number(this.locationDetailsList[j].lat);
            this.gpsLong[this.k] = Number(this.locationDetailsList[j].lng);
            this.k++;
          }
        }
        // this.onMapReady(this.map);
        this.show = true;
      });
  }

  latitudeMap: number;
  longitudeMap: number;
  zoom: number;
  newDeliveryDetails: boolean = false;

  onPositionClick(lat: any, long: any) {
    this.isShowOpenGoogle = true;
    console.log(this.isShowOpenGoogle)
    this.gpsLati = [];
    this.gpsLong = [];
    if (Number(long) != 0 && Number(lat) != 0) {
      this.gpsLati[0] = Number(lat);
      this.gpsLong[0] = Number(long);

      this.latitudeMap = Number(lat);
      this.longitudeMap = Number(long);
    }
    // this.onMapReady(this.map);
  }

  getGoogleMap() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitudeMap = position.coords.latitude;
        this.longitudeMap = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitudeMap, this.longitudeMap);
      });
    }
  }

  @ViewChild("map", { static: true }) map: any;
  @ViewChild("search")
  public searchElementRef: ElementRef;
  mapReady(event: any) {
    this.map = event;
    const input = document.getElementById('Map-Search');
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(document.getElementById('Logout'));
  }

  markerDragEnd(event: any) {
    this.latitudeMap = event.coords.lat;
    this.longitudeMap = event.coords.lng;
    this.getAddress(this.latitudeMap, this.longitudeMap);
    this.createSetUpForm.patchValue({
      latitude: event.coords.lat,
      longitude: event.coords.lng,
    });
  }

  private geoCoder: any;
  addressMap: string;

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: { formatted_address: string; }[], status: string) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.addressMap = results[0].formatted_address;
          } else {
            //window.alert("No results found");
          }
        } else {
          //window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  onSearch() {
    let window: any;
    console.log("clicked")
    // let latlong = "";
    // this.gpsLati.forEach((e, index) => {
    //   latlong = latlong + e + "," + this.gpsLong[index] + "&";
    // })
    // latlong = latlong.substring(0,latlong.length-1)
    // console.log(latlong)
    //window.open("https://www.google.com/maps/search/?api=1&query=" + this.latitudeMap + "," + this.longitudeMap);
  }

  //Career
  showCareer: boolean = false;
  displayedColumns: string[] = ["position", "name", "place", "Submit"];
  dataSourceCareer;
  createCareerSetUpForm: UntypedFormGroup;
  careerSetupList: WebOrderCareerSetUp[] = [];
  readonly uploadFileSize: number = 10485760; //10mb to bytes
  readonly fileBigSize: string = "10Mb Max File Size..";
  uploadedFileCareer: File;
  uploadedFileNameCareer: string;
  showfileCareer: boolean = false;
  showApplayCareer: boolean = false;

  private onCreateCarrerClickSubscription: Subscription;

  handleFileInputCareer(files: FileList) {
    if (files[0].size > this.uploadFileSize) alert(this.fileBigSize);
    else {
      this.createSetUpForm.patchValue({
        documentPath: files.item(0).name,
      });
      this.uploadedFileCareer = files.item(0);
      this.uploadedFileNameCareer = files.item(0).name;
    }
  }

  removeSelectedFile() {
    this.uploadedFileNameCareer = null;
    this.uploadedFileCareer = undefined;
  }

  applayCareer(type: string, index: number) {
    this.uploadedFileCareer = new File([""], "");
    this.uploadedFileNameCareer = "";
    this.createCareerSetUpForm.get("documentPath").reset();
    this.createCareerSetUpForm.patchValue({
      position: this.careerSetupList[index].position,
      place: this.careerSetupList[index].place,
    });
    if (type) {
      this.showfileCareer = true;
    } else {
      this.showfileCareer = false;
    }
    this.scrollToElementById("contact");
  }

  onCareerCreateSetUp() {
    this.createCareerSetUpForm = this.formBuilder.group({
      name: ["", Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phone: [null, [Validators.required, Validators.pattern("[6-9][0-9]{9}")]],
      position: ["", Validators.required],
      place: ["", Validators.required],
      documentPath: ["", Validators.required],
    });
  }

  onCreateCareerSetUpClick() {
    if (this.createCareerSetUpForm.invalid) {
      this.toastrService.showErrorToast("Invalid form");
      return;
    }
    this.createCareerSetUpForm.patchValue({
      documentPath: this.uploadedFile.name,
    });
    this.onCreateCarrerClickSubscription = this.careerService.sendMessageByMailChimp(this.apiFactory.sendMessageByMailChimp, this.createCareerSetUpForm, this.uploadedFileCareer)
      .subscribe((data) => {
        if (this.createCareerSetUpForm.valid) {
          this.toastrService.showSuccessToast("Thank you for your intrest to work with us.");
          this.createCareerSetUpForm.reset();
        }
      });
  }

  getWebOrderCareerSetUpList() {
    // this.webOrderCareerSubcription = this.careerService.getWebOrderCareerSetup(this.apiFactory.getCareerSetupDetails, this.currentUrl)
    //   .subscribe((data) => {
    let data: WebOrderCareerSetUp[] = this.fileService.getFileData("careerSetupList");
    if (data.length > 0) {
      this.showCareer = true;
      this.careerSetupList = data;
      console.log(this.careerSetupList);
      this.careerSetupList.forEach(element => {
        if (element.description)
          element.description = "<li>" + element.description.replace('. ', ".<li>");
      })
      this.dataSourceCareer = this.careerSetupList;
    } else {
      this.showApplayCareer = false;
    }
    // });
  }

  // Contact-Us
  createSetUpFormContactUs: UntypedFormGroup;
  uploadedFileContactUs: File;
  isDisabledSubmitContactUs: boolean = false;
  phoneNoContactUs: string = "";
  feedbackFilesList: File[] = [];
  feedbackSetupList: FeedbackSetupWebDto[] = [];
  feedbackTypeList: any[] = [];
  feedbackSubList: any[] = [];
  feedbackList: any[] = [];

  //Subscription
  private onCreateSetUpClickContactUsSubscription: Subscription;
  private checkNumberVerfiedContactUsSubscription: Subscription;
  private webOrderFeedbackSubcription: Subscription;

  getWebOrderFeedbackSetUpList() {
    // this.webOrderFeedbackSubcription = this.contactService.getWebOrderFeedbackSetup(this.apiFactory.getFeedbackSetupDetails, this.currentUrl)
    //   .subscribe((data: FeedbackSetupWebDto[]) => {
    let data: FeedbackSetupWebDto[] = this.fileService.getFileData("feedBackSetupList");
    if (data.length > 0) {
      this.feedbackSetupList = data;
      this.feedbackSetupList.forEach(element => {
        if (!this.feedbackTypeList.some(e => e.grivenceTypeCode == element.grivenceTypeCode))
          this.feedbackTypeList.push(element);
      })
    } else {
      this.toastrService.showErrorToast('No data available');
    }
    // });
  }

  onFeedbackTypeSelect(feedbackTypeCode: number) {
    this.feedbackSubList = this.feedbackSetupList.filter(e => e.grivenceTypeCode == feedbackTypeCode);
  }

  onFeedbackSubTypeSelect(feedbackSubTypeCode: number) {
    this.feedbackList = this.feedbackSubList.filter(e => e.subGrivenceTypeCode == feedbackSubTypeCode);
  }

  onCreateSetUpContactUs() {
    this.createSetUpFormContactUs = this.formBuilder.group({
      name: ["", Validators.required],
      email: [null, Validators.compose([Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),]),],
      phone: ["", [Validators.required, Validators.pattern("[6-9]\\d{9}")]],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      grivenceTypeCode: ["", Validators.required],
      subGrivenceTypeCode: ["", Validators.required],
      grivenceCode: ["", Validators.required],
      image1: [],
      video1: [],
      fileImage1: [],
      fileVideo1: [],
    });
  }

  getFileDataContactUs(fileName: string) {
    if (!this.createSetUpFormContactUs.get(fileName).value) {
      return false;
    } else {
      return this.createSetUpFormContactUs.get(fileName).value;
    }
  }

  onChangeUploadFileContactUs(event: any, imageFile: string, image: string) {
    if (event) {
      const file = event.target.files && event.target.files[0];
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          let result = (<FileReader>event.target).result;
          this.createSetUpFormContactUs.get(imageFile).setValue(result);
          this.createSetUpFormContactUs.get(image).setValue(file);
        }
      }
    }
  }

  onRemoveFileClickContactUs(file: string, image: any) {
    this.createSetUpFormContactUs.get(image).reset();
    this.createSetUpFormContactUs.get(file).reset();
    const fileInputElement = document.getElementById(image) as HTMLInputElement;
    if (fileInputElement) {
      fileInputElement.value = ''; // Clear the file input value
    }
  }

  onCreateSetUpClickContactUs() {
    console.log(this.createSetUpFormContactUs);
    if (this.createSetUpFormContactUs.invalid) {
      this.toastrService.showErrorToast("Invalid form");
      return;
    }

    const imgFile: File = this.createSetUpFormContactUs.get('image1').value;
    const videoFile: File = this.createSetUpFormContactUs.get('video1').value;
    this.createSetUpFormContactUs.get('image1').setValue(null);
    this.createSetUpFormContactUs.get('video1').setValue(null);
    this.createSetUpFormContactUs.get('fileImage1').setValue(null);
    this.createSetUpFormContactUs.get('fileVideo1').setValue(null);

    this.onCreateSetUpClickContactUsSubscription = this.contactService.sendMessage(this.apiFactory.insertFeedback, this.createSetUpFormContactUs, imgFile, videoFile)
      .subscribe((data) => {
        if (this.createSetUpFormContactUs.valid) {
          this.onCreateSetUpClickContactUsSubscription = this.contactService.sendMessageByMailChimpFromContact(
            this.apiFactory.sendMessageByMailChimpFromContact, this.createSetUpFormContactUs, imgFile, videoFile).subscribe((data) => {
              this.toastrService.showSuccessToast("Thank you for contacting us. We shall assist you at the earliest.");
              this.isDisabledSubmitContactUs = false;
              this.createSetUpFormContactUs.reset();
              this.feedbackSubList = [];
              this.feedbackList = [];
            });
        }
      });
  }

  onVerifyClickContactUs() {
    if (this.createSetUpFormContactUs.get("phone").invalid) {
      this.toastrService.showErrorToast("Invalid Mobile No");
      return;
    }
    const dialogRef = this.dialog.open(VerifyMobileNoDialogComponent, {
      width: "250px",
      data: this.createSetUpFormContactUs.get("phone").value,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      this.isDisabledSubmitContactUs = result;
      this.phoneNoContactUs = result.mblNo;
    });
  }

  checkOtpContactUs(value: string) {
    console.log(this.phoneNoContactUs);
    if (value.length == 10) {
      this.checkNumberVerfiedContactUsSubscription = this.contactService.checkOTPSentOrNot(this.apiFactory.checkOTPSentOrNot, value)
        .subscribe((data: string) => {
          if (data == "Yes") {
            this.isDisabledSubmitContactUs = true;
          }
          if (data == "No") {
            this.isDisabledSubmitContactUs = false;
          }
        });
    }
  }

}
