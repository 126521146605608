import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { AdvanceSearchDTO } from "src/app/shared/models/advance-search-dto";
import { Cess } from "src/app/shared/models/cess";
import { Product } from "src/app/shared/models/product";
import { Tax } from "src/app/shared/models/tax";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";

@Component({
  selector: "app-product-filter",
  templateUrl: "./product-filter.component.html",
  styleUrls: ["./product-filter.component.scss"],
})
export class ProductFilterComponent implements OnInit {
  advSearchForm: UntypedFormGroup;
  min: number = 0;
  max: number = 100;
  offerControl = new UntypedFormControl();
  foodTypeControl = new UntypedFormControl();
  advanceSearchData: AdvanceSearchDTO = new AdvanceSearchDTO();
  offer: any;
  offerTypeArray: string[] = [];
  disbleBtn: boolean = null;
  resultProductList: Product[] = [];
  locationCode: number;
  taxList: Tax[] = [];
  cessList: Cess[] = [];
  foodTypes: string[] = [];
  disbleSearchBtn: boolean = false;

  //Subscription
  productMinMaxPriceSubscription: Subscription;
  promotionMasterSubscription: Subscription;
  searchProductsByObjSub: Subscription;
  getTaxSubscription: Subscription;
  getCessSubscription: Subscription;
  getFoodTypeSubscription: Subscription;
  
  constructor(
    private localStorage:LocalStorageService,
    private dialogRef: MatDialogRef<ProductFilterComponent>,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private productService: ProductService,
    private apiFactory: APIFactoryEndPoints,
    private toast: ToastService,
    private cdr: ChangeDetectorRef,
    
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.locationCode = JSON.parse(this.localStorage.getItem("location_code"));

    this.productMinMaxPriceSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.productMinMaxPrice)
      .subscribe((data) => {
        this.min = data[0][0];
        this.max = data[0][1];

        // this.advanceSearchData.minPrice = this.min;
        // this.advanceSearchData.maxPrice = this.max;
      });
    if (this.dialogData.advSearchInput) {
      this.advanceSearchData = this.dialogData.advSearchInput;
    }

    this.advanceSearchData.offer = [];
    this.promotionMasterSubscription = this.productService.getPromostionMaster(this.apiFactory.getPromotionMasterData,
        this.locationCode
      )
      .subscribe((data) => {
        this.offer = data;
        this.offer.forEach((offerObj) => {
          if (!this.offerTypeArray.includes(offerObj.schemeType)) {
            this.offerTypeArray.push(offerObj.schemeType);
          }
        });
      });
  }

  ngOnInit(): void {
    this.getFoodType();
    this.onAdvSearchFormSetUp();
    this.getAllTax();
    this.getAllCess();
    this.cdr.detectChanges();
  }

  getFoodType() {
    this.getFoodTypeSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getFoodType)
      .subscribe((data) => {
        if (data.length > 0) {
          this.foodTypes = data;
        }
      });
  }

  getAllTax() {
    this.getTaxSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllTax)
      .subscribe((data) => {
        if (data.length > 0) {
          this.taxList = data;
        }
      });
  }

  getAllCess() {
    this.getCessSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllCess)
      .subscribe((data) => {
        if (data.length > 0) {
          this.cessList = data;
        }
      });
  }

  onAdvSearchFormSetUp() {
    this.advSearchForm = this.formBuilder.group({
      minValue: [this.min],
      maxValue: [this.max],
      minDiscRate: [0],
      minSlider: [],
      maxSlider: [],
      minDisc: [0],
      maxDisc: [99],
      minSlider2: [],
      maxSlider2: [],
    });

    if (this.dialogData.advSearchInput) {
      console.log(this.dialogData.advSearchInput);
      if (this.dialogData.disbleBtn) this.disbleSearchBtn = true;
      else this.disbleSearchBtn = false;

      this.advSearchForm.patchValue({
        minValue: this.dialogData.advSearchInput.minPrice,
        maxValue: this.dialogData.advSearchInput.maxPrice,
        minDisc: this.dialogData.advSearchInput.minDiscRate,
        maxDisc: this.dialogData.advSearchInput.maxDiscRate,
        minSlider: this.dialogData.advSearchInput.minPrice,
        maxSlider: this.dialogData.advSearchInput.maxPrice,
        minSlider2: this.dialogData.advSearchInput.minDiscRate,
        maxSlider2: this.dialogData.advSearchInput.maxDiscRate,
      });
      this.foodTypeControl.setValue(this.dialogData.advSearchInput.food_Type);
    }
  }

  closeClick() {
    if (this.disbleBtn === false) {
      this.dialogRef.close({
        resultProductList: this.resultProductList,
        advSearchInput: this.advanceSearchData,
        disbleBtn: this.disbleBtn,
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  validateMinMax(key: string, callOrigin: string) {
    key = this.validateNumber(key, "ts", "");

    if (callOrigin === "min") {
      if (Number.parseInt(key) > this.max) {
        key = (this.max - 1).toString();

        this.advSearchForm.get("minValue").setValue(key);
        this.advanceSearchData.minPrice = Number.parseInt(key);
      }
      this.advSearchForm.get("maxValue").setValue(Number.parseInt(key) + 1);
      this.advanceSearchData.maxPrice = Number.parseInt(key) + 1;
    } else if (callOrigin === "max") {
      if (Number.parseInt(key) > this.max) {
        key = this.max.toString();

        this.advSearchForm.get("maxValue").setValue(key);
        this.advanceSearchData.maxPrice = Number.parseInt(key);
      }
    }
  }

  validate(callOrigin: string, key: string) {
    if (callOrigin === "price") {
      if (
        Number.parseInt(this.advSearchForm.get("minValue").value) >
        Number.parseInt(key)
      ) {
        key = (
          Number.parseInt(this.advSearchForm.get("minValue").value) + 1
        ).toString();
        this.advSearchForm.get("maxValue").setValue(key);
      }
    } else if (callOrigin === "disc") {
      if (
        Number.parseInt(this.advSearchForm.get("minDisc").value) >
        Number.parseInt(key)
      ) {
        key = (
          Number.parseInt(this.advSearchForm.get("minDisc").value) + 1
        ).toString();
        this.advSearchForm.get("maxDisc").setValue(key);
      }
    }
  }

  onInputChange(event: any, callOrigin: string) {
    console.log(this.advSearchForm.get("minSlider").value);
    this.disbleSearchBtn = true;
    if (callOrigin === "min") {
      if (
        this.advSearchForm.get("minSlider").value >
        this.advSearchForm.get("maxValue").value
      ) {
        this.advSearchForm.get("maxValue").setValue(this.max);
      }
    }
  }

  validateNumber(key: string, callOrigin: string, target: string) {
    this.disbleSearchBtn = true;
    if (Number.parseInt(key) <= 0) {
      key = "1";
    }

    let reg = null;
    if (callOrigin === "template") {
      reg = /^\d{0,2}(?:[.,]\d{1,2})?$/;
    } else {
      reg = /^\d*(?:[.,]\d{1,2})?$/;
    }

    if (!reg.test(key)) {
      let count = 0;
      if (key.includes(".")) {
        count++;
      }

      let lastIndex = key.length - 1;
      if (count == 1 && key.charAt(lastIndex) == ".") {
      } else {
        key = key.substring(0, lastIndex);
      }
    }

    if (callOrigin === "template") {
      // this.advSearchForm.get('minDiscRate').patchValue(key);

      if (target === "min") {
        this.advSearchForm.get("minDisc").patchValue(key);
      } else if (target === "max") {
        this.advSearchForm.get("maxDisc").patchValue(key);
      }
    } else {
      return key;
    }
  }

  onOfferSelect(value: any) {
    console.log(value);
    this.advanceSearchData.offer = [];
    if (value == "none") {
      this.advanceSearchData.offer = [];
    } else {
      this.disbleSearchBtn = true;
      this.offer.forEach((element) => {
        if (element.schemeType == value)
          this.advanceSearchData.offer.push(element.buyProductCode);
      });
    }
  }

  onFoodTypeSelect(value: string) {
    console.log(value);
    this.advanceSearchData.food_Type = null;
    if (value == "none") {
      this.advanceSearchData.food_Type = null;
    } else {
      this.disbleSearchBtn = true;
      this.advanceSearchData.food_Type = [value];
    }
  }

  okClick(
    minValue: number,
    maxValue: number,
    minDiscRate: number,
    maxDiscRate: number
  ) {
    console.log(minValue, maxValue);
    this.advanceSearchData.locationCode = this.locationCode;
    if (maxValue != 0) {
      this.advanceSearchData.maxPrice = maxValue;
    } else {
      this.advanceSearchData.maxPrice = this.max;
    }
    if (minValue != 0) {
      this.advanceSearchData.minPrice = minValue;
    }
    if (minDiscRate != 0) {
      this.advanceSearchData.minDiscRate = minDiscRate;
    }
    if (maxDiscRate != 0) {
      this.advanceSearchData.maxDiscRate = maxDiscRate;
    }
    // this.advanceSearchData.minDiscRate = this.advSearchForm.get('minDiscRate').value;

    if (this.disbleBtn === false) {
      this.dialogRef.close({
        resultProductList: this.resultProductList,
        advSearchInput: this.advanceSearchData,
        disbleBtn: this.disbleBtn,
      });

      return;
    }
    console.log(this.advanceSearchData);
    this.searchProductsByObjSub = this.productService
      .searchProductsByObj(
        this.apiFactory.searchProductsByObj,
        this.advanceSearchData
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data) {
            this.resultProductList = data;
            this.resultProductList.forEach((product) => {
              this.taxList.forEach((tax) => {
                if (product.gst_Rate_Code == tax.refNo) {
                  product.tax = tax;
                }
              });
              this.cessList.forEach((cess) => {
                if (product.cess_Rate_Code == cess.refNo) {
                  product.cess = cess;
                }
              });
            });
            if (this.disbleBtn === null) {
              this.disbleBtn = true;
            }
            this.dialogRef.close({
              resultProductList: this.resultProductList,
              advSearchInput: this.advanceSearchData,
              disbleBtn: this.disbleBtn,
            });
          }
        },
        (err) => {
          if (err.error) {
            this.toast.showErrorToast(err.error.message);
          }
        }
      );
  }

  cancelClick() {
    // this.ngOnInit();

    this.advSearchForm.reset();

    this.offerControl.reset();
    this.foodTypeControl.reset();

    this.advanceSearchData = new AdvanceSearchDTO();
    this.advanceSearchData.offer = [];
    // this.advanceSearchData.minPrice = this.min;
    // this.advanceSearchData.maxPrice = this.max;
    this.resultProductList = [];

    this.disbleBtn = false;
    this.disbleSearchBtn = false;
  }

  Apply() {}
  Close() {
    this.dialog.closeAll();
  }
}
