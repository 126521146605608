import { Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DialogPosition, MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { APIFactoryEndPoints } from "src/app/common/apifactory-end-points";
import { LocalStorageService } from "src/app/common/LocalStorageService";
import { AdvanceSearchDTO } from "src/app/shared/models/advance-search-dto";
import { MakeProductDto } from "src/app/shared/models/makeproductdto";
import { MakeToOrderSummary } from "src/app/shared/models/maketoordersummary";
import { Product } from "src/app/shared/models/product";
import { ProductGroupWithIdAndName } from "src/app/shared/models/productgroupwithidandname";
import { ProductSubGroupDtoWithIdName } from "src/app/shared/models/productsubgroupdtowithidname";
import { AuthService } from "src/app/shared/services/auth.service";
import { MakeCakeService } from "src/app/shared/services/make-cake.service";
import { PinDialogService } from "src/app/shared/services/pin-dialog.service";
import { ProductService } from "src/app/shared/services/product.service";
import { ToastService } from "src/app/shared/services/toastr.service";
import { environment } from "src/environments/environment";
import { getWindow } from "ssr-window";
import { ProductDialogComponent } from "../product-dialog/product-dialog.component";
import { MakeToOrderPinDialogComponent } from "../product/make-to-order-pin-dialog/make-to-order-pin-dialog.component";
import { PinDialogComponent } from "../product/pin-dialog/pin-dialog.component";
import { ProductFilterComponent } from "../product/product-filter/product-filter/product-filter.component";
import { MatrixDto } from "src/app/shared/models/matrix-dto";
import { Tax } from "src/app/shared/models/tax";
import { Cess } from "src/app/shared/models/cess";
import { ProductsWithWebOrderOffers } from "src/app/shared/models/productswithweborderoffers";
import { FileService } from "src/app/common/File-Service";
import { ProductSubGroup } from "src/app/shared/models/productsubgroup";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ViewOfferSetUpDto } from "src/app/shared/models/viewoffersetupdto";
import { OfferDetailsDialogComponent } from "../product/offer-details-dialog/offer-details-dialog.component";
import { GoogleCustomer } from "src/app/shared/models/googlecustomer";


@Component({
  selector: "app-make-cake",
  templateUrl: "./make-cake.component.html",
  styleUrls: ["./make-cake.component.scss"],
})
export class MakeCakeComponent implements OnInit, OnDestroy {

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  webUrl: string;
  createSetUpForm: UntypedFormGroup;
  matrixList: MatrixDto[] = [];
  subGroupList: ProductSubGroupDtoWithIdName[] = [];
  show: boolean = false;
  subGroupShow: boolean = false;
  loading = false;

  makeToorderSummary: MakeToOrderSummary = new MakeToOrderSummary();
  makeProductSummary: MakeToOrderSummary = new MakeToOrderSummary();
  brands = ["All", "Apple", "Realme", "Nokia", "Motorolla"];
  selectedBrand: "All";

  page = 1; //demo
  pageNo: number = 0;

  productList: Product[] = new Array(1200);
  product: Product = new Product();

  makeYourGroupId: number;
  makeYourSubGroupId: number;
  makeProduct: MakeProductDto = new MakeProductDto();
  selectedIndex: any;
  pincode: string;
  selfLife: any;
  disbleBtn: boolean = false;
  companyCode: number;
  currentUrl: string;
  serverUrl: string;
  sharingUrl: string = '';

  groupClickId: number;
  subGroupClickId: number;

  defaultCake: string = "product/defaultCake.webp";

  // FILTER //

  productWithOffers: ProductsWithWebOrderOffers[];
  offerControl = new UntypedFormControl();
  locationCode: number;
  minPrice: number;
  maxPrice: number;
  minDiscRate: number;
  maxDiscRate: number;
  min: number;
  max: number;
  offer: any;
  advanceSearchData: AdvanceSearchDTO = new AdvanceSearchDTO();
  offerTypeArray: string[] = [];
  flavoursArray: string[] = [];
  foodTypes: string[] = ["Veg", "Non-Veg"];
  taxList: Tax[] = [];
  cessList: Cess[] = [];
  resultProductList: Product[] = [];
  values: string[] = [];
  foodType: string[] = [];
  openfilter: boolean = false;
  searchBtnForDisc: boolean = true;
  cleatBtnDisc: boolean = false;
  searchForm: UntypedFormGroup;
  radioStatus: boolean;
  checkedStatus: boolean;
  checkedValues: string[] = [];
  selectedValues: string[] = [];

  makeToOrderGroupList: ProductGroupWithIdAndName[] = [];
  allSubGroupListMto: ProductSubGroup[] = [];
  allProductListMto: Product[] = [];

  category: string;
  code: number;
  filter: string;

  deliveryType: string;
  googleCustomer: GoogleCustomer;

  // Subscription  // 
  private makeToOrderGroupSubcription: Subscription;
  private subGroupSubscription: Subscription;
  private productSubscription: Subscription;
  private productSubcription: Subscription;
  private webOrderBestProductSubcription: Subscription;
  private productMinMaxPriceSubscription: Subscription;
  private promotionMasterSubscription: Subscription;
  private getFoodTypeSubscription: Subscription;
  private getTaxSubscription: Subscription;
  private getCessSubscription: Subscription;
  private offerSubscription: Subscription;
  private searchProductsByObjSub: Subscription;

  constructor(
    private localStorage: LocalStorageService,
    public authService: AuthService,
    private productService: ProductService,
    private toastrService: ToastService,
    private apiFactory: APIFactoryEndPoints,
    private makecakeService: MakeCakeService,
    public dialog: MatDialog,
    private pincodeDialogService: PinDialogService,
    private navRoute: Router,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private fileService: FileService,
  ) {
    this.serverUrl = this.apiFactory.getServerUrl;
    this.locationCode = JSON.parse(this.localStorage.getItem("location_code"));
    this.deliveryType = JSON.parse(this.localStorage.getItem("delivery_type"));
    this.googleCustomer = JSON.parse(this.localStorage.getItem("google_loggedIn_details"));

    this.route.queryParams.subscribe((paramMap) => {
      if (paramMap["locCode"])
        this.localStorage.setItem("orderFromTvLocation", JSON.stringify(Number(paramMap["locCode"])));
      else
        this.localStorage.setItem("orderFromTvLocation", JSON.stringify(0));

      console.log(this.localStorage.getItem("orderFromTvLocation"));
    });
  }

  ngOnInit() {
    // this.getSummaryDetails();
    this.currentUrl = getWindow().location.hostname;
    this.sharingUrl = getWindow().location.origin + '/make-product' + '/';
    this.webUrl = environment.cartApiUrl;
    this.companyCode = JSON.parse(this.localStorage.getItem("company_code"));
    this.route.queryParams.subscribe(params => {
      const hasCategory = 'category' in params;
      const hasCode = 'code' in params;
      const hasFilter = 'filter' in params;

      if (hasCategory || hasCode || hasFilter) {
        this.localStorage.removeItem('filterMtoItems');
        this.getAllProducts();
        this.category = params['category'];
        this.code = +params['code'];  // The + sign converts the string to a number
        this.filter = params['filter'];

        if (this.filter != 'offers') {
          this.filterProducts();
        }
      } else {
        this.getAllProducts();
      }
    });
  }

  ngOnDestroy() {
    if (this.makeToOrderGroupSubcription != undefined) {
      this.makeToOrderGroupSubcription.unsubscribe();
    }
    if (this.subGroupSubscription != undefined) {
      this.subGroupSubscription.unsubscribe();
    }
    if (this.productSubscription != undefined) {
      this.productSubscription.unsubscribe();
    }
    if (this.productSubcription != undefined) {
      this.productSubcription.unsubscribe();
    }
  }

  filterProducts(): void {
    this.productList = this.allProductListMto;
    this.groupClickId = null;
    this.subGroupClickId = null;

    if (this.code) {
      if (this.category == 'Group') {
        this.productList = this.productList.filter(product => product.productSubGroup.productGroup.refNo === this.code);
      } else if (this.category == 'Matrix') {
        this.productList = this.productList.filter(product => product.productSubGroup.productGroup.productMatrix.refNo === this.code);
      }
    }
    if (this.filter) {
      this.productList = this.applyIndexFilter(this.filter);
    }
    this.makeToOrderGroupList.forEach(e => e.collapsed = (this.productList.map(p => p.productSubGroup.productGroup.refNo).includes(e.refNo)));
    this.selectedFilters.subGroupCodes = [];
    this.selectedFilters.groupSubGroups = [];
    this.productList.forEach(p => {
      if (!this.selectedFilters.subGroupCodes.map(m => m).includes(p.productSubGroup.refNo)) {
        this.selectedFilters.subGroupCodes.push(p.productSubGroup.refNo);
      }
      if (!this.selectedFilters.groupSubGroups.map(g => g.refNo).includes(p.productSubGroup.refNo)) {
        this.selectedFilters.groupSubGroups.push(p.productSubGroup);
      }
    });
    this.selectedFilters.showClearAll = true;
  }

  applyIndexFilter(filter: string): any[] {
    switch (filter) {
      case 'under220':
        this.isCollapsed['price'] = !this.isCollapsed['price'];
        this.selectedFilters.cleatBtnPrice = true;
        this.selectedFilters.priceRange = { min: 1, max: 220 };
        this.searchForm.get('minValue').setValue(this.selectedFilters.priceRange.min);
        this.searchForm.get('maxValue').setValue(this.selectedFilters.priceRange.max);
        return this.productList.filter(product => product.net_Sale_Price <= 220 && product.productSubGroup.productGroup.productMatrix.refNo === 4);
      case 'under400':
        this.isCollapsed['price'] = !this.isCollapsed['price'];
        this.selectedFilters.cleatBtnPrice = true;
        this.selectedFilters.priceRange = { min: 1, max: 400 };
        this.searchForm.get('minValue').setValue(this.selectedFilters.priceRange.min);
        this.searchForm.get('maxValue').setValue(this.selectedFilters.priceRange.max);
        return this.productList.filter(product => product.net_Sale_Price <= 400 && product.productSubGroup.productGroup.productMatrix.refNo === 4);
      case 'combos':
        return this.productList.filter(product => product.productSubGroup.productGroup.productMatrix.refNo === 4 || product.productSubGroup.productGroup.productMatrix.refNo === 38);
      case 'partybox':
        return this.productList.filter(product => product.productSubGroup.productGroup.productMatrix.refNo === 70);
      case 'bulkbuy':
        return this.productList.filter(product => product.productSubGroup.productSubGroupName === 'Bulk Buy');
      default:
        return this.productList;
    }
  }

  getSummaryDetails() {
    this.makeProductSummary = this.pincodeDialogService.getMakeToOrderSummary();
    if (this.makeProductSummary != null) {
      this.onGroupSelect(this.makeProductSummary.makeProductDto.groupId);
      this.onSubGroupSelect(this.makeProductSummary.makeProductDto.subGroupId);
      this.setRow(this.makeProductSummary.makeProductDto.selectedProduct);
    }
  }

  getMatrixList() {
    let matrixId: number = JSON.parse(this.localStorage.getItem("mto_matrix_code"));
    let groupId: number = JSON.parse(this.localStorage.getItem("mto_group_code"));

    if (groupId) {
      this.getGroupByMatrixCode(matrixId);
      this.onGroupSelect(groupId);
    } else if (matrixId) {
      this.onMatrixSelect(matrixId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    this.makeToOrderGroupSubcription = this.productService.getMtoMatrixList(this.currentUrl).subscribe((data) => {
      this.matrixList = data;
    });
  }

  displayProduct: Product[] = new Array(1200);
  List: Product[] = new Array(1200);
  productListForFilters: Product[] = new Array(1200);
  onMatrixSelect(matrixCode: number) {
    this.localStorage.setItem("mto_matrix_code", JSON.stringify(matrixCode));
    this.localStorage.removeItem("mto_group_code");

    this.loading = true;
    this.productSubscription = this.productService.getProductDetailsByMatrix(this.apiFactory.getProductDetailsByMatrixCode, matrixCode, this.currentUrl)
      .subscribe((data) => {
        this.productList = data;
        this.productListForFilters = data;
        this.displayProduct = data;
        this.show = true;
        this.loading = false;
        var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
        var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
        console.log(`Max: ${Max}`);
        console.log(`Min: ${Min}`);
        this.searchForm.patchValue({ minValue: Min, maxValue: Max });
      });

    this.getGroupByMatrixCode(matrixCode);
  }

  getGroupByMatrixCode(matrixCode: number) {
    this.subGroupSubscription = this.productService.getGroupListByMatrix(this.apiFactory.getMtoGroupDetailsByMatrix, this.currentUrl, matrixCode)
      .subscribe((data) => {
        this.makeToOrderGroupList = data;
        this.subGroupShow = true;
      });
  }

  getMakeToOrderGroupList() {
    this.makeToOrderGroupList = this.fileService.getFileData("makeToOrderGroupList");
    this.allSubGroupListMto = this.fileService.getFileData("allSubGroupListMto");
    this.allProductListMto = this.fileService.getFileData("allProductListMto");

    this.selfLife = this.localStorage.getItem("min_selflife");

    let groupId: number = JSON.parse(this.localStorage.getItem("mto_group_code"));
    let subgroupId: number = JSON.parse(this.localStorage.getItem("mto_subgroup_code"));
    this.groupClickId = groupId;
    this.subGroupClickId = subgroupId;

    if (subgroupId != undefined) {
      this.getSubGroupByGroupCode(groupId);
      this.onSubGroupSelect(subgroupId);
    } else if (groupId != undefined) {
      this.onGroupSelect(groupId);
    } else {
      this.getWebOrderBestProductSetUpList();
    }

    // this.makeToOrderGroupSubcription = this.makecakeService.getMakeToOrderProductList(this.currentUrl).subscribe((data) => {
    //   this.MakeToOrderGroupList = data;
    // });
  }

  onGroupSelect(prodGroupId: number) {
    this.localStorage.setItem("mto_group_code", JSON.stringify(prodGroupId));
    this.groupClickId = prodGroupId;

    this.loading = true;
    // this.productSubscription = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    let data: Product[] = this.allProductListMto.filter(e => e.productSubGroup.productGroup.refNo == prodGroupId);

    this.productList = data;
    this.productListForFilters = data;
    this.displayProduct = data;
    this.show = true;
    this.loading = false;
    var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    // });

    this.makeProduct.groupId = prodGroupId;
    this.getSubGroupByGroupCode(prodGroupId);
  }

  getSubGroupByGroupCode(prodGroupId: number) {
    // this.subGroupSubscription = this.productService.getSubGroupDetailsByGroupCode(this.apiFactory.getSubGroupDetailsByGroupCode, prodGroupId)
    //   .subscribe((data) => {
    let data: ProductSubGroupDtoWithIdName[] = this.allSubGroupListMto.filter(e => e.productGroupCode == prodGroupId);
    this.subGroupList = data;
    this.subGroupShow = true;
    // });
  }

  onSubGroupSelect(prodSubGroupId: number) {
    this.localStorage.setItem("mto_subgroup_code", JSON.stringify(prodSubGroupId));
    this.subGroupClickId = prodSubGroupId;

    this.makeProduct.subGroupId = prodSubGroupId;
    this.loading = true;
    // this.productSubscription = this.productService.getProductDetailsBySubGroupCode(this.apiFactory.getProductDetailsBySubGroupCode, prodSubGroupId)
    //   .subscribe((data) => {
    let data: Product[] = this.allProductListMto.filter(e => e.productSubGroup.refNo == prodSubGroupId);

    this.productList = data;
    this.productListForFilters = data;
    this.displayProduct = data;
    this.show = true;
    this.loading = false;
    var Max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    var Min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    console.log(`Max: ${Max}`);
    console.log(`Min: ${Min}`);
    this.searchForm.patchValue({ minValue: Min, maxValue: Max });
    // this.getImages();
    // });
  }

  // getAllProducts() {
  //   this.loading = true;
  //   this.pageNo = this.pageNo + 1;
  //   this.productSubcription = this.productService.getProducts(this.pageNo).subscribe((data) => {
  //     this.productList = data;
  //     this.productListForFilters = data;
  //     this.displayProduct = data;
  //     this.loading = false;
  //   });
  // }

  getWebOrderBestProductSetUpList() {
    this.webOrderBestProductSubcription = this.productService.getWebOrderBestProductSetup(this.apiFactory.getBestMtoProducts, this.currentUrl)
      .subscribe((data) => {
        this.productList = data;
        this.productListForFilters = data;
        this.displayProduct = data;
        this.show = true;
        this.loading = false;
        console.log(this.productList);
      });
  }

  addFavourite(product: Product) {
    product.cartProductType = "Make To Order";

    this.productService.addFavouriteProduct(product);
  }

  addToCart(product: Product) {
    this.productService.addToCart(product);
  }

  viewProductDetails(id: number) {
    this.productSubcription = this.productService
      .getProductById(id)
      .subscribe((data) => {
        this.product = data;

        const dialogRef = this.dialog.open(ProductDialogComponent, {
          width: "450px",
          data: this.product,
        });
        dialogRef.afterClosed().subscribe((result) => { });
      });
  }

  setPinDetails(id: number) {
    this.makeToorderSummary.makeProductDto = this.makeProduct;
    // this.productService.setMakeProduct(this.makeProduct);

    this.productSubcription = this.productService
      .getProductById(id)
      .subscribe((data) => {
        this.product = data;
        this.makeToorderSummary.product = this.product;
        this.pincode = this.localStorage.getItem("pincode_item");
        if (this.pincode == null) {
          const dialogRef = this.dialog.open(MakeToOrderPinDialogComponent, {
            width: "300px",
            data: this.makeToorderSummary,
            panelClass: "custom-dialog-container",
          });

          dialogRef.afterClosed().subscribe((result) => { });
        } else {
          this.navRoute.navigate([
            "/make-product",
            this.makeToorderSummary.product.product_Code,
          ]);
        }
      });
  }

  public setRow(_index: number) {
    this.makeProduct.selectedProduct = _index;
    this.selectedIndex = _index;
  }

  openPinDialog() {
    this.pincode = this.localStorage.getItem("pincode_item");

    if (this.pincode == null) {
      const dialogRef = this.dialog.open(MakeToOrderPinDialogComponent, {
        width: "300px",
        data: this.makeToorderSummary,
        panelClass: "custom-dialog-container",
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(result);
        if (result?.result == true) {
          this.getMakeToOrderGroupList();
        }
      });
    } else {
      this.getMakeToOrderGroupList();
    }
    // }
  }

  openFilterDialog(): void {
    this.advanceSearchData.module = "WebMTO";

    const dialogPosition: DialogPosition = {
      top: "250px",
      right: "0px",
    };
    const dialogRef = this.dialog.open(ProductFilterComponent, {
      width: "220px",
      position: dialogPosition,
      data: { advSearchInput: this.advanceSearchData, disbleBtn: this.disbleBtn, },
      panelClass: 'custom-container'
    });

    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);
      if (data) {
        // console.log(data)
        let list = data.resultProductList;
        if (list.length > 0) {
          this.productList = list;
        }
        this.disbleBtn = data.disbleBtn;

        this.advanceSearchData = data.advSearchInput;
      }
    });
  }

  getImages() {
    this.productList.forEach((e) => {
      let file: Blob;
      this.productSubscription = this.productService
        .getImage(this.apiFactory.getServerUrl, e.product_Picture)
        .subscribe((response) => {
          if (response != "SORRY") {
            if (response.toString().includes("ArrayBuffer") == true) {
              file = new Blob([response], { type: "image/jpeg" });
              let fl1 = URL.createObjectURL(file);
              let fl2: SafeUrl = this.domSanitizer.bypassSecurityTrustUrl(fl1);
              e.image = fl2;
            }
          } else {
            this.productSubscription = this.productService
              .getImage(
                this.apiFactory.getServerUrl,
                "product/defaultCake.webp"
              )
              .subscribe((response) => {
                if (response.toString().includes("ArrayBuffer") == true) {
                  file = new Blob([response], { type: "image/jpeg" });
                  let fl1 = URL.createObjectURL(file);
                  let fl2 = this.domSanitizer.bypassSecurityTrustUrl(fl1);
                  e.image = fl2;
                }
              });
          }
        });
    });
  }

  onShareClick(product: Product) {
    var url = 'https://web.whatsapp.com/send'
    var text = getWindow().location.origin + '/make-product' + '/' + product.product_Code;
    console.log(text)
    window.open(url + '?text=' + text)
  }

  getMinMaxPrice() {
    this.productMinMaxPriceSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.productMinMaxPrice)
      .subscribe((data) => {
        this.min = data[0][0];
        this.max = data[0][1];
        this.searchForm.patchValue({ minValue: this.min, maxValue: this.max });
      });
  }

  getPromotionMasterData() {
    this.advanceSearchData.offer = [];
    this.promotionMasterSubscription = this.productService.getPromostionMaster(this.apiFactory.getPromotionMasterData,
      !this.locationCode ? 0 : this.locationCode
    )
      .subscribe((data) => {
        this.offer = data;
        this.offer.forEach((offerObj) => {
          if (!this.offerTypeArray.includes(offerObj.schemeType)) {
            this.offerTypeArray.push(offerObj.schemeType);
          }
        });
      });
  }

  getFoodType() {
    this.getFoodTypeSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getFoodType)
      .subscribe((data) => {
        if (data.length > 0) {
          this.foodTypes = data;
        }
      });
  }

  getAllTax() {
    this.getTaxSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllTax)
      .subscribe((data) => {
        if (data.length > 0) {
          this.taxList = data;
        }
      });
  }

  getAllCess() {
    this.getCessSubscription = this.productService
      .getMinMaxPrice(this.apiFactory.getAllCess)
      .subscribe((data) => {
        if (data.length > 0) {
          this.cessList = data;
        }
      });
  }
  getOfferDetails() {
    let productId: number[] = this.allProductListMto.map(element => element.product_Code);
    if (productId.length === 0) return;

    let type = this.deliveryType ? (this.deliveryType === 'storePickup' ? 'Pickup' : 'Delivery') : null;

    const fetchOfferDetails = (locId: number) => {
      this.offerSubscription = this.productService.getOfferDetails(this.apiFactory.getOfferDetails, locId, productId, this.currentUrl)
        .subscribe((data: any) => {
          this.processOfferDetails(data);
        });
    };

    if (!type) {
      fetchOfferDetails(0);
      return;
    }

    this.offerSubscription = this.productService.getOfferAllowFor(this.apiFactory.getOfferAllowFor, this.currentUrl, type)
      .subscribe((data: string) => {
        if (data === 'Not Available') {
          console.log("No offers available.");
          return;
        }

        let locId = 0;
        if (data === 'allowForWebsite') {
          locId = this.googleCustomer?.locationCode || 0;
        } else if (data === 'allowForLocation') {
          locId = JSON.parse(this.localStorage.getItem('location_code')) || 0;
        }
        fetchOfferDetails(locId);
      });
  }

  processOfferDetails(data: any) {
    this.productWithOffers = data;
    console.log(this.productWithOffers);

    this.offerTypeArray = [];

    if (this.productWithOffers.length > 0) {
      this.productWithOffers.forEach(element => {
        element.offerDetails.forEach(offerDetails => {
          if (!this.offerTypeArray.includes(offerDetails.schemeType)) {
            this.offerTypeArray.push(offerDetails.schemeType);
          }
        });
      });
    }

    [this.allProductListMto, this.productList].forEach(productList => {
      productList.forEach(element => {
        let arr = this.productWithOffers.filter(offer => offer.productCode === element.product_Code);
        if (arr.length !== 0) {
          element.offerDetails = arr[0].offerDetails;
          element.offers = arr[0].offers;
        }
      });
    });

    if (this.filter === 'offers') {
      this.isCollapsed['offer'] = !this.isCollapsed['offer'];
      this.selectedFilters.offers = ['Buy X get Y Free'];
      this.productList = this.allProductListMto.filter(product =>
        product.offerDetails?.some(offerDetails => offerDetails.schemeType === 'Buy X get Y Free')
      );
    }
    if (!this.filter && this.selectedFilters.offers.length > 0) {
      this.applyFilters();
      this.expandAndCollapseAllFilters();
    }
    console.log(this.productList);
  }

  viewOfferDetail(offers: ViewOfferSetUpDto[]) {
    console.log(offers);
    let dialogRef = this.dialog.open(OfferDetailsDialogComponent, {
      width: '550px',
      minHeight: '18%',
      maxWidth: '90%',
      maxHeight: '70%',
      autoFocus: false,
      data: { offerData: offers },
    });

    dialogRef.afterClosed().subscribe((res) => { });
  }

  //  FILTER   //
  selectedFilters = {
    priceRange: { min: 0, max: 1000 },
    flavours: [],
    foodTypes: [],
    offers: [],
    deliveryWithin: [],
    subGroupCodes: [],
    groupSubGroups: [],
    showClearAll: false,
    cleatBtnPrice: false
  };

  isCollapsed = {
    price: true,
    flavour: true,
    foodType: true,
    offer: true,
    deliveryWithin: true
  };
  activeSort: string = '';
  deliveryWithinOptions = [
    { label: 'Today', value: 0 },
    { label: 'Tomorrow', value: 1 },
    { label: 'In 2 Days', value: 2 },
    { label: 'In 3 Days', value: 3 },
    { label: 'After 3 Days', value: 4 }
  ];

  getAllProducts() {
    this.makeToOrderGroupList = this.fileService.getFileData("makeToOrderGroupList");
    this.allSubGroupListMto = this.fileService.getFileData("allSubGroupListMto");
    this.allProductListMto = this.fileService.getFileData("allProductListMto");
    console.log(this.allProductListMto)
    this.makeToOrderGroupList.forEach(e => e.subGroupList = this.allSubGroupListMto.filter((f) => f.productGroupCode == e.refNo));
    this.productList = this.allProductListMto;
    this.max = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a > b ? a : b, 0);
    this.min = this.productList.map(p => +p.net_Sale_Price).reduce((a, b) => a < b ? a : b);
    this.show = true;
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.searchFormSetUp();
    this.setLocalStorageData();
    this.getOfferDetails();
  }

  setLocalStorageData() {
    if (this.localStorage.getItem('filterMtoItems')) {
      this.selectedFilters = JSON.parse(this.localStorage.getItem('filterMtoItems'));
      this.searchForm.patchValue({ minValue: this.selectedFilters.priceRange.min, maxValue: this.selectedFilters.priceRange.max });
      this.applyFilters();
      this.expandAndCollapseAllFilters();
    }
  }

  expandAndCollapseAllFilters() {
    if (this.selectedFilters.showClearAll) {
      this.selectedFilters.flavours.length > 0 ? this.toggleSection('flavour') : '';
      this.selectedFilters.foodTypes.length > 0 ? this.toggleSection('foodType') : '';
      this.selectedFilters.offers.length > 0 ? this.toggleSection('offer') : '';
      this.selectedFilters.deliveryWithin.length > 0 ? this.toggleSection('deliveryWithin') : '';
      this.selectedFilters.priceRange.min != this.min || this.selectedFilters.priceRange.max != this.max ? this.toggleSection('price') : '';
      console.log(this.selectedFilters.groupSubGroups)
      if (this.selectedFilters.groupSubGroups.length > 0)
        this.makeToOrderGroupList.forEach(e => e.collapsed = this.selectedFilters.groupSubGroups.some(f => f.productGroupCode == e.refNo));
    } else {
      this.isCollapsed.price = true;
      this.isCollapsed.flavour = true;
      this.isCollapsed.foodType = true;
      this.isCollapsed.offer = true;
      this.isCollapsed.deliveryWithin = true;
      this.makeToOrderGroupList.forEach(e => e.collapsed = false);
    }
  }

  searchFormSetUp() {
    this.searchForm = this.formBuilder.group({
      minValue: [this.min],
      maxValue: [this.max]
    });
  }

  onFilterChange(type: string, value: string, event: any) {
    if (event.target.checked) {
      this.selectedFilters[type].push(value);
    } else {
      const index = this.selectedFilters[type].indexOf(value);
      if (index > -1) {
        this.selectedFilters[type].splice(index, 1);
      }
    }
    this.applyFilters();
  }

  validateMinMax(value: string, type: string) {
    if (type === 'min' && +value > +this.searchForm.get('maxValue').value) {
      this.searchForm.get('minValue').setValue(+this.searchForm.get('maxValue').value - 1);
    } else if (type === 'max' && +value < +this.searchForm.get('minValue').value) {
      this.searchForm.get('maxValue').setValue(+this.searchForm.get('minValue').value + 1);
    }
    this.selectedFilters.cleatBtnPrice = false;
  }

  validate(callOrigin: string, key: string) {
    if (callOrigin === 'price') {
      if (Number.parseInt(this.searchForm.get('minValue').value) > Number.parseInt(key)) {
        key = (Number.parseInt(this.searchForm.get('minValue').value) + 1).toString();
        this.searchForm.get('maxValue').setValue(key);
      }
    }
  }

  applyPriceInput(type: string) {
    if (type === 'price') {
      this.selectedFilters.priceRange.min = this.searchForm.get('minValue').value;
      this.selectedFilters.priceRange.max = this.searchForm.get('maxValue').value;
      this.selectedFilters.cleatBtnPrice = true;
      this.applyFilters();
    }
  }

  clearPriceInput() {
    this.searchForm.get('minValue').reset();
    this.searchForm.get('maxValue').reset();
    this.selectedFilters.cleatBtnPrice = false;
    this.searchForm.patchValue({ minValue: this.min, maxValue: this.max });
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.applyFilters();
  }

  onOfferCheckBoxClick(event: boolean, value: string) {
    if (event) {
      this.selectedFilters.offers.push(value);
    } else {
      const index = this.selectedFilters.offers.indexOf(value);
      if (index > -1) {
        this.selectedFilters.offers.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  onFoodTypeCheckBoxClick(event: boolean, value: string) {
    if (event) {
      this.selectedFilters.foodTypes.push(value);
    } else {
      const index = this.selectedFilters.foodTypes.indexOf(value);
      if (index > -1) {
        this.selectedFilters.foodTypes.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  ondeliveryWithinCheckBoxClick(event: boolean, option: any) {
    if (event) {
      this.selectedFilters.deliveryWithin.push(option.value);
    } else {
      const index = this.selectedFilters.deliveryWithin.indexOf(option.value);
      if (index > -1) {
        this.selectedFilters.deliveryWithin.splice(index, 1);
      }
    }
    this.applyFilters();
  }

  onSubGroupChange(event: boolean, subGroupCode: number) {
    if (event) {
      this.selectedFilters.subGroupCodes.push(subGroupCode);
    } else {
      const index = this.selectedFilters.subGroupCodes.indexOf(subGroupCode);
      if (index > -1) {
        this.selectedFilters.subGroupCodes.splice(index, 1);
      }
    }
    this.selectedFilters.groupSubGroups = this.allSubGroupListMto.filter(f => this.selectedFilters.subGroupCodes.includes(f.refNo));
    this.applyFilters();
  }

  applyFilters() {
    this.selectedFilters.showClearAll = (this.selectedFilters.cleatBtnPrice || this.selectedFilters.flavours.length > 0 || this.selectedFilters.foodTypes.length > 0 || this.selectedFilters.offers.length > 0 || this.selectedFilters.subGroupCodes.length > 0 || this.selectedFilters.groupSubGroups.length > 0 || this.selectedFilters.deliveryWithin.length > 0)
    this.productList = this.allProductListMto.filter(product => {
      const matchesPrice = product.net_Sale_Price >= this.selectedFilters.priceRange.min && product.net_Sale_Price <= this.selectedFilters.priceRange.max;
      const matchesFlavour = this.selectedFilters.flavours.length === 0 || this.selectedFilters.flavours.includes(product.composition);
      const matchesFoodType = this.selectedFilters.foodTypes.length === 0 || this.selectedFilters.foodTypes.includes(product.food_Type);
      const matchesOffer = this.selectedFilters.offers.length === 0 || product.offerDetails?.some(offerDetails => this.selectedFilters.offers.includes(offerDetails.schemeType));
      const matchesSubGroup = this.selectedFilters.subGroupCodes.length === 0 || this.selectedFilters.subGroupCodes.includes(product.productSubGroup.refNo);
      const matchesGroupSubGroup = this.selectedFilters.groupSubGroups.length === 0 || this.selectedFilters.groupSubGroups.map(e => e.refNo).includes(product.productSubGroup.refNo);
      const matchesdeliveryWithin = this.selectedFilters.deliveryWithin.length === 0 || this.selectedFilters.deliveryWithin.includes(product.minimum_Order_Time) ||
        (this.selectedFilters.deliveryWithin.includes(4) && product.minimum_Order_Time > 3);
      return matchesPrice && matchesFlavour && matchesFoodType && matchesOffer && matchesSubGroup && matchesGroupSubGroup && matchesdeliveryWithin;
    });
    console.log(this.selectedFilters)
    this.localStorage.setItem('filterMtoItems', JSON.stringify(this.selectedFilters));
  }

  toggleSection(section: string): void {
    this.isCollapsed[section] = !this.isCollapsed[section];
  }

  removeSubGroupFilter(subGroupCode: number): void {
    const index = this.selectedFilters.groupSubGroups.map(e => e.refNo).indexOf(subGroupCode);
    if (index > -1) {
      this.selectedFilters.groupSubGroups.splice(index, 1);
    }
    this.selectedFilters.subGroupCodes = this.selectedFilters.subGroupCodes.filter(
      subgroup => this.selectedFilters.groupSubGroups.map(sub => sub.refNo).includes(subgroup));

    this.applyFilters();
  }

  isFilterChecked(type: string, value: any) {
    return this.selectedFilters[type].includes(value);
  }

  clearAllFilters(): void {
    this.selectedFilters = {
      priceRange: { min: 0, max: 1000 },
      flavours: [],
      foodTypes: [],
      offers: [],
      deliveryWithin: [],
      subGroupCodes: [],
      groupSubGroups: [],
      showClearAll: false,
      cleatBtnPrice: false
    };
    this.selectedFilters.cleatBtnPrice = false;
    this.searchForm.patchValue({ minValue: this.min, maxValue: this.max });
    this.selectedFilters.priceRange = { min: this.min, max: this.max };
    this.deliveryWithinOptions = [
      { label: 'Today', value: 0 },
      { label: 'Tomorrow', value: 1 },
      { label: 'In 2 Days', value: 2 },
      { label: 'In 3 Days', value: 3 },
      { label: 'After 3 Days', value: 4 }
    ];
    this.expandAndCollapseAllFilters();
    this.applyFilters();
  }

  sortProducts(criteria: string) {
    this.activeSort = criteria;
    if (criteria === 'lowToHigh') {
      this.productList.sort((a, b) => a.net_Sale_Price - b.net_Sale_Price);
    } else if (criteria === 'highToLow') {
      this.productList.sort((a, b) => b.net_Sale_Price - a.net_Sale_Price);
    } else if (criteria === 'recommended') {
      // Implement your recommended sorting logic here
    }
    const sortData = [];
    this.productList.forEach(e => {
      sortData.push(e)
    })
    this.productList = sortData;
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.viewport.checkViewportSize();
  }

}
