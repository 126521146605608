import { Routes, RouterModule } from "@angular/router";
import { MakeToOrderPaymentComponent } from './make-to-order-payment.component';
const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "make-to-order-payment-app",
                component: MakeToOrderPaymentComponent,
            },
        ],
    },
];

export const MakeToOrderPaymentRoutes = RouterModule.forChild(routes);