import { Routes } from "@angular/router";
import { IndexComponent } from "../../base/index/index.component";
import { CartPaymentComponent } from "./cart-payment/cart-payment.component";
import { CartProductsComponent } from "./cart-products/cart-products.component";
import { FavouriteProductsComponent } from "./favourite-products/favourite-products.component";
import { MakeProductComponent } from "./make-product/make-product.component";
import { PaymentConfirmationComponent } from "./payment-confirmation/payment-confirmation.component";
import { PaymentFailComponent } from "./payment-fail/payment-fail.component";
import { PaymentSuccessComponent } from "./payment-success/payment-success.component";
import { ProductDeliveryAddressComponent } from "./product-delivery-address/product-delivery-address.component";
import { ProductDetailComponent } from "./product-detail/product-detail.component";
import { ProductListComponent } from "./product-list/product-list.component";
import { ProductOrderSummaryComponent } from "./product-order-summary/product-order-summary.component";

export const ProductRoutes: Routes = [
  {
    path: "productss",
    children: [
      // {
      //   path: "",
      //   component: IndexComponent,
      // },
      {
        path: "all-products",
        component: ProductListComponent,
      },
      {
        path: "favourite-products",
        component: FavouriteProductsComponent,
      },
      {
        path: "cart-items",
        component: CartProductsComponent,
      },
      {
        path: "order-summary",
        component: ProductOrderSummaryComponent,
      },
      {
        path: "cart-payment",
        component: CartPaymentComponent,
      },
      {
        path: "delivery-address",
        component: ProductDeliveryAddressComponent,
      },
      {
        path: "payment-confirmation",
        component: PaymentConfirmationComponent,
      },
      {
        path: "payment-success",
        component: PaymentSuccessComponent,
      },
      {
        path: "payment-fail",
        component: PaymentFailComponent,
      },
      {
        path: "product/:id",
        component: ProductDetailComponent,
      },
      {
        path: "make-product/:id",
        component: MakeProductComponent,
      },
    
    ],
  },
];
