<div class="container">
    <app-navbar></app-navbar>

    <main>
        <router-outlet></router-outlet>
    </main>
    <app-loader-spinner></app-loader-spinner>
    <app-loader></app-loader>

</div>
