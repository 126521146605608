import { LocationDto } from "./locationdto";

export class WebOrderLocationSetup {
  refNo: number;
  pincode: number;
  pin: string;
  minSelfLife: number;
  // location: LocationDto;
  locationCode: number;
  locationName: string;
  companyCode: number;
  address: string;
  address1: string;
  address2: string;
  stateCode: number;
  parentLocationCode: number;
  minOrderAmount: number;
  minOrderTime: string;
  maxOrderTime: string;
  cityName: string;
  distanceInMeters:string;
  areaNames: string;
}
