import { Routes, RouterModule } from "@angular/router";
import { MakeToOrderAddressComponent } from './make-to-order-address.component';

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "make-to-order-address-app",
                component: MakeToOrderAddressComponent,
            },
        ],
    },
];

export const MakeToOrderAddressRoutes = RouterModule.forChild(routes);
