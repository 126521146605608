export class WalletOfferRequestDto {

    locationCode: number;
    amount: number;
    email: string;
    transactionPoint: string;
    applicableCustomer: string;
    transactionType: string;

}
