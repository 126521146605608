<div class="section-heading" style="display: flex;justify-content: center;">
    <h5>Offers</h5>
    <i [mat-dialog-close]="false" class="fa fa-close"
        style="color: white;font-size: 20px;cursor: pointer;display: flex;align-items: center;"></i>
</div>
<div style="height: 205px;overflow: auto;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
        <ng-container matColumnDef="offerName">
            <th mat-header-cell *matHeaderCellDef>Offer Name</th>
            <td mat-cell *matCellDef="let element">{{ element.schemeName }}</td>
        </ng-container>

        <ng-container matColumnDef="offerType">
            <th mat-header-cell *matHeaderCellDef>Offer Type</th>
            <td mat-cell *matCellDef="let element">{{ element.schemeType }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element; let i = index">
                <mat-radio-button style="width: 20px; height: 20px; accent-color: #db3f4e" [checked]="element.checked"
                    [value]="element.offerId" (click)="onChangeOffer(element)">
                </mat-radio-button>
                <!-- <mat-checkbox style="width: 20px; height: 20px; accent-color: #db3f4e" [checked]="element.checked"
                [value]="element.offerId" (change)="onChangeOffer($event,element)">
            </mat-checkbox> -->
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>

<mat-button class="btn btn-primary" style="margin-top: 20px; margin-left: 45%" [mat-dialog-close]="offer">OK
</mat-button>