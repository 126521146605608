<div class="container">
  <div class="mbr-section mbr-section__container mbr-section__container--middle">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 text-xs-center" id="div1_style">
          <h2 class="mbr-section-title h2_font_size">Wishlist</h2>
          <div class="hr"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="container" *ngIf="favoruiteProducts.length > 0">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-6 col-6" style="margin-bottom: 10px;"
        *ngFor="let product of favoruiteProducts; let i = index">
        <div class="item">
          <div class="mb-r">
            <div class="card card-cascade wider">
              <a *ngIf="product.cartProductType == 'Product'">
                <div class="view overlay hm-white-slight">
                  <img src="../../../../assets/img/{{ product.product_Picture }}" class="img-fluid"
                    alt="Image not found" width="360px" height="300px"
                    onerror="this.src='../../../../assets/img/defaultCake.webp';"
                    [routerLink]="['/product', product.product_Code]" />

                  <span>
                    <img *ngIf="product.food_Type == 'Veg'" src="../../../../assets/img/Veg.webp" alt=""
                      class="img-responsive img_align" />
                    <img *ngIf="product.food_Type == 'Non-Veg'" src="../../../../assets/img/Nonveg.webp" alt=""
                      class="img-responsive img_align" />
                  </span>

                  <span *ngIf="product.offers >= 1 && product.cartProductType == 'Product'">
                    <img class="img-responsive offer_align" src="../../../../assets/img/Offer 1.webp" alt=""
                      (click)="viewOfferDetail(product.offerDetails)" matTooltip="Offers" />
                  </span>
                </div>
              </a>

              <a [routerLink]="['/make-product', product.product_Code]"
                *ngIf="product.cartProductType == 'Make To Order'">
                <div class="view overlay hm-white-slight">
                  <img src="../../../../assets/img/{{ product.product_Picture }}" class="img-fluid"
                    alt="Image not found" width="360px" height="300px"
                    onerror="this.src='../../../../assets/img/defaultCake.webp';" />
                  <img *ngIf="product.food_Type == 'Veg'" class="img-responsive img_align"
                    src="../../../../assets/img/Veg.webp" alt="" />
                  <img *ngIf="product.food_Type == 'Non-Veg'" class="img-responsive img_align"
                    src="../../../../assets/img/Nonveg.webp" alt="" />
                  <a>
                    <div class="mask waves-effect waves-light" mdbRippleRadius></div>
                  </a>
                </div>
              </a>

              <div style="display: flex;justify-content: space-between;">
                <a placement="top" mdbTooltip="Remove Product" container="body"
                  (click)="removeFavourite(product.product_Code)">
                  <i class="fa fa-trash" style="font-size: 17px; color: #e91e63"></i>
                </a>
                <mat-icon [matMenuTriggerFor]="menu" id="icon_align">share</mat-icon>
                <mat-menu #menu="matMenu">
                  <share-buttons theme="circles-dark" [include]="['copy','whatsapp','facebook','email','telegram']"
                    [showIcon]="true" [showText]="false" [url]="product.sharingUrl" [description]="product.product_Name"
                    class="pt-5">
                  </share-buttons>
                </mat-menu>
              </div>

              <div class="card-body text-center no-padding">
                <p class="card-title product_name">
                  <strong style="height: 80%;">
                    <a [routerLink]="['/product', product.product_Code]" *ngIf="product.cartProductType == 'Product'"
                      style="color: #e91e63;">
                      {{product.product_Name }}</a>
                    <a [routerLink]="['/make-product', product.product_Code]"
                      *ngIf="product.cartProductType == 'Make To Order'" style="color: #e91e63;">
                      {{ product.product_Name}}</a>
                  </strong>
                  <strong class="price_style">
                    <span>₹ {{ product.net_Sale_Price | number: "1.2-2"}}</span>
                  </strong>
                </p>

                <div class="card-footer">
                  <span class="left">
                    <!-- <a placement="top" mdbTooltip="Remove Product" container="body"
                      (click)="removeFavourite(product.product_Code)">
                      <i class="fa fa-trash" style="font-size: 17px; color: #e91e63"></i>
                    </a> -->
                    <button type="button" class="add_to_cart_btn" (click)="addToCart(product, false)">Add to
                      Cart</button>
                  </span>

                  <span class="right">
                    <!-- <a placement="top" mdbTooltip="Add to Cart" container="body" (click)="addToCart(product, false)">
                      <i class="fa fa-shopping-cart" style="margin-left: 6px;color: #e91e63;font-size: 19px;"></i>
                    </a> -->
                    <button type="button" class="add_to_cart_btn" (click)="addToCart(product, true)">Buy Now</button>
                  </span>
                </div>
              </div>

              <!-- <div class="card-body text-center no-padding">
                <h5 class="card-title">
                  <strong>
                    <a [routerLink]="['/product', product.product_Code]" *ngIf="product.cartProductType == 'Product'">{{
                      product.product_Name }}</a>
                    <a [routerLink]="['/make-product', product.product_Code]"
                      *ngIf="product.cartProductType == 'Make To Order'">{{ product.product_Name }}</a>
                  </strong>
                </h5>

                <div class="card-footer">
                  <span>₹ {{ product.net_Sale_Price | number: "1.2-2" }}</span>
                  <span class="left">
                    <a placement="top" mdbTooltip="Remove Product" container="body"
                      (click)="removeFavourite(product.product_Code)">
                      <i class="fa fa-trash" style="font-size: 17px; color: #e91e63"></i>
                    </a>
                  </span>
                  <span class="right" style="margin-right: -25px">
                    <a placement="top" mdbTooltip="Add to Cart" container="body" (click)="addToCart(product)">
                      <i class="fa fa-shopping-cart" style="margin-left: 6px;color: #e91e63;font-size: 19px;"></i>
                    </a>
                  </span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="favoruiteProducts.length === 0" class="col-md-12">
    <h4 class="empty"><i class="i-style">Your Wishlist is Empty</i></h4>
    <button type="button" class="btn btn-primary"
      style="display: block;padding-top: 10px;padding-bottom: 10px;font-size: 16px;font-weight: 500;border-radius: 4px;margin-top: 5px;float: right;"
      (click)="goShopping()">
      Start Shopping
      <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
    </button>
    <!-- <a class="btn btn-continue pull-right" routerLink="/">
      Start Shopping
      <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
    </a> -->
  </div>

  <!-- <div class="container">
    <div style="text-align: center; margin-top: -16px;">
      <button (click)="openScanner()" class="btn btn-primary">Open QR Scanner</button>
    </div>

    <div *ngIf="isScannerVisible" class="scanner-wrapper">
      <ngx-scanner-qrcode #action="scanner" (event)="onEvent($event, action)"></ngx-scanner-qrcode>
      <button (click)="closeScanner()" class="btn btn-danger close-btn">Close</button>
    </div>

  </div>

  <div style="text-align: center; margin-top: -6px;">
    <button (click)="jopenScanner()" class="btn btn-primary">Open QR ZXScanner</button>
  </div>

  <div *ngIf="jisScannerVisible" class="scanner-wrapper">
    <zxing-scanner [device]="selectedDevice" (scanSuccess)="onCodeResult($event)"></zxing-scanner>
    <button (click)="jcloseScanner()" class="btn btn-danger close-btn">Close</button>
  </div>

  <div *ngIf="scannedResult">
    <p>Scanned QR Code: {{ scannedResult }}</p>
  </div>

  <div *ngIf="isLoading" class="loading-indicator">
    <p>⌛ Loading Camera...</p>
  </div> -->






  <!-- <div class="container" *ngIf="favoruiteProducts.length === 0">
  <app-no-products-found [title]="messageTitle" [description]="messageDescription"></app-no-products-found>
</div> -->