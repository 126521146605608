<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="topnav" id="myTopnav" style="position: fixed!important;;top: 0;width: 100%;z-index: 1050;">

    <a>
        <!-- <video src="../../../../assets/img/logo.mp4" style="height: 100px; width: 100px;margin-left: 0px!important;"
            (click)="toggle()" [routerLink]="['/']" alt="Image"></video> -->
        <img src="../../../../assets/img/logo.gif" style="height: 100px; width: 100px;margin-left: 0px!important;"
            (click)="toggle()" [routerLink]="['/']" alt="Image" />
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/']">
        {{ this.translate.data["HOME"] | translate }}
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/products']">
        {{this.translate.data["PRODUCTS"] | translate }}
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/cake-catalogue']">
        {{this.translate.data["MAKEYOURCAKE"] | translate }}
        <span class="sr-only">(current)</span>
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/customized-product']">
        {{this.translate.data["CUSTOMIZEDPRODUCTS"] | translate }}
        <span class="sr-only">(current)</span>
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/store']">
        {{this.translate.data["STORES"] | translate }}
        <span class="sr-only">(current)</span>
    </a>

    <a class="other" (click)="toggle()" data-target=".navbar-collapse" href="#"
        [routerLink]="['/franchise']">{{ this.translate.data["FRANCHISE"] | translate }}
        <span class="sr-only">(current)</span>
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/career']">{{
        this.translate.data["CAREERS"] | translate }}
        <span class="sr-only">(current) </span>
    </a>

    <a class="other" href="#" (click)="toggle()" [routerLink]="['/contact-us']">{{
        this.translate.data["CONTACTUS"] | translate }}
        <span class="sr-only">(current)</span>
    </a>

    <a *ngIf="isShowB2b" class="other" href="#" (click)="toggle()" (click)="navigateToB2b()">B2B
        <span class="sr-only">(current)</span>
    </a>

    <!-- <a class="display_none_mob other" href="#" (click)="toggle()"
        href="/products?category=Group&code=168">
        <div class="teacher-day-cake">
            Christmas Cake
        </div>
    </a> -->

    <ul class="new-nav">
        <div>
            <span #toggleButton (click)="onSearchClick($event)">
                <i class="fa fa-search" style="padding-right: 20px;color: white;cursor: pointer;">
                </i>
            </span>

            <div class="togglesearch" *ngIf="isSearchEnable" (click)="$event.stopPropagation()" #search>
                <input type="text" placeholder="Search Product" matInput [formControl]="myControl"
                    [(ngModel)]="productName" (keyup)="getProductFromInventorytypeNameWeb($any($event.target).value)">
            </div>
        </div>
        <div>
            <span href="#" [routerLink]="['/favourite-products']">
                <i class="fa fa-heart" style="padding-right: 20px;color: white;cursor: pointer;">
                    <span class="mt-2">
                        {{ productService.getLocalFavouriteProducts().length }}</span>
                </i>
            </span>
        </div>
        <div>
            <span href="#" (click)="navigateToCart()"
                style="color: white;padding-right: 20px;cursor: pointer;">
                <i class="fa fa-shopping-cart">
                    <span class="mt-2">
                        {{ productService.getLocalCartProductLength() }}</span>
                </i>
            </span>
        </div>

        <!-- *ngIf="showSignIn" -->
        <div class="nav-item active" *ngIf="showSignIn == true">
            <span [routerLink]="['/login', 'Login']" (click)="loginClick()"
                style="color: white;padding-right: 20px;cursor: pointer;">
                <i class="fa fa-sign-in mr-1"></i>
                {{ this.translate.data["SIGNIN"] | translate }}</span>
        </div>
        <div *ngIf="showSignIn == false" class="nav-item active">
            <span [matMenuTriggerFor]="menu" (click)="isMenuOpen = !isMenuOpen">
                {{ name }}
                <i class="fa" [ngClass]="isMenuOpen ? 'fa-caret-up' : 'fa-caret-down'"
                    style="margin-left: 5px;margin-top: 2px;"></i>
            </span>
            <mat-menu #menu="matMenu" (closed)="isMenuOpen = false">
                <a [routerLink]="['/profile']" mat-menu-item>Profile</a>
                <a [routerLink]="['/wallet']" mat-menu-item>Wallet</a>
                <a [routerLink]="['/invoice-history']" mat-menu-item>Order history</a>
                <a [routerLink]="['/payment-history']" mat-menu-item>Payment history</a>
                <!-- <a (click)="openDeliveryTypeDialog()" *ngIf="showSignIn == false" mat-menu-item>Change delivery
                    option</a> -->
                <a [routerLink]="['/my-coupon']" mat-menu-item>My Coupons</a>
                <a [routerLink]="['/feedback']" mat-menu-item>Feedback</a>
                <a [routerLink]="['/b2b-onboarding']" mat-menu-item>Become B2B Customer</a>
                <a (click)="logout(false)" *ngIf="showSignIn == false" mat-menu-item>Logout</a>
            </mat-menu>
        </div>

        <!-- <div class="nav-item">
            <a style="color: white;" class="nav-link" (click)="logout()" *ngIf="showSignIn == false">
                <i class="fa fa-sign-in mr-1"></i>{{ this.translate.data["LOGOUT"] | translate }}</a>
        </div> -->
    </ul>
    <a href="#" class="icon" (click)="myFunction();isShowMenu = !isShowMenu">
        <i *ngIf="!isShowMenu" class="fa fa-bars" style="font-size: 16px;"></i>
        <i *ngIf="isShowMenu" class="fa fa-window-close-o"
            style="position: fixed;right: 5px;top: 53px;font-size: 16px;"></i>
    </a>

    <!-- <a class="other icon" href="#" href="/products?category=Group&code=168">
        <div *ngIf="!isShowMenu" class="teacher-day-cake" style="position: fixed; right: 30px;top: 53px;">
            Christmas Cake
        </div>
        <div *ngIf="isShowMenu" class="teacher-day-cake" style="position: fixed;right: 30px;top: 51px;">
            Christmas Cake
        </div>
    </a> -->
</div>